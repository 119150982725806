import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Box, Container, Typography } from "@mui/material";
import DashboardLayout from "../../../layouts/DashboardLayout";
import {
  getApprovalMonthlySummary,
  getApprovalTopicStatus,
} from "../../../redux/actions";
import Header from "../../ApprovalStatus/MonthlyFacilityDetails/Header";
import Status from "../../ApprovalStatus/MonthlyFacilityDetails/Status";
import useStyles from "./styles";
import CeroTable from "../../../components/CeroTable";
import { STATUS } from "../../../redux/constants";
import { getCookie, setCookie } from "../../../services/cookie";

const ApprovalRequestDetails = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );
  const approvalSummaryData = useSelector(
    (state) => state.approval.approvalMonthlySummary.data
  );
  const approvalStatus = useSelector(
    (state) => state.approval.approvalStatus.status
  );
  const approvalStatusData = useSelector(
    (state) => state.approval.approvalStatus.data?.emission_list
  );
  const cardStatus = useSelector(
    (state) => state.approval.approvalStatus.data?.status
  );
  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));
  const { year } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const selectedMonth = queryParams.get("month");
  const selectedFacility = queryParams.get("facility");
  const selectedId = queryParams.get("id");
  const role = getCookie("role");

  useEffect(() => {
    dispatch(
      getApprovalMonthlySummary(
        selectedId,
        year,
        selectedMonth,
        selectedFacility
      )
    );
    dispatch(getApprovalTopicStatus(selectedId));
  }, [dispatch, selectedId, year, selectedMonth, selectedFacility]);

  const onApplyFilter = (filter) => {
    const currentFilter = encodeURI(
      `?${filter.month ? `&month=${filter.month}` : ""}${
        filter.facility ? `&facility=${filter.facility}` : ""
      }`
    );
    navigate(`/approval-status/${filter.year}${currentFilter}`);
  };

  const onSelectApprovalData = (item) => {
    if (role === "business_user") {
      if (
        [
          "upstream_t_and_d",
          "downstream_t_and_d",
          "business_travel",
          "employee_commute",
          "purchased_goods_and_services",
          "capital_goods",
          "fuel_and_energy_related_activities",
          "upstream_leased_asset",
          "downstream_leased_asset",
          "use_of_sold_products",
          "processing_of_sold_products",
          "franchises",
          "investments",
        ].includes(item.type)
      ) {
        navigate(
          `/pending-approvals-scope3/${selectedId}/${item.sub_category_type}/${item.type}`
        );
      } else {
        navigate(
          `/request-approvals/${selectedId}/${item.sub_category_type}/${item.type}`
        );
      }
    } else {
      if (item.sub_category_type === "environmental") {
        if (
          [
            "upstream_t_and_d",
            "downstream_t_and_d",
            "business_travel",
            "employee_commute",
            "purchased_goods_and_services",
            "capital_goods",
            "fuel_and_energy_related_activities",
            "upstream_leased_asset",
            "downstream_leased_asset",
            "use_of_sold_products",
            "processing_of_sold_products",
            "franchises",
            "investments",
          ].includes(item.type)
        ) {
          navigate(
            `/pending-approvals-scope3/${selectedId}/${item.sub_category_type}/${item.type}`
          );
        } else {
          navigate(
            `/pending-approvals/${selectedId}/${item.sub_category_type}/${item.type}`
          );
        }
      } else {
        navigate(
          `/pending-approval-details/${selectedId}/${item.sub_category_type}/${item.type}`
        );
      }
    }
  };

  const approvalTopicsColumn = [
    {
      columnKey: "sector",
      columnId: "sector",
      columnHeader: "Topic",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
  ];

  const getStatus = (item) => {
    if (item === "added") {
      return <div className={classes.added}>Added</div>;
    } else if (item === "approved") {
      return <div className={classes.approved}>Approved</div>;
    } else if (item === "audited") {
      return <div className={classes.audited}>Audited</div>;
    } else if (item === "verified") {
      return <div className={classes.verified}>Verified</div>;
    } else if (item === "review") {
      return <div className={classes.review}>Review</div>;
    } else {
      return <div className={classes.Inprogress}>In Progress</div>;
    }
  };

  const uniqueSubCategoryTypes = Array.isArray(approvalStatusData)
    ? [...new Set(approvalStatusData.map((item) => item.sub_category_type))]
    : [];

  useEffect(() => {
    setCookie("approvalDataLength", approvalStatusData?.length.toString());
  }, [approvalStatusData]);

  const getApprovalTopicsData = () =>
    approvalStatus === STATUS.SUCCESS
      ? uniqueSubCategoryTypes.map((subCategoryType) => {
          const dataForSubCategory = approvalStatusData
            .filter((item) => item.sub_category_type === subCategoryType)
            .map((item) => ({
              ...item,
              topic: item.sector ? item.sector : "",
              status: item.status ? getStatus(item.status) : "",
            }));
          return { subCategoryType, data: dataForSubCategory };
        })
      : [];
  const formatText = (text) => {
    return text
      .replace(/_/g, " ")
      .replace(/\b\w/g, (match) => match.toUpperCase());
  };

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <Header
          approveId={selectedId}
          onApplyFilter={onApplyFilter}
          selectedYear={year}
          selectedMonth={selectedMonth}
          selectedFacility={selectedFacility}
          facilitiesList={facilitiesList}
          // actions={approvalMonthlyData?.actions}
          // statusId={approvalMonthlyData?.monthly_approval_status_id}
        />
        <Status
          status={cardStatus}
          approvedBy={approvalSummaryData.approved_by_name}
          auditStatus={approvalSummaryData.audited_status}
          noOfTickets={approvalSummaryData.open_tickets}
          auditorAssigned={approvalSummaryData.audited_by_name}
          auditorStatus={approvalSummaryData.audited_status}
          // actions={approvalMonthlyData?.actions}
          period={approvalSummaryData.period}
          approval_cycle_type={approvalSummaryData.approval_cycle_type}
          audit_cycle_type={approvalSummaryData.audit_cycle_type}
          assessmentYear={approvalSummaryData?.assessment_year}
          facility={approvalSummaryData?.facility_name}
          auditedOn={approvalSummaryData.audited_on}
        />
        {getApprovalTopicsData().map(({ subCategoryType, data }) => (
          <Box key={subCategoryType}>
            <Typography variant="body1" className={classes.tableTitle}>
              {formatText(subCategoryType)}
            </Typography>
            <CeroTable
              columns={approvalTopicsColumn}
              data={data}
              hasMore={false}
              loading={false}
              useExtraStyles={true}
              onSelectRow={onSelectApprovalData}
              classes={{ tableContainer: classes.tableContainer }}
            />
          </Box>
        ))}
      </Container>
    </DashboardLayout>
  );
};

export default ApprovalRequestDetails;
