export const UpstreamTransportationFuel = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "fuel_source_name",
    columnId: "fuel_source_name",
    columnHeader: "Fuel Source",
  },
  {
    columnKey: "amount_fuel",
    columnId: "amount_fuel",
    columnHeader: "Amount of Fuel",
  },
  {
    columnKey: "co2",
    columnId: "co2",
    columnHeader: "co2",
  },
  {
    columnKey: "ch4",
    columnId: "ch4",
    columnHeader: "ch4",
  },
  {
    columnKey: "n2o",
    columnId: "n2o",
    columnHeader: "n2o",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];

export const UpstreamTransportationDistance = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "mode_of_transport_name",
    columnId: "mode_of_transport_name",
    columnHeader: "Mode of Transport",
  },
  {
    columnKey: "distance_travelled",
    columnId: "distance_travelled",
    columnHeader: "Distance Travelled",
  },
  {
    columnKey: "mass_purchased_goods",
    columnId: "mass_purchased_goods",
    columnHeader: "Mass of Purchased Goods",
  },
  {
    columnKey: "co2",
    columnId: "co2",
    columnHeader: "co2",
  },
  {
    columnKey: "ch4",
    columnId: "ch4",
    columnHeader: "ch4",
  },
  {
    columnKey: "n2o",
    columnId: "n2o",
    columnHeader: "n2o",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];

export const UpstreamTransportationSpend = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "mode_of_transport_name",
    columnId: "mode_of_transport_name",
    columnHeader: "Mode of Transport",
  },
  {
    columnKey: "total_spent",
    columnId: "total_spent",
    columnHeader: "Total spend",
  },
  {
    columnKey: "co2",
    columnId: "co2",
    columnHeader: "co2",
  },
  {
    columnKey: "ch4",
    columnId: "ch4",
    columnHeader: "ch4",
  },
  {
    columnKey: "n2o",
    columnId: "n2o",
    columnHeader: "n2o",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];
export const UpstreamTransportationRefrigerant = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "gas_type_name",
    columnId: "gas_type_name",
    columnHeader: "Gas type",
  },
  {
    columnKey: "refrigerant_name",
    columnId: "refrigerant_name",
    columnHeader: "refrigerant",
  },
  {
    columnKey: "amount_refrigerants",
    columnId: "amount_refrigerants",
    columnHeader: "Quantity of refrigerant leakage(unit)",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];

export const UpstreamDistributionSite = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "fuel_source_name",
    columnId: "fuel_source_name",
    columnHeader: "Fuel Source",
  },
  {
    columnKey: "amount_fuel",
    columnId: "amount_fuel",
    columnHeader: "Amount of Fuel",
  },
  {
    columnKey: "amount_electricity",
    columnId: "amount_electricity",
    columnHeader: "Amount of electiricity consumed(unit)",
  },
  {
    columnKey: "gas_type_name",
    columnId: "gas_type_name",
    columnHeader: "Gas type",
  },
  {
    columnKey: "refrigerant_name",
    columnId: "refrigerant_name",
    columnHeader: "refrigerant",
  },
  {
    columnKey: "amount_refrigerants",
    columnId: "amount_refrigerants",
    columnHeader: "Quantity of refrigerant leakage(unit)",
  },
  {
    columnKey: "co2",
    columnId: "co2",
    columnHeader: "co2",
  },
  {
    columnKey: "ch4",
    columnId: "ch4",
    columnHeader: "ch4",
  },
  {
    columnKey: "n2o",
    columnId: "n2o",
    columnHeader: "n2o",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];

export const UpstreamDistributionAverage = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "volume_stored",
    columnId: "volume_stored",
    columnHeader: "Volume of stored goods",
  },
  {
    columnKey: "emission_factor",
    columnId: "emission_factor",
    columnHeader: "Emission Factor",
  },
  {
    columnKey: "average_number_of_days",
    columnId: "average_number_of_days",
    columnHeader: "Average number of goods stored",
  },
  {
    columnKey: "co2",
    columnId: "co2",
    columnHeader: "co2",
  },
  {
    columnKey: "ch4",
    columnId: "ch4",
    columnHeader: "ch4",
  },
  {
    columnKey: "n2o",
    columnId: "n2o",
    columnHeader: "n2o",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];

export const BusinessTravelSpend = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "mode_of_transport_name",
    columnId: "mode_of_transport_name",
    columnHeader: "Mode of Transport",
  },
  {
    columnKey: "total_spent",
    columnId: "total_spent",
    columnHeader: "Total spend",
  },
  {
    columnKey: "co2",
    columnId: "co2",
    columnHeader: "co2",
  },
  {
    columnKey: "ch4",
    columnId: "ch4",
    columnHeader: "ch4",
  },
  {
    columnKey: "n2o",
    columnId: "n2o",
    columnHeader: "n2o",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },

  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];
export const BusinessTravelFuel = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "fuel_source_name",
    columnId: "fuel_source_name",
    columnHeader: "Fuel Source",
  },
  {
    columnKey: "amount_fuel",
    columnId: "amount_fuel",
    columnHeader: "Amount of Fuel",
  },
  {
    columnKey: "co2",
    columnId: "co2",
    columnHeader: "co2",
  },
  {
    columnKey: "ch4",
    columnId: "ch4",
    columnHeader: "ch4",
  },
  {
    columnKey: "n2o",
    columnId: "n2o",
    columnHeader: "n2o",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },

  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];

export const BusinessTravelDistance = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "mode_of_transport_name",
    columnId: "mode_of_transport_name",
    columnHeader: "Mode of Transport",
  },
  {
    columnKey: "distance_travelled",
    columnId: "distance_travelled",
    columnHeader: "Distance Travelled",
  },
  {
    columnKey: "co2",
    columnId: "co2",
    columnHeader: "co2",
  },
  {
    columnKey: "ch4",
    columnId: "ch4",
    columnHeader: "ch4",
  },
  {
    columnKey: "n2o",
    columnId: "n2o",
    columnHeader: "n2o",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];

export const EmployeeCommuteDistance = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "mode_of_transport_name",
    columnId: "mode_of_transport_name",
    columnHeader: "Mode of Transport",
  },

  {
    columnKey: "activity_type_name",
    columnId: "activity_type_name",
    columnHeader: "Activity Type",
  },
  {
    columnKey: "daily_one_way_distance",
    columnId: "daily_one-way_distance",
    columnHeader: "Distance Travelled",
  },
  {
    columnKey: "commuting_days_per_month",
    columnId: "commuting_days_per_month",
    columnHeader: "Number of commuting days per month",
  },

  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];

export const EmployeeCommuteAverage = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "total_employees",
    columnId: "total_employees",
    columnHeader: "Total employees",
  },
  {
    columnKey: "mode_of_transport_name",
    columnId: "mode_of_transport_name",
    columnHeader: "Mode of Transport",
  },

  {
    columnKey: "number_of_employees_using_mode",
    columnId: "number_of_employees_using_mode",
    columnHeader: "Number of employees using this transport",
  },

  {
    columnKey: "one_way_distance",
    columnId: "one_way_distance",
    columnHeader: "One way distance Travelled",
  },

  {
    columnKey: "working_days_per_month",
    columnId: "working_days_per_month",
    columnHeader: "Working days per month",
  },

  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];

export const EmployeeCommuteFuel = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "fuel_source",
    columnId: "fuel_source",
    columnHeader: "Fuel Source",
  },
  {
    columnKey: "fuel_amount",
    columnId: "fuel_amount",
    columnHeader: "Amount of Fuel",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];

export const PurchasedGoodsSpend = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "value_of_purchased_goods",
    columnId: "value_of_purchased_goods",
    columnHeader: "Value of purchased goods or services",
  },
  {
    columnKey: "currency",
    columnId: "currency",
    columnHeader: "Currency",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];
export const PurchasedGoodsAverageData = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "mass_of_purchased_goods",
    columnId: "mass_of_purchased_goods",
    columnHeader: "Mass ofpurchased goods(unit)",
  },
  {
    columnKey: "ef_of_purchased_goods_per_mass",
    columnId: "ef_of_purchased_goods_per_mass",
    columnHeader: "Emission factor",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];

export const PurchasedGoodsSupplierSpecific = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "quantity_of_purchased_goods",
    columnId: "quantity_of_purchased_goods",
    columnHeader: "Quantity of goods(kg)",
  },
  {
    columnKey: "supplier_specific_product_ef",
    columnId: "supplier_specific_product_ef",
    columnHeader: "Supplier-specific product Emission factor",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];

export const CapitalGoodsSupplier = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "quantity_of_purchased_goods",
    columnId: "quantity_of_purchased_goods",
    columnHeader: "Quantity of goods(unit)",
  },
  {
    columnKey: "supplier_specific_product_ef",
    columnId: "supplier_specific_product_ef",
    columnHeader: "Supplier specific emission factor",
  },
  {
    columnKey: "co2",
    columnId: "co2",
    columnHeader: "co2",
  },
  {
    columnKey: "ch4",
    columnId: "ch4",
    columnHeader: "ch4",
  },
  {
    columnKey: "n2o",
    columnId: "n2o",
    columnHeader: "n2o",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];

export const CapitalGoodsSpend = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "vendor_company_name",
    columnId: "vendor_company_name",
    columnHeader: "Company",
  },
  {
    columnKey: "sector_name",
    columnId: "sector_name",
    columnHeader: "Sector",
  },
  {
    columnKey: "industry_name",
    columnId: "industry_name",
    columnHeader: "Industry",
  },
  {
    columnKey: "value_of_purchased_goods",
    columnId: "value_of_purchased_goods",
    columnHeader: "Value of capital goods",
  },
  {
    columnKey: "currency",
    columnId: "currency",
    columnHeader: "Currency",
  },
  {
    columnKey: "co2",
    columnId: "co2",
    columnHeader: "co2",
  },
  {
    columnKey: "ch4",
    columnId: "ch4",
    columnHeader: "ch4",
  },
  {
    columnKey: "n2o",
    columnId: "n2o",
    columnHeader: "n2o",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];

export const CapitalGoodsAverage = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "mass_of_purchased_goods",
    columnId: "mass_of_purchased_goods",
    columnHeader: "Mass of capital goods(unit)",
  },
  {
    columnKey: "ef_of_purchased_goods_per_mass",
    columnId: "ef_of_purchased_goods_per_mass",
    columnHeader: "Emission factor",
  },
  {
    columnKey: "co2",
    columnId: "co2",
    columnHeader: "co2",
  },
  {
    columnKey: "ch4",
    columnId: "ch4",
    columnHeader: "ch4",
  },
  {
    columnKey: "n2o",
    columnId: "n2o",
    columnHeader: "n2o",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];

export const FuelEnergyPurchasedFules = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "fuel_type_name",
    columnId: "fuel_type_name",
    columnHeader: "Fuel Source",
  },
  {
    columnKey: "amount_fuel",
    columnId: "amount_fuel",
    columnHeader: "Amount of fuel consumed(Unit)",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];
export const FuelEnergyPurchasedElectricity = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "calculation_approach_name",
    columnId: "calculation_approach_name",
    columnHeader: "Calculation Approach",
  },
  {
    columnKey: "amount_electricity",
    columnId: "amount_electricity",
    columnHeader:
      "Quantity of electricity,steam,heating,cooling consumed(unit)",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];

export const FuelEnergyTandDLosses = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "calculation_approach_name",
    columnId: "calculation_approach_name",
    columnHeader: "calculation_approach_name",
  },
  {
    columnKey: "amount_electricity",
    columnId: "amount_electricity",
    columnHeader:
      "Quantity of electricity,steam,heating,cooling consumed(unit)",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];
export const FuelEnergyPurchasedAndSold = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "calculation_approach_name",
    columnId: "calculation_approach_name",
    columnHeader: "Calculation Approach",
  },
  {
    columnKey: "amount_electricity",
    columnId: "amount_electricity",
    columnHeader:
      "Amount of electricity,steam,heating,cooling purchased for resale(unit)",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];

export const UpstreamLeasedAsset = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "fuel_source",
    columnId: "fuel_source",
    columnHeader: "Fuel Source",
  },
  {
    columnKey: "fuel_amount",
    columnId: "fuel_amount",
    columnHeader: "Amount of Fuel",
  },
  {
    columnKey: "amount_refrigerants_leakage",
    columnId: "amount_refrigerants_leakage",
    columnHeader: "Quantity of refrigerant leakage(unit)",
  },
  {
    columnKey: "amount_electricity",
    columnId: "amount_electricity",
    columnHeader:
      "Quantity of electricity, steam, heating, cooling consumed(unit)",
  },
  {
    columnKey: "process_emission",
    columnId: "process_emission",
    columnHeader: "Process Emissions",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];

export const UpstreamLeasedAverage1 = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },

  {
    columnKey: "total_floor_space",
    columnId: "total_floor_space",
    columnHeader: "Total floor space of building type(unit)",
  },
  {
    columnKey: "building_type_name",
    columnId: "building_type_name",
    columnHeader: "Building type",
  },

  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];
export const UpstreamLeasedAverage2 = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },

  {
    columnKey: "number_of_assets",
    columnId: "number_of_assets",
    columnHeader: "Number of assets",
  },
  {
    columnKey: "average_emission_factor_of_building_type",
    columnId: "average_emission_factor_of_building_type",
    columnHeader: "Average emission factor for asset type(kg co2e/m2/year)",
  },

  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];

export const DirectUsephaseFuels = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "fuel_source",
    columnId: "fuel_source",
    columnHeader: "Fuel / Feedstock",
  },
  {
    columnKey: "amount_fuel",
    columnId: "amount_fuel",
    columnHeader: "Quantity of fuel/feedstock",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];

export const DirectUsephaseGreenhouse = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "product_name",
    columnId: "product_name",
    columnHeader: "Product Name",
  },
  {
    columnKey: "total_number_of_product_sold",
    columnId: "total_number_of_product_sold",
    columnHeader: "Total number of solid products",
  },
  {
    columnKey: "ghg_contained_name",
    columnId: "ghg_contained_name",
    columnHeader: "GHG Contained",
  },
  {
    columnKey: "amount_of_ghg",
    columnId: "amount_of_ghg",
    columnHeader: "Amount of GHG per product",
  },
  {
    columnKey: "percentage_of_ghg",
    columnId: "percentage_of_ghg",
    columnHeader: "% GHG released",
  },

  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];

export const DirectUsephaseProducts = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "product_name",
    columnId: "product_name",
    columnHeader: "Product Name",
  },
  {
    columnKey: "total_lifetime_expected_use",
    columnId: "total_lifetime_expected_use",
    columnHeader: "Product lifetime usage",
  },
  {
    columnKey: "number_sold",
    columnId: "number_sold",
    columnHeader: "Number of sold",
  },
  {
    columnKey: "fuel_source",
    columnId: "fuel_source",
    columnHeader: "Fuel source",
  },
  {
    columnKey: "amount_fuel",
    columnId: "amount_fuel",
    columnHeader: "Fuel Consumed",
  },
  {
    columnKey: "amount_electricity",
    columnId: "amount_electricity",
    columnHeader: "Electricity Consumed",
  },
  {
    columnKey: "refrigerant_unit",
    columnId: "refrigerant_unit",
    columnHeader: "Refrigerant Leakage ",
  },

  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];

export const IndirectUsephaseCalculation = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "total_intermediate_products",
    columnId: "total_intermediate_products",
    columnHeader: "Total Intermediate sold products",
  },
  {
    columnKey: "total_lifetime_use_of_sold_products",
    columnId: "total_lifetime_use_of_sold_products",
    columnHeader: "Product lifetime usage",
  },
  {
    columnKey: "emission_per_use_sold_intermediate_products",
    columnId: "emission_per_use_sold_intermediate_products",
    columnHeader: "Emission per use of sold intermediate product kg CO2 e/use",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];

export const IndirectUseOfEmission = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "product_name",
    columnId: "product_name",
    columnHeader: "Product Name",
  },
  {
    columnKey: "total_lifetime_use_of_sold_products",
    columnId: "total_lifetime_use_of_sold_products",
    columnHeader: "Product lifetime usage",
  },
  {
    columnKey: "number_sold",
    columnId: "number_sold",
    columnHeader: "Number of sold",
  },
  {
    columnKey: "fuel_source",
    columnId: "fuel_source",
    columnHeader: "Fuel source",
  },
  {
    columnKey: "amount_fuel",
    columnId: "amount_fuel",
    columnHeader: "Fuel Consumed",
  },
  {
    columnKey: "amount_electricity",
    columnId: "amount_electricity",
    columnHeader: "Electricity Consumed",
  },
  {
    columnKey: "refrigerant_unit",
    columnId: "refrigerant_unit",
    columnHeader: "Refrigerant Leakage ",
  },

  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];

export const ProcessingOfSoldProductsSite = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "fuel_source_name",
    columnId: "fuel_source_name",
    columnHeader: "Fuel Source",
  },
  {
    columnKey: "amount_fuel",
    columnId: "amount_fuel",
    columnHeader: "Amount of Fuel",
  },
  {
    columnKey: "amount_electricity",
    columnId: "amount_electricity",
    columnHeader: "Amount of electiricity consumed(unit)",
  },
  {
    columnKey: "amount_refrigerants",
    columnId: "amount_refrigerants",
    columnHeader: "Quantity of refrigerant leakage(unit)",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];

export const ProcessingOfSoldProductsAverage = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "mass_of_sold_product",
    columnId: "mass_of_sold_product",
    columnHeader: "Mass of solid intermediate product",
  },
  {
    columnKey: "emission_factor_of_sold_products",
    columnId: "emission_factor_of_sold_products",
    columnHeader: "Emission factor for processing of solid products",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];

export const Franchisespecific = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "franchise_name",
    columnId: "franchise_name",
    columnHeader: "Franchise Name",
  },
  {
    columnKey: "country_name",
    columnId: "country_name",
    columnHeader: "Country",
  },
  {
    columnKey: "scope_1_emissions",
    columnId: "scope_1_emissions",
    columnHeader: "Scope 1 emissions",
  },
  {
    columnKey: "scope_2_emissions",
    columnId: "scope_2_emissions",
    columnHeader: "Scope 2 emissions",
  },

  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];

export const FranchiseAverage1 = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },

  {
    columnKey: "total_floor_space",
    columnId: "total_floor_space",
    columnHeader: "Total floor space of building type(unit)",
  },
  {
    columnKey: "building_type_name",
    columnId: "building_type_name",
    columnHeader: "Building type",
  },

  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];
export const FranchiseAverage2 = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },

  {
    columnKey: "number_of_assets",
    columnId: "number_of_assets",
    columnHeader: "Number of Buildings",
  },
  {
    columnKey: "average_emission_factor_of_building_type",
    columnId: "average_emission_factor_of_building_type",
    columnHeader: "Average emission factor for asset type(kg co2e/m2/year)",
  },

  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];

export const InvestmentSpecific = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "scope1_emissions",
    columnId: "scope1_emissions",
    columnHeader: "Scope 1 emissions of equity investment",
  },
  {
    columnKey: "scope2_emissions",
    columnId: "scope2_emissions",
    columnHeader: "Scope 2 emissions of equity investment",
  },
  {
    columnKey: "share_percentage",
    columnId: "share_percentage",
    columnHeader: "Share of equity(%)",
  },

  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];

export const AverageDataMethod1 = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "sector_name",
    columnId: "sector_name",
    columnHeader: "Sector related to investment",
  },
  {
    columnKey: "total_revenue",
    columnId: "total_revenue",
    columnHeader: "Investee company total revenue",
  },
  {
    columnKey: "currency",
    columnId: "currency",
    columnHeader: "Currency",
  },
  {
    columnKey: "share_percentage",
    columnId: "share_percentage",
    columnHeader: "Share of equity(%)",
  },

  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];

export const ProjectSpecificMethod = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "scope1_emissions",
    columnId: "scope1_emissions",
    columnHeader: "Scope 1 emissions of relevant project",
  },
  {
    columnKey: "scope2_emissions",
    columnId: "scope2_emissions",
    columnHeader: "Scope 2 missions of relevant project",
  },
  {
    columnKey: "share_percentage",
    columnId: "share_percentage",
    columnHeader: "Share of equity(%)",
  },

  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];

export const AverageDataMethod2 = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "sector_name",
    columnId: "sector_name",
    columnHeader: "Project construction/operating sector",
  },
  {
    columnKey: "total_revenue",
    columnId: "total_revenue",
    columnHeader:
      "Project construction cost/Project revenue in the reporting year - ($)",
  },
  {
    columnKey: "currency",
    columnId: "currency",
    columnHeader: "Currency",
  },
  {
    columnKey: "share_percentage",
    columnId: "share_percentage",
    columnHeader: "Share of total project costs(%)",
  },

  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];

export const LifetimeEmission = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "projected_annual_emissions",
    columnId: "projected_annual_emissions",
    columnHeader: "Projected annual emissions of project",
  },
  {
    columnKey: "project_lifetime",
    columnId: "project_lifetime",
    columnHeader: "Projected lifetime of project(years)",
  },
  {
    columnKey: "share_percentage",
    columnId: "share_percentage",
    columnHeader: "Share of total project costs(%)",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];
