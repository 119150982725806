import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Typography,
  Tooltip,
  Box,
  TextField,
  IconButton,
  Chip,
  Modal,
} from "@mui/material";
import useStyles from "./styles";
import DashboardLayout from "../../../layouts/DashboardLayout";
import {
  addReviewComments,
  approveMonthlyApproval,
  assignMonthlyApproval,
  resetApprovalData,
  roleBasedApproval,
  submitMonthlyApproval,
} from "../../../redux/actions";
import CeroCheckboxTable from "../../../components/CeroCheckboxTable";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  StationaryColumns,
  MobileColumns,
  PurchasedElectricityColumns,
  WaterDischargeColumns,
  WaterConsumptionColumns,
  RefrigerantsColumns,
  TransportationColumns,
  WasteCombustionColumns,
} from "../../ApprovalStatus/MonthlyFacilityDetails/ApprovalStatusTable/ApprovalTableColumns";
import { STATUS } from "../../../redux/constants";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { getCookie } from "../../../services/cookie";
import CeroPopUp from "../../../components/CeroPopUp";
import CeroButton from "../../../components/CeroButton";
import { useSnackbar } from "notistack";
import CreateIcon from "@mui/icons-material/CreateOutlined";
import Download from "../../../assets/images/Download";
import CloseIcon from "@mui/icons-material/Close";
import {
  UpstreamDistributionAverage,
  UpstreamDistributionSite,
  UpstreamTransportationDistance,
  UpstreamTransportationFuel,
  UpstreamTransportationRefrigerant,
  UpstreamTransportationSpend,
  BusinessTravelDistance,
  BusinessTravelFuel,
  BusinessTravelSpend,
  EmployeeCommuteAverage,
  EmployeeCommuteDistance,
  EmployeeCommuteFuel,
  PurchasedGoodsSupplierSpecific,
  PurchasedGoodsAverageData,
  PurchasedGoodsSpend,
  CapitalGoodsAverage,
  CapitalGoodsSupplier,
  CapitalGoodsSpend,
  FuelEnergyPurchasedFules,
  FuelEnergyPurchasedElectricity,
  FuelEnergyTandDLosses,
  FuelEnergyPurchasedAndSold,
  UpstreamLeasedAsset,
  UpstreamLeasedAverage1,
  UpstreamLeasedAverage2,
  DirectUsephaseFuels,
  DirectUsephaseGreenhouse,
  DirectUsephaseProducts,
  IndirectUsephaseCalculation,
  IndirectUseOfEmission,
  ProcessingOfSoldProductsAverage,
  ProcessingOfSoldProductsSite,
  FranchiseAverage2,
  FranchiseAverage1,
  Franchisespecific,
  InvestmentSpecific,
  AverageDataMethod1,
  ProjectSpecificMethod,
  AverageDataMethod2,
  LifetimeEmission,
} from "../../ApprovalStatus/MonthlyFacilityDetails/ApprovalStatusScope3Table/ApprovalTableColumns";

const ApprovalTableScope3 = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, type, subType } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  let userRole = getCookie("role");
  const readOnlyMode = getCookie("read_only_mode") === "disable_edit";

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [reviewPopup, setReviewPopup] = useState(false);
  const [reviewConfirm, setReviewConfirm] = useState(false);
  const [checkedRows, setCheckedRows] = useState([]);
  const [canShowReviewButton, setCanShowReviewButton] = useState(true);
  const [comment, setComment] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");

  const handlePdfOpen = (file) => {
    setSelectedFile(file);
    setIsModalOpen(true);
  };

  const handlePdfClose = () => {
    setIsModalOpen(false);
  };

  const newColumn = {
    columnKey: "attachment", // Unique key for the column
    columnId: "attachment", // ID for internal reference
    columnHeader: "Attachment", // Column header to display
  };

  const getTableColumn = {
    stationary_combustion: [...StationaryColumns, newColumn],
    mobile_combustion: [...MobileColumns, newColumn],
    purchased_electricity: [...PurchasedElectricityColumns, newColumn],
    water_discharge: [...WaterDischargeColumns, newColumn],
    water_consumption: [...WaterConsumptionColumns, newColumn],
    refrigerants: [...RefrigerantsColumns, newColumn],
    transportation: [...TransportationColumns, newColumn],
    waste: [...WasteCombustionColumns, newColumn],
  };
  const scope3TopicDataMapper = {
    upstream_t_and_d: {
      transportation: {
        distance_based: {
          categoryTitle: "Upstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Transportation",
          subCategory: "Distance Based",
          tableColumns: UpstreamTransportationDistance,
        },
        fuel_based: {
          categoryTitle: "Upstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Transportation",
          subCategory: "Fuel Based",
          tableColumns: UpstreamTransportationFuel,
        },
        refrigerant_leakage: {
          categoryTitle: "Upstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Transportation",
          subCategory: "Refrigerant Leakage Based",
          tableColumns: UpstreamTransportationRefrigerant,
        },
        spend_based: {
          categoryTitle: "Upstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Transportation",
          subCategory: "Spend Based",
          tableColumns: UpstreamTransportationSpend,
        },
      },
      distribution: {
        site_specific: {
          categoryTitle: "Upstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Distribution",
          subCategory: "Site Specific Based",
          tableColumns: UpstreamDistributionSite,
        },
        average_method: {
          categoryTitle: "Upstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Distribution",
          subCategory: "Average Method Based",
          tableColumns: UpstreamDistributionAverage,
        },
      },
    },
    downstream_t_and_d: {
      transportation: {
        distance_based: {
          categoryTitle: "Downstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Transportation",
          subCategory: "Distance Based",
          tableColumns: UpstreamTransportationDistance,
        },
        fuel_based: {
          categoryTitle: "Downstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Transportation",
          subCategory: "Fuel Based",
          tableColumns: UpstreamTransportationFuel,
        },
        refrigerant_leakage: {
          categoryTitle: "Downstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Transportation",
          subCategory: "Refrigerant Leakage Based",
          tableColumns: UpstreamTransportationRefrigerant,
        },
        spend_based: {
          categoryTitle: "Downstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Transportation",
          subCategory: "Spend Based",
          tableColumns: UpstreamTransportationSpend,
        },
      },
      distribution: {
        site_specific: {
          categoryTitle: "Downstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Distribution",
          subCategory: "Site Specific Based",
          tableColumns: UpstreamDistributionSite,
        },
        average_method: {
          categoryTitle: "Downstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Distribution",
          subCategory: "Average Method Based",
          tableColumns: UpstreamDistributionAverage,
        },
      },
    },
    business_travel: {
      spend_based: {
        categoryTitle: "Calculation Method: ",
        category: "Spend Based",
        tableColumns: BusinessTravelSpend,
      },
      fuel_based: {
        categoryTitle: "Calculation Method: ",
        category: "Fuel Based",
        tableColumns: BusinessTravelFuel,
      },
      distance_based: {
        categoryTitle: "Calculation Method: ",
        category: "Distance Based",
        tableColumns: BusinessTravelDistance,
      },
    },
    employee_commute: {
      average_method: {
        categoryTitle: "Calculation Method: ",
        category: "average data method",
        tableColumns: EmployeeCommuteAverage,
      },
      distance_based: {
        categoryTitle: "Calculation Method: ",
        category: "distance based",
        tableColumns: EmployeeCommuteDistance,
      },
      fuel_based: {
        categoryTitle: "Calculation Method: ",
        category: "fuel based",
        tableColumns: EmployeeCommuteFuel,
      },
    },
    purchased_goods_and_services: {
      spend_based: {
        categoryTitle: "Calculation Method: ",
        category: "Spend Based",
        tableColumns: PurchasedGoodsSpend,
      },
      average_method: {
        categoryTitle: "Calculation Method: ",
        category: "Average Data Method",
        tableColumns: PurchasedGoodsAverageData,
      },
      supplier_specific: {
        categoryTitle: "Calculation Method: ",
        category: "Supplier-specific Method",
        tableColumns: PurchasedGoodsSupplierSpecific,
      },
    },
    capital_goods: {
      spend_based: {
        categoryTitle: "Calculation Method: ",
        category: "Spend Based",
        tableColumns: CapitalGoodsSpend,
      },
      supplier_specific: {
        categoryTitle: "Calculation Method: ",
        category: "Supplier specific",
        tableColumns: CapitalGoodsSupplier,
      },
      average_method: {
        categoryTitle: "Calculation Method: ",
        category: "Average method",
        tableColumns: CapitalGoodsAverage,
      },
    },
    fuel_and_energy_related_activities: {
      purchased_fuel: {
        categoryTitle: "Calculation Method: ",
        category: "Upstream emissions of purchased fuels",
        tableColumns: FuelEnergyPurchasedFules,
      },
      purchased_electricity: {
        categoryTitle: "Calculation Method: ",
        category: "Upstream emissions of purchased electricity",
        tableColumns: FuelEnergyPurchasedElectricity,
      },
      transmission_distribution_loss: {
        categoryTitle: "Calculation Method: ",
        category: "Emissions from transmission and distribution losses",
        tableColumns: FuelEnergyTandDLosses,
      },
      life_cycle_emission: {
        categoryTitle: "Calculation Method: ",
        category: "Life cycle emissions from power that is purchased and sold",
        tableColumns: FuelEnergyPurchasedAndSold,
      },
    },
    upstream_leased_asset: {
      asset_specific: {
        categoryTitle: "Calculation Method:",
        category: "Asset specific method",
        tableColumns: UpstreamLeasedAsset,
      },
      lessor_specific: {
        categoryTitle: "Calculation Method:",
        category: "Lessor specific method",
        tableColumns: UpstreamLeasedAsset,
      },
      average_method_type_1: {
        categoryTitle: "Calculation Method:",
        category: "Average data method type 1",
        tableColumns: UpstreamLeasedAverage1,
      },
      average_method_type_2: {
        categoryTitle: "Calculation Method:",
        category: "Average data method type 2",
        tableColumns: UpstreamLeasedAverage2,
      },
    },
    downstream_leased_asset: {
      asset_specific: {
        categoryTitle: "Calculation Method:",
        category: "Asset specific method",
        tableColumns: UpstreamLeasedAsset,
      },
      lessor_specific: {
        categoryTitle: "Calculation Method:",
        category: "Lessor specific method",
        tableColumns: UpstreamLeasedAsset,
      },
      average_method_type_1: {
        categoryTitle: "Calculation Method:",
        category: "Average data method type 1",
        tableColumns: UpstreamLeasedAverage1,
      },
      average_method_type_2: {
        categoryTitle: "Calculation Method:",
        category: "Average data method type 2",
        tableColumns: UpstreamLeasedAverage2,
      },
    },
    use_of_sold_products: {
      direct_use_phase: {
        fuel_feedstock: {
          categoryTitle: "Types of emissions from sold products: ",
          subCategoryTitle: "Choose the type of direct use phase emission : ",
          category: "Direct use phase emissions",
          subCategory: "Fuels and Feedstock",
          tableColumns: DirectUsephaseFuels,
        },
        greenhouse_gas_emissions: {
          categoryTitle: "Types of emissions from sold products: ",
          subCategoryTitle: "Choose the type of direct use phase emission : ",
          category: "Direct use phase emissions",
          subCategory:
            "Greenhouse gases and products that contain or form greenhouse gases that are emitted during use",
          tableColumns: DirectUsephaseGreenhouse,
        },
        products_consume_energy: {
          categoryTitle: "Types of emissions from sold products: ",
          subCategoryTitle: "Choose the type of direct use phase emission : ",
          category: "Direct use phase emissions",
          subCategory: "Products that directly consume energy during use",
          tableColumns: DirectUsephaseProducts,
        },
      },
      indirect_use_phase: {
        sold_intermediate_products: {
          categoryTitle: "Types of emissions from sold products: ",
          subCategoryTitle: "Choose the type of indirect use phase emission : ",
          category: "Indirect use phase emissions",
          subCategory: "Calculation method for sold intermediate products",
          tableColumns: IndirectUsephaseCalculation,
        },
        indirect_use_of_emission_from_products: {
          categoryTitle: "Types of emissions from sold products: ",
          subCategoryTitle: "Choose the type of indirect use phase emission : ",
          category: "Indirect use phase emissions",
          subCategory:
            "Indirect use phase emissions from products that indirectly consume energy during use",
          tableColumns: IndirectUseOfEmission,
        },
      },
    },
    processing_of_sold_products: {
      average_data: {
        categoryTitle: "Calculation Method:",
        category: "Average data method",
        tableColumns: ProcessingOfSoldProductsAverage,
      },
      site_specific: {
        categoryTitle: "Calculation Method: ",
        category: "Site Specific Method",
        tableColumns: ProcessingOfSoldProductsSite,
      },
    },
    franchises: {
      franchise_specific: {
        categoryTitle: "Calculation Method:",
        category: "Franchise specific method",
        tableColumns: Franchisespecific,
      },
      average_method_type_1: {
        categoryTitle: "Calculation Method:",
        category: "Average data method type 1",
        tableColumns: FranchiseAverage1,
      },
      average_method_type_2: {
        categoryTitle: "Calculation Method:",
        category: "Average data method type 2",
        tableColumns: FranchiseAverage2,
      },
    },
    investments: {
      equity_investments: {
        investment_specific: {
          categoryTitle: "Type of financial investment: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Equity investments",
          subCategory: "Investment-specific method",
          tableColumns: InvestmentSpecific,
        },
        average_data_method: {
          categoryTitle: "Type of financial investment: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Equity investments",
          subCategory: "Average-data method",
          tableColumns: AverageDataMethod1,
        },
      },
      project_finance_and_debt: {
        project_specific: {
          categoryTitle: "Type of financial investment: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Product finance and from debt investments",
          subCategory: "Project-specific method",
          tableColumns: ProjectSpecificMethod,
        },
        average_data_method: {
          categoryTitle: "Type of financial investment: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Product finance and from debt investments",
          subCategory: "Average-data method",
          tableColumns: AverageDataMethod2,
        },
        total_lifetime_emissions: {
          categoryTitle: "Type of financial investment: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Product finance and from debt investments",
          subCategory: "Total lifetime emissions",
          tableColumns: LifetimeEmission,
        },
      },
    },
  };

  useEffect(() => {
    dispatch(resetApprovalData());
    dispatch(roleBasedApproval(id, type, subType));
  }, [dispatch, id, subType, subType]);

  const approvalData = useSelector(
    (state) => state.approval.roleBasedApproval.data?.topic_list
  );
  console.log(approvalData, type, "nikhil");

  const approvalDataStatus = useSelector(
    (state) => state.approval.roleBasedApproval.status
  );

  const submitStatus = useSelector(
    (state) => state.approval.submitMonthlyApproval
  );

  const assignStatus = useSelector(
    (state) => state.approval.assignMonthlyApproval
  );

  const approvalStatus = useSelector(
    (state) => state.approval.approveMonthlyApproval
  );

  const reviewStatus = useSelector((state) => state.approval.reviewComments);

  const dataCount = useSelector(
    (state) => state.approval.roleBasedApproval.data?.emission_count
  );
  const handlePdfPopup = () => {
    return (
      <embed
        src={`${selectedFile}#toolbar=0`}
        type="application/pdf"
        width="100%"
        height="600px"
      />
    );
  };
  function collectListsFromDict(data) {
    let TotalElementsList = [];

    for (const key in data) {
      if (Array.isArray(data[key])) {
        // If the value is an array, add its elements to TotalElementsList
        TotalElementsList = TotalElementsList.concat(data[key]);
      } else if (typeof data[key] === "object" && data[key] !== null) {
        // If the value is an object (nested dictionary), iterate through it
        for (const subKey in data[key]) {
          if (Array.isArray(data[key][subKey])) {
            TotalElementsList = TotalElementsList.concat(data[key][subKey]);
          }
        }
      }
    }

    return TotalElementsList;
  }
  const TotalElementsList = collectListsFromDict(approvalData);
  console.log("nikTotal", TotalElementsList);

  const itemCount = TotalElementsList?.length;
  const canReviewActionDoIds = TotalElementsList?.filter(
    (item) => item.can_review_action_do
  ).map((item) => item.id);
  const approvalDataLength = getCookie("approvalDataLength")
    ? parseInt(getCookie("approvalDataLength"))
    : 0;
  const getStatusColor = (item) => {
    if (item === "approved") {
      return (
        <Tooltip title="Approved" arrow>
          <Chip className={classes.signalApproved} />
        </Tooltip>
      );
    } else if (item === "submitted") {
      return (
        <Tooltip title="Submitted" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      );
    } else if (item === "audited") {
      return (
        <Tooltip title="Audited" arrow>
          <Chip className={classes.signalAudited} />
        </Tooltip>
      );
    } else if (item === "review") {
      return (
        <Tooltip title="Review" arrow>
          <Chip className={classes.signalReview} />
        </Tooltip>
      );
    } else if (item === "added") {
      return (
        <Tooltip title="Added" arrow>
          <Chip className={classes.signalAdded} />
        </Tooltip>
      );
    } else if (item === "assigned") {
      return (
        <Tooltip title="assigned" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      );
    } else if (item === "verified") {
      return (
        <Tooltip title="Verified" arrow>
          <Chip className={classes.signalVerified} />
        </Tooltip>
      );
    } else if (item === "Awaiting approval") {
      return (
        <Tooltip title="Awaiting approval" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      );
    } else if (item === "In Progress") {
      return (
        <Tooltip title="In Progress" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      );
    } else if (item === "pending_approval") {
      return (
        <Tooltip title="Pending Approval" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={item} arrow>
          <Chip className={classes.signalAdded} />
          {item}
        </Tooltip>
      );
    }
  };

  const onEditEmissionData = (e, emission, status, ticketId) => {
    e.stopPropagation();
    e.preventDefault();
    if (status === "review") {
      navigate(`/tickets/id?ticketId=${ticketId}`);
    } else {
      navigate(`/emissions/edit/${type}/${emission}`);
    }
  };

  const getEmissionData = (tableData) => {
    return Array.isArray(tableData)
      ? tableData.map((item) => ({
          ...item,
          status: item.status ? getStatusColor(item.status) : "",
          treatment_required: item.treatment_required === true ? "Yes" : "No",
          mass_of_sold_product:
            item.mass_of_sold_product + " " + item.mass_unit,
          amount_fuel: item.amount_fuel + " " + item.fuel_unit,
          amount_electricity:
            item.amount_electricity + " " + item.electricity_unit,
          amount_refrigerants:
            item.amount_refrigerants + " " + item.refrigerant_unit,
          pending_action_role:
            item.pending_action_role === null ? (
              "NA"
            ) : (
              <Box className={classes.actionContainer}>
                {item.pending_action_role}
                {userRole === "business_user" &&
                  !readOnlyMode &&
                  (item.status === "added" || item.status === "review") && (
                    <IconButton
                      className={classes.editIcon}
                      onMouseDown={(e) => {
                        onEditEmissionData(
                          e,
                          item.id,
                          item.status,
                          item.ticket_id
                        );
                      }}
                    >
                      <CreateIcon />
                    </IconButton>
                  )}
              </Box>
            ),
          amount: item.amount
            ? item.unit
              ? `${item.amount} ${item.unit}`
              : item.amount
            : "",
          attachment: item.blob_url ? (
            <div
              style={{ transform: "scale(0.6)", transformOrigin: "center" }}
              onClick={() => handlePdfOpen(item.blob_url)}
            >
              <Download />
            </div>
          ) : (
            "-"
          ),
        }))
      : [];
  };
  console.log("how it is possible", checkedRows);
  const handleCheckedRowsChange = (newCheckedRows) => {
    console.log(newCheckedRows, "nik checked rows");
    setCheckedRows(newCheckedRows);
    setCanShowReviewButton(
      newCheckedRows.every((element) => canReviewActionDoIds.includes(element))
    );
  };
  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleReviewPopup = () => {
    setReviewConfirm(true);
  };
  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setReviewPopup(false);
  };

  const handleAction = () => {
    console.log("handle action", checkedRows);

    if (userRole === "business_user") {
      dispatch(submitMonthlyApproval(id, subType, checkedRows));
    } else if (userRole === "facility_manager") {
      dispatch(assignMonthlyApproval(id, checkedRows));
    } else {
      dispatch(approveMonthlyApproval(id, subType, checkedRows));
    }
    handleClosePopup();
  };

  useEffect(() => {
    if (approvalStatus.status === STATUS.SUCCESS) {
      if (itemCount === checkedRows.length) {
        if (approvalDataLength > 1) {
          dispatch(resetApprovalData());
          navigate(-1);
        } else {
          if (userRole === "business_user") {
            navigate("/request-approvals");
          } else {
            navigate("/pending-approvals");
          }
        }
      }
      dispatch(resetApprovalData());
      dispatch(roleBasedApproval(id, type, subType));
      enqueueSnackbar(approvalStatus?.data?.message, {
        variant: "success",
        key: "approve",
      });
    } else if (approvalStatus.status === STATUS.ERROR) {
      enqueueSnackbar(
        approvalStatus.data.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetApprovalData());
    }
  }, [enqueueSnackbar, approvalStatus, dispatch, id, type]);

  useEffect(() => {
    if (submitStatus.status === STATUS.SUCCESS) {
      if (itemCount === checkedRows.length) {
        if (approvalDataLength > 1) {
          dispatch(resetApprovalData());
          navigate(-1);
        } else {
          dispatch(resetApprovalData());
          if (userRole === "business_user") {
            navigate("/request-approvals");
          } else {
            navigate("/pending-approvals");
          }
        }
      }
      dispatch(resetApprovalData());
      dispatch(roleBasedApproval(id, type, subType));
      enqueueSnackbar(submitStatus?.data?.message, {
        variant: "success",
        key: "submit",
      });
    } else if (submitStatus.status === STATUS.ERROR) {
      enqueueSnackbar(
        "error" || "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetApprovalData());
    }
  }, [enqueueSnackbar, submitStatus, dispatch, id, type, subType]);

  useEffect(() => {
    if (reviewStatus.status === STATUS.SUCCESS) {
      dispatch(resetApprovalData());
      dispatch(roleBasedApproval(id, type, subType));
      enqueueSnackbar(reviewStatus?.data?.message, {
        variant: "success",
        key: "review",
      });
    } else if (reviewStatus.status === STATUS.ERROR) {
      enqueueSnackbar(
        reviewStatus.data.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetApprovalData());
    }
  }, [enqueueSnackbar, reviewStatus, dispatch, id, type]);

  useEffect(() => {
    if (assignStatus.status === STATUS.SUCCESS) {
      if (itemCount === checkedRows.length) {
        if (approvalDataLength > 1) {
          dispatch(resetApprovalData());
          navigate(-1);
        } else {
          if (userRole === "business_user") {
            navigate("/request-approvals");
          } else {
            navigate("/pending-approvals");
          }
        }
      }
      dispatch(resetApprovalData());
      dispatch(roleBasedApproval(id, type, subType));
      enqueueSnackbar(assignStatus?.data?.message, {
        variant: "success",
        key: "assign",
      });
    } else if (assignStatus.status === STATUS.ERROR) {
      enqueueSnackbar(
        assignStatus.data.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetApprovalData());
    }
  }, [enqueueSnackbar, assignStatus, dispatch, id, type]);

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const onSubmitReview = () => {
    setReviewPopup(false);
    dispatch(addReviewComments(id, checkedRows, comment));
  };

  const getReviewComments = () => {
    return (
      <>
        <TextField
          id="outlined-multiline-static"
          multiline
          rows={5}
          placeholder="Comment"
          style={{ width: "100%" }}
          value={comment}
          onChange={handleCommentChange}
          className={classes.textField}
        />
        <div className={classes.textLimit}>
          <div className={classes.noComments}>
            {comment.trim().length === 0 ? "No comments" : ""}
          </div>
          <div className={classes.maxCharacters}>
            {"Maximum 200 characters"}
          </div>
        </div>
        <div className={classes.reviewButtons}>
          <CeroButton
            className={classes.buttonPrimary}
            variant="contained"
            onClick={onSubmitReview}
            buttonText="Save"
          />
          <CeroButton
            className={classes.buttonSecondary}
            variant="outlined"
            onClick={() => setReviewPopup(false)}
            buttonText="Cancel"
          />
        </div>
      </>
    );
  };

  const getIcon = () => {
    if (userRole === "business_user") {
      return (
        <Tooltip title="Submit" arrow>
          <UploadOutlinedIcon
            onClick={handleOpenPopup}
            className={classes.icon}
          />
        </Tooltip>
      );
    } else {
      return (
        <>
          <Tooltip title="Approve" arrow>
            <CheckCircleOutlineOutlinedIcon
              onClick={handleOpenPopup}
              className={classes.icon}
            />
          </Tooltip>
          {canShowReviewButton && (
            <Tooltip title="Review" arrow>
              <ArrowCircleLeftOutlinedIcon
                onClick={handleReviewPopup}
                className={classes.icon}
              />
            </Tooltip>
          )}
        </>
      );
    }
  };

  const getConfirmation = () => {
    return (
      <Box className={classes.popUp}>
        {userRole === "business_user" ? (
          <Typography variant="body1" style={{ fontWeight: 500 }}>
            Are you sure you want to submit {checkedRows.length} records?
          </Typography>
        ) : (
          <Typography variant="body1" style={{ fontWeight: 500 }}>
            Are you sure you want to approve {checkedRows.length} records?
          </Typography>
        )}
        <div className={classes.buttonContainer}>
          <CeroButton
            className={classes.buttonPrimary}
            variant="contained"
            onClick={handleAction}
            buttonText="Yes"
          />
          <CeroButton
            className={classes.buttonSecondary}
            variant="outlined"
            onClick={handleClosePopup}
            buttonText="No"
          />
        </div>
      </Box>
    );
  };

  const getReviewConfirm = () => {
    return (
      <Box className={classes.popUp}>
        <Typography variant="body1" style={{ fontWeight: 500 }}>
          Are you sure you want to sent {checkedRows.length} records to review?
        </Typography>
        <div className={classes.buttonContainer}>
          <CeroButton
            className={classes.buttonPrimary}
            variant="contained"
            onClick={() => {
              setReviewPopup(true);
              setReviewConfirm(false);
            }}
            buttonText="Yes"
          />
          <CeroButton
            className={classes.buttonSecondary}
            variant="outlined"
            onClick={() => setReviewConfirm(false)}
            buttonText="No"
          />
        </div>
      </Box>
    );
  };

  const navigateBack = () => {
    if (userRole === "business_user") {
      if (dataCount > 0) {
        navigate(-1);
      } else {
        navigate(`/request-approvals`);
      }
    } else {
      if (dataCount > 0) {
        navigate(-1);
      } else {
        navigate(`/pending-approvals`);
      }
    }
  };
  const typeMap = {
    upstream_t_and_d: "upstream_T&D",
    downstream_t_and_d: "downstream_T&D",
  };
  const onSelectRowData = (rowData) => {
    userRole === "auditor"
      ? console.log("No action")
      : navigate(
          `/pending-approval/emissions/${typeMap[type] || type}/details/${
            rowData.id
          }/${subType}/${id}
          `
        );
  };
  const twoTyreTopics = [
    "upstream_t_and_d",
    "downstream_t_and_d",
    "use_of_sold_products",
    "investments",
  ];

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <div className={classes.topContainer}>
          <Typography variant="h6">
            {type.replace(/_/g, " ").replace("asset", "assets")}
          </Typography>
          <CeroButton
            buttonText="Back"
            variant="outlined"
            className={classes.buttonSecondary}
            startIcon={<ArrowBackIcon />}
            onClick={navigateBack}
          />
        </div>
        {approvalDataStatus === STATUS.SUCCESS
          ? twoTyreTopics.includes(type)
            ? Object.keys(scope3TopicDataMapper[type]).map((key) =>
                Object.keys(scope3TopicDataMapper[type][key]).map((subKey) => {
                  if (approvalData[key]?.[subKey]?.length > 0) {
                    return (
                      <div key={`${key}-${subKey}`}>
                        <div className={classes.subHeads}>
                          <Typography variant="h7" component="span">
                            <span className={classes.tableSubTitle}>
                              {`${scope3TopicDataMapper[type][key][subKey]["categoryTitle"]} 
                          ${scope3TopicDataMapper[type][key][subKey]["category"]}`}
                            </span>
                          </Typography>
                          <br />
                        </div>
                        <div className={classes.subHeads}>
                          <Typography variant="h7" component="span">
                            <span className={classes.tableSubTitle}>
                              {`${scope3TopicDataMapper[type][key][subKey]["subCategoryTitle"]} 
                          ${scope3TopicDataMapper[type][key][subKey]["subCategory"]}`}
                            </span>
                          </Typography>
                        </div>
                        <CeroCheckboxTable
                          columns={
                            scope3TopicDataMapper[type][key][subKey][
                              "tableColumns"
                            ] || []
                          }
                          data={getEmissionData(approvalData[key]?.[subKey])}
                          hasMore={false}
                          loading={false}
                          // loadMore={onLoadMore}
                          classes={{ tableContainer: classes.tableContainer }}
                          showSelectAllCheckbox={true}
                          isSelectable
                          showDropdown={true}
                          checkedRows={checkedRows}
                          onCheckedRowsChange={handleCheckedRowsChange}
                          showIcon={true && getIcon()}
                          enableRowClick={true}
                          onSelectRow={onSelectRowData}
                        />
                      </div>
                    );
                  }
                })
              )
            : Object.keys(scope3TopicDataMapper[type]).map((key) => {
                if (approvalData[key]?.length > 0) {
                  return (
                    <div key={`${key}`}>
                      <div className={classes.subHeads}>
                        <Typography variant="h7" component="span">
                          <span className={classes.tableSubTitle}>
                            {`${scope3TopicDataMapper[type][key]["categoryTitle"]} 
                          ${scope3TopicDataMapper[type][key]["category"]}`}
                          </span>
                        </Typography>
                        <br />
                      </div>
                      <CeroCheckboxTable
                        columns={
                          scope3TopicDataMapper[type][key]["tableColumns"] || []
                        }
                        data={getEmissionData(approvalData[key])}
                        hasMore={false}
                        loading={false}
                        // loadMore={onLoadMore}
                        classes={{ tableContainer: classes.tableContainer }}
                        showSelectAllCheckbox={true}
                        isSelectable
                        showDropdown={true}
                        checkedRows={checkedRows}
                        onCheckedRowsChange={handleCheckedRowsChange}
                        showIcon={true && getIcon()}
                        enableRowClick={true}
                        onSelectRow={onSelectRowData}
                      />
                    </div>
                  );
                }
              })
          : ""}
        <CeroPopUp
          primaryPopUp={{
            open: isPopupOpen,
            onClose: () => setIsPopupOpen(false),
            content: getConfirmation(),
            header: { title: "" },
          }}
          showCloseButton={true}
          classes={{ dialog: classes.dialog }}
        />
        <CeroPopUp
          primaryPopUp={{
            open: reviewPopup,
            onClose: () => setReviewPopup(false),
            content: getReviewComments(),
            header: { title: "Review Comments" },
          }}
          showCloseButton={true}
        />
        <CeroPopUp
          primaryPopUp={{
            open: reviewConfirm,
            onClose: () => setReviewConfirm(false),
            content: getReviewConfirm(),
            header: { title: "" },
          }}
          showCloseButton={true}
          classes={{ dialog: classes.dialog }}
        />
        <CeroPopUp
          primaryPopUp={{
            open: reviewConfirm,
            onClose: () => setReviewConfirm(false),
            content: getReviewConfirm(),
            header: { title: "" },
          }}
          showCloseButton={true}
          classes={{ dialog: classes.dialog }}
        />
        <Modal open={isModalOpen} onClose={handlePdfClose}>
          <Box className={classes.modalContent}>
            <CloseIcon
              onClick={handlePdfClose}
              className={classes.closeButton}
            />
            {handlePdfPopup()}
          </Box>
        </Modal>
      </Container>
    </DashboardLayout>
  );
};

export default ApprovalTableScope3;
