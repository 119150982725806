import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Typography, Box, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { months, sampleYear } from "../../../constants";
import { STATUS } from "../../../redux/constants";
import { addMobileCombustionValidation } from "./schema";
import {
  addInvestments,
  addMobileCombustion,
  getEmissionFuelList,
  resetAddCombustionStatus,
} from "../../../redux/actions";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import CeroButton from "../../../components/CeroButton";
import CeroSelect from "../../../components/CeroSelect";
import CeroInput from "../../../components/CeroInput";
import useStyles from "./styles";
import DownloadIcon from "@mui/icons-material/Download";
import EmissionUploadDrawer from "../../EmissionList/EmissionUploadDrawer";
import { useLocation, useNavigate } from "react-router-dom";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CeroItemPair from "../../../components/CeroItemPair";
import FileUpload from "../FileUpload";

const AddInvestments = (props) => {
  const { onCancel, onDownload, onUpload, onValidate, uploadDisabled } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const isCalculateDone = useSelector(
    (state) => state.emission.addInvestments.isCalculateDone
  );
  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );
  const activityTypesData = useSelector(
    (state) => state.emission.mobileCombustionInputs.data.activity_types
  );
  const fuelSourceData = useSelector(
    (state) => state.emission.mobileCombustionInputs.data.fuel_sources
  );
  const customFuelSourceData = useSelector(
    (state) => state.emission.mobileCombustionInputs.data.custom_fuel_sources
  );
  const vehicleTypeData = useSelector(
    (state) => state.emission.mobileCombustionInputs.data.vehicle_types
  );
  const fuelUnitData = useSelector(
    (state) => state.emission.mobileCombustionInputs.data.units
  );
  const addEmissionData = useSelector((state) => state.emission.addInvestments);
  const emissionPerformData = useSelector((state) => state.emission.fuelList);
  const currentYear = new Date().getFullYear().toString();
  const [selectedFile, setSelectedFile] = useState(null);

  const formik = useFormik({
    initialValues: {
      facility: "",
      year: currentYear,
      month: "",
      investmentType: "",
      calculationMethod: "",
      averagedatamethod: "",
      averageDataSubMethod: "",
      buildingTypeUnit: "",
      scope1Emission: "",
      scope2Emission: "",
      scope1Unit: "",
      scope2Unit: "",
      shareOfEquity: "",
      sectorInvestment: "",
      industry: "",
      industrySubcategory: "",
      projectConstructionRevenue: "",
      currency: "",
      shareOfProject: "",
      projectedAnnualEmissions: "",
      projectedUnit: "",
      projectLifetime: "",
      numberofbuilding: "",
      averageemissionfactor: "",
      totalFloorSpace: "",
      buildingType: "",
      franchisename: "",
      country: "",
      city: "",
    },
    validationSchema: addMobileCombustionValidation,
    onSubmit: () => {},
  });

  const selectedUnitType = activityTypesData.find(
    (item) => item.id === formik.values.activityType
  );

  const calculationNamesMap = {};
  // const calculationInputList = () =>
  //   emissionPerformData?.data?.calculation_methods?.filter((item) => item.id === formik.values.investmentType)
  //     .map((item) => {
  //       calculationNamesMap[item.id] = item.name; // Populate namesMap
  //       return {
  //         key: item.id,
  //         value: item.name,
  //       };
  //     }) || [];
  const calculationMap = {};

  // const calculationInputList = () => {
  //   const availableCalculationMethod = emissionPerformData?.data?.investment_types?.find(
  //     (item) => item.id === formik.values.investmentType
  //   )?.calculation_methods;

  //   if (!availableCalculationMethod) return [];

  //   return emissionPerformData?.data?.calculation_method
  //     ?.filter((item) => availableCalculationMethod.includes(item.name))
  //     .map((item) => ({ key: item.id, value: item.name })) || [];
  // };
  const normalize = (str) => str.toLowerCase().replace(/[\s-]/g, "");

  const calculationInputList = () => {
    const selectedInvestment =
      emissionPerformData?.data?.investment_types?.find(
        (item) => item.id === formik.values.investmentType
      );

    if (!selectedInvestment || !selectedInvestment.calculation_methods) {
      return [];
    }

    return (
      emissionPerformData?.data?.calculation_method
        ?.filter((method) =>
          selectedInvestment.calculation_methods.some(
            (name) => normalize(name) === normalize(method.name)
          )
        )
        .map((item) => ({
          key: item.id,
          value: item.name,
        })) || []
    );
  };

  // const calculationInputList =
  //   emissionPerformData?.data?.calculation_method?.map((item) => {
  //     calculationMap[item.id] = item.name.toLowerCase();
  //     return {
  //       key: item.id,
  //       value: item.name,
  //     };
  //   });
  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));
  const activityType = activityTypesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const averageMethodMap = {};

  const averageMethodList = emissionPerformData?.data?.average_data_types?.map(
    (item) => {
      averageMethodMap[item.id] = item.name.toLowerCase();
      return {
        key: item.id,
        value: item.name,
      };
    }
  );
  const buildingTypeList = emissionPerformData?.data?.building_types?.map(
    (item) => {
      return {
        key: item.id,
        value: item.name,
      };
    }
  );

  const industryTypeList = emissionPerformData?.data?.investment_types?.map(
    (item) => {
      return {
        key: item.id,
        value: item.name,
      };
    }
  );

  const industrySubList = emissionPerformData?.data?.industry_sub_sector
    ?.filter((item) => item.industry_id === formik.values.industry)
    ?.map((item) => {
      return {
        key: item.id,
        value: item.name,
      };
    });
  const industryList = emissionPerformData?.data?.industries
    ?.filter((item) => item.sector_id === formik.values.sectorInvestment)
    ?.map((item) => {
      return {
        key: item.id,
        value: item.name,
      };
    });
  const currencyInputList = emissionPerformData?.data?.currency?.map((item) => {
    return {
      key: item.id,
      value: item.name,
    };
  });

  const sectorList = emissionPerformData?.data?.sectors?.map((item) => {
    return {
      key: item.id,
      value: item.name,
    };
  });

  const unitList = emissionPerformData?.data?.unit?.map((item) => {
    return {
      key: item.id,
      value: item.name,
    };
  });

  const buildingTypeUnitList = emissionPerformData?.data?.units?.map((item) => {
    return {
      key: item.name,
      value: item.name,
    };
  });

  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));

  //   const calculationNamesMap = {};
  //   const calculationInputList =
  //     emissionPerformData?.data?.calculation_method?.map((item) => {
  //       calculationNamesMap[item.id] = item.name; // Populate namesMap
  //       return {
  //         key: item.id,
  //         value: item.name,
  //       };
  //     }) || [];

  useEffect(() => {
    dispatch(getEmissionFuelList("investments"));
    return () => {
      dispatch(resetAddCombustionStatus());
    };
  }, [dispatch]);

  //   useEffect(() => {
  //     dispatch(getCountryList());
  //   }, [dispatch]);

  useEffect(() => {
    if (addEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar("Investments added successfully", {
        variant: "success",
      });
      dispatch(resetAddCombustionStatus());
      onCancel();
    } else if (addEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar(
        addEmissionData.message.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
    }
  }, [addEmissionData, dispatch, enqueueSnackbar, onCancel]);

  const onCalculate = () => {
    const requestData = {
      facility_id: formik.values.facility,
      year: formik.values.year,
      month: formik.values.month,
      investment_type_id: formik.values.investmentType,
      calculation_method: formik.values.calculationMethod,
      scope1_emissions: formik.values.scope1Emission,
      scope2_emissions: formik.values.scope2Emission,
      scope1_unit_id: formik.values.scope1Unit,
      scope2_unit_id: formik.values.scope2Unit,
      share_percentage: formik.values.shareOfEquity,
      sector_id: formik.values.sectorInvestment,
      industry_id: formik.values.industry,
      industry_subcategory_id: formik.values.industrySubcategory,
      total_revenue: formik.values.projectConstructionRevenue,
      projected_annual_emissions: formik.values.projectedAnnualEmissions,
      projected_annual_emissions_unit_id: formik.values.projectedUnit,
      project_lifetime: formik.values.projectLifetime,
      currency_id: formik.values.currency,
      save: false,
      file: selectedFile,
    };
    dispatch(addInvestments(requestData));
  };

  const onAddMobileCombustionData = () => {
    const requestData = {
      facility_id: formik.values.facility,
      year: formik.values.year,
      month: formik.values.month,
      calculation_method: formik.values.calculationMethod,
      scope1_emissions: formik.values.scope1Emission,
      scope2_emissions: formik.values.scope2Emission,
      scope1_unit_id: formik.values.scope1Unit,
      scope2_unit_id: formik.values.scope2Unit,
      share_percentage: formik.values.shareOfEquity,
      sector_id: formik.values.sectorInvestment,
      industry_id: formik.values.industry,
      industry_subcategory_id: formik.values.industrySubcategory,
      total_revenue: formik.values.projectConstructionRevenue,
      project_lifetime: formik.values.projectLifetime,
      currency: formik.values.currency,
      investment_type_id: formik.values.investmentType,
      projected_annual_emissions: formik.values.projectedAnnualEmissions,
      projected_annual_emissions_unit_id: formik.values.projectedUnit,
      currency_id: formik.values.currency,
      save: true,
      file: selectedFile,
    };
    sessionStorage.setItem("year", requestData.year);
    dispatch(addInvestments(requestData));
  };
  const averageUnitInputList = emissionPerformData?.data?.mass_unit?.map(
    (item) => ({
      key: item.name,
      value: item.name,
    })
  );

  const unitInputList = emissionPerformData?.data?.currency?.map((item) => ({
    key: item.name,
    value: item.name,
  }));

  const countryList = emissionPerformData?.data?.countries?.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const selectedMethod = emissionPerformData?.data?.calculation_method?.find(
    (item) => item.id === formik.values.calculationMethod
  );

  const [isOpenEmissionTypeUpload, setIsOpenEmissionTypeUpload] =
    useState(false);
  const onCloseDrawerUpload = () => {
    setIsOpenEmissionTypeUpload(false);
  };
  const handleFileUpload = (file) => {
    setSelectedFile(file);
    // dispatch(uploadEmissionAttachement("emissionId", file));
  };

  const handleFileRemove = () => {
    setSelectedFile(null);
  };
  const info = () => {
    return (
      <div>
        <Typography variant="body2">
          Fuel use: Mobile combustion by the usage of fuel for mobile operations
          such as transport.
          <br />
          Distance activity: Distance travelled by the usage of fuel
        </Typography>
      </div>
    );
  };

  return (
    <>
      <div className={classes.backContainer}>
        <div
          className={classes.backButtonDiv}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIosIcon />
          Back
        </div>
        <div className={classes.templateButtonDiv}></div>
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Typography className={classes.title} variant="h6" component="div">
            Add Investments
          </Typography>

          <Box className={classes.topContainer}>
            <Grid className={classes.dynamicBoard}>
              {/* Always Visible Fields */}
              <Grid item className={classes.individualInput}>
                <CeroSelect
                  required
                  id="facility"
                  name="facility"
                  label="Facility"
                  fullWidth
                  options={facilitiesList}
                  selectedValue={formik.values.facility}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.facility && formik.errors.facility}
                />
              </Grid>

              <Grid item className={classes.individualInput}>
                <CeroAutoComplete
                  id="year"
                  name="year"
                  label="Assessment Year"
                  onChange={(e, value) =>
                    formik.setFieldValue("year", value.id)
                  }
                  onBlur={formik.handleBlur}
                  error={formik.touched.year && formik.errors.year}
                  options={yearList}
                  value={formik.values.year}
                />
              </Grid>

              <Grid item className={classes.individualInput}>
                <CeroSelect
                  required
                  id="month"
                  name="month"
                  label="Month"
                  fullWidth
                  options={months}
                  selectedValue={formik.values.month}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.month && formik.errors.month}
                />
              </Grid>

              <Grid item className={classes.individualInput}>
                <CeroSelect
                  required
                  id="investmentType"
                  name="investmentType"
                  label="Type of Financial Investment"
                  fullWidth
                  options={industryTypeList}
                  selectedValue={formik.values.investmentType}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.investmentType &&
                    formik.errors.investmentType
                  }
                />
              </Grid>

              {formik.values.investmentType !== "" && (
                <Grid item className={classes.individualInput}>
                  <CeroSelect
                    required
                    id="calculationMethod"
                    name="calculationMethod"
                    label="Choose the type of direct use phase emissions"
                    fullWidth
                    options={calculationInputList()}
                    selectedValue={formik.values.calculationMethod}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.calculationMethod &&
                      formik.errors.calculationMethod
                    }
                    classes={{
                      input: classes.input,
                      container: classes.selectContainer,
                    }}
                  />
                </Grid>
              )}

              {formik.values.calculationMethod &&
                selectedMethod?.name?.toLowerCase() ===
                  "investment-specific method" && (
                  <>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="scope1Emission"
                        name="scope1Emission"
                        label="Scope 1 emissions of equity investment"
                        value={formik.values.scope1Emission}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.scope1Emission &&
                          formik.errors.scope1Emission
                        }
                      />
                    </Grid>

                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="scope1Unit"
                        name="scope1Unit"
                        label="unit"
                        fullWidth
                        options={unitList}
                        selectedValue={formik.values.scope1Unit || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.scope1Unit && formik.errors.scope1Unit
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="scope2Emission"
                        name="scope2Emission"
                        label="Scope 2 emissions of equity investment"
                        value={formik.values.scope2Emission}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.scope2Emission &&
                          formik.errors.scope2Emission
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="scope2Unit"
                        name="scope2Unit"
                        label="unit"
                        fullWidth
                        options={unitList}
                        selectedValue={formik.values.scope2Unit || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.scope2Unit && formik.errors.scope2Unit
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="shareOfEquity"
                        name="shareOfEquity"
                        label="Share of equity(%)"
                        value={formik.values.shareOfEquity}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.shareOfEquity &&
                          formik.errors.shareOfEquity
                        }
                      />
                    </Grid>
                  </>
                )}

              {formik.values.calculationMethod &&
                selectedMethod?.name?.toLowerCase() === "average-data method" &&
                emissionPerformData?.data?.investment_types?.find(
                  (item) => item.id === formik.values.investmentType
                )?.name === "Equity Investments" && (
                  <>
                    {/* Equity Investments - Average Data Method Fields */}
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="sectorInvestment"
                        name="sectorInvestment"
                        label="Sector related to investment"
                        fullWidth
                        options={sectorList}
                        selectedValue={formik.values.sectorInvestment || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.sectorInvestment &&
                          formik.errors.sectorInvestment
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="industry"
                        name="industry"
                        label="Industry"
                        fullWidth
                        options={industryList}
                        selectedValue={formik.values.industry || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.industry && formik.errors.industry
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="industrySubcategory"
                        name="industrySubcategory"
                        label="Industry Subcategory"
                        fullWidth
                        options={industrySubList}
                        selectedValue={formik.values.industrySubcategory || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.industrySubcategory &&
                          formik.errors.industrySubcategory
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="projectConstructionRevenue"
                        name="projectConstructionRevenue"
                        label="Investee company total revenue"
                        value={formik.values.projectConstructionRevenue}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.projectConstructionRevenue &&
                          formik.errors.projectConstructionRevenue
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="currency"
                        name="currency"
                        label="Currency"
                        fullWidth
                        options={currencyInputList}
                        selectedValue={formik.values.currency || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.currency && formik.errors.currency
                        }
                      />
                    </Grid>
                    <CeroInput
                      required
                      id="shareOfEquity"
                      name="shareOfEquity"
                      label="Share of equity(%)"
                      value={formik.values.shareOfEquity}
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.shareOfEquity &&
                        formik.errors.shareOfEquity
                      }
                    />
                  </>
                )}

              {formik.values.calculationMethod &&
                selectedMethod?.name?.toLowerCase() === "average-data method" &&
                emissionPerformData?.data?.investment_types?.find(
                  (item) => item.id === formik.values.investmentType
                )?.name === "Project Finance & Debt Investments" && (
                  <>
                    {/* Project Finance & Debt Investments - Average Data Method Fields */}
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="sectorInvestment"
                        name="sectorInvestment"
                        label="Product construction/operating sector"
                        fullWidth
                        options={sectorList}
                        selectedValue={formik.values.sectorInvestment || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.sectorInvestment &&
                          formik.errors.sectorInvestment
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="industry"
                        name="industry"
                        label="Industry"
                        fullWidth
                        options={industryList}
                        selectedValue={formik.values.industry || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.industry && formik.errors.industry
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="industrySubcategory"
                        name="industrySubcategory"
                        label="Industry Subcategory"
                        fullWidth
                        options={industrySubList}
                        selectedValue={formik.values.industrySubcategory || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.industrySubcategory &&
                          formik.errors.industrySubcategory
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="projectConstructionRevenue"
                        name="projectConstructionRevenue"
                        label="Project construction cost/ project revenue in the reporting year-($)"
                        value={formik.values.projectConstructionRevenue}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.projectConstructionRevenue &&
                          formik.errors.projectConstructionRevenue
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="currency"
                        name="currency"
                        label="Currency"
                        fullWidth
                        options={currencyInputList}
                        selectedValue={formik.values.currency || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.currency && formik.errors.currency
                        }
                      />
                    </Grid>
                    <CeroInput
                      required
                      id="shareOfEquity"
                      name="shareOfEquity"
                      label="Share of equity(%)"
                      value={formik.values.shareOfEquity}
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.shareOfEquity &&
                        formik.errors.shareOfEquity
                      }
                    />
                  </>
                )}

              {formik.values.calculationMethod &&
                selectedMethod?.name?.toLowerCase() ===
                  "project-specific method" && (
                  <>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="scope1Emission"
                        name="scope1Emission"
                        label="Scope 1 emissions of relevant project"
                        value={formik.values.scope1Emission}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.scope1Emission &&
                          formik.errors.scope1Emission
                        }
                      />
                    </Grid>

                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="scope1Unit"
                        name="scope1Unit"
                        label="unit"
                        fullWidth
                        options={unitList}
                        selectedValue={formik.values.scope1Unit || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.scope1Unit && formik.errors.scope1Unit
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="scope2Emission"
                        name="scope2Emission"
                        label="Scope 2 emissions of relevant project"
                        value={formik.values.scope2Emission}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.scope2Emission &&
                          formik.errors.scope2Emission
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="scope2Unit"
                        name="scope2Unit"
                        label="unit"
                        fullWidth
                        options={unitList}
                        selectedValue={formik.values.scope2Unit || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.scope2Unit && formik.errors.scope2Unit
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="shareOfEquity"
                        name="shareOfEquity"
                        label="Share of total project costs(%)"
                        value={formik.values.shareOfEquity}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.shareOfEquity &&
                          formik.errors.shareOfEquity
                        }
                      />
                    </Grid>
                  </>
                )}

              {formik.values.calculationMethod &&
                selectedMethod?.name?.toLowerCase() ===
                  "lifetime-emissions" && (
                  <>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="projectedAnnualEmissions"
                        name="projectedAnnualEmissions"
                        label="Projected annual emissions of project"
                        value={formik.values.projectedAnnualEmissions}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.scope1Emission &&
                          formik.errors.projectedAnnualEmissions
                        }
                      />
                    </Grid>

                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="projectedUnit"
                        name="projectedUnit"
                        label="unit"
                        fullWidth
                        options={unitList}
                        selectedValue={formik.values.projectedUnit || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.projectedUnit &&
                          formik.errors.projectedUnit
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="projectLifetime"
                        name="projectLifetime"
                        label="Projected lifetime of project - (years)"
                        value={formik.values.projectLifetime}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.scope2Emission &&
                          formik.errors.projectLifetime
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="shareOfEquity"
                        name="shareOfEquity"
                        label="Share of total project costs(%)"
                        value={formik.values.shareOfEquity}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.shareOfEquity &&
                          formik.errors.shareOfEquity
                        }
                      />
                    </Grid>
                  </>
                )}
            </Grid>

            <CeroButton
              buttonText="Calculate"
              className={classes.buttonPrimary}
              onClick={onCalculate}
              disabled={!formik.dirty || !formik.isValid}
            />
            <CeroButton
              buttonText="Clear"
              variant="outlined"
              className={classes.buttonSecondary}
              onClick={formik.resetForm}
            />
            {isCalculateDone && (
              <Box className={classes.bottomContainer}>
                <Typography
                  variant="h6"
                  component="h6"
                  className={classes.previewTitle}
                >
                  Emission Preview
                </Typography>
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  justifyContent="space-between"
                  spacing={8}
                >
                  <Grid item container direction="column" xs={12} md={6}>
                    <CeroItemPair
                      title={
                        <>
                          CO<sub>2</sub>
                        </>
                      }
                      value={`${addEmissionData.data.co2} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={
                        <>
                          CH<sub>4</sub>
                        </>
                      }
                      value={`${addEmissionData.data.ch4} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={
                        <>
                          BioFuel CO<sub>2</sub>
                        </>
                      }
                      value={`${addEmissionData.data.biofuel_co2} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                  </Grid>
                  <Grid
                    className={classes.secondResultContainer}
                    item
                    container
                    direction="column"
                    xs={6}
                  >
                    <CeroItemPair
                      title={
                        <>
                          CO<sub>2</sub>e
                        </>
                      }
                      value={`${addEmissionData.data.co2e} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={
                        <>
                          N<sub>2</sub>O
                        </>
                      }
                      value={`${addEmissionData.data.n2o} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={<>EF</>}
                      value={
                        <>
                          {addEmissionData.data.ef} kgCO<sub>2</sub>e/unit
                        </>
                      }
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
          <FileUpload
            onFileUpload={handleFileUpload}
            onFileRemove={handleFileRemove}
          />
        </Box>
        <Box className={classes.buttonContainer}>
          <CeroButton
            buttonText="Add Data"
            disabled={
              !isCalculateDone || addEmissionData.status === STATUS.RUNNING
            }
            className={classes.buttonPrimary}
            onClick={() => onAddMobileCombustionData(formik.values)}
          />

          {/* <div className={classes.buttonContainer}>
                <CeroButton
                   buttonText="Bulk Data Upload"
                   className={classes.bulkButton}
                   startIcon={<FileUploadIcon />}
                   onClick={() => setIsOpenEmissionTypeUpload(true)}
                />
                <CeroButton
                    buttonText="Download Template"
                    className={classes.bulkButton}
                    startIcon={<DownloadIcon />}
                    onClick={onDownload}
                  />
                </div> */}
        </Box>
        <EmissionUploadDrawer
          isOpen={isOpenEmissionTypeUpload}
          onClose={onCloseDrawerUpload}
          onUpload={onUpload}
          onValidate={onValidate}
          uploadDisabled={uploadDisabled}
        />
      </Container>
    </>
  );
};

export default AddInvestments;
