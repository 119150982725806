import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Typography, Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { months, sampleYear } from "../../../constants";
import { STATUS } from "../../../redux/constants";
import { addMobileCombustionValidation } from "./schema";
import {
  getEmissionFuelList,
  resetAddCombustionStatus,
  updateInvestments,
} from "../../../redux/actions";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import CeroButton from "../../../components/CeroButton";
import CeroSelect from "../../../components/CeroSelect";
import CeroInput from "../../../components/CeroInput";
import useStyles from "./styles";
import EmissionUploadDrawer from "../../EmissionList/EmissionUploadDrawer";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CeroItemPair from "../../../components/CeroItemPair";
import FileUpload from "../../AddEmissions/FileUpload";

const EditInvestments = (props) => {
  const {
    onCancel,
    onUpload,
    onValidate,
    uploadDisabled,
    emissionData,
    emissionId,
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const isCalculateDone = useSelector(
    (state) => state.emission.updateInvestments.isCalculateDone
  );
  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );

  const editEmissionData = useSelector(
    (state) => state.emission.updateInvestments
  );
  const emissionPerformData = useSelector((state) => state.emission.fuelList);
  const currentYear = new Date().getFullYear().toString();
  const [selectedFile, setSelectedFile] = useState(null);

  const formik = useFormik({
    initialValues: {
      facility: emissionData.facility_id || "",
      year: emissionData.year || currentYear,
      month: emissionData.month || "",
      investmentType:emissionData.investment_type_id || "",
      scope1Emission:emissionData.scope1_emissions || 0,
      calculationMethod: emissionData.calculation_method || "",
      scope2Emission:emissionData.scope2_emissions || 0,
      scope1Unit:emissionData.scope1_unit || "",
      scope2Unit:emissionData.scope2_unit || "",
      shareOfEquity:emissionData.share_percentage || 0,
      sectorInvestment:emissionData.sector || "",
      industry:emissionData.industry_id|| "",
      industrySubcategory:emissionData.industry_subcategory_id|| "",
      projectConstructionRevenue:emissionData.total_revenue|| 0,
      projectedAnnualEmissions:emissionData.projected_annual_emissions|| 0,
      projectedUnit:emissionData.projected_annual_emissions_unit|| "",
      projectLifetime:emissionData.project_lifetime|| 0,
      currency:emissionData.currency|| "",
    },
    validationSchema: addMobileCombustionValidation,
    onSubmit: () => {},
    enableReinitialize: true,
  });

  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));

  const emissionTypeList =
    emissionPerformData?.data?.type_of_emission_form?.map((item) => {
      return {
        key: item.id,
        value: item.name,
      };
    });

  const calculationNamesMap = {};
  const unitMap = {};
  var electricityId = "";
  const normalize = (str) =>
    str.toLowerCase().replace(/[\s-]/g, "");
  
  const calculationInputList = () => {
    const selectedInvestment = emissionPerformData?.data?.investment_types?.find(
      (item) => item.id === formik.values.investmentType
    );
  
    if (!selectedInvestment || !selectedInvestment.calculation_methods) {
      return [];
    }
  
    return emissionPerformData?.data?.calculation_method
      ?.filter((method) =>
        selectedInvestment.calculation_methods.some(
          (name) => normalize(name) === normalize(method.name)
        )
      )
      .map((item) => ({
        key: item.id,
        value: item.name,
      })) || [];
  };
 
const industryTypeList = emissionPerformData?.data?.investment_types?.map(
    (item) => {
      return {
        key: item.id,
        value: item.name,
      };
    }
  );
  
  const industrySubList = emissionPerformData?.data?.industry_sub_sector?.map(
    (item) => {
      return {
        key: item.id,
        value: item.name,
      };
    }
  );
  const industryList = emissionPerformData?.data?.industries?.map(
    (item) => {
      return {
        key: item.id,
        value: item.name,
      };
    }

  );
  const currencyInputList = emissionPerformData?.data?.currency?.map(
    (item) => {
      return {
        key: item.id,
        value: item.name,
      };
    }
  );
  
  const sectorList = emissionPerformData?.data?.sectors?.map(
    (item) => {
      return {
        key: item.id,
        value: item.name,
      };
    }
  );



  const unitList = emissionPerformData?.data?.unit?.map(
    (item) => {
      return {
        key: item.id,
        value: item.name,
      };
    }
  );


   const addEmissionData = useSelector(
     (state) => state.emission.addInvestments
   );
  
  const selectedMethod = emissionPerformData?.data?.calculation_method?.find(
    (item) => item.id === formik.values.calculationMethod
  );

  useEffect(() => {
    dispatch(getEmissionFuelList("investments"));
    return () => {
      dispatch(resetAddCombustionStatus());
    };
  }, [dispatch]);

  useEffect(() => {
    if (editEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar("Investments data updated successfully", {
        variant: "success",
      });
      dispatch(resetAddCombustionStatus());
      onCancel();
    } else if (editEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar(
        editEmissionData.message.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
    }
  }, [editEmissionData, dispatch, enqueueSnackbar, onCancel]);

  const onCalculate = () => {
    const requestData = {
      id: emissionId,
      facility_id: formik.values.facility,
      year: formik.values.year,
      month: formik.values.month,
      investment_type_id: formik.values.investmentType,
      calculation_method: formik.values.calculationMethod,
      scope1_emissions:formik.values.scope1Emission,
      scope2_emissions:formik.values.scope2Emission,
      scope1_unit_id:formik.values.scope1Unit,
      scope2_unit_id:formik.values.scope2Unit,
      share_percentage:formik.values.shareOfEquity,
      sector_id:formik.values.sectorInvestment,
      industry_id:formik.values.industry,
      industry_subcategory_id:formik.values.industrySubcategory,
      total_revenue:formik.values.projectConstructionRevenue,
      projected_annual_emissions:formik.values.projectedAnnualEmissions,
      projected_annual_emissions_unit_id:formik.values.projectedUnit,
      project_lifetime:formik.values.projectLifetime,
      currency_id:formik.values.currency,
      save: false,
      file: selectedFile,
    };
    dispatch(updateInvestments(requestData));
  };

  const onAddMobileCombustionData = () => {
    const requestData = {
      id: emissionId,
      facility_id: formik.values.facility,
      year: formik.values.year,
      month: formik.values.month,
      calculation_method: formik.values.calculationMethod,
      scope1_emissions:formik.values.scope1Emission,
      scope2_emissions:formik.values.scope2Emission,
      scope1_unit_id:formik.values.scope1Unit,
      scope2_unit_id:formik.values.scope2Unit,
      share_percentage:formik.values.shareOfEquity,
      sector_id:formik.values.sectorInvestment,
      industry_id:formik.values.industry,
      industry_subcategory_id:formik.values.industrySubcategory,
      total_revenue:formik.values.projectConstructionRevenue,
      project_lifetime:formik.values.projectLifetime,
      currency_id:formik.values.currency,
      investment_type_id: formik.values.investmentType,
      projected_annual_emissions:formik.values.projectedAnnualEmissions,
      projected_annual_emissions_unit_id:formik.values.projectedUnit,
      
      save: true,
      file: selectedFile,
    };
    sessionStorage.setItem("year", requestData.year);
    dispatch(updateInvestments(requestData));
  };

  const [isOpenEmissionTypeUpload, setIsOpenEmissionTypeUpload] =
    useState(false);
  const onCloseDrawerUpload = () => {
    setIsOpenEmissionTypeUpload(false);
  };
  const handleFileUpload = (file) => {
    setSelectedFile(file);
    // dispatch(uploadEmissionAttachement("emissionId", file));
  };

  const handleFileRemove = () => {
    setSelectedFile(null);
  };

  return (
    <>
      <div className={classes.backContainer}>
        <div
          className={classes.backButtonDiv}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIosIcon />
          Back
        </div>
        <div className={classes.templateButtonDiv}></div>
      </div>
      <Container className={classes.container}>
              <Box className={classes.innerContainer}>
              <Typography className={classes.title} variant="h6" component="div">
        Edit Investments
      </Typography>
      
      <Box className={classes.topContainer}>
        <Grid className={classes.dynamicBoard}>
          {/* Always Visible Fields */}
          <Grid item className={classes.individualInput}>
            <CeroSelect
              required
              id="facility"
              name="facility"
              label="Facility"
              fullWidth
              options={facilitiesList}
              selectedValue={formik.values.facility}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.facility && formik.errors.facility}
            />
          </Grid>
      
          <Grid item className={classes.individualInput}>
            <CeroAutoComplete
              id="year"
              name="year"
              label="Assessment Year"
              onChange={(e, value) =>
                formik.setFieldValue("year", value.id)
              }
              onBlur={formik.handleBlur}
              error={formik.touched.year && formik.errors.year}
              options={yearList}
              value={formik.values.year}
            />
          </Grid>
      
          <Grid item className={classes.individualInput}>
            <CeroSelect
              required
              id="month"
              name="month"
              label="Month"
              fullWidth
              options={months}
              selectedValue={formik.values.month}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.month && formik.errors.month}
            />
          </Grid>
      
          <Grid item className={classes.individualInput}>
            <CeroSelect
              required
              id="investmentType"
              name="investmentType"
              label="Type of Financial Investment"
              fullWidth
              options={industryTypeList}
              selectedValue={formik.values.investmentType}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.investmentType && formik.errors.investmentType}
            />
          </Grid>
      
          {formik.values.investmentType !== "" && (
                      <Grid item className={classes.individualInput}>
                        <CeroSelect
                          required
                          id="calculationMethod"
                          name="calculationMethod"
                          label="Choose the type of direct use phase emissions"
                          fullWidth
                          options={calculationInputList()}
                          selectedValue={formik.values.calculationMethod}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.calculationMethod && formik.errors.calculationMethod}
                          classes={{
                            input: classes.input,
                            container: classes.selectContainer,
                          }}
                        />
                      </Grid>
                    )}
      
                    {formik.values.calculationMethod &&
                      selectedMethod?.name?.toLowerCase() === "investment-specific method" && (
                        <>
                        <Grid item className={classes.individualInput}>
                            <CeroInput
                              required
                              id="scope1Emission"
                              name="scope1Emission"
                              label="Scope 1 emissions of equity investment"
                              value={formik.values.scope1Emission}
                              fullWidth
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.scope1Emission && formik.errors.scope1Emission
                              }
                            />
                          </Grid>
                        
                          <Grid item className={classes.individualInput}>
                                          <CeroSelect
                              required
                              id="scope1Unit"
                              name="scope1Unit"
                              label="unit"
                              fullWidth
                              options={unitList}
                              selectedValue={formik.values.scope1Unit || ""}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.scope1Unit &&
                                formik.errors.scope1Unit
                              }
                            />
                          </Grid>
                        <Grid item className={classes.individualInput}>
                          <CeroInput
                            required
                            id="scope2Emission"
                            name="scope2Emission"
                            label="Scope 2 emissions of equity investment"
                            value={formik.values.scope2Emission}
                            fullWidth
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.scope2Emission && formik.errors.scope2Emission
                            }
                            type="number"
                          />
                        </Grid>
                        <Grid item className={classes.individualInput}>
                            <CeroSelect
                              required
                              id="scope2Unit"
                              name="scope2Unit"
                              label="unit"
                              fullWidth
                              options={unitList}
                              selectedValue={formik.values.scope2Unit || ""}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.scope2Unit &&
                                formik.errors.scope2Unit
                              }
                            />
                          </Grid>
                          <Grid item className={classes.individualInput}>
                              <CeroInput
                                required
                                id="shareOfEquity"
                                name="shareOfEquity"
                                label="Share of equity(%)"
                                value={formik.values.shareOfEquity}
                                fullWidth
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                  formik.touched.shareOfEquity && formik.errors.shareOfEquity
                                }
                              />
                            </Grid>
                            </>
                      )}
      
      {formik.values.calculationMethod &&
        selectedMethod?.name?.toLowerCase() === "average-data method" &&
        emissionPerformData?.data?.investment_types?.find((item) => item.id === formik.values.investmentType)?.name === "Equity Investments" && (
          <>
            {/* Equity Investments - Average Data Method Fields */}
             <Grid item className={classes.individualInput}>
                            <CeroSelect
                              required
                              id="sectorInvestment"
                              name="sectorInvestment"
                              label="Sector related to investment"
                              fullWidth
                              options={sectorList}
                              selectedValue={formik.values.sectorInvestment || ""}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.sectorInvestment &&
                                formik.errors.sectorInvestment
                              }
                            />
                          </Grid>
                           <Grid item className={classes.individualInput}>
                            <CeroSelect
                              required
                              id="industry"
                              name="industry"
                              label="Industry"
                              fullWidth
                              options={industryList}
                              selectedValue={formik.values.industry || ""}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.industry &&
                                formik.errors.industry
                              }
                            />
                          </Grid>
                          <Grid item className={classes.individualInput}>
                            <CeroSelect
                              required
                              id="industrySubcategory"
                              name="industrySubcategory"
                              label="Industry Subcategory"
                              fullWidth
                              options={industrySubList}
                              selectedValue={formik.values.industrySubcategory || ""}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.industrySubcategory &&
                                formik.errors.industrySubcategory
                              }
                            />
                          </Grid>
                          <Grid item className={classes.individualInput}>
                        <CeroInput
                          required
                          id="projectConstructionRevenue"
                          name="projectConstructionRevenue"
                          label="Investee company total revenue"
                          value={formik.values.projectConstructionRevenue}
                          fullWidth
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.projectConstructionRevenue && formik.errors.projectConstructionRevenue
                          }
                        />
                      </Grid>
                      <Grid item className={classes.individualInput}>
                            <CeroSelect
                              required
                              id="currency"
                              name="currency"
                              label="Currency"
                              fullWidth
                              options={currencyInputList}
                              selectedValue={formik.values.currency || ""}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.currency &&
                                formik.errors.currency
                              }
                            />
                          </Grid>
                          <CeroInput
                            required
                            id="shareOfEquity"
                            name="shareOfEquity"
                            label="Share of equity(%)"
                            value={formik.values.shareOfEquity}
                            fullWidth
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.shareOfEquity && formik.errors.shareOfEquity
                            }
                          />
                     </>
                    )}
      
                            {formik.values.calculationMethod &&
                              selectedMethod?.name?.toLowerCase() === "average-data method" &&
                              emissionPerformData?.data?.investment_types?.find((item) => item.id === formik.values.investmentType)?.name === "Project Finance & Debt Investments" && (
                                <>
                                  {/* Project Finance & Debt Investments - Average Data Method Fields */}
                                  <Grid item className={classes.individualInput}>
                            <CeroSelect
                              required
                              id="sectorInvestment"
                              name="sectorInvestment"
                              label="Product construction/operating sector"
                              fullWidth
                              options={sectorList}
                              selectedValue={formik.values.sectorInvestment || ""}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.sectorInvestment &&
                                formik.errors.sectorInvestment
                              }
                            />
                          </Grid>
                           <Grid item className={classes.individualInput}>
                            <CeroSelect
                              required
                              id="industry"
                              name="industry"
                              label="Industry"
                              fullWidth
                              options={industryList}
                              selectedValue={formik.values.industry || ""}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.industry &&
                                formik.errors.industry
                              }
                            />
                          </Grid>
                          <Grid item className={classes.individualInput}>
                            <CeroSelect
                              required
                              id="industrySubcategory"
                              name="industrySubcategory"
                              label="Industry Subcategory"
                              fullWidth
                              options={industrySubList}
                              selectedValue={formik.values.industrySubcategory || ""}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.industrySubcategory &&
                                formik.errors.industrySubcategory
                              }
                            />
                          </Grid>
                          <Grid item className={classes.individualInput}>
                        <CeroInput
                          required
                          id="projectConstructionRevenue"
                          name="projectConstructionRevenue"
                          label="Project construction cost/ project revenue in the reporting year-($)"
                          value={formik.values.projectConstructionRevenue}
                          fullWidth
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.projectConstructionRevenue && formik.errors.projectConstructionRevenue
                          }
                        />
                      </Grid>
                      <Grid item className={classes.individualInput}>
                            <CeroSelect
                              required
                              id="currency"
                              name="currency"
                              label="Currency"
                              fullWidth
                              options={currencyInputList}
                              selectedValue={formik.values.currency || ""}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.currency &&
                                formik.errors.currency
                              }
                            />
                          </Grid>
                          <CeroInput
                            required
                            id="shareOfEquity"
                            name="shareOfEquity"
                            label="Share of equity(%)"
                            value={formik.values.shareOfEquity}
                            fullWidth
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.shareOfEquity && formik.errors.shareOfEquity
                            }
                          />
                                      
                        
          </>
      )}
      
      
      {formik.values.calculationMethod &&
                      selectedMethod?.name?.toLowerCase() === "project-specific method" && (
                        <>
                        <Grid item className={classes.individualInput}>
                            <CeroInput
                              required
                              id="scope1Emission"
                              name="scope1Emission"
                              label="Scope 1 emissions of relevant project"
                              value={formik.values.scope1Emission}
                              fullWidth
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.scope1Emission && formik.errors.scope1Emission
                              }
                            />
                          </Grid>
                        
                          <Grid item className={classes.individualInput}>
                                          <CeroSelect
                              required
                              id="scope1Unit"
                              name="scope1Unit"
                              label="unit"
                              fullWidth
                              options={unitList}
                              selectedValue={formik.values.scope1Unit || ""}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.scope1Unit &&
                                formik.errors.scope1Unit
                              }
                            />
                          </Grid>
                        <Grid item className={classes.individualInput}>
                          <CeroInput
                            required
                            id="scope2Emission"
                            name="scope2Emission"
                            label="Scope 2 emissions of relevant project"
                            value={formik.values.scope2Emission}
                            fullWidth
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.scope2Emission && formik.errors.scope2Emission
                            }
                            type="number"
                          />
                        </Grid>
                        <Grid item className={classes.individualInput}>
                            <CeroSelect
                              required
                              id="scope2Unit"
                              name="scope2Unit"
                              label="unit"
                              fullWidth
                              options={unitList}
                              selectedValue={formik.values.scope2Unit || ""}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.scope2Unit &&
                                formik.errors.scope2Unit
                              }
                            />
                          </Grid>
                          <Grid item className={classes.individualInput}>
                              <CeroInput
                                required
                                id="shareOfEquity"
                                name="shareOfEquity"
                                label="Share of total project costs(%)"
                                value={formik.values.shareOfEquity}
                                fullWidth
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                  formik.touched.shareOfEquity && formik.errors.shareOfEquity
                                }
                              />
                            </Grid>
                            </>
                      )}
      
      
      {formik.values.calculationMethod &&
                      selectedMethod?.name?.toLowerCase() === "lifetime-emissions" && (
                        <>
                        <Grid item className={classes.individualInput}>
                            <CeroInput
                              required
                              id="projectedAnnualEmissions"
                              name="projectedAnnualEmissions"
                              label="Projected annual emissions of project"
                              value={formik.values.projectedAnnualEmissions}
                              fullWidth
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.scope1Emission && formik.errors.projectedAnnualEmissions
                              }
                            />
                          </Grid>
                        
                          <Grid item className={classes.individualInput}>
                                          <CeroSelect
                              required
                              id="projectedUnit"
                              name="projectedUnit"
                              label="unit"
                              fullWidth
                              options={unitList}
                              selectedValue={formik.values.projectedUnit || ""}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.projectedUnit &&
                                formik.errors.projectedUnit
                              }
                            />
                          </Grid>
                        <Grid item className={classes.individualInput}>
                          <CeroInput
                            required
                            id="projectLifetime"
                            name="projectLifetime"
                            label="Projected lifetime of project - (years)"
                            value={formik.values.projectLifetime}
                            fullWidth
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.scope2Emission && formik.errors.projectLifetime
                            }
                            type="number"
                          />
                        </Grid>
                          <Grid item className={classes.individualInput}>
                              <CeroInput
                                required
                                id="shareOfEquity"
                                name="shareOfEquity"
                                label="Share of total project costs(%)"
                                value={formik.values.shareOfEquity}
                                fullWidth
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                  formik.touched.shareOfEquity && formik.errors.shareOfEquity
                                }
                              />
                            </Grid>
                            </>
                      )}
                     
                        
                  </Grid>
      
                  <CeroButton
                    buttonText="Calculate"
                    className={classes.buttonPrimary}
                    onClick={onCalculate}
                    disabled={!formik.dirty || !formik.isValid}
                  />
                  <CeroButton
                    buttonText="Clear"
                    variant="outlined"
                    className={classes.buttonSecondary}
                    onClick={formik.resetForm}
                  />
                  {isCalculateDone && (
                    <Box className={classes.bottomContainer}>
                      <Typography
                        variant="h6"
                        component="h6"
                        className={classes.previewTitle}
                      >
                        Emission Preview
                      </Typography>
                      <Grid
                        container
                        direction="row"
                        wrap="nowrap"
                        justifyContent="space-between"
                        spacing={8}
                      >
                        <Grid item container direction="column" xs={12} md={6}>
                          <CeroItemPair
                            title={
                              <>
                                CO<sub>2</sub>
                              </>
                            }
                            value={`${editEmissionData.data.co2} tonnes`}
                            classes={{
                              container: classes.infoContainer,
                              title: classes.emissionTitle,
                              value: classes.value,
                            }}
                          />
                          <CeroItemPair
                            title={
                              <>
                                CH<sub>4</sub>
                              </>
                            }
                            value={`${editEmissionData.data.ch4} tonnes`}
                            classes={{
                              container: classes.infoContainer,
                              title: classes.emissionTitle,
                              value: classes.value,
                            }}
                          />
                          <CeroItemPair
                            title={
                              <>
                                BioFuel CO<sub>2</sub>
                              </>
                            }
                            value={`${editEmissionData.data.biofuel_co2} tonnes`}
                            classes={{
                              container: classes.infoContainer,
                              title: classes.emissionTitle,
                              value: classes.value,
                            }}
                          />
                        </Grid>
                        <Grid
                          className={classes.secondResultContainer}
                          item
                          container
                          direction="column"
                          xs={6}
                        >
                          <CeroItemPair
                            title={
                              <>
                                CO<sub>2</sub>e
                              </>
                            }
                            value={`${editEmissionData.data.co2e} tonnes`}
                            classes={{
                              container: classes.infoContainer,
                              title: classes.emissionTitle,
                              value: classes.value,
                            }}
                          />
                          <CeroItemPair
                            title={
                              <>
                                N<sub>2</sub>O
                              </>
                            }
                            value={`${editEmissionData.data.n2o} tonnes`}
                            classes={{
                              container: classes.infoContainer,
                              title: classes.emissionTitle,
                              value: classes.value,
                            }}
                          />
                          <CeroItemPair
                            title={<>EF</>}
                            value={
                              <>
                                {editEmissionData.data.ef} kgCO<sub>2</sub>e/unit
                              </>
                            }
                            classes={{
                              container: classes.infoContainer,
                              title: classes.emissionTitle,
                              value: classes.value,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Box>
                <FileUpload
                  onFileUpload={handleFileUpload}
                  onFileRemove={handleFileRemove}
                />
              </Box>
              <Box className={classes.buttonContainer}>
                <CeroButton
                  buttonText="Add Data"
                  disabled={
                    !isCalculateDone || editEmissionData.status === STATUS.RUNNING
                  }
                  className={classes.buttonPrimary}
                  onClick={() => onAddMobileCombustionData(formik.values)}
                />
      
                {/* <div className={classes.buttonContainer}>
                      <CeroButton
                         buttonText="Bulk Data Upload"
                         className={classes.bulkButton}
                         startIcon={<FileUploadIcon />}
                         onClick={() => setIsOpenEmissionTypeUpload(true)}
                      />
                      <CeroButton
                          buttonText="Download Template"
                          className={classes.bulkButton}
                          startIcon={<DownloadIcon />}
                          onClick={onDownload}
                        />
                      </div> */}
              </Box>
              <EmissionUploadDrawer
                isOpen={isOpenEmissionTypeUpload}
                onClose={onCloseDrawerUpload}
                onUpload={onUpload}
                onValidate={onValidate}
                uploadDisabled={uploadDisabled}
              />
            </Container>
          </>
        );
      };
      
      export default EditInvestments;