export const ActionTypes = {
  USER_SIGN_UP: "USER_SIGN_UP",
  USER_SIGN_UP_SUCCESS: "USER_SIGN_UP_SUCCESS",
  USER_SIGN_UP_FAILURE: "USER_SIGN_UP_FAILURE",

  AUDITOR_SIGN_UP: "AUDITOR_SIGN_UP",
  AUDITOR_SIGN_UP_SUCCESS: "AUDITOR_SIGN_UP_SUCCESS",
  AUDITOR_SIGN_UP_FAILURE: "AUDITOR_SIGN_UP_FAILURE",

  USER_LOGIN: "USER_LOGIN",
  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  USER_LOGIN_FAILURE: "USER_LOGIN_FAILURE",
  USER_ACTIVE_ROLE: "USER_ACTIVE_ROLE",

  GET_FORGOT_PASSWORD_OTP: "GET_FORGOT_PASSWORD_OTP",
  GET_FORGOT_PASSWORD_OTP_SUCCESS: "GET_FORGOT_PASSWORD_OTP_SUCCESS",
  GET_FORGOT_PASSWORD_OTP_FAILURE: "GET_FORGOT_PASSWORD_OTP_FAILURE",

  VERIFY_FORGOT_PASSWORD_OTP: "VERIFY_FORGOT_PASSWORD_OTP",
  VERIFY_FORGOT_PASSWORD_OTP_SUCCESS: "VERIFY_FORGOT_PASSWORD_OTP_SUCCESS",
  VERIFY_FORGOT_PASSWORD_OTP_FAILURE: "VERIFY_FORGOT_PASSWORD_OTP_FAILURE",

  SUPER_ADMIN_TWOFA_VALIDATION: "SUPER_ADMIN_TWOFA_VALIDATION",
  SUPER_ADMIN_TWOFA_VALIDATION_SUCCESS: "SUPER_ADMIN_TWOFA_VALIDATION_SUCCESS",
  SUPER_ADMIN_TWOFA_VALIDATION_FAILURE: "SUPER_ADMIN_TWOFA_VALIDATION_FAILURE",

  PROXY_SUSTAINABILITY_MANAGER_LOGIN: "PROXY_SUSTAINABILITY_MANAGER_LOGIN",
  PROXY_SUSTAINABILITY_MANAGER_LOGIN_SUCCESS:
    "PROXY_SUSTAINABILITY_MANAGER_LOGIN_SUCCESS",
  PROXY_SUSTAINABILITY_MANAGER_LOGIN_FAILURE:
    "PROXY_SUSTAINABILITY_MANAGER_LOGIN_FAILURE",

  PROXY_LOGIN_ACCESS_SUPERADMIN: "PROXY_LOGIN_ACCESS_SUPERADMIN",
  PROXY_LOGIN_ACCESS_SUPERADMIN_SUCCESS:
    "PROXY_LOGIN_ACCESS_SUPERADMIN_SUCCESS",
  PROXY_LOGIN_ACCESS_SUPERADMIN_FAILURE:
    "PROXY_LOGIN_ACCESS_SUPERADMIN_FAILURE",

  RESET_PROXY_LOGIN_ACCESS_SUPERADMIN: "RESET_PROXY_LOGIN_ACCESS_SUPERADMIN",

  SWITCH_BACK_TO_SUPER_ADMIN: "SWITCH_BACK_TO_SUPER_ADMIN",
  SWITCH_BACK_TO_SUPER_ADMIN_SUCCESS: "SWITCH_BACK_TO_SUPER_ADMIN_SUCCESS",
  SWITCH_BACK_TO_SUPER_ADMIN_FAILURE: "SWITCH_BACK_TO_SUPER_ADMIN_FAILURE",

  TRADE_LOGIN_ARRO_USER: "TRADE_LOGIN_ARRO_USER",
  TRADE_LOGIN_ARRO_USER_SUCCESS: "TRADE_LOGIN_ARRO_USER_SUCCESS",
  TRADE_LOGIN_ARRO_USER_FAILURE: "TRADE_LOGIN_ARRO_USER_FAILURE",

  RESET_PASSWORD: "RESET_PASSWORD",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",

  RESET_AUTH_STATUS: "RESET_AUTH_STATUS",
  RESET_AUTHENTICATION: "RESET_AUTHENTICATION",

  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",

  USER_INVITE_LOGIN: "USER_INVITE_LOGIN",
  USER_INVITE_LOGIN_SUCCESS: "USER_INVITE_LOGIN_SUCCESS",
  USER_INVITE_LOGIN_FAILURE: "USER_INVITE_LOGIN_FAILURE",

  GET_ACCOUNT_DETAILS: "GET_ACCOUNT_DETAILS",
  GET_ACCOUNT_DETAILS_SUCCESS: "GET_ACCOUNT_DETAILS_SUCCESS",
  GET_ACCOUNT_DETAILS_FAILURE: "GET_ACCOUNT_DETAILS_FAILURE",

  UPDATE_ACCOUNT_DETAILS: "UPDATE_ACCOUNT_DETAILS",
  UPDATE_ACCOUNT_DETAILS_SUCCESS: "UPDATE_ACCOUNT_DETAILS_SUCCESS",
  UPDATE_ACCOUNT_DETAILS_FAILURE: "UPDATE_ACCOUNT_DETAILS_FAILURE",

  GET_USER_COMPANY_DETAILS: "GET_USER_COMPANY_DETAILS",
  GET_USER_COMPANY_DETAILS_SUCCESS: "GET_USER_COMPANY_DETAILS_SUCCESS",
  GET_USER_COMPANY_DETAILS_FAILURE: "GET_USER_COMPANY_DETAILS_FAILURE",

  UPDATE_COMPANY_DETAILS: "UPDATE_COMPANY_DETAILS",
  UPDATE_COMPANY_DETAILS_SUCCESS: "UPDATE_COMPANY_DETAILS_SUCCESS",
  UPDATE_COMPANY_DETAILS_FAILURE: "UPDATE_COMPANY_DETAILS_FAILURE",

  RESET_ACCOUNT_STATUS: "RESET_ACCOUNT_STATUS",

  GET_EMISSION_LIST: "GET_EMISSION_LIST",
  GET_EMISSION_LIST_SUCCESS: "GET_EMISSION_LIST_SUCCESS",
  GET_EMISSION_LIST_FAILURE: "GET_EMISSION_LIST_FAILURE",
  CLEAR_EMISSION_LIST: "CLEAR_EMISSION_LIST",

  GET_EMISSION: "GET_EMISSION",
  GET_EMISSION_SUCCESS: "GET_EMISSION_SUCCESS",
  GET_EMISSION_FAILURE: "GET_EMISSION_FAILURE",

  GET_EMISSION_INPUT_FORMAT: "GET_EMISSION_INPUT_FORMAT",
  GET_EMISSION_INPUT_FORMAT_SUCCESS: "GET_EMISSION_INPUT_FORMAT_SUCCESS",
  GET_EMISSION_INPUT_FORMAT_FAILURE: "GET_EMISSION_INPUT_FORMAT_FAILURE",

  RESET_GET_EMISSION_INPUT_FORMAT_STATUS:
    "RESET_GET_EMISSION_INPUT_FORMAT_STATUS",

  LIST_FACILITIES: "LIST_FACILITIES",
  LIST_FACILITIES_SUCCESS: "LIST_FACILITIES_SUCCESS",
  LIST_FACILITIES_FAILURE: "LIST_FACILITIES_FAILURE",

  GET_FACILITY: "GET_FACILITY",
  GET_FACILITY_SUCCESS: "GET_FACILITY_SUCCESS",
  GET_FACILITY_FAILURE: "GET_FACILITY_FAILURE",

  ADD_FACILITY: "ADD_FACILITY",
  ADD_FACILITY_SUCCESS: "ADD_FACILITY_SUCCESS",
  ADD_FACILITY_FAILURE: "ADD_FACILITY_FAILURE",

  EDIT_FACILITY: "EDIT_FACILITY",
  EDIT_FACILITY_SUCCESS: "EDIT_FACILITY_SUCCESS",
  EDIT_FACILITY_FAILURE: "EDIT_FACILITY_FAILURE",

  DELETE_FACILITY: "DELETE_FACILITY",
  DELETE_FACILITY_SUCCESS: "DELETE_FACILITY_SUCCESS",
  DELETE_FACILITY_FAILURE: "DELETE_FACILITY_FAILURE",

  RESET_ADD_FACILITY_STATUS: "RESET_ADD_FACILITY_STATUS",

  ADD_STATIONARY_COMBUSTION: "ADD_STATIONARY_COMBUSTION",
  ADD_STATIONARY_COMBUSTION_SUCCESS: "ADD_STATIONARY_COMBUSTION_SUCCESS",
  ADD_STATIONARY_COMBUSTION_FAILURE: "ADD_STATIONARY_COMBUSTION_FAILURE",

  ADD_PURCHASED_ELECTRICITY: "ADD_PURCHASED_ELECTRICITY",
  ADD_PURCHASED_ELECTRICITY_SUCCESS: "ADD_PURCHASED_ELECTRICITY_SUCCESS",
  ADD_PURCHASED_ELECTRICITY_FAILURE: "ADD_PURCHASED_ELECTRICITY_FAILURE",

  ADD_REFRIGERANTS: "ADD_REFRIGERANTS",
  ADD_REFRIGERANTS_SUCCESS: "ADD_REFRIGERANTS_SUCCESS",
  ADD_REFRIGERANTS_FAILURE: "ADD_REFRIGERANTS_FAILURE",

  UPDATE_REFRIGERANTS: "UPDATE_REFRIGERANTS",
  UPDATE_REFRIGERANTS_SUCCESS: "UPDATE_REFRIGERANTS_SUCCESS",
  UPDATE_REFRIGERANTS_FAILURE: "UPDATE_REFRIGERANTS_FAILURE",

  UPDATE_PURCHASED_ELECTRICITY: "UPDATE_PURCHASED_ELECTRICITY",
  UPDATE_PURCHASED_ELECTRICITY_SUCCESS: "UPDATE_PURCHASED_ELECTRICITY_SUCCESS",
  UPDATE_PURCHASED_ELECTRICITY_FAILURE: "UPDATE_PURCHASED_ELECTRICITY_FAILURE",

  UPDATE_STATIONARY_COMBUSTION: "UPDATE_STATIONARY_COMBUSTION",
  UPDATE_STATIONARY_COMBUSTION_SUCCESS: "UPDATE_STATIONARY_COMBUSTION_SUCCESS",
  UPDATE_STATIONARY_COMBUSTION_FAILURE: "UPDATE_STATIONARY_COMBUSTION_FAILURE",

  UPDATE_MOBILE_COMBUSTION: "UPDATE_MOBILE_COMBUSTION",
  UPDATE_MOBILE_COMBUSTION_SUCCESS: "UPDATE_MOBILE_COMBUSTION_SUCCESS",
  UPDATE_MOBILE_COMBUSTION_FAILURE: "UPDATE_MOBILE_COMBUSTION_FAILURE",

  UPDATE_WATER_DISCHARGE_COMBUSTION: "UPDATE_WATER_DISCHARGE_COMBUSTION",
  UPDATE_WATER_DISCHARGE_COMBUSTION_SUCCESS:
    "UPDATE_WATER_DISCHARGE_COMBUSTION_SUCCESS",
  UPDATE_WATER_DISCHARGE_COMBUSTION_FAILURE:
    "UPDATE_WATER_DISCHARGE_COMBUSTION_FAILURE",

  UPDATE_WATER_CONSUMPTION_COMBUSTION: "UPDATE_WATER_CONSUMPTION_COMBUSTION",
  UPDATE_WATER_CONSUMPTION_COMBUSTION_SUCCESS:
    "UPDATE_WATER_CONSUMPTION_COMBUSTION_SUCCESS",
  UPDATE_WATER_CONSUMPTION_COMBUSTION_FAILURE:
    "UPDATE_WATER_CONSUMPTION_COMBUSTION_FAILURE",

  UPDATE_WASTE_COMBUSTION: "UPDATE_WASTE_COMBUSTION",
  UPDATE_WASTE_COMBUSTION_SUCCESS: "UPDATE_WASTE_COMBUSTION_SUCCESS",
  UPDATE_WASTE_COMBUSTION_FAILURE: "UPDATE_WASTE_COMBUSTION_FAILURE",

  UPDATE_MANAGEMENT_DIVERSITY: "UPDATE_MANAGEMENT_DIVERSITY",
  UPDATE_MANAGEMENT_DIVERSITY_SUCCESS: "UPDATE_MANAGEMENT_DIVERSITY_SUCCESS",
  UPDATE_MANAGEMENT_DIVERSITY_FAILURE: "UPDATE_MANAGEMENT_DIVERSITY_FAILURE",

  UPDATE_BOARD_DIVERSITY: "UPDATE_BOARD_DIVERSITY",
  UPDATE_BOARD_DIVERSITY_SUCCESS: "UPDATE_BOARD_DIVERSITY_SUCCESS",
  UPDATE_BOARD_DIVERSITY_FAILURE: "UPDATE_BOARD_DIVERSITY_FAILURE",

  CLEAR_UPDATE_BOARD_DIVERSITY_STATUS: "CLEAR_UPDATE_BOARD_DIVERSITY_STATUS",
  CLEAR_UPDATE_MANAGEMENT_DIVERSITY_STATUS:
    "CLEAR_UPDATE_MANAGEMENT_DIVERSITY_STATUS",

  DELETE_EMISSIONS: "DELETE_EMISSIONS",
  DELETE_EMISSIONS_SUCCESS: "DELETE_EMISSIONS_SUCCESS",
  DELETE_EMISSIONS_FAILURE: "DELETE_EMISSIONS_FAILURE",

  ADD_MOBILE_COMBUSTION: "ADD_MOBILE_COMBUSTION",
  ADD_MOBILE_COMBUSTION_SUCCESS: "ADD_MOBILE_COMBUSTION_SUCCESS",
  ADD_MOBILE_COMBUSTION_FAILURE: "ADD_MOBILE_COMBUSTION_FAILURE",

  ADD_EMPLOYEE_COMMUTING: "ADD_EMPLOYEE_COMMUTING",
  ADD_EMPLOYEE_COMMUTING_SUCCESS: "ADD_EMPLOYEE_COMMUTING_SUCCESS",
  ADD_EMPLOYEE_COMMUTING_FAILURE: "ADD_EMPLOYEE_COMMUTING_FAILURE",

  ADD_UPSTREAM_LEASED_ASSET: "ADD_UPSTREAM_LEASED_ASSET",
  ADD_UPSTREAM_LEASED_ASSET_SUCCESS: "ADD_UPSTREAM_LEASED_ASSET_SUCCESS",
  ADD_UPSTREAM_LEASED_ASSET_FAILURE: "ADD_UPSTREAM_LEASED_ASSET_FAILURE",

  UPDATE_UPSTREAM_LEASED_ASSET: "UPDATE_UPSTREAM_LEASED_ASSET",
  UPDATE_UPSTREAM_LEASED_ASSET_SUCCESS: "UPDATE_UPSTREAM_LEASED_ASSET_SUCCESS",
  UPDATE_UPSTREAM_LEASED_ASSET_FAILURE: "UPDATE_UPSTREAM_LEASED_ASSET_FAILURE",

  UPDATE_FRANCHISE: "UPDATE_FRANCHISE",
  UPDATE_FRANCHISE_SUCCESS: "UPDATE_FRANCHISE_SUCCESS",
  UPDATE_FRANCHISE_FAILURE: "UPDATE_FRANCHISE_FAILURE",

  ADD_DOWNSTREAM_LEASED_ASSET: "ADD_DOWNSTREAM_LEASED_ASSET",
  ADD_DOWNSTREAM_LEASED_ASSET_SUCCESS: "ADD_DOWNSTREAM_LEASED_ASSET_SUCCESS",
  ADD_DOWNSTREAM_LEASED_ASSET_FAILURE: "ADD_DOWNSTREAM_LEASED_ASSET_FAILURE",

  UPDATE_DOWNSTREAM_LEASED_ASSET: "UPDATE_DOWNSTREAM_LEASED_ASSET",
  UPDATE_DOWNSTREAM_LEASED_ASSET_SUCCESS:
    "UPDATE_DOWNSTREAM_LEASED_ASSET_SUCCESS",
  UPDATE_DOWNSTREAM_LEASED_ASSET_FAILURE:
    "UPDATE_DOWNSTREAM_LEASED_ASSET_FAILURE",

  ADD_TRANSPORTATION_COMBUSTION: "ADD_TRANSPORTATION_COMBUSTION",
  ADD_TRANSPORTATION_COMBUSTION_SUCCESS:
    "ADD_TRANSPORTATION_COMBUSTION_SUCCESS",
  ADD_TRANSPORTATION_COMBUSTION_FAILURE:
    "ADD_TRANSPORTATION_COMBUSTION_FAILURE",

  EDIT_TRANSPORTATION_COMBUSTION: "EDIT_TRANSPORTATION_COMBUSTION",
  EDIT_TRANSPORTATION_COMBUSTION_SUCCESS:
    "EDIT_TRANSPORTATION_COMBUSTION_SUCCESS",
  EDIT_TRANSPORTATION_COMBUSTION_FAILURE:
    "EDIT_TRANSPORTATION_COMBUSTION_FAILURE",

  ADD_WATER_DISCHARGE_COMBUSTION: "ADD_WATER_DISCHARGE_COMBUSTION",
  ADD_WATER_DISCHARGE_COMBUSTION_SUCCESS:
    "ADD_WATER_DISCHARGE_COMBUSTION_SUCCESS",
  ADD_WATER_DISCHARGE_COMBUSTION_FAILURE:
    "ADD_WATER_DISCHARGE_COMBUSTION_FAILURE",

  ADD_WATER_CONSUMPTION_COMBUSTION: "ADD_WATER_CONSUMPTION_COMBUSTION",
  ADD_WATER_CONSUMPTION_COMBUSTION_SUCCESS:
    "ADD_WATER_CONSUMPTION_COMBUSTION_SUCCESS",
  ADD_WATER_CONSUMPTION_COMBUSTION_FAILURE:
    "ADD_WATER_CONSUMPTION_COMBUSTION_FAILURE",

  ADD_WASTE_COMBUSTION: "ADD_WASTE_COMBUSTION",
  ADD_WASTE_COMBUSTION_SUCCESS: "ADD_WASTE_COMBUSTION_SUCCESS",
  ADD_WASTE_COMBUSTION_FAILURE: "ADD_WASTE_COMBUSTION_FAILURE",

  RESET_ADD_COMBUSTION_STATUS: "RESET_ADD_COMBUSTION_STATUS",

  GET_EMISSION_FUEL_LIST: "GET_EMISSION_FUEL_LIST",
  GET_EMISSION_FUEL_LIST_SUCCESS: "GET_EMISSION_FUEL_LIST_SUCCESS",
  GET_EMISSION_FUEL_LIST_FAILURE: "GET_EMISSION_FUEL_LIST_FAILURE",

  GET_MOBILE_COMBUSTION_INPUTS: "GET_MOBILE_COMBUSTION_INPUTS",
  GET_MOBILE_COMBUSTION_INPUTS_SUCCESS: "GET_MOBILE_COMBUSTION_INPUTS_SUCCESS",
  GET_MOBILE_COMBUSTION_INPUTS_FAILURE: "GET_MOBILE_COMBUSTION_INPUTS_FAILURE",

  LIST_EMISSION_AUDIT_TRAILS: "LIST_EMISSION_AUDIT_TRAILS",
  LIST_EMISSION_AUDIT_TRAILS_SUCCESS: "LIST_EMISSION_AUDIT_TRAILS_SUCCESS",
  LIST_EMISSION_AUDIT_TRAILS_FAILURE: "LIST_EMISSION_AUDIT_TRAILS_FAILURE",
  CLEAR_LIST_EMISSION_AUDIT_TRAILS: "CLEAR_LIST_EMISSION_AUDIT_TRAILS",

  REFRESH_TOKEN: "REFRESH_TOKEN",
  REFRESH_TOKEN_SUCCESS: "REFRESH_TOKEN_SUCCESS",
  REFRESH_TOKEN_FAILURE: "REFRESH_TOKEN_FAILURE",

  USER_LOGOUT: "USER_LOGOUT",
  USER_LOGOUT_SUCCESS: "USER_LOGOUT_SUCCESS",
  USER_LOGOUT_FAILURE: "USER_LOGOUT_FAILURE",
  USER_AUTO_LOGOUT: "USER_AUTO_LOGOUT",

  GET_INDUSTRY_TYPES: "GET_INDUSTRY_TYPES",
  GET_INDUSTRY_TYPES_SUCCESS: "GET_INDUSTRY_TYPES_SUCCESS",
  GET_INDUSTRY_TYPES_FAILURE: "GET_INDUSTRY_TYPES_FAILURE",

  GET_COUNTRY_LIST: "GET_COUNTRY_LIST",
  GET_COUNTRY_LIST_SUCCESS: "GET_COUNTRY_LIST_SUCCESS",
  GET_COUNTRY_LIST_FAILURE: "GET_COUNTRY_LIST_FAILURE",

  LIST_ASSIGNEE: "LIST_ASSIGNEE",
  LIST_ASSIGNEE_SUCCESS: "LIST_ASSIGNEE_SUCCESS",
  LIST_ASSIGNEE_FAILURE: "LIST_ASSIGNEE_FAILURE",

  LIST_FRAMEWORK: "LIST_FRAMEWORK",
  LIST_FRAMEWORK_SUCCESS: "LIST_FRAMEWORK_SUCCESS",
  LIST_FRAMEWORK_FAILURE: "LIST_FRAMEWORK_FAILURE",

  LIST_TOPIC: "LIST_TOPIC",
  LIST_TOPIC_SUCCESS: "LIST_TOPIC_SUCCESS",
  LIST_TOPIC_FAILURE: "LIST_TOPIC_FAILURE",

  LIST_GRID_REGIONS: "LIST_GRID_REGIONS",
  LIST_GRID_REGIONS_SUCCESS: "LIST_GRID_REGIONS_SUCCESS",
  LIST_GRID_REGIONS_FAILURE: "LIST_GRID_REGIONS_FAILURE",

  GET_EMISSION_YEAR: "GET_EMISSION_YEAR",
  GET_EMISSION_YEAR_SUCCESS: "GET_EMISSION_YEAR_SUCCESS",
  GET_EMISSION_YEAR_FAILURE: "GET_EMISSION_YEAR_FAILURE",

  GET_EMISSION_TYPES: "GET_EMISSION_TYPES",
  GET_EMISSION_TYPES_SUCCESS: "GET_EMISSION_TYPES_SUCCESS",
  GET_EMISSION_TYPES_FAILURE: "GET_EMISSION_TYPES_FAILURE",

  GET_EMISSION_REGION: "GET_EMISSION_REGION",
  GET_EMISSION_REGION_SUCCESS: "GET_EMISSION_REGION_SUCCESS",
  GET_EMISSION_REGION_FAILURE: "GET_EMISSION_REGION_FAILURE",

  GET_EMISSIONS_BY_MONTH: "GET_EMISSIONS_BY_MONTH",
  GET_EMISSIONS_BY_MONTH_SUCCESS: "GET_EMISSIONS_BY_MONTH_SUCCESS",
  GET_EMISSIONS_BY_MONTH_FAILURE: "GET_EMISSIONS_BY_MONTH_FAILURE",

  LIST_USERS: "LIST_USERS",
  LIST_USERS_SUCCESS: "LIST_USERS_SUCCESS",
  LIST_USERS_FAILURE: "LIST_USERS_FAILURE",

  GET_USER_DETAILS: "GET_USER_DETAILS",
  GET_USER_DETAILS_SUCCESS: "GET_USER_DETAILS_SUCCESS",
  GET_USER_DETAILS_FAILURE: "GET_USER_DETAILS_FAILURE",

  GET_MANAGER_LIST: "GET_MANAGER_LIST",
  GET_MANAGER_LIST_SUCCESS: "GET_MANAGER_LIST_SUCCESS",
  GET_MANAGER_LIST_FAILURE: "GET_MANAGER_LIST_FAILURE",

  ADD_USER: "ADD_USER",
  ADD_USER_SUCCESS: "ADD_USER_SUCCESS",
  ADD_USER_FAILURE: "ADD_USER_FAILURE",

  EDIT_USER: "EDIT_USER",
  EDIT_USER_SUCCESS: "EDIT_USER_SUCCESS",
  EDIT_USER_FAILURE: "EDIT_USER_FAILURE",

  DELETE_USER: "DELETE_USER",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_FAILURE: "DELETE_USER_FAILURE",

  RESET_USER_STATUS: "RESET_USER_STATUS",

  GET_COMPANY_LIST: "GET_COMPANY_LIST",
  GET_COMPANY_LIST_SUCCESS: "GET_COMPANY_LIST_SUCCESS",
  GET_COMPANY_LIST_FAILURE: "GET_COMPANY_LIST_FAILURE",

  GET_COMPANY_DETAILS: "GET_COMPANY_DETAILS",
  GET_COMPANY_DETAILS_SUCCESS: "GET_COMPANY_DETAILS_SUCCESS",
  GET_COMPANY_DETAILS_FAILURE: "GET_COMPANY_DETAILS_FAILURE",

  GET_COMPANY_AUDIT_HISTORY: "GET_COMPANY_AUDIT_HISTORY",
  GET_COMPANY_AUDIT_HISTORY_SUCCESS: "GET_COMPANY_AUDIT_HISTORY_SUCCESS",
  GET_COMPANY_AUDIT_HISTORY_FAILURE: "GET_COMPANY_AUDIT_HISTORY_FAILURE",

  GET_APPROVAL_SUMMARY: "GET_APPROVAL_SUMMARY",
  GET_APPROVAL_SUMMARY_SUCCESS: "GET_APPROVAL_SUMMARY_SUCCESS",
  GET_APPROVAL_SUMMARY_FAILURE: "GET_APPROVAL_SUMMARY_FAILURE",

  RESET_APPROVAL_SUMMARY: "RESET_APPROVAL_SUMMARY",

  GET_APPROVAL_DETAILS: "GET_APPROVAL_DETAILS",
  GET_APPROVAL_DETAILS_SUCCESS: "GET_APPROVAL_DETAILS_SUCCESS",
  GET_APPROVAL_DETAILS_FAILURE: "GET_APPROVAL_DETAILS_FAILURE",

  RESET_APPROVAL_DETAILS: "RESET_APPROVAL_DETAILS",

  GET_AUDIT_SUMMARY: "GET_AUDIT_SUMMARY",
  GET_AUDIT_SUMMARY_SUCCESS: "GET_AUDIT_SUMMARY_SUCCESS",
  GET_AUDIT_SUMMARY_FAILURE: "GET_AUDIT_SUMMARY_FAILURE",

  GET_YEARLY_AUDIT_SUMMARY: "GET_YEARLY_AUDIT_SUMMARY",
  GET_YEARLY_AUDIT_SUMMARY_SUCCESS: "GET_YEARLY_AUDIT_SUMMARY_SUCCESS",
  GET_YEARLY_AUDIT_SUMMARY_FAILURE: "GET_YEARLY_AUDIT_SUMMARY_FAILURE",

  GET_YEARLY_AUDIT_SUMMARY_OVERVIEW: "GET_YEARLY_AUDIT_SUMMARY_OVERVIEW",
  GET_YEARLY_AUDIT_SUMMARY_OVERVIEW_SUCCESS:
    "GET_YEARLY_AUDIT_SUMMARY_OVERVIEW_SUCCESS",
  GET_YEARLY_AUDIT_SUMMARY_OVERVIEW_FAILURE:
    "GET_YEARLY_AUDIT_SUMMARY_OVERVIEW_FAILURE",

  REQUEST_AUDIT: "REQUEST_AUDIT",
  REQUEST_AUDIT_SUCCESS: "REQUEST_AUDIT_SUCCESS",
  REQUEST_AUDIT_FAILURE: "REQUEST_AUDIT_FAILURE",
  RESET_REQUEST_AUDIT_DATA: "RESET_REQUEST_AUDIT_DATA",

  ANSWER_QUALITATIVE_QUESTION: "ANSWER_QUALITATIVE_QUESTION",
  ANSWER_QUALITATIVE_QUESTION_SUCCESS: "ANSWER_QUALITATIVE_QUESTION_SUCCESS",
  ANSWER_QUALITATIVE_QUESTION_FAILURE: "ANSWER_QUALITATIVE_QUESTION_FAILURE",

  RESET_QUESTION_ANSWER_STATUS: "RESET_QUESTION_ANSWER_STATUS",

  GET_ALL_QUESTIONS: "GET_ALL_QUESTIONS",
  GET_ALL_QUESTIONS_SUCCESS: "GET_ALL_QUESTIONS_SUCCESS",
  GET_ALL_QUESTIONS_FAILURE: "GET_ALL_QUESTIONS_FAILURE",

  GET_APPROVAL_MONTHLY_DETAILS: "GET_APPROVAL_MONTHLY_DETAILS",
  GET_APPROVAL_MONTHLY_DETAILS_SUCCESS: "GET_APPROVAL_MONTHLY_DETAILS_SUCCESS",
  GET_APPROVAL_MONTHLY_DETAILS_FAILURE: "GET_APPROVAL_MONTHLY_DETAILS_FAILURE",

  GET_APPROVAL_MONTHLY_SUMMARY: "GET_APPROVAL_MONTHLY_SUMMARY",
  GET_APPROVAL_MONTHLY_SUMMARY_SUCCESS: "GET_APPROVAL_MONTHLY_SUMMARY_SUCCESS",
  GET_APPROVAL_MONTHLY_SUMMARY_FAILURE: "GET_APPROVAL_MONTHLY_SUMMARY_FAILURE",

  GET_COMPANY_AUDIT_DETAILS: "GET_COMPANY_AUDIT_DETAILS",
  GET_COMPANY_AUDIT_DETAILS_SUCCESS: "GET_COMPANY_AUDIT_DETAILS_SUCCESS",
  GET_COMPANY_AUDIT_DETAILS_FAILURE: "GET_COMPANY_AUDIT_DETAILS_FAILURE",

  APPROVE_COMPANY_AUDIT: "APPROVE_COMPANY_AUDIT",
  APPROVE_COMPANY_AUDIT_SUCCESS: "APPROVE_COMPANY_AUDIT_SUCCESS",
  APPROVE_COMPANY_AUDIT_FAILURE: "APPROVE_COMPANY_AUDIT_FAILURE",
  APPROVE_COMPANY_AUDIT_RESET: "APPROVE_COMPANY_AUDIT_RESET",

  GET_DASHBOARD_SUMMARY: "GET_DASHBOARD_SUMMARY",
  GET_DASHBOARD_SUMMARY_SUCCESS: "GET_DASHBOARD_SUMMARY_SUCCESS",
  GET_DASHBOARD_SUMMARY_FAILURE: "GET_DASHBOARD_SUMMARY_FAILURE",

  GET_DASHBOARD_STATISTICS: "GET_DASHBOARD_STATISTICS",
  GET_DASHBOARD_STATISTICS_SUCCESS: "GET_DASHBOARD_STATISTICS_SUCCESS",
  GET_DASHBOARD_STATISTICS_FAILURE: "GET_DASHBOARD_STATISTICS_FAILURE",

  SUBMIT_APPROVAL: "SUBMIT_APPROVAL",
  SUBMIT_APPROVAL_SUCCESS: "SUBMIT_APPROVAL_SUCCESS",
  SUBMIT_APPROVAL_FAILURE: "SUBMIT_APPROVAL_FAILURE",

  REQUEST_APPROVAL: "REQUEST_APPROVAL",
  REQUEST_APPROVAL_SUCCESS: "REQUEST_APPROVAL_SUCCESS",
  REQUEST_APPROVAL_FAILURE: "REQUEST_APPROVAL_FAILURE",

  APPROVE_REQUEST: "APPROVE_REQUEST",
  APPROVE_REQUEST_SUCCESS: "APPROVE_REQUEST_SUCCESS",
  APPROVE_REQUEST_FAILURE: "APPROVE_REQUEST_FAILURE",

  RESET_APPROVAL_DATA: "RESET_APPROVAL_DATA",
  RESET_DATA_APPROVED: "RESET_DATA_APPROVED",

  CREATE_TICKET: "CREATE_TICKET",
  CREATE_TICKET_SUCCESS: "CREATE_TICKET_SUCCESS",
  CREATE_TICKET_FAILURE: "CREATE_TICKET_FAILURE",

  CLOSE_TICKET: "CLOSE_TICKET",
  CLOSE_TICKET_SUCCESS: "CLOSE_TICKET_SUCCESS",
  CLOSE_TICKET_FAILURE: "CLOSE_TICKET_FAILURE",

  DELETE_TICKET: "DELETE_TICKET",
  DELETE_TICKET_SUCCESS: "DELETE_TICKET_SUCCESS",
  DELETE_TICKET_FAILURE: "DELETE_TICKET_FAILURE",

  LIST_TICKETS: "LIST_TICKETS",
  LIST_TICKETS_SUCCESS: "LIST_TICKETS_SUCCESS",
  LIST_TICKETS_FAILURE: "LIST_TICKETS_FAILURE",

  LIST_SCOPE_TICKETS: "LIST_SCOPE_TICKETS",
  LIST_SCOPE_TICKETS_SUCCESS: "LIST_SCOPE_TICKETS_SUCCESS",
  LIST_SCOPE_TICKETS_FAILURE: "LIST_SCOPE_TICKETS_FAILURE",
  RESET_LIST_SCOPE_TICKETS: "RESET_LIST_SCOPE_TICKETS",

  GET_TICKET_DETAILS: "GET_TICKET_DETAILS",
  GET_TICKET_DETAILS_SUCCESS: "GET_TICKET_DETAILS_SUCCESS",
  GET_TICKET_DETAILS_FAILURE: "GET_TICKET_DETAILS_FAILURE",

  ADD_RESPONSE: "ADD_RESPONSE",
  ADD_COMMENT_SUCCESS: "ADD_COMMENT_SUCCESS",
  ADD_COMMENT_FAILURE: "ADD_COMMENT_FAILURE",

  RESET_TICKET_STATUS: "RESET_TICKET_STATUS",

  GET_ALL_REPORTS: "GET_ALL_REPORTS",
  GET_ALL_REPORTS_SUCCESS: "GET_ALL_REPORTS_SUCCESS",
  GET_ALL_REPORTS_FAILURE: "GET_ALL_REPORTS_FAILURE",

  GET_REPORT_DETAILS: "GET_REPORT_DETAILS",
  GET_REPORT_DETAILS_SUCCESS: "GET_REPORT_DETAILS_SUCCESS",
  GET_REPORT_DETAILS_FAILURE: "GET_REPORT_DETAILS_FAILURE",

  DELETE_REPORT: "DELETE_REPORT",
  DELETE_REPORT_SUCCESS: "DELETE_REPORT_SUCCESS",
  DELETE_REPORT_FAILURE: "DELETE_REPORT_FAILURE",

  CREATE_REPORT: "CREATE_REPORT",
  CREATE_REPORT_SUCCESS: "CREATE_REPORT_SUCCESS",
  CREATE_REPORT_FAILURE: "CREATE_REPORT_FAILURE",

  GENERATE_AUDITED_REPORT: "GENERATE_AUDITED_REPORT",
  GENERATE_AUDITED_REPORT_SUCCESS: "GENERATE_AUDITED_REPORT_SUCCESS",
  GENERATE_AUDITED_REPORT_FAILURE: "GENERATE_AUDITED_REPORT_FAILURE",

  DOWNLOAD_AUDITED_REPORT: "GENERATE_AUDITED_REPORT",
  DOWNLOAD_AUDITED_REPORT_SUCCESS: "DOWNLOAD_AUDITED_REPORT_SUCCESS",
  DOWNLOAD_AUDITED_REPORT_FAILURE: "DOWNLOAD_AUDITED_REPORT_FAILURE",

  UPDATE_REPORT: "UPDATE_REPORT",
  UPDATE_REPORT_SUCCESS: "UPDATE_REPORT_SUCCESS",
  UPDATE_REPORT_FAILURE: "UPDATE_REPORT_FAILURE",

  RESET_REPORT_STATUS: "RESET_REPORT_STATUS",

  DOWNLOAD_REPORT: "DOWNLOAD_REPORT",
  DOWNLOAD_REPORT_SUCCESS: "GET_ALL_REPORTS_SUCCESS",
  DOWNLOAD_REPORT_FAILURE: "GET_ALL_REPORTS_FAILURE",

  ASSIGN_REPORT: "ASSIGN_REPORT",
  ASSIGN_REPORT_SUCCESS: "ASSIGN_REPORT_SUCCESS",
  ASSIGN_REPORT_FAILURE: "ASSIGN_REPORT_FAILURE",

  GET_REPORT_LIST_TOPICS: "GET_REPORT_LIST_TOPICS",
  GET_REPORT_LIST_TOPICS_SUCCESS: "GET_REPORT_LIST_TOPICS_SUCCESS",
  GET_REPORT_LIST_TOPICS_FAILURE: "GET_REPORT_LIST_TOPICS_FAILURE",

  GET_REPORT_EMISSION_DETAILS: "GET_REPORT_EMISSION_DETAILS",
  GET_REPORT_EMISSION_DETAILS_SUCCESS: "GET_REPORT_EMISSION_DETAILS_SUCCESS",
  GET_REPORT_EMISSION_DETAILS_FAILURE: "GET_REPORT_EMISSION_DETAILS_FAILURE",

  CLEAR_REPORT_LIST_TOPICS: "CLEAR_REPORT_LIST_TOPICS",

  LIST_EMISSION_COMMENTS: "LIST_EMISSION_COMMENTS",
  LIST_EMISSION_COMMENTS_SUCCESS: "LIST_EMISSION_COMMENTS_SUCCESS",
  LIST_EMISSION_COMMENTS_FAILURE: "LIST_EMISSION_COMMENTS_FAILURE",
  RESET_LIST_EMISSION_COMMENTS: "RESET_LIST_EMISSION_COMMENTS",

  ADD_EMISSION_COMMENT: "ADD_EMISSION_COMMENT",
  ADD_EMISSION_COMMENT_SUCCESS: "ADD_EMISSION_COMMENT_SUCCESS",
  ADD_EMISSION_COMMENT_FAILURE: "ADD_EMISSION_COMMENT_FAILURE",
  CLEAR_ADD_EMISSION_COMMENT: "CLEAR_ADD_EMISSION_COMMENT",

  LIST_EMISSION_FILES: "LIST_EMISSION_FILES",
  LIST_EMISSION_FILES_SUCCESS: "LIST_EMISSION_FILES_SUCCESS",
  LIST_EMISSION_FILES_FAILURE: "LIST_EMISSION_FILES_FAILURE",
  CLEAR_LIST_EMISSION_FILES: "CLEAR_LIST_EMISSION_FILES",

  UPLOAD_EMISSION_ATTACHEMENT: "UPLOAD_EMISSION_ATTACHEMENT",
  UPLOAD_EMISSION_ATTACHEMENT_SUCCESS: "UPLOAD_EMISSION_ATTACHEMENT_SUCCESS",
  UPLOAD_EMISSION_ATTACHEMENT_FAILURE: "UPLOAD_EMISSION_ATTACHEMENT_FAILURE",
  CLEAR_UPLOAD_EMISSION_ATTACHEMENT: "CLEAR_UPLOAD_EMISSION_ATTACHEMENT",

  DELETE_EMISSION_ATTACHEMENT: "DELETE_EMISSION_ATTACHEMENT",
  DELETE_EMISSION_ATTACHEMENT_SUCCESS: "DELETE_EMISSION_ATTACHEMENT_SUCCESS",
  DELETE_EMISSION_ATTACHEMENT_FAILURE: "DELETE_EMISSION_ATTACHEMENT_FAILURE",
  CLEAR_DELETE_EMISSION_ATTACHEMENT: "CLEAR_DELETE_EMISSION_ATTACHEMENT",

  GET_FACILITY_TOPIC_EMISSION: "GET_FACILITY_TOPIC_EMISSION",
  GET_FACILITY_TOPIC_EMISSION_SUCCESS: "GET_FACILITY_TOPIC_EMISSION_SUCCESS",
  GET_FACILITY_TOPIC_EMISSION_FAILURE: "GET_FACILITY_TOPIC_EMISSION_FAILURE",

  GET_FUEL_SOURCE_EMISSION: "GET_FUEL_SOURCE_EMISSION",
  GET_FUEL_SOURCE_EMISSION_SUCCESS: "GET_FUEL_SOURCE_EMISSION_SUCCESS",
  GET_FUEL_SOURCE_EMISSION_FAILURE: "GET_FUEL_SOURCE_EMISSION_FAILURE",

  GET_EMISSIONS_BY_SCOPE: "GET_EMISSION_BY_SCOPE",
  GET_EMISSIONS_BY_SCOPE_SUCCESS: "GET_EMISSION_BY_SCOPE_SUCCESS",
  GET_EMISSIONS_BY_SCOPE_FAILURE: "GET_EMISSION_BY_SCOPE_FAILURE",

  LIST_NOTIFICATIONS: "LIST_NOTIFICATIONS",
  LIST_NOTIFICATIONS_SUCCESS: "LIST_NOTIFICATIONS_SUCCESS",
  LIST_NOTIFICATIONS_FAILURE: "LIST_NOTIFICATIONS_FAILURE",

  MARK_ALL_READ: "MARK_ALL_READ",
  MARK_ALL_READ_SUCCESS: "MARK_ALL_READ_SUCCESS",
  MARK_ALL_READ_FAILURE: "MARK_ALL_READ_FAILURE",

  MARK_AS_READ: "MARK_AS_READ",
  MARK_AS_READ_SUCCESS: "MARK_AS_READ_SUCCESS",
  MARK_AS_READ_FAILURE: "MARK_AS_READ_FAILURE",

  SET_EMAIL_CONFIRMED: "SET_EMAIL_CONFIRMED",
  SET_EMAIL_CONFIRMED_SUCCESS: "SET_EMAIL_CONFIRMED_SUCCESS",
  SET_EMAIL_CONFIRMED_FAILURE: "SET_EMAIL_CONFIRMED_FAILURE",
  CLEAR_EMAIL_CONFIRMED_FAILURE: "CLEAR_EMAIL_CONFIRMED_FAILURE",

  ADD_DEVELOPMENT_TRAINING_DETAILS: "ADD_DEVELOPMENT_TRAINING_DETAILS",
  ADD_DEVELOPMENT_TRAINING_DETAILS_SUCCESS:
    "ADD_DEVELOPMENT_TRAINING_DETAILS_SUCCESS",
  ADD_DEVELOPMENT_TRAINING_DETAILS_FAILURE:
    "ADD_DEVELOPMENT_TRAINING_DETAILS_FAILURE",

  UPDATE_DEVELOPMENT_TRAINING_DETAILS: "UPDATE_DEVELOPMENT_TRAINING_DETAILS",
  UPDATE_DEVELOPMENT_TRAINING_DETAILS_SUCCESS:
    "UPDATE_DEVELOPMENT_TRAINING_DETAILS_SUCCESS",
  UPDATE_DEVELOPMENT_TRAINING_DETAILS_FAILURE:
    "UPDATE_DEVELOPMENT_TRAINING_DETAILS_FAILURE",

  UPDATE_WORKER_SAFETY_TRAINING: "UPDATE_WORKER_SAFETY_TRAINING",
  UPDATE_WORKER_SAFETY_TRAINING_SUCCESS:
    "UPDATE_WORKER_SAFETY_TRAINING_SUCCESS",
  UUPDATE_WORKER_SAFETY_TRAINING_FAILURE:
    "UPDATE_WORKER_SAFETY_TRAINING_FAILURE",

  GET_YEARLY_AUDIT_STATUS_SUMMARY_OVERVIEW:
    "GET_YEARLY_AUDIT_STATUS_SUMMARY_OVERVIEW",
  GET_YEARLY_AUDIT_STATUS_SUMMARY_OVERVIEW_SUCCESS:
    "GET_YEARLY_AUDIT_STATUS_SUMMARY_OVERVIEW_SUCCESS",
  GET_YEARLY_AUDIT_STATUS_SUMMARY_OVERVIEW_FAILURE:
    "GET_YEARLY_AUDIT_STATUS_SUMMARY_OVERVIEW_FAILURE",

  ADD_EMPLOYEE_HEALTH_DETAILS: "ADD_EMPLOYEE_HEALTH_DETAILS",
  ADD_EMPLOYEE_HEALTH_DETAILS_SUCCESS: "ADD_EMPLOYEE_HEALTH_DETAILS_SUCCESS",
  ADD_EMPLOYEE_HEALTH_DETAILS_FAILURE: "ADD_EMPLOYEE_HEALTH_DETAILS_FAILURE",

  UPDATE_EMPLOYEE_HEALTH_DETAILS: "UPDATE_EMPLOYEE_HEALTH_DETAILS",
  UPDATE_EMPLOYEE_HEALTH_DETAILS_SUCCESS:
    "UPDATE_EMPLOYEE_HEALTH_DETAILS_SUCCESS",
  UPDATE_EMPLOYEE_HEALTH_DETAILS_FAILURE:
    "UPDATE_EMPLOYEE_HEALTH_DETAILS_FAILURE",

  ADD_WORKER_SAFETY_TRAINING: "ADD_WORKER_SAFETY_TRAINING",
  ADD_WORKER_SAFETY_TRAINING_SUCCESS: "ADD_WORKER_SAFETY_TRAINING_SUCCESS",
  ADD_WORKER_SAFETY_TRAINING_FAILURE: "ADD_WORKER_SAFETY_TRAINING_FAILURE",

  ADD_DESCRIMINATION_INCIDENT_RECORD: "ADD_DESCRIMINATION_INCIDENT_RECORD",
  ADD_DESCRIMINATION_INCIDENT_RECORD_SUCCESS:
    "ADD_DESCRIMINATION_INCIDENT_RECORD_SUCCESS",
  ADD_DESCRIMINATION_INCIDENT_RECORD_FAILURE:
    "ADD_DESCRIMINATION_INCIDENT_RECORD_FAILURE",

  ADD_SUPPLIER_HUMAN_RIGHTS_TRAINING: "ADD_SUPPLIER_HUMAN_RIGHTS_TRAINING",
  ADD_SUPPLIER_HUMAN_RIGHTS_TRAINING_SUCCESS:
    "ADD_SUPPLIER_HUMAN_RIGHTS_TRAINING_SUCCESS",
  ADD_SUPPLIER_HUMAN_RIGHTS_TRAINING_FAILURE:
    "ADD_SUPPLIER_HUMAN_RIGHTS_TRAINING_FAILURE",

  ADD_SOCIAL_HUMAN_RIGHTS_TRAINING: "ADD_SOCIAL_HUMAN_RIGHTS_TRAINING",
  ADD_SOCIAL_HUMAN_RIGHTS_TRAINING_SUCCESS:
    "ADD_SOCIAL_HUMAN_RIGHTS_TRAINING_SUCCESS",
  ADD_SOCIAL_HUMAN_RIGHTS_TRAINING_FAILURE:
    "ADD_SOCIAL_HUMAN_RIGHTS_TRAINING_FAILURE",

  ADD_SUPPLIER_SCREENING: "ADD_SUPPLIER_SCREENING",
  ADD_SUPPLIER_SCREENING_SUCCESS: "ADD_SUPPLIER_SCREENING_SUCCESS",
  ADD_SUPPLIER_SCREENING_FAILURE: "ADD_SUPPLIER_SCREENING_FAILURE",

  ADD_LOCAL_COMMUNITIES: "ADD_LOCAL_COMMUNITIES",
  ADD_LOCAL_COMMUNITIES_SUCCESS: "ADD_LOCAL_COMMUNITIES_SUCCESS",
  ADD_LOCAL_COMMUNITIES_FAILURE: "ADD_LOCAL_COMMUNITIES_FAILURE",

  ADD_POLITICAL_CONTRIBUTION: "ADD_POLITICAL_CONTRIBUTION",
  ADD_POLITICAL_CONTRIBUTION_SUCCESS: "ADD_POLITICAL_CONTRIBUTION_SUCCESS",
  ADD_POLITICAL_CONTRIBUTION_FAILURE: "ADD_POLITICAL_CONTRIBUTION_FAILURE",

  ADD_ANTI_CORRUPTION_DISCLOSURE: "ADD_ANTI_CORRUPTION_DISCLOSURE",
  ADD_ANTI_CORRUPTION_DISCLOSURE_SUCCESS:
    "ADD_ANTI_CORRUPTION_DISCLOSURE_SUCCESS",
  ADD_ANTI_CORRUPTION_DISCLOSURE_FAILURE:
    "ADD_ANTI_CORRUPTION_DISCLOSURE_FAILURE",

  ADD_ANTI_CORRUPTION_TRAINING: "ADD_ANTI_CORRUPTION_TRAINING",
  ADD_ANTI_CORRUPTION_TRAINING_SUCCESS: "ADD_ANTI_CORRUPTION_TRAINING_SUCCESS",
  ADD_ANTI_CORRUPTION_TRAINING_FAILURE: "ADD_ANTI_CORRUPTION_TRAINING_FAILURE",

  ADD_ANTI_COMPETITIVE_DISCLOSURE: "ADD_ANTI_COMPETITIVE_DISCLOSURE",
  ADD_ANTI_COMPETITIVE_DISCLOSURE_SUCCESS:
    "ADD_ANTI_COMPETITIVE_DISCLOSURE_SUCCESS",
  ADD_ANTI_COMPETITIVE_DISCLOSURE_FAILURE:
    "ADD_ANTI_COMPETITIVE_DISCLOSURE_FAILURE",

  ADD_SUBSIDIES_FINANCIAL_ASSISTANCE: "ADD_SUBSIDIES_FINANCIAL_ASSISTANCE",
  ADD_SUBSIDIES_FINANCIAL_ASSISTANCE_SUCCESS:
    "ADD_SUBSIDIES_FINANCIAL_ASSISTANCE_SUCCESS",
  ADD_SUBSIDIES_FINANCIAL_ASSISTANCE_FAILURE:
    "ADD_SUBSIDIES_FINANCIAL_ASSISTANCE_FAILURE",

  ADD_BOARD_DIVERSITY: "ADD_BOARD_DIVERSITY",
  ADD_BOARD_DIVERSITY_SUCCESS: "ADD_BOARD_DIVERSITY_SUCCESS",
  ADD_BOARD_DIVERSITY_FAILURE: "ADD_BOARD_DIVERSITY_FAILURE",

  RESET_ADD_BOARD_DIVERSITY_STATUS: "RESET_ADD_BOARD_DIVERSITY_STATUS",

  ADD_MANAGEMENT_DIVERSITY: "ADD_MANAGEMENT_DIVERSITY",
  ADD_MANAGEMENT_DIVERSITY_SUCCESS: "ADD_MANAGEMENT_DIVERSITY_SUCCESS",
  ADD_MANAGEMENT_DIVERSITY_FAILURE: "ADD_MANAGEMENT_DIVERSITY_FAILURE",

  RESET_ADD_MANAGEMENT_DIVERSITY_STATUS:
    "RESET_ADD_MANAGEMENT_DIVERSITY_STATUS",

  ADD_UPLOAD_FILE_EMISSION: "ADD_UPLOAD_FILE_EMISSION",
  ADD_UPLOAD_FILE_EMISSION_SUCCESS: "ADD_UPLOAD_FILE_EMISSION_SUCCESS",
  ADD_UPLOAD_FILE_EMISSION_FAILURE: "ADD_UPLOAD_FILE_EMISSION_FAILURE",

  GET_NON_EMISSION_DETAILS: "GET_NON_EMISSION_DETAILS",
  GET_NON_EMISSION_DETAILS_SUCCESS: "GET_NON_EMISSION_DETAILS_SUCCESS",
  GET_NON_EMISSION_DETAILS_FAILURE: "GET_NON_EMISSION_DETAILS_FAILURE",
  CLEAR_GET_NON_EMISSION_DETAILS: "CLEAR_GET_NON_EMISSION_DETAILS",

  UPDATE_NON_EMISSION_DETAILS: "UPDATE_NON_EMISSION_DETAILS",
  UPDATE_NON_EMISSION_DETAILS_SUCCESS: "UPDATE_NON_EMISSION_DETAILS_SUCCESS",
  UPDATE_NON_EMISSION_DETAILS_FAILURE: "UPDATE_NON_EMISSION_DETAILS_FAILURE",

  LIST_ASSESSMENT_CYCLE: "LIST_ASSESSMENT_CYCLE",
  LIST_ASSESSMENT_CYCLE_SUCCESS: "LIST_ASSESSMENT_CYCLE_SUCCESS",
  LIST_ASSESSMENT_CYCLE_FAILURE: "LIST_ASSESSMENT_CYCLE_FAILURE",

  GET_ASSESSMENT_DETAILS: "GET_ASSESSMENT_DETAILS",
  GET_ASSESSMENT_DETAILS_SUCCESS: "GET_ASSESSMENT_DETAILS_SUCCESS",
  GET_ASSESSMENT_DETAILS_FAILURE: "GET_ASSESSMENT_DETAILS_FAILURE",

  CREATE_ASSESSMENT_CYCLE: "CREATE_ASSESSMENT_CYCLE",
  CREATE_ASSESSMENT_CYCLE_SUCCESS: "CREATE_ASSESSMENT_CYCLE_SUCCESS",
  CREATE_ASSESSMENT_CYCLE_FAILURE: "CREATE_ASSESSMENT_CYCLE_FAILURE",

  UPDATE_ASSESSMENT_CYCLE: "UPDATE_ASSESSMENT_CYCLE",
  UPDATE_ASSESSMENT_CYCLE_SUCCESS: "UPDATE_ASSESSMENT_CYCLE_SUCCESS",
  UPDATE_ASSESSMENT_CYCLE_FAILURE: "UPDATE_ASSESSMENT_CYCLE_FAILURE",

  RESET_ASSESSMENT_CYCLE: "RESET_ASSESSMENT_CYCLE",

  UPDATE_EMPLOYEE_TURNOVER: "UPDATE_EMPLOYEE_TURNOVER",
  UPDATE_EMPLOYEE_TURNOVER_SUCCESS: "UPDATE_EMPLOYEE_TURNOVER_SUCCESS",
  UPDATE_EMPLOYEE_TURNOVER_FAILURE: "UPDATE_EMPLOYEE_TURNOVER_FAILURE",

  UPDATE_AGE_BASED_STATISTICS: "UPDATE_AGE_BASED_STATISTICS",
  UPDATE_AGE_BASED_STATISTICS_SUCCESS: "UPDATE_AGE_BASED_STATISTICS_SUCCESS",
  UPDATE_AGE_BASED_STATISTICS_FAILURE: "UPDATE_AGE_BASED_STATISTICS_FAILURE",

  UPDATE_GENDER_BASED_STATISTICS: "UPDATE_GENDER_BASED_STATISTICS",
  UPDATE_GENDER_BASED_STATISTICS_SUCCESS:
    "UPDATE_GENDER_BASED_STATISTICS_SUCCESS",
  UPDATE_GENDER_BASED_STATISTICS_FAILURE:
    "UPDATE_GENDER_BASED_STATISTICS_FAILURE",

  UPDATE_DISCRIMINATION_INCIDENT_RECORD:
    "UPDATE_DISCRIMINATION_INCIDENT_RECORD",
  UPDATE_DISCRIMINATION_INCIDENT_RECORD_SUCCESS:
    "UPDATE_DISCRIMINATION_INCIDENT_RECORD_SUCCESS",
  UPDATE_DISCRIMINATION_INCIDENT_RECORD_FAILURE:
    "UPDATE_DISCRIMINATION_INCIDENT_RECORD_FAILURE",

  UPDATE_SUPPLIER_SCREENING: "UPDATE_SUPPLIER_SCREENING",
  UPDATE_SUPPLIER_SCREENING_SUCCESS: "UPDATE_SUPPLIER_SCREENING_SUCCESS",
  UPDATE_SUPPLIER_SCREENING_FAILURE: "UPDATE_SUPPLIER_SCREENING_FAILURE",

  UPDATE_SUPPLIER_HUMAN_RIGHTS_TRAINING:
    "UPDATE_SUPPLIER_HUMAN_RIGHTS_TRAINING",
  UPDATE_SUPPLIER_HUMAN_RIGHTS_TRAINING_SUCCESS:
    "UPDATE_SUPPLIER_HUMAN_RIGHTS_TRAINING_SUCCESS",
  UPDATE_SUPPLIER_HUMAN_RIGHTS_TRAINING_FAILURE:
    "UPDATE_SUPPLIER_HUMAN_RIGHTS_TRAINING_FAILURE",

  UPDATE_SOCIAL_HUMAN_RIGHTS_TRAINING: "UPDATE_SOCIAL_HUMAN_RIGHTS_TRAINING",
  UPDATE_SOCIAL_HUMAN_RIGHTS_TRAINING_SUCCESS:
    "UPDATE_SOCIAL_HUMAN_RIGHTS_TRAINING_SUCCESS",
  UPDATE_SOCIAL_HUMAN_RIGHTS_TRAINING_FAILURE:
    "UPDATE_SOCIAL_HUMAN_RIGHTS_TRAINING_FAILURE",

  UPDATE_LOCAL_COMMUNITIES: "UPDATE_LOCAL_COMMUNITIES",
  UPDATE_LOCAL_COMMUNITIES_SUCCESS: "UPDATE_LOCAL_COMMUNITIES_SUCCESS",
  UPDATE_LOCAL_COMMUNITIES_FAILURE: "UPDATE_LOCAL_COMMUNITIES_FAILURE",

  UPDATE_POLITICAL_CONTRIBUTIONS: "UPDATE_POLITICAL_CONTRIBUTIONS",
  UPDATE_POLITICAL_CONTRIBUTIONS_SUCCESS:
    "UPDATE_POLITICAL_CONTRIBUTIONS_SUCCESS",
  UPDATE_POLITICAL_CONTRIBUTIONS_FAILURE:
    "UPDATE_POLITICAL_CONTRIBUTIONS_FAILURE",

  UPDATE_ANTI_CORRUPTION_DISCLOSURE: "UPDATE_ANTI_CORRUPTION_DISCLOSURE",
  UPDATE_ANTI_CORRUPTION_DISCLOSURE_SUCCESS:
    "UPDATE_ANTI_CORRUPTION_DISCLOSURE_SUCCESS",
  UPDATE_ANTI_CORRUPTION_DISCLOSURE_FAILURE:
    "UPDATE_ANTI_CORRUPTION_DISCLOSURE_FAILURE",

  UPDATE_ANTI_CORRUPTION_TRAINING: "UPDATE_ANTI_CORRUPTION_TRAINING",
  UPDATE_ANTI_CORRUPTION_TRAINING_SUCCESS:
    "UPDATE_ANTI_CORRUPTION_TRAINING_SUCCESS",
  UPDATE_ANTI_CORRUPTION_TRAINING_FAILURE:
    "UPDATE_ANTI_CORRUPTION_TRAINING_FAILURE",

  UPDATE_ANTI_COMPETITIVE_DISCLOSURE: "UPDATE_ANTI_COMPETITIVE_DISCLOSURE",
  UPDATE_ANTI_COMPETITIVE_DISCLOSURE_SUCCESS:
    "UPDATE_ANTI_COMPETITIVE_DISCLOSURE_SUCCESS",
  UPDATE_ANTI_COMPETITIVE_DISCLOSURE_FAILURE:
    "UPDATE_ANTI_COMPETITIVE_DISCLOSURE_FAILURE",

  UPDATE_SUBSIDIES_FINANCIAL_ASSISTANCE:
    "UPDATE_SUBSIDIES_FINANCIAL_ASSISTANCE",
  UPDATE_SUBSIDIES_FINANCIAL_ASSISTANCE_SUCCESS:
    "UPDATE_SUBSIDIES_FINANCIAL_ASSISTANCE_SUCCESS",
  UPDATE_SUBSIDIES_FINANCIAL_ASSISTANCE_FAILURE:
    "UPDATE_SUBSIDIES_FINANCIAL_ASSISTANCE_FAILURE",

  GET_AUDIT_TRAILS: "GET_AUDIT_TRAILS",
  GET_AUDIT_TRAILS_SUCCESS: "GET_AUDIT_TRAILS_SUCCESS",
  GET_AUDIT_TRAILS_FAILURE: "GET_AUDIT_TRAILS_FAILURE",

  GET_ALL_EMISSIONS: "GET_ALL_EMISSIONS",
  GET_ALL_EMISSIONS_SUCCESS: "GET_ALL_EMISSIONS_SUCCESS",
  GET_ALL_EMISSIONS_FAILURE: "GET_ALL_EMISSIONS_FAILURE",
  RESET_GET_ALL_EMISSION_STATUS: "RESET_GET_ALL_EMISSION_STATUS",

  GET_AUDITOR_LIST: "GET_AUDITOR_LIST",
  GET_AUDITOR_LIST_SUCCESS: "GET_AUDITOR_LIST_SUCCESS",
  GET_AUDITOR_LIST_FAILURE: "GET_AUDITOR_LIST_FAILURE",

  UPDATE_AUDITOR_STATUS: "UPDATE_AUDITOR_STATUS",
  UPDATE_AUDITOR_STATUS_SUCCESS: "UPDATE_AUDITOR_STATUS_SUCCESS",
  UPDATE_AUDITOR_STATUS_FAILURE: "UPDATE_AUDITOR_STATUS_FAILURE",

  GET_UPLOAD_HISTORY: "GET_UPLOAD_HISTORY",
  GET_UPLOAD_HISTORY_SUCCESS: "GET_UPLOAD_HISTORY_SUCCESS",
  GET_UPLOAD_HISTORY_FAILURE: "GET_UPLOAD_HISTORY_FAILURE",

  GET_UPLOAD_HISTORY_DETAILS: "GET_UPLOAD_HISTORY_DETAILS",
  GET_UPLOAD_HISTORY_DETAILS_SUCCESS: "GET_UPLOAD_HISTORY_DETAILS_SUCCESS",
  GET_UPLOAD_HISTORY_DETAILS_FAILURE: "GET_UPLOAD_HISTORY_DETAILS_FAILURE",

  RESET_UPDATE_AUDITOR_STATUS: "RESET_UPDATE_AUDITOR_STATUS",

  GET_SUPERADMIN_DASHBOARD: "GET_SUPERADMIN_DASHBOARD",
  GET_SUPERADMIN_DASHBOARD_SUCCESS: "GET_SUPERADMIN_DASHBOARD_SUCCESS",
  GET_SUPERADMIN_DASHBOARD_FAILURE: "GET_SUPERADMIN_DASHBOARD_FAILURE",

  GET_TOTAL_COMPANIES: "GET_TOTAL_COMPANIES",
  GET_TOTAL_COMPANIES_SUCCESS: "GET_TOTAL_COMPANIES_SUCCESS",
  GET_TOTAL_COMPANIES_FAILURE: "GET_TOTAL_COMPANIES_FAILURE",

  COMPANY_DETAILS: "COMPANY_DETAILS",
  COMPANY_DETAILS_SUCCESS: "COMPANY_DETAILS_SUCCESS",
  COMPANY_DETAILS_FAILURE: "COMPANY_DETAILS_FAILURE",

  COMPANY_COUNTRY_LIST: "COMPANY_COUNTRY_LIST",
  COMPANY_COUNTRY_LIST_SUCCESS: "COMPANY_COUNTRY_LIST_SUCCESS",
  COMPANY_COUNTRY_LIST_FAILURE: "COMPANY_COUNTRY_LIST_FAILURE",

  COMPANY_FACILITY_LIST: "COMPANY_FACILITY_LIST",
  COMPANY_FACILITY_LIST_SUCCESS: "COMPANY_FACILITY_LIST_SUCCESS",
  COMPANY_FACILITY_LIST_FAILURE: "COMPANY_FACILITY_LIST_FAILURE",

  COMPANY_USERS_LIST: "COMPANY_USERS_LIST",
  COMPANY_USERS_LIST_SUCCESS: "COMPANY_USERS_LIST_SUCCESS",
  COMPANY_USERS_LIST_FAILURE: "COMPANY_FUSERS_LIST_FAILURE",

  COMPANY_FACILITY_DETAILS: "COMPANY_FACILITY_DETAILS",
  COMPANY_FACILITY_DETAILS_SUCCESS: "COMPANY_FACILITY_DETAILS_SUCCESS",
  COMPANY_FACILITY_DETAILS_FAILURE: "COMPANY_FACILITY_DETAILS_FAILURE",

  GET_USER_STATISTICS: "GET_USER_STATISTICS",
  GET_USER_STATISTICS_SUCCESS: "GET_USER_STATISTICS_SUCCESS",
  GET_USER_STATISTICS_FAILURE: "GET_USER_STATISTICS_FAILURE",

  GET_USERS_LIST: "GET_USERS_LIST",
  GET_USERS_LIST_SUCCESS: "GET_USERS_LIST_SUCCESS",
  GET_USERS_LIST_FAILURE: "GET_USERS_LIST_FAILURE",

  COMPANY_USER_DETAILS: "COMPANY_USER_DETAILS",
  COMPANY_USER_DETAILS_SUCCESS: "COMPANY_USER_DETAILS_SUCCESS",
  COMPANY_USER_DETAILS_FAILURE: "COMPANY_USER_DETAILS_FAILURE",

  TOTAL_USER_DETAILS: "TOTAL_USER_DETAILS",
  TOTAL_USER_DETAILS_SUCCESS: "TOTAL_USER_DETAILS_SUCCESS",
  TOTAL_USER_DETAILS_FAILURE: "TOTAL_USER_DETAILS_FAILURE",

  GET_SUPERADMIN_LIST: "GET_SUPERADMIN_LIST",
  GET_SUPERADMIN_LIST_SUCCESS: "GET_SUPERADMIN_LIST_SUCCESS",
  GET_SUPERADMIN_LIST_FAILURE: "GET_SUPERADMIN_LIST_FAILURE",

  ADD_SUPER_ADMIN: "ADD_SUPER_ADMIN",
  ADD_SUPER_ADMIN_SUCCESS: "ADD_SUPER_ADMIN_SUCCESS",
  ADD_SUPER_ADMIN_FAILURE: "ADD_SUPER_ADMIN_FAILURE",

  EDIT_SUPER_ADMIN: "EDIT_SUPER_ADMIN",
  EDIT_SUPER_ADMIN_SUCCESS: "EDIT_SUPER_ADMIN_SUCCESS",
  EDIT_SUPER_ADMIN_FAILURE: "EDIT_SUPER_ADMIN_FAILURE",

  DELETE_SUPER_ADMIN: "DELETE_SUPER_ADMIN",
  DELETE_SUPER_ADMIN_SUCCESS: "DELETE_SUPER_ADMIN_SUCCESS",
  DELETE_SUPER_ADMIN_FAILURE: "DELETE_SUPER_ADMIN_FAILURE",

  GET_SUPERADMIN: "GET_SUPERADMIN",
  GET_SUPERADMIN_SUCCESS: "GET_SUPERADMIN_SUCCESS",
  GET_SUPERADMIN_FAILURE: "GET_SUPERADMIN_FAILURE",

  RESET_SUPER_ADMIN_STATUS: "RESET_SUPER_ADMIN_STATUS",

  GET_ASSIGNED_STAKEHOLDERS: "GET_ASSIGNED_STAKEHOLDERS",
  GET_ASSIGNED_STAKEHOLDERS_SUCSESS: "GET_ASSIGNED_STAKEHOLDERS_SUCSESS",
  GET_ASSIGNED_STAKEHOLDERS_FAILURE: "GET_ASSIGNED_STAKEHOLDERS_FAILURE",

  UPDATE_STAKEHOLDER_STATUS: "UPDATE_STAKEHOLDER_STATUS",
  UPDATE_STAKEHOLDER_STATUS_SUCCESS: "UPDATE_STAKEHOLDER_STATUS_SUCCESS",
  UPDATE_STAKEHOLDER_STATUS_FAILURE: "UPDATE_STAKEHOLDER_STATUS_FAILURE",

  RESET_UPDATE_STAKEHOLDER_STATUS: "RESET_UPDATE_STAKEHOLDER_STATUS",

  EDIT_USER_STAKEHOLDER: "EDIT_USER_STAKEHOLDER",
  EDIT_USER_STAKEHOLDER_SUCCESS: "EDIT_USER_STAKEHOLDER_SUCCESS",
  EDIT_USER_STAKEHOLDER_FAILURE: "EDIT_USER_STAKEHOLDER_FAILURE",

  GET_STAKEHOLDER_COMPANY: "GET_STAKEHOLDER_COMPANY",
  GET_STAKEHOLDER_COMPANY_SUCCESS: "GET_STAKEHOLDER_COMPANY_SUCCESS",
  GET_STAKEHOLDER_COMPANY_FAILURE: "GET_STAKEHOLDER_COMPANY_FAILURE",

  STAKEHOLDER_COMPANY_DETAILS: "STAKEHOLDER_COMPANY_DETAILS",
  STAKEHOLDER_COMPANY_DETAILS_SUCCESS: "STAKEHOLDER_COMPANY_DETAILS_SUCCESS",
  STAKEHOLDER_COMPANY_DETAILS_FAILURE: "STAKEHOLDER_COMPANY_DETAILS_FAILURE",

  STAKEHOLDER_DASHBOARD: "STAKEHOLDER_DASHBOARD",
  STAKEHOLDER_DASHBOARD_SUCCESS: "STAKEHOLDER_DASHBOARD_SUCCESS",
  STAKEHOLDER_DASHBOARD_FAILURE: "STAKEHOLDER_DASHBOARD_FAILURE",

  GET_STAKEHOLDER_REPORTS_LIST: "GET_STAKEHOLDER_REPORTS_LIST",
  GET_STAKEHOLDER_REPORTS_LIST_SUCCESS: "GET_STAKEHOLDER_REPORTS_LIST_SUCCESS",
  GET_STAKEHOLDER_REPORTS_LIST_FAILURE: "GET_STAKEHOLDER_REPORTS_LIST_FAILURE",

  GET_ORGANIZATION_COUNTRY: "GET_ORGANIZATION_COUNTRY",
  GET_ORGANIZATION_COUNTRY_SUCCESS: "GET_ORGANIZATION_COUNTRY_SUCCESS",
  GET_ORGANIZATION_COUNTRY_FAILURE: "GET_ORGANIZATION_COUNTRY_FAILURE",

  GET_STAKEHOLDER_ASSIGNED_REPORTS: "GET_STAKEHOLDER_ASSIGNED_REPORTS",
  GET_STAKEHOLDER_ASSIGNED_REPORTS_SUCCESS:
    "GET_STAKEHOLDER_ASSIGNED_REPORTS_SUCCESS",
  GET_STAKEHOLDER_ASSIGNED_REPORTS_FAILURE:
    "GET_STAKEHOLDER_ASSIGNED_REPORTS_FAILURE",

  GET_STAKEHOLDER_AUDITED_REPORTS: "GET_STAKEHOLDER_AUDITED_REPORTS",
  GET_STAKEHOLDER_AUDITED_REPORTS_SUCCESS:
    "GET_STAKEHOLDER_AUDITED_REPORTS_SUCCESS",
  GET_STAKEHOLDER_AUDITED_REPORTS_FAILURE:
    "GET_STAKEHOLDER_AUDITED_REPORTS_FAILURE",

  GET_STAKEHOLDER_TOPICS_SUMMARY: "GET_STAKEHOLDER_TOPICS_SUMMARY",
  GET_STAKEHOLDER_TOPICS_SUMMARY_SUCCESS:
    "GET_STAKEHOLDER_TOPICS_SUMMARY_SUCCESS",
  GET_STAKEHOLDER_TOPICS_SUMMARY_FAILURE:
    "GET_STAKEHOLDER_TOPICS_SUMMARY_FAILURE",

  GET_STAKEHOLDER_TOPICS_DATA: "GET_STAKEHOLDER_TOPICS_DATA",
  GET_STAKEHOLDER_TOPICS_DATA_SUCCESS: "GET_STAKEHOLDER_TOPICS_DATA_SUCCESS",
  GET_STAKEHOLDER_TOPICS_DATA_FAILURE: "GET_STAKEHOLDER_TOPICS_DATA_FAILURE",

  GET_READ_ONLY_USER_ASSIGNED_REPORTS: "GET_READ_ONLY_USER_ASSIGNED_REPORTS",
  GET_READ_ONLY_USER_ASSIGNED_REPORTS_SUCCESS:
    "GET_READ_ONLY_USER_ASSIGNED_REPORTS_SUCCESS",
  GET_READ_ONLY_USER_ASSIGNED_REPORTS_FAILURE:
    "GET_READ_ONLY_USER_ASSIGNED_REPORTS_FAILURE",
  RESET_REVOKE_GRANT_REPORT_ACCESS: "RESET_REVOKE_GRANT_REPORT_ACCESS",

  REVOKE_GRANT_REPORT_ACCESS: "REVOKE_GRANT_REPORT_ACCESS",
  REVOKE_GRANT_REPORT_ACCESS_SUCCESS: "REVOKE_GRANT_REPORT_ACCESS_SUCCESS",
  REVOKE_GRANT_REPORT_ACCESS_FAILURE: "REVOKE_GRANT_REPORT_ACCESS_FAILURE",

  GET_EMISSIONS_BY_SCOPE_BY_YEAR: "GET_EMISSIONS_BY_SCOPE_BY_YEAR",
  GET_EMISSIONS_BY_SCOPE_BY_YEAR_SUCCESS:
    "GET_EMISSIONS_BY_SCOPE_BY_YEAR_SUCCESS",
  GET_EMISSIONS_BY_SCOPE_BY_YEAR_FAILURE:
    "GET_EMISSIONS_BY_SCOPE_BY_YEAR_FAILURE",

  GET_ESG_OVERVIEW: "GET_ESG_OVERVIEW",
  GET_ESG_OVERVIEW_SUCCESS: "GET_ESG_OVERVIEW_SUCCESS",
  GET_ESG_OVERVIEW_FAILURE: "GET_ESG_OVERVIEW_FAILURE",

  STAKEHOLDER_ESG_OVERVIEW: "STAKEHOLDER_ESG_OVERVIEW",
  STAKEHOLDER_ESG_OVERVIEW_SUCCESS: "STAKEHOLDER_ESG_OVERVIEW_SUCCESS",
  STAKEHOLDER_ESG_OVERVIEW_FAILURE: "STAKEHOLDER_ESG_OVERVIEW_FAILURE",

  STAKEHOLDER_EMISSION_BY_SCOPE_BY_YEAR:
    "STAKEHOLDER_EMISSION_BY_SCOPE_BY_YEAR",
  STAKEHOLDER_EMISSION_BY_SCOPE_BY_YEAR_SUCCESS:
    "STAKEHOLDER_EMISSION_BY_SCOPE_BY_YEAR_SUCCESS",
  STAKEHOLDER_EMISSION_BY_SCOPE_BY_YEAR_FAILURE:
    "STAKEHOLDER_EMISSION_BY_SCOPE_BY_YEAR_FAILURE",

  GET_STAKEHOLDER_ACCOUNT: "GET_STAKEHOLDER_ACCOUNT",
  GET_STAKEHOLDER_ACCOUNT_SUCCESS: "GET_STAKEHOLDER_ACCOUNT_SUCCESS",
  GET_STAKEHOLDER_ACCOUNT_FAILURE: "GET_STAKEHOLDER_ACCOUNT_FAILURE",

  GET_NEWS: "GET_NEWS",
  GET_NEWS_SUCCESS: "GET_NEWS_SUCCESS",
  GET_NEWS_FAILURE: "GET_NEWS_FAILURE",

  GET_AUDITOR_DASHBOARD: "GET_AUDITOR_DASHBOARD",
  GET_AUDITOR_DASHBOARD_SUCCESS: "GET_AUDITOR_DASHBOARD_SUCCESS",
  GET_AUDITOR_DASHBOARD_FAILURE: "GET_AUDITOR_DASHBOARD_FAILURE",

  GET_AUDIT_STATUS_GRAPH: "GET_AUDIT_STATUS_GRAPH",
  GET_AUDIT_STATUS_GRAPH_SUCCESS: "GET_AUDIT_STATUS_GRAPH_SUCCESS",
  GET_AUDIT_STATUS_GRAPH_FAILURE: "GET_AUDIT_STATUS_GRAPH_FAILURE",

  GET_AUDIT_TREND_GRAPH: "GET_AUDIT_TREND_GRAPH",
  GET_AUDIT_TREND_GRAPH_SUCCESS: "GET_AUDIT_TREND_GRAPH_SUCCESS",
  GET_AUDIT_TREND_GRAPH_FAILURE: "GET_AUDIT_TREND_GRAPH_FAILURE",

  GET_ESG_OVERVIEW_GRAPH: "GET_ESG_OVERVIEW_GRAPH",
  GET_ESG_OVERVIEW_GRAPH_SUCCESS: "GET_ESG_OVERVIEW_GRAPH_SUCCESS",
  GET_ESG_OVERVIEW_GRAPH_FAILURE: "GET_ESG_OVERVIEW_GRAPH_FAILURE",

  GET_RECORD_STATUS: "GET_RECORD_STATUS",
  GET_RECORD_STATUS_SUCCESS: "GET_RECORD_STATUS_SUCCESS",
  GET_RECORD_STATUS_FAILURE: "GET_RECORD_STATUS_FAILURE",

  GET_TICKET_STATUS: "GET_TICKET_STATUS",
  GET_TICKET_STATUS_SUCCESS: "GET_TICKET_STATUS_SUCCESS",
  GET_TICKET_STATUS_FAILURE: "GET_TICKET_STATUS_FAILURE",

  FACILITY_DISTRIBUTION: "FACILITY_DISTRIBUTION",
  FACILITY_DISTRIBUTION_SUCCESS: "FACILITY_DISTRIBUTION_SUCCESS",
  FACILITY_DISTRIBUTION_FAILURE: "FACILITY_DISTRIBUTION_FAILURE",

  GET_CUMULATIVE_TOPICS: "GET_CUMULATIVE_TOPICS",
  GET_CUMULATIVE_TOPICS_SUCCESS: "GET_CUMULATIVE_TOPICS_SUCCESS",
  GET_CUMULATIVE_TOPICS_FAILURE: "GET_CUMULATIVE_TOPICS_FAILURE",

  STAKEHOLDER_EMISSION_TYPE_GRAPH: "STAKEHOLDER_EMISSION_TYPE_GRAPH",
  STAKEHOLDER_EMISSION_TYPE_GRAPH_SUCCESS:
    "STAKEHOLDER_EMISSION_TYPE_GRAPH_SUCCESS",
  STAKEHOLDER_EMISSION_TYPE_GRAPH_FAILURE:
    "STAKEHOLDER_EMISSION_TYPE_GRAPH_FAILURE",

  GET_STAKEHOLDER_EMISSION_YEAR_DATA: "GET_STAKEHOLDER_EMISSION_YEAR_DATA",
  GET_STAKEHOLDER_EMISSION_YEAR_DATA_SUCCESS:
    "GET_STAKEHOLDER_EMISSION_YEAR_DATA_SUCCESS",
  GET_STAKEHOLDER_EMISSION_YEAR_DATA_FAILURE:
    "GET_STAKEHOLDER_EMISSION_YEAR_DATA_FAILURE",

  GET_FACILITY_PERIODS: "GET_FACILITY_PERIODS",
  GET_FACILITY_PERIODS_SUCCESS: "GET_FACILITY_PERIODS_SUCCESS",
  GET_FACILITY_PERIODS_FAILURE: "GET_FACILITY_PERIODS_FAILURE",

  GET_APPROVAL_TOPIC_STATUS: "GET_APPROVAL_TOPIC_STATUS",
  GET_APPROVAL_TOPIC_STATUS_SUCCESS: "GET_APPROVAL_TOPIC_STATUS_SUCCESS",
  GET_APPROVAL_TOPIC_STATUS_FAILURE: "GET_APPROVAL_TOPIC_STATUS_FAILURE",

  ROLE_BASED_APPROVAL: "ROLE_BASED_APPROVAL",
  ROLE_BASED_APPROVAL_SUCCESS: "ROLE_BASED_APPROVAL_SUCCESS",
  ROLE_BASED_APPROVAL_FAILURE: "ROLE_BASED_APPROVAL_FAILURE",

  SUBMIT_MONTHLY_APPROVAL: "SUBMIT_MONTHLY_APPROVAL",
  SUBMIT_MONTHLY_APPROVAL_SUCCESS: "SUBMIT_MONTHLY_APPROVAL_SUCCESS",
  SUBMIT_MONTHLY_APPROVAL_FAILURE: "SUBMIT_MONTHLY_APPROVAL_FAILURE",

  GET_REFRESH_TOKEN: "GET_REFRESH_TOKEN",
  GET_REFRESH_TOKEN_SUCCESS: "GET_REFRESH_TOKEN_SUCCESS",
  GET_REFRESH_TOKEN_FAILURE: "GET_REFRESH_TOKEN_FAILURE",

  SHOW_SWITCH_USER_MODAL: "SHOW_SWITCH_USER_MODAL",

  ASSIGN_MONTHLY_APPROVAL: "ASSIGN_MONTHLY_APPROVAL",
  ASSIGN_MONTHLY_APPROVAL_SUCCESS: "ASSIGN_MONTHLY_APPROVAL_SUCCESS",
  ASSIGN_MONTHLY_APPROVAL_FAILURE: "ASSIGN_MONTHLY_APPROVAL_FAILURE",

  APPROVE_MONTHLY_APPROVAL: "APPROVE_MONTHLY_APPROVAL",
  APPROVE_MONTHLY_APPROVAL_SUCCESS: "APPROVE_MONTHLY_APPROVAL_SUCCESS",
  APPROVE_MONTHLY_APPROVAL_FAILURE: "APPROVE_MONTHLY_APPROVAL_FAILURE",

  PENDING_APPROVALS: "PENDING_APPROVALS",
  PENDING_APPROVALS_SUCCESS: "PENDING_APPROVALS_SUCCESS",
  PENDING_APPROVALS_FAILURE: "PENDING_APPROVALS_FAILURE",

  ADD_REVIEW_COMMENTS: "ADD_REVIEW_COMMENTS",
  ADD_REVIEW_COMMENTS_SUCCESS: "ADD_REVIEW_COMMENTS_SUCCESS",
  ADD_REVIEW_COMMENTS_FAILURE: "ADD_REVIEW_COMMENTS_FAILURE",

  CLEAR_DASHBOARD_DATA: "CLEAR_DASHBOARD_DATA",
  CLEAR_NOTIFICATIONS: "CLEAR_NOTIFICATIONS",

  SET_ACTIVE_ROLE: "SET_ACTIVE_ROLE",
  SET_ACTIVE_ROLE_SUCCESS: "SET_ACTIVE_ROLE_SUCCESS",
  SET_ACTIVE_ROLE_FAILURE: "SET_ACTIVE_ROLE_FAILURE",

  ROLE_DISTRIBUTION: "ROLE_DISTRIBUTION",
  ROLE_DISTRIBUTION_SUCCESS: "ROLE_DISTRIBUTION_SUCCESS",
  ROLE_DISTRIBUTION_FAILURE: "ROLE_DISTRIBUTION_FAILURE",

  GET_ASSESSMENT_PERIOD: "GET_ASSESSMENT_PERIOD",
  GET_ASSESSMENT_PERIOD_SUCCESS: "GET_ASSESSMENT_PERIOD_SUCCESS",
  GET_ASSESSMENT_PERIOD_FAILURE: "GET_ASSESSMENT_PERIOD_FAILURE",

  ADD_EMPLOYEE_HIRE: "ADD_EMPLOYEE_HIRE",
  ADD_EMPLOYEE_HIRE_SUCCESS: "ADD_EMPLOYEE_HIRE_SUCCESS",
  ADD_EMPLOYEE_HIRE_FAILURE: "ADD_EMPLOYEE_HIRE_FAILURE",

  ADD_EMPLOYEE_BENEFIT: "ADD_EMPLOYEE_BENEFIT",
  ADD_EMPLOYEE_BENEFIT_SUCCESS: "ADD_EMPLOYEE_BENEFIT_SUCCESS",
  ADD_EMPLOYEE_BENEFIT_FAILURE: "ADD_EMPLOYEE_BENEFIT_FAILURE",

  ADD_EMPLOYEE_TRAINING_HOURS: "ADD_EMPLOYEE_TRAINING_HOURS",
  ADD_EMPLOYEE_TRAINING_HOURS_SUCCESS: "ADD_EMPLOYEE_TRAINING_HOURS_SUCCESS",
  ADD_EMPLOYEE_TRAINING_HOURS_FAILURE: "ADD_EMPLOYEE_TRAINING_HOURS_FAILURE",

  UPDATE_EMPLOYEE_TRAINING_HOURS: "UPDATE_EMPLOYEE_TRAINING_HOURS",
  UPDATE_EMPLOYEE_TRAINING_HOURS_SUCCESS:
    "UPDATE_EMPLOYEE_TRAINING_HOURS_SUCCESS",
  UPDATE_EMPLOYEE_TRAINING_HOURS_FAILURE:
    "UPDATE_EMPLOYEE_TRAINING_HOURS_FAILURE",

  ADD_PERFORMANCE_AND_CAREER_DEVELOPMENT_PROGRAMS:
    "ADD_PERFORMANCE_AND_CAREER_DEVELOPMENT_PROGRAMS",
  ADD_PERFORMANCE_AND_CAREER_DEVELOPMENT_PROGRAMS_SUCCESS:
    "ADD_PERFORMANCE_AND_CAREER_DEVELOPMENT_PROGRAMS_SUCCESS",
  ADD_PERFORMANCE_AND_CAREER_DEVELOPMENT_PROGRAMS_FAILURE:
    "ADD_PERFORMANCE_AND_CAREER_DEVELOPMENT_PROGRAMS_FAILURE",

  UPDATE_PERFORMANCE_AND_CAREER_DEVELOPMENT_PROGRAMS:
    "UPDATE_PERFORMANCE_AND_CAREER_DEVELOPMENT_PROGRAMS",
  UPDATE_PERFORMANCE_AND_CAREER_DEVELOPMENT_PROGRAMS_SUCCESS:
    "UPDATE_PERFORMANCE_AND_CAREER_DEVELOPMENT_PROGRAMS_SUCCESS",
  UPDATE_PERFORMANCE_AND_CAREER_DEVELOPMENT_PROGRAMS_FAILURE:
    "UPDATE_PERFORMANCE_AND_CAREER_DEVELOPMENT_PROGRAMS_FAILURE",

  RESET_PERFORMANCE_AND_CAREER_DEVELOPMENT_PROGRAMS:
    "RESET_PERFORMANCE_AND_CAREER_DEVELOPMENT_PROGRAMS",
  RESET_EMPLOYEE_TRAINING_HOURS: "RESET_EMPLOYEE_TRAINING_HOURS",

  ADD_EMPLOYEE_DIVERSITY: "ADD_EMPLOYEE_DIVERSITY",
  ADD_EMPLOYEE_DIVERSITY_SUCCESS: "ADD_EMPLOYEE_DIVERSITY_SUCCESS",
  ADD_EMPLOYEE_DIVERSITY_FAILURE: "ADD_EMPLOYEE_DIVERSITY_FAILURE",

  ADD_ECONOMIC_IMPACT: "ADD_ECONOMIC_IMPACT",
  ADD_ECONOMIC_IMPACT_SUCCESS: "ADD_ECONOMIC_IMPACT_SUCCESS",
  ADD_ECONOMIC_IMPACT_FAILURE: "ADD_ECONOMIC_IMPACT_FAILURE",

  UPDATE_ECONOMIC_IMPACT: "UPDATE_ECONOMIC_IMPACT",
  UPDATE_ECONOMIC_IMPACT_SUCCESS: "UPDATE_ECONOMIC_IMPACT_SUCCESS",
  UPDATE_ECONOMIC_IMPACT_FAILURE: "UPDATE_ECONOMIC_IMPACT_FAILURE",

  UPDATE_EMPLOYEE_DIVERSITY: "UPDATE_EMPLOYEE_DIVERSITY",
  UPDATE_EMPLOYEE_DIVERSITY_SUCCESS: "UPDATE_EMPLOYEE_DIVERSITY_SUCCESS",
  UPDATE_EMPLOYEE_DIVERSITY_FAILURE: "UPDATE_EMPLOYEE_DIVERSITY_FAILURE",

  UPDATE_EMPLOYEE_BENEFITS: "UPDATE_EMPLOYEE_BENEFITS",
  UPDATE_EMPLOYEE_BENEFITS_SUCCESS: "UPDATE_EMPLOYEE_BENEFITS_SUCCESS",
  UPDATE_EMPLOYEE_BENEFITS_FAILURE: "UPDATE_EMPLOYEE_BENEFITS_FAILURE",

  UPDATE_EMPLOYEE_HIRE: "UPDATE_EMPLOYEE_HIRE",
  UPDATE_EMPLOYEE_HIRE_SUCCESS: "UPDATE_EMPLOYEE_HIRE_SUCCESS",
  UPDATE_EMPLOYEE_HIRE_FAILURE: "UPDATE_EMPLOYEE_HIRE_FAILURE",

  APPROVE_AUDIT_BY_SUSMGR: "APPROVE_AUDIT_BY_SUSMGR",
  APPROVE_AUDIT_BY_SUSMGR_SUCCESS: "APPROVE_AUDIT_BY_SUSMGR_SUCCESS",
  APPROVE_AUDIT_BY_SUSMGR_FAILURE: "APPROVE_AUDIT_BY_SUSMGR_FAILURE",

  GET_AUDIT_TYPES: "GET_AUDIT_TYPES",
  GET_AUDIT_TYPES_SUCCESS: "GET_AUDIT_TYPES_SUCCESS",
  GET_AUDIT_TYPES_FAILURE: "GET_AUDIT_TYPES_FAILURE",

  UPDATE_AUDIT_TYPES: "UPDATE_AUDIT_TYPES",
  UPDATE_AUDIT_TYPES_SUCCESS: "UPDATE_AUDIT_TYPES_SUCCESS",
  UPDATE_AUDIT_TYPES_FAILURE: "UPDATE_AUDIT_TYPES_FAILURE",

  GENERAL_USER_LOGIN: "GENERAL_USER_LOGIN",
  GENERAL_USER_LOGIN_SUCCESS: "GENERAL_USER_LOGIN_SUCCESS",
  GENERAL_USER_LOGIN_FAILURE: "GENERAL_USER_LOGIN_FAILURE",

  GET_APPROVAL_EMISSION: "GET_APPROVAL_EMISSION",
  GET_APPROVAL_EMISSION_SUCCESS: "GET_APPROVAL_EMISSION_SUCCESS",
  GET_APPROVAL_EMISSION_FAILURE: "GET_APPROVAL_EMISSION_FAILURE",

  GET_TOPIC_BASED_APPROVAL: "GET_TOPIC_BASED_APPROVAL",
  GET_TOPIC_BASED_APPROVAL_SUCCESS: "GET_TOPIC_BASED_APPROVAL_SUCCESS",
  GET_TOPIC_BASED_APPROVAL_FAILURE: "GET_TOPIC_BASED_APPROVAL_FAILURE",

  ASSIGN_TO_SUBMIT: "ASSIGN_TO_SUBMIT",
  ASSIGN_TO_SUBMIT_SUCCESS: "ASSIGN_TO_SUBMIT_SUCCESS",
  ASSIGN_TO_SUBMIT_FAILURE: "ASSIGN_TO_SUBMIT_FAILURE",

  PENDING_APPROVAL_EMISSION: "PENDING_APPROVAL_EMISSION",
  PENDING_APPROVAL_EMISSION_SUCCESS: "PENDING_APPROVAL_EMISSION_SUCCESS",
  PENDING_APPROVAL_EMISSION_FAILURE: "PENDING_APPROVAL_EMISSION_FAILURE",

  GET_APPROVED_DATA: "GET_APPROVED_DATA",
  GET_APPROVED_DATA_SUCCESS: "GET_APPROVED_DATA_SUCCESS",
  GET_APPROVED_DATA_FAILURE: "GET_APPROVED_DATA_FAILURE",

  GET_STAKEHOLDER_CUMULATIVE_DATA: "GET_STAKEHOLDER_CUMULATIVE_DATA",
  GET_STAKEHOLDER_CUMULATIVE_DATA_SUCCESS:
    "GET_STAKEHOLDER_CUMULATIVE_DATA_SUCCESS",
  GET_STAKEHOLDER_CUMULATIVE_DATA_FAILURE:
    "GET_STAKEHOLDER_CUMULATIVE_DATA_FAILURE",

  RESEND_OTP: "RESEND_OTP",
  RESEND_OTP_SUCCESS: "RESEND_OTP_SUCCESS",
  RESEND_OTP_FAILURE: "RESEND_OTP_FAILURE",

  CLEAR_OTP: "CLEAR_OTP",

  UPDATE_TICKET: "UPDATE_TICKET",
  UPDATE_TICKET_SUCCESS: "UPDATE_TICKET_SUCCESS",
  UPDATE_TICKET_FAILURE: "UPDATE_TICKET_FAILURE",
  ADD_VISION: "ADD_VISION",
  ADD_VISION_SUCCESS: "ADD_VISION_SUCCESS",
  ADD_VISION_FAILURE: "ADD_VISION_FAILURE",

  GET_VISION: "GET_VISION",
  GET_VISION_SUCCESS: "GET_VISION_SUCCESS",
  GET_VISION_FAILURE: "GET_VISION_FAILURE",

  ADD_MISSION: "ADD_MISSION",
  ADD_MISSION_SUCCESS: "ADD_MISSION_SUCCESS",
  ADD_MISSION_FAILURE: "ADD_MISSION_FAILURE",

  EDIT_MISSION: "EDIT_MISSION",
  EDIT_MISSION_SUCCESS: "EDIT_MISSION_SUCCESS",
  EDIT_MISSION_FAILURE: "EDIT_MISSION_FAILURE",

  GET_MISSION: "GET_MISSION",
  GET_MISSION_SUCCESS: "GET_MISSION_SUCCESS",
  GET_MISSION_FAILURE: "GET_MISSION_FAILURE",

  RESET_GOALS: "RESET_GOALS",

  RESET_EMISSION_DELETE: "RESET_EMISSION_DELETE",

  GET_INITIATIVE_DETAILS: "GET_INITIATIVE_DETAILS",
  GET_INITIATIVE_DETAILS_SUCCESS: "GET_INITIATIVE_DETAILS_SUCCESS",
  GET_INITIATIVE_DETAILS_FAILURE: "GET_INITIATIVE_DETAILS_FAILURE",

  ADD_INITIATIVE: "ADD_INITIATIVE",
  ADD_INITIATIVE_SUCCESS: "ADD_INITIATIVE_SUCCESS",
  ADD_INITIATIVE_FAILURE: "ADD_INITIATIVE_FAILURE",

  RESET_ADD_INITIATIVE_STATUS: "RESET_ADD_INITIATIVE_STATUS",

  LIST_INITIATIVE: "LIST_INITIATIVE",
  LIST_INITIATIVE_SUCCESS: "LIST_INITIATIVE_SUCCESS",
  LIST_INITIATIVE_FAILURE: "LIST_INITIATIVE_FAILURE",

  ADD_TASKS: "ADD_TASKS",
  ADD_TASKS_SUCCESS: "ADD_TASKS_SUCCESS",
  ADD_TASKS_FAILURE: "ADD_TASKS_FAILURE",

  GET_TASKS_LIST: "GET_TASKS_LIST",
  GET_TASKS_LIST_SUCCESS: "GET_TASKS_LIST_SUCCESS",
  GET_TASKS_LIST_FAILURE: "GET_TASKS_LIST_FAILURE",

  RESET_ADD_TASK_STATUS: "RESET_ADD_TASK_STATUS",
  SAVE_TASK_TRACKING: "SAVE_TASK_TRACKING",
  SAVE_TASK_TRACKING_SUCCESS: "SAVE_TASK_TRACKING_SUCCESS",
  SAVE_TASK_TRACKING_FAILURE: "SAVE_TASK_TRACKING_FAILURE",

  ADD_TARGET_ACTUAL: "ADD_TARGET_ACTUAL",
  ADD_TARGET_ACTUAL_SUCCESS: "ADD_TARGET_ACTUAL_SUCCESS",
  ADD_TARGET_ACTUAL_FAILURE: "ADD_TARGET_ACTUAL_FAILURE",

  GET_INITIATIVE_TRACKING: "GET_INITIATIVE_TRACKING",
  GET_INITIATIVE_TRACKING_SUCCESS: "GET_INITIATIVE_TRACKING_SUCCESS",
  GET_INITIATIVE_TRACKING_FAILURE: "GET_INITIATIVE_TRACKING_FAILURE",

  GET_ENERGY_MATERIAL: "GET_ENERGY_MATERIAL",
  GET_ENERGY_MATERIAL_SUCCESS: "GET_ENERGY_MATERIAL_SUCCESS",
  GET_ENERGY_MATERIAL_FAILURE: "GET_ENERGY_MATERIAL_FAILURE",

  GET_FUEL_SOURCES_TARGET: "GET_FUEL_SOURCES_TARGET",
  GET_FUEL_SOURCES_TARGET_SUCCESS: "GET_FUEL_SOURCES_TARGET_SUCCESS",
  GET_FUEL_SOURCES_TARGET_FAILURE: "GET_FUEL_SOURCES_TARGET_FAILURE",

  GET_EMPLOYEE_DIVERSITY: "GET_EMPLOYEE_DIVERSITY",
  GET_EMPLOYEE_DIVERSITY_SUCCESS: "GET_EMPLOYEE_DIVERSITY_SUCCESS",
  GET_EMPLOYEE_DIVERSITY_FAILURE: "GET_EMPLOYEE_DIVERSITY_FAILURE",

  GET_DEVELOPMENT_TRAINING: "GET_DEVELOPMENT_TRAINING",
  GET_DEVELOPMENT_TRAINING_SUCCESS: "GET_DEVELOPMENT_TRAINING_SUCCESS",
  GET_DEVELOPMENT_TRAINING_FAILURE: "GET_DEVELOPMENT_TRAINING_FAILURE",

  GET_SOCIAL_ENGAGEMENT: "GET_SOCIAL_ENGAGEMENT",
  GET_SOCIAL_ENGAGEMENT_SUCCESS: "GET_SOCIAL_ENGAGEMENT_SUCCESS",
  GET_SOCIAL_ENGAGEMENT_FAILURE: "GET_SOCIAL_ENGAGEMENT_FAILURE",

  GET_HIRE_RESIGN_GRAPH: "GET_HIRE_RESIGN_GRAPH",
  GET_HIRE_RESIGN_GRAPH_SUCCESS: "GET_HIRE_RESIGN_GRAPH_SUCCESS",
  GET_HIRE_RESIGN_GRAPH_FAILURE: "GET_HIRE_RESIGN_GRAPH_FAILURE",

  GET_BOARD_MANAGEMENT_GRAPH: "GET_BOARD_MANAGEMENT_GRAPH",
  GET_BOARD_MANAGEMENT_GRAPH_SUCCESS: "GET_BOARD_MANAGEMENT_GRAPH_SUCCESS",
  GET_BOARD_MANAGEMENT_GRAPH_FAILURE: "GET_BOARD_MANAGEMENT_GRAPH_FAILURE",

  GET_EMPLOYEE_TURNOVER_GRAPH: "GET_EMPLOYEE_TURNOVER_GRAPH",
  GET_EMPLOYEE_TURNOVER_GRAPH_SUCCESS: "GET_EMPLOYEE_TURNOVER_GRAPH_SUCCESS",
  GET_EMPLOYEE_TURNOVER_GRAPH_FAILURE: "GET_EMPLOYEE_TURNOVER_GRAPH_FAILURE",

  GET_ECONOMIC_GRAPH: "GET_ECONOMIC_GRAPH",
  GET_ECONOMIC_GRAPH_SUCCESS: "GET_ECONOMIC_GRAPH_SUCCESS",
  GET_ECONOMIC_GRAPH_FAILURE: "GET_ECONOMIC_GRAPH_FAILURE",

  GET_ETHICAL_BEHAVIOUR_GRAPH: "GET_ETHICAL_BEHAVIOUR_GRAPH",
  GET_ETHICAL_BEHAVIOUR_GRAPH_SUCCESS: "GET_ETHICAL_BEHAVIOUR_GRAPH_SUCCESS",
  GET_ETHICAL_BEHAVIOUR_GRAPH_FAILURE: "GET_ETHICAL_BEHAVIOUR_GRAPH_FAILURE",

  GET_ANTI_CORRUPTION_TRAINING: "GET_ANTI_CORRUPTION_TRAINING",
  GET_ANTI_CORRUPTION_TRAINING_SUCCESS: "GET_ANTI_CORRUPTION_TRAINING_SUCCESS",
  GET_ANTI_CORRUPTION_TRAINING_FAILURE: "GET_ANTI_CORRUPTION_TRAINING_FAILURE",

  GET_ANTI_CORRUPTION_DISCLOSURE: "GET_ANTI_CORRUPTION_DISCLOSURE",
  GET_ANTI_CORRUPTION_DISCLOSURE_SUCCESS:
    "GET_ANTI_CORRUPTION_DISCLOSURE_SUCCESS",
  GET_ANTI_CORRUPTION_DISCLOSURE_FAILURE:
    "GET_ANTI_CORRUPTION_DISCLOSURE_FAILURE",

  GET_TRACKING_CYCLE_ASSESSMENT_PERIOD: "GET_TRACKING_CYCLE_ASSESSMENT_PERIOD",
  GET_TRACKING_CYCLE_ASSESSMENT_PERIOD_SUCCESS:
    "GET_TRACKING_CYCLE_ASSESSMENT_PERIOD_SUCCESS",
  GET_TRACKING_CYCLE_ASSESSMENT_PERIOD_FAILURE:
    "GET_TRACKING_CYCLE_ASSESSMENT_PERIOD_FAILURE",

  UPDATE_COMPANY_SUBSCRIPTION: "UPDATE_COMPANY_SUBSCRIPTION",
  UPDATE_COMPANY_SUBSCRIPTION_SUCCESS: "UPDATE_COMPANY_SUBSCRIPTION_SUCCESS",
  UPDATE_COMPANY_SUBSCRIPTION_FAILURE: "UPDATE_COMPANY_SUBSCRIPTION_FAILURE",

  GET_INITIATIVE_TRACKING_DRILL_DOWN: "GET_INITIATIVE_TRACKING_DRILL_DOWN",
  GET_INITIATIVE_TRACKING_DRILL_DOWN_SUCCESS:
    "GET_INITIATIVE_TRACKING_DRILL_DOWN_SUCCESS",
  GET_INITIATIVE_TRACKING_DRILL_DOWN_FAILURE:
    "GET_INITIATIVE_TRACKING_DRILL_DOWN_FAILURE",

  GET_ENERGY_MATERIALS_DRILL_DOWN: "GET_ENERGY_MATERIALS_DRILL_DOWN",
  GET_ENERGY_MATERIALS_DRILL_DOWN_SUCCESS:
    "GET_ENERGY_MATERIALS_DRILL_DOWN_SUCCESS",
  GET_ENERGY_MATERIALS_DRILL_DOWN_FAILURE:
    "GET_ENERGY_MATERIALS_DRILL_DOWN_FAILURE",

  EDIT_TASK: "EDIT_TASK",
  EDIT_TASK_SUCCESS: "EDIT_TASK_SUCCESS",
  EDIT_TASK_FAILURE: "EDIT_TASK_FAILURE",

  SET_AUDIT_TOPICS: "SET_AUDIT_TOPICS",
  SET_AUDIT_TOPICS_SUCCESS: "SET_AUDIT_TOPICS_SUCCESS",
  SET_AUDIT_TOPICS_FAILURE: "SET_AUDIT_TOPICS_FAILURE",

  CREATE_BUSINESS_ADMIN: "CREATE_BUSINESS_ADMIN",
  CREATE_BUSINESS_ADMIN_SUCCESS: "CREATE_BUSINESS_ADMIN_SUCCESS",
  CREATE_BUSINESS_ADMIN_FAILURE: "CREATE_BUSINESS_ADMIN_FAILURE",

  RESET_CREATE_BUSINESS_STATUS: "RESET_CREATE_BUSINESS_STATUS",

  UPDATE_BUSINESS_ADMIN_STATUS: "UPDATE_BUSINESS_ADMIN_STATUS",
  UPDATE_BUSINESS_ADMIN_STATUS_SUCCESS: "UPDATE_BUSINESS_ADMIN_STATUS_SUCCESS",
  UPDATE_BUSINESS_ADMIN_STATUS_FAILURE: "UPDATE_BUSINESS_ADMIN_STATUS_FAILURE",

  GET_USER_LOGS: "GET_USER_LOGS",
  GET_USER_LOGS_SUCCESS: "GET_USER_LOGS_SUCCESS",
  GET_USER_LOGS_FAILURE: "GET_USER_LOGS_FAILURE",

  LOCK_UNLOCK_USER: "LOCK_UNLOCK_USER",
  LOCK_UNLOCK_USER_SUCCESS: "LOCK_UNLOCK_USER_SUCCESS",
  LOCK_UNLOCK_USER_FAILURE: "LOCK_UNLOCK_USER_FAILURE",

  GET_RECENT_LOGS: "GET_RECENT_LOGS",
  GET_RECENT_LOGS_SUCCESS: "GET_RECENT_LOGS_SUCCESS",
  GET_RECENT_LOGS_FAILURE: "GET_RECENT_LOGS_FAILURE",

  RESET_LIST_USERS: "RESET_LIST_USERS",

  ADMIN_TWOFA_OTP_GENERATE: "ADMIN_TWOFA_OTP_GENERATE",
  ADMIN_TWOFA_OTP_GENERATE_SUCCESS: "ADMIN_TWOFA_OTP_GENERATE_SUCCESS",
  ADMIN_TWOFA_OTP_GENERATE_FAILURE: "ADMIN_TWOFA_OTP_GENERATE_FAILURE",

  ADMIN_TWOFA_OTP_SUBMIT: "ADMIN_TWOFA_OTP_SUBMIT",
  ADMIN_TWOFA_OTP_SUBMIT_SUCCESS: "ADMIN_TWOFA_OTP_SUBMIT_SUCCESS",
  ADMIN_TWOFA_OTP_SUBMIT_FAILURE: "ADMIN_TWOFA_OTP_SUBMIT_FAILURE",

  ADMIN_PASSWORD_SUBMIT: "ADMIN_PASSWORD_SUBMIT",
  ADMIN_PASSWORD_SUBMIT_SUCCESS: "ADMIN_PASSWORD_SUBMIT_SUCCESS",
  ADMIN_PASSWORD_SUBMIT_FAILURE: "ADMIN_PASSWORD_SUBMIT_FAILURE",

  GET_SUBSCRIPTION_DETAILS: "GET_SUBSCRIPTION_DETAILS",
  GET_SUBSCRIPTION_DETAILS_SUCCESS: "GET_SUBSCRIPTION_DETAILS_SUCCESS",
  GET_SUBSCRIPTION_DETAILS_FAILURE: "GET_SUBSCRIPTION_DETAILS_FAILURE",

  CREATE_SUBSCRIPTION_PLAN: "CREATE_SUBSCRIPTION_PLAN",
  CREATE_SUBSCRIPTION_PLAN_SUCCESS: "CREATE_SUBSCRIPTION_PLAN_SUCCESS",
  CREATE_SUBSCRIPTION_PLAN_FAILURE: "CREATE_SUBSCRIPTION_PLAN_FAILURE",

  GET_SUBSCRIPTION_DETAILS_SUPERADMIN: "GET_SUBSCRIPTION_DETAILS_SUPERADMIN",
  GET_SUBSCRIPTION_DETAILS_SUPERADMIN_SUCCESS:
    "GET_SUBSCRIPTION_DETAILS_SUPERADMIN_SUCCESS",
  GET_SUBSCRIPTION_DETAILS_SUPERADMIN_FAILURE:
    "GET_SUBSCRIPTION_DETAILS_SUPERADMIN_FAILURE",

  GET_ALL_SUBSCRIPTION_PLANS_SUPERADMIN:
    "GET_ALL_SUBSCRIPTION_PLANS_SUPERADMIN",
  GET_ALL_SUBSCRIPTION_PLANS_SUPERADMIN_SUCCESS:
    "GET_ALL_SUBSCRIPTION_PLANS_SUPERADMIN_SUCCESS",
  GET_ALL_SUBSCRIPTION_PLANS_SUPERADMIN_FAILURE:
    "GET_ALL_SUBSCRIPTION_PLANS_SUPERADMIN_FAILURE",
  CLEAR_SUBSCRIPTION_STATUS: "CLEAR_SUBSCRIPTION_STATUS",
  GET_STATES: "GET_STATES",
  GET_STATES_SUCCESS: "GET_STATES_SUCCESS",
  GET_STATES_FAILURE: "GET_STATES_FAILURE",
  RESET_SIGNUP_ACTION: "RESET_SIGNUP_ACTION",

  GET_APPROVAL_FLOW: "GET_APPROVAL_FLOW",
  GET_APPROVAL_FLOW_SUCCESS: "GET_APPROVAL_FLOW_SUCCESS",
  GET_APPROVAL_FLOW_FAILURE: "GET_APPROVAL_FLOW_FAILURE",

  UPDATE_APPROVAL_FLOW: "UPDATE_APPROVAL_FLOW",
  UPDATE_APPROVAL_FLOW_SUCCESS: "UPDATE_APPROVAL_FLOW_SUCCESS",
  UPDATE_APPROVAL_FLOW_FAILURE: "UPDATE_APPROVAL_FLOW_FAILURE",

  RESET_APPROVAL_UPDATE: "RESET_APPROVAL_UPDATE",
  RESET_GET_APPROVAL: "RESET_GET_APPROVAL",

  GET_REPORT_BLOB: "GET_REPORT_BLOB",
  GET_REPORT_BLOB_SUCCESS: "GET_REPORT_BLOB_SUCCESS",
  GET_REPORT_BLOB_FAILURE: "GET_REPORT_BLOB_FAILURE",

  REDIRECT_CARBON_CREDIT_POOL: "REDIRECT_CARBON_CREDIT_POOL",

  //Trade
  SIGNUP_TRADE: "SIGNUP_TRADE",
  SIGNUP_TRADE_SUCCESS: "SIGNUP_TRADE_SUCCESS",
  SIGNUP_TRADE_FAILURE: "SIGNUP_TRADE_FAILURE",

  RESET_SIGNUP: "RESET_SIGNUP",

  LOGIN_TRADE: "LOGIN_TRADE",
  LOGIN_TRADE_SUCCESS: "LOGIN_TRADE_SUCCESS",
  LOGIN_TRADE_FAILURE: "LOGIN_TRADE_FAILURE",

  RESET_LOGIN_TRADE: "RESET_LOGIN_TRADE",

  INDUSTRY_TYPES: "INDUSTRY_TYPES",
  INDUSTRY_TYPES_SUCCESS: "INDUSTRY_TYPES_SUCCESS",
  INDUSTRY_TYPES_FAILURE: "INDUSTRY_TYPES_FAILURE",

  GET_LOCATIONS: "GET_LOCATIONS",
  GET_LOCATIONS_SUCCESS: "GET_LOCATIONS_SUCCESS",
  GET_LOCATIONS_FAILURE: "GET_LOCATIONS_FAILURE",

  SELL_CARBON_CREDITS: "SELL_CARBON_CREDITS",
  SELL_CARBON_CREDITS_SUCCESS: "SELL_CARBON_CREDITS_SUCCESS",
  SELL_CARBON_CREDITS_FAILURE: "SELL_CARBON_CREDITS_FAILURE",

  GET_CARBON_CREDITS_ADDED: "GET_CARBON_CREDITS_ADDED",
  GET_CARBON_CREDITS_ADDED_SUCCESS: "GET_CARBON_CREDITS_ADDED_SUCCESS",
  GET_CARBON_CREDITS_ADDED_FAILURE: "GET_CARBON_CREDITS_ADDED_FAILURE",

  BUY_CARBON_CREDIT_LIST: "BUY_CARBON_CREDIT_LIST",
  BUY_CARBON_CREDIT_LIST_SUCCESS: "BUY_CARBON_CREDIT_LIST_SUCCESS",
  BUY_CARBON_CREDIT_LIST_FAILURE: "BUY_CARBON_CREDIT_LIST_FAILURE",

  GET_PROJECT_DETAILS: "GET_PROJECT_DETAILS",
  GET_PROJECT_DETAILS_SUCCESS: "GET_PROJECT_DETAILS_SUCCESS",
  GET_PROJECT_DETAILS_FAILURE: "GET_PROJECT_DETAILS_FAILURE",

  GET_TRADE_ACCOUNT: "GET_TRADE_ACCOUNT",
  GET_TRADE_ACCOUNT_SUCCESS: "GET_TRADE_ACCOUNT_SUCCESS",
  GET_TRADE_ACCOUNT_FAILURE: "GET_TRADE_ACCOUNT_FAILURE",

  UPDATE_TRADE_ACCOUNT: "UPDATE_TRADE_ACCOUNT",
  UPDATE_TRADE_ACCOUNT_SUCCESS: "UPDATE_TRADE_ACCOUNT_SUCCESS",
  UPDATE_TRADE_ACCOUNT_FAILURE: "UPDATE_TRADE_ACCOUNT_FAILURE",

  GET_SUPER_ADMIN_REPORT: "GET_SUPER_ADMIN_REPORT",
  GET_SUPER_ADMIN_REPORT_SUCCESS: "GET_SUPER_ADMIN_REPORT_SUCCESS",
  GET_SUPER_ADMIN_REPORT_FAILURE: "GET_SUPER_ADMIN_REPORT_FAILURE",

  GET_SUPER_ADMIN_ORGANIZATION_COUNTRY: "GET_SUPER_ADMIN_ORGANIZATION_COUNTRY",
  GET_SUPER_ADMIN_ORGANIZATION_COUNTRY_SUCCESS:
    "GET_SUPER_ADMIN_ORGANIZATION_COUNTRY_SUCCESS",
  GET_SUPER_ADMIN_ORGANIZATION_COUNTRY_FAILURE:
    "GET_SUPER_ADMIN_ORGANIZATION_COUNTRY_FAILURE",

  GET_MATERIALITY_INDUSTRIES: "GET_MATERIALITY_INDUSTRIES",
  GET_MATERIALITY_INDUSTRIES_SUCCESS: "GET_MATERIALITY_INDUSTRIES_SUCCESS",
  GET_MATERIALITY_INDUSTRIES_FAILURE: "GET_MATERIALITY_INDUSTRIES_FAILURE",

  GET_MATERIALITY_QUESTIONS: "GET_MATERIALITY_QUESTIONS",
  GET_MATERIALITY_QUESTIONS_SUCCESS: "GET_MATERIALITY_QUESTIONS_SUCCESS",
  GET_MATERIALITY_QUESTIONS_FAILURE: "GET_MATERIALITY_QUESTIONS_FAILURE",

  CREATE_MATERIALITY_QUESTIONS: "CREATE_MATERIALITY_QUESTIONS",
  CREATE_MATERIALITY_QUESTIONS_SUCCESS: "CREATE_MATERIALITY_QUESTIONS_SUCCESS",
  CREATE_MATERIALITY_QUESTIONS_FAILURE: "CREATE_MATERIALITY_QUESTIONS_FAILURE",

  RESET_CREATE_QUESTIONS_STATUS: "RESET_CREATE_QUESTIONS_STATUS",

  CREATE_SURVEY: "CREATE_SURVEY",
  CREATE_SURVEY_SUCCESS: "CREATE_SURVEY_SUCCESS",
  CREATE_SURVEY_FAILURE: "CREATE_SURVEY_FAILURE",

  GET_SURVEY: "GET_SURVEY",
  GET_SURVEY_SUCCESS: "GET_SURVEY_SUCCESS",
  GET_SURVEY_FAILURE: "GET_SURVEY_FAILURE",

  SEND_QUESTIONNAIRES: "SEND_QUESTIONNAIRES",
  SEND_QUESTIONNAIRES_SUCCESS: "SEND_QUESTIONNAIRES_SUCCESS",
  SEND_QUESTIONNAIRES_FAILURE: "SEND_QUESTIONNAIRES_FAILURE",

  RESET_SEND_QUESTIONNAIRES_STATUS: "RESET_SEND_QUESTIONNAIRES_STATUS",

  ASSIGN_AUDITOR: "ASSIGN_AUDITOR",
  ASSIGN_AUDITOR_SUCCESS: "ASSIGN_AUDITOR_SUCCESS",
  ASSIGN_AUDITOR_FAILURE: "ASSIGN_AUDITOR_FAILURE",

  RESET_ASSIGN_AUDITOR: "RESET_ASSIGN_AUDITOR",

  AUDITOR_REACTION: "AUDITOR_REACTION",
  AUDITOR_REACTION_SUCCESS: "AUDITOR_REACTION_SUCCESS",
  AUDITOR_REACTION_FAILURE: "AUDITOR_REACTION_FAILURE",

  RESET_AUDITED_DATA: "RESET_AUDITED_DATA",

  GET_SUPERADMIN_TICKETS: "GET_SUPERADMIN_TICKETS",
  GET_SUPERADMIN_TICKETS_SUCCESS: "GET_SUPERADMIN_TICKETS_SUCCESS",
  GET_SUPERADMIN_TICKETS_FAILURE: "GET_SUPERADMIN_TICKETS_FAILURE",

  GET_SUPERADMIN_TICKET_DETAILS: "GET_SUPERADMIN_TICKET_DETAILS",
  GET_SUPERADMIN_TICKET_DETAILS_SUCCESS:
    "GET_SUPERADMIN_TICKET_DETAILS_SUCCESS",
  GET_SUPERADMIN_TICKET_DETAILS_FAILURE:
    "GET_SUPERADMIN_TICKET_DETAILS_FAILURE",

  ADD_SUPERADMIN_RESPONSE: "ADD_SUPERADMIN_RESPONSE",
  ADD_SUPERADMIN_RESPONSE_SUCCESS: "ADD_SUPERADMIN_RESPONSE_SUCCESS",
  ADD_SUPERADMIN_RESPONSE_FAILURE: "ADD_SUPERADMIN_RESPONSE_FAILURE",

  CLOSE_SUPERADMIN_TICKET: "CLOSE_SUPERADMIN_TICKET",
  CLOSE_SUPERADMIN_TICKET_SUCCESS: "CLOSE_SUPERADMIN_TICKET_SUCCESS",
  CLOSE_SUPERADMIN_TICKET_FAILURE: "CLOSE_SUPERADMIN_TICKET_FAILURE",

  LIST_CARBON_OFFSET_PROGRAMS: "LIST_CARBON_OFFSET_PROGRAMS",
  LIST_CARBON_OFFSET_PROGRAMS_SUCCESS: "LIST_CARBON_OFFSET_PROGRAMS_SUCCESS",
  LIST_CARBON_OFFSET_PROGRAMS_FAILURE: "LIST_CARBON_OFFSET_PROGRAMS_FAILURE",

  ADD_CARBON_OFFSET: "ADD_CARBON_OFFSET",
  ADD_CARBON_OFFSET_SUCCESS: "ADD_CARBON_OFFSET_SUCCESS",
  ADD_CARBON_OFFSET_FAILURE: "ADD_CARBON_OFFSET_FAILURE",

  GET_PROGRAM_TYPES: "GET_PROGRAM_TYPES",
  GET_PROGRAM_TYPES_SUCCESS: "GET_PROGRAM_TYPES_SUCCESS",
  GET_PROGRAM_TYPES_FAILURE: "GET_PROGRAM_TYPES_FAILURE",

  GET_PROGRAMS: "GET_PROGRAMS",
  GET_PROGRAMS_SUCCESS: "GET_PROGRAMS_SUCCESS",
  GET_PROGRAMS_FAILURE: "GET_PROGRAMS_FAILURE",

  RESET_CARBON_OFFSET: "RESET_CARBON_OFFSET",

  GET_OFFSET_DETAILS: "GET_OFFSET_DETAILS",
  GET_OFFSET_DETAILS_SUCCESS: "GET_OFFSET_DETAILS_SUCCESS",
  GET_OFFSET_DETAILS_FAILURE: "GET_OFFSET_DETAILS_FAILURE",

  GET_IOT_DATA: "GET_IOT_DATA",
  GET_IOT_DATA_SUCCESS: "GET_IOT_DATA_SUCCESS",
  GET_IOT_DATA_FAILURE: "GET_IOT_DATA_FAILURE",

  UPDATE_IOT_DATA: "UPDATE_IOT_DATA",
  UPDATE_IOT_DATA_SUCCESS: "UPDATE_IOT_DATA_SUCCESS",
  UPDATE_IOT_DATA_FAILURE: "UPDATE_IOT_DATA_FAILURE",

  RESET_IOT_DATA: "RESET_IOT_DATA",

  REFRESH_IOT_DATA: "REFRESH_IOT_DATA",
  REFRESH_IOT_DATA_SUCCESS: "REFRESH_IOT_DATA_SUCCESS",
  REFRESH_IOT_DATA_FAILURE: "REFRESH_IOT_DATA_FAILURE",

  GET_OFFSET_COUNTRIES: "GET_OFFSET_COUNTRIES",
  GET_OFFSET_COUNTRIES_SUCCESS: "GET_OFFSET_COUNTRIES_SUCCESS",
  GET_OFFSET_COUNTRIES_FAILURE: "GET_OFFSET_COUNTRIES_FAILURE",

  GET_SURVEY_QUESTIONS: "GET_SURVEY_QUESTIONS",
  GET_SURVEY_QUESTIONS_SUCCESS: "GET_SURVEY_QUESTIONS_SUCCESS",
  GET_SURVEY_QUESTIONS_FAILURE: "GET_SURVEY_QUESTIONS_FAILURE",

  ANSWER_SURVEY_QUESTIONS: "ANSWER_SURVEY_QUESTIONS",
  ANSWER_SURVEY_QUESTIONS_SUCCESS: "ANSWER_SURVEY_QUESTIONS_SUCCESS",
  ANSWER_SURVEY_QUESTIONS_FAILURE: "ANSWER_SURVEY_QUESTIONS_FAILURE",

  GET_ASSESSMENT_YEAR: "GET_ASSESSMENT_YEAR",
  GET_ASSESSMENT_YEAR_SUCCESS: "GET_ASSESSMENT_YEAR_SUCCESS",
  GET_ASSESSMENT_YEAR_FAILURE: "GET_ASSESSMENT_YEAR_FAILURE",

  GET_NFT_URI: "GET_NFT_URI",
  GET_NFT_URI_SUCCESS: "GET_NFT_URI_SUCCESS",
  GET_NFT_URI_FAILURE: "GET_NFT_URI_FAILURE",

  SET_INDUSTRY_TYPE: "SET_INDUSTRY_TYPE",
  SET_INDUSTRY_TYPE_SUCCESS: "SET_INDUSTRY_TYPE_SUCCESS",
  SET_INDUSTRY_TYPE_FAILURE: "SET_INDUSTRY_TYPE_FAILURE",

  GET_SURVEY_LIST: "GET_SURVEY_LIST",
  GET_SURVEY_LIST_SUCCESS: "GET_SURVEY_LIST_SUCCESS",
  GET_SURVEY_LIST_FAILURE: "GET_SURVEY_LIST_FAILURE",

  TRACK_SURVEY: "TRACK_SURVEY",
  TRACK_SURVEY_SUCCESS: "TRACK_SURVEY_SUCCESS",
  TRACK_SURVEY_FAILURE: "TRACK_SURVEY_FAILURE",

  SEND_REMINDER: "SEND_REMINDER",
  SEND_REMINDER_SUCCESS: "SEND_REMINDER_SUCCESS",
  SEND_REMINDER_FAILURE: "SEND_REMINDER_FAILURE",

  SURVEY_RESPONSE: "SURVEY_RESPONSE",
  SURVEY_RESPONSE_SUCCESS: "SURVEY_RESPONSE_SUCCESS",
  SURVEY_RESPONSE_FAILURE: "SURVEY_RESPONSE_FAILURE",

  SURVEY_QUESTIONS: "SURVEY_QUESTIONS",
  SURVEY_QUESTIONS_SUCCESS: "SURVEY_QUESTIONS_SUCCESS",
  SURVEY_QUESTIONS_FAILURE: "SURVEY_QUESTIONS_FAILURE",

  GET_ADMIN_REPORT_DETAILS: "GET_ADMIN_REPORT_DETAILS",
  GET_ADMIN_REPORT_DETAILS_SUCCESS: "GET_ADMIN_REPORT_DETAILS_SUCCESS",
  GET_ADMIN_REPORT_DETAILS_FAILURE: "GET_ADMIN_REPORT_DETAILS_FAILURE",

  GENERATE_ANNOUNCEMENT: "GENERATE_ANNOUNCEMENT",
  GENERATE_ANNOUNCEMENT_SUCCESS: "GENERATE_ANNOUNCEMENT_SUCCESS",
  GENERATE_ANNOUNCEMENT_FAILURE: "GENERATE_ANNOUNCEMENT_FAILURE",

  UPDATE_ANNOUNCEMENT: "UPDATE_ANNOUNCEMENT",
  UPDATE_ANNOUNCEMENT_SUCCESS: "UPDATE_ANNOUNCEMENT_SUCCESS",
  UPDATE_ANNOUNCEMENT_FAILURE: "UPDATE_ANNOUNCEMENT_FAILURE",

  GET_ANNOUNCEMENT: "GET_ANNOUNCEMENT",
  GET_ANNOUNCEMENT_SUCCESS: "GET_ANNOUNCEMENT_SUCCESS",
  GET_ANNOUNCEMENT_FAILURE: "GET_ANNOUNCEMENT_FAILURE",

  LIST_ANNOUNCEMENT: "LIST_ANNOUNCEMENT",
  LIST_ANNOUNCEMENT_SUCCESS: "LIST_ANNOUNCEMENT_SUCCESS",
  LIST_ANNOUNCEMENT_FAILURE: "LIST_ANNOUNCEMENT_FAILURE",

  GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
  GET_NOTIFICATIONS_SUCCESS: "GET_NOTIFICATIONS_SUCCESS",
  GET_NOTIFICATIONS_FAILURE: "GET_NOTIFICATIONS_FAILURE",

  LOADING_START: "LOADING_START",
  LOADING_END: "LOADING_END",

  GENERATE_BLOCKCHAIN_CERTIFICATE: "GENERATE_BLOCKCHAIN_CERTIFICATE",
  GENERATE_BLOCKCHAIN_CERTIFICATE_SUCCESS:
    "GENERATE_BLOCKCHAIN_CERTIFICATE_SUCCESS",
  GENERATE_BLOCKCHAIN_CERTIFICATE_FAILURE:
    "GENERATE_BLOCKCHAIN_CERTIFICATE_FAILURE",

  GET_NFT_LIST: "GET_NFT_LIST",
  GET_NFT_LIST_SUCCESS: "GET_NFT_LIST_SUCCESS",
  GET_NFT_LIST_FAILURE: "GET_NFT_LIST_FAILURE",

  GET_NFT_DETAILS: "GET_NFT_DETAILS",
  GET_NFT_DETAILS_SUCCESS: "GET_NFT_DETAILS_SUCCESS",
  GET_NFT_DETAILS_FAILURE: "GET_NFT_DETAILS_FAILURE",

  VIEW_CERTIFICATE: "VIEW_CERTIFICATE",
  VIEW_CERTIFICATE_SUCCESS: "VIEW_CERTIFICATE_SUCCESS",
  VIEW_CERTIFICATE_FAILURE: "VIEW_CERTIFICATE_FAILURE",
  GET_DASHBOARD_WIDGET: "GET_DASHBOARD_WIDGET",
  GET_DASHBOARD_WIDGET_SUCCESS: "GET_DASHBOARD_WIDGET_SUCCESS",
  GET_DASHBOARD_WIDGET_FAILURE: "GET_DASHBOARD_WIDGET_FAILURE",

  GET_CARBON_EMISSIONS: "GET_CARBON_EMISSIONS",
  GET_CARBON_EMISSIONS_SUCCESS: "GET_CARBON_EMISSIONS_SUCCESS",
  GET_CARBON_EMISSIONS_FAILURE: "GET_CARBON_EMISSIONS_FAILURE",

  GET_COUNTRY_FACILITY_AGGREGATE: "GET_COUNTRY_FACILITY_AGGREGATE",
  GET_COUNTRY_FACILITY_AGGREGATE_SUCCESS:
    "GET_COUNTRY_FACILITY_AGGREGATE_SUCCESS",
  GET_COUNTRY_FACILITY_AGGREGATE_FAILURE:
    "GET_COUNTRY_FACILITY_AGGREGATE_FAILURE",

  GET_ROLE_BASED_AGGREGATE: "GET_ROLE_BASED_AGGREGATE",
  GET_ROLE_BASED_AGGREGATE_SUCCESS: "GET_ROLE_BASED_AGGREGATE_SUCCESS",
  GET_ROLE_BASED_AGGREGATE_FAILURE: "GET_ROLE_BASED_AGGREGATE_FAILURE",

  GET_SUPPLIER_QUESTIONS: "GET_SUPPLIER_QUESTIONS",
  GET_SUPPLIER_QUESTIONS_SUCCESS: "GET_SUPPLIER_QUESTIONS_SUCCESS",
  GET_SUPPLIER_QUESTIONS_FAILURE: "GET_SUPPLIER_QUESTIONS_FAILURE",

  CREATE_SUPPLIER_QUESTIONS: "CREATE_SUPPLIER_QUESTIONS",
  CREATE_SUPPLIER_QUESTIONS_SUCCESS: "CREATE_SUPPLIER_QUESTIONS_SUCCESS",
  CREATE_SUPPLIER_QUESTIONS_FAILURE: "CREATE_SUPPLIER_QUESTIONS_FAILURE",

  GET_GENERIC_QUESTIONS: "GET_GENERIC_QUESTIONS",
  GET_GENERIC_QUESTIONS_SUCCESS: "GET_GENERIC_QUESTIONS_SUCCESS",
  GET_GENERIC_QUESTIONS_FAILURE: "GET_GENERIC_QUESTIONS_FAILURE",

  SEND_SUPPLIER_QUESTIONS: "SEND_SUPPLIER_QUESTIONS",
  SEND_SUPPLIER_QUESTIONS_SUCCESS: "SEND_SUPPLIER_QUESTIONS_SUCCESS",
  SEND_SUPPLIER_QUESTIONS_FAILURE: "SEND_SUPPLIER_QUESTIONS_FAILURE",

  GET_USER_TYPE: "GET_USER_TYPE",
  GET_USER_TYPE_SUCCESS: "GET_USER_TYPE_SUCCESS",
  GET_USER_TYPE_FAILURE: "GET_USER_TYPE_FAILURE",

  WALLET_ADDRESS: "WALLET_ADDRESS",
  GET_SUPPLIER_SURVEY_QUESTIONS: "GET_SUPPLIER_SURVEY_QUESTIONS",
  GET_SUPPLIER_SURVEY_QUESTIONS_SUCCESS:
    "GET_SUPPLIER_SURVEY_QUESTIONS_SUCCESS",
  GET_SUPPLIER_SURVEY_QUESTIONS_FAILURE:
    "GET_SUPPLIER_SURVEY_QUESTIONS_FAILURE",

  SUPPLIER_SURVEY_RESPONSE: "SUPPLIER_SURVEY_RESPONSE",
  SUPPLIER_SURVEY_RESPONSE_SUCCESS: "SUPPLIER_SURVEY_RESPONSE_SUCCESS",
  SUPPLIER_SURVEY_RESPONSE_FAILURE: "SUPPLIER_SURVEY_RESPONSE_FAILURE",

  GET_USER_GRAPH_LIST: "GET_USER_GRAPH_LIST",
  GET_USER_GRAPH_LIST_SUCCESS: "GET_USER_GRAPH_LIST_SUCCESS",
  GET_USER_GRAPH_LIST_FAILURE: "GET_USER_GRAPH_LIST_FAILURE",

  SAVE_USER_GRAPH_CONFIG: "SAVE_USER_GRAPH_CONFIG",
  SAVE_USER_GRAPH_CONFIG_SUCCESS: "SAVE_USER_GRAPH_CONFIG_SUCCESS",
  SAVE_USER_GRAPH_CONFIG_FAILURE: "SAVE_USER_GRAPH_CONFIG_FAILURE",

  GET_USER_GRAPH_CONFIG: "GET_USER_GRAPH_CONFIG",
  GET_USER_GRAPH_CONFIG_SUCCESS: "GET_USER_GRAPH_CONFIG_SUCCESS",
  GET_USER_GRAPH_CONFIG_FAILURE: "GET_USER_GRAPH_CONFIG_FAILURE",

  GET_SUPPLIER_SURVEYS: "GET_SUPPLIER_SURVEYS",
  GET_SUPPLIER_SURVEYS_SUCCESS: "GET_SUPPLIER_SURVEYS_SUCCESS",
  GET_SUPPLIER_SURVEYS_FAILURE: "GET_SUPPLIER_SURVEYS_FAILURE",

  GET_SUPPLIER_RESPONDENT_LIST: "GET_SUPPLIER_RESPONDENT_LIST",
  GET_SUPPLIER_RESPONDENT_LIST_SUCCESS: "GET_SUPPLIER_RESPONDENT_LIST_SUCCESS",
  GET_SUPPLIER_RESPONDENT_LIST_FAILURE: "GET_SUPPLIER_RESPONDENT_LIST_FAILURE",

  SEND_RESPONDENT_REMINDER: "SEND_RESPONDENT_REMINDER",
  SEND_RESPONDENT_REMINDER_SUCCESS: "SEND_RESPONDENT_REMINDER_SUCCESS",
  SEND_RESPONDENT_REMINDER_FAILURE: "SEND_RESPONDENT_REMINDER_FAILURE",

  RESET_SEND_RESPONDENT_REMINDER_STATUS:
    "RESET_SEND_RESPONDENT_REMINDER_STATUS",

  GET_SUPPLIER_SURVEY_TRACK_QUESTIONS: "GET_SUPPLIER_SURVEY_TRACK_QUESTIONS",
  GET_SUPPLIER_SURVEY_TRACK_QUESTIONS_SUCCESS:
    "GET_SUPPLIER_SURVEY_TRACK_QUESTIONS_SUCCESS",
  GET_SUPPLIER_SURVEY_TRACK_QUESTIONS_FAILURE:
    "GET_SUPPLIER_SURVEY_TRACK_QUESTIONS_FAILURE",

  GET_SUPPLIER_SURVEY_TRACK_ANSWERS: "GET_SUPPLIER_SURVEY_TRACK_ANSWERS",
  GET_SUPPLIER_SURVEY_TRACK_ANSWERS_SUCCESS:
    "GET_SUPPLIER_SURVEY_TRACK_ANSWERS_SUCCESS",
  GET_SUPPLIER_SURVEY_TRACK_ANSWERS_FAILURE:
    "GET_SUPPLIER_SURVEY_TRACK_ANSWERS_FAILURE",

  RESET_DASHBOARD_CONFIG_STATUS: "RESET_DASHBOARD_CONFIG_STATUS",
  GET_STATES_PUBLIC: "GET_STATES_PUBLIC",

  GET_OFFSET_IOT_DATA_DETAILS: "GET_OFFSET_IOT_DATA_DETAILS",
  GET_OFFSET_IOT_DATA_DETAILS_SUCCESS: "GET_OFFSET_IOT_DATA_DETAILS_SUCCESS",
  GET_OFFSET_IOT_DATA_DETAILS_FAILURE: "GET_OFFSET_IOT_DATA_DETAILS_FAILURE",

  GET_OVERALL_APPROVAL_STATUS: "GET_OVERALL_APPROVAL_STATUS",
  GET_OVERALL_APPROVAL_STATUS_SUCCESS: "GET_OVERALL_APPROVAL_STATUS_SUCCESS",
  GET_OVERALL_APPROVAL_STATUS_FAILURE: "GET_OVERALL_APPROVAL_STATUS_FAILURE",

  GET_OVERALL_REQUEST_APPROVAL_STATUS: "GET_OVERALL_REQUEST_APPROVAL_STATUS",
  GET_OVERALL_REQUEST_APPROVAL_STATUS_SUCCESS:
    "GET_OVERALL_REQUEST_APPROVAL_STATUS_SUCCESS",
  GET_OVERALL_REQUEST_APPROVAL_STATUS_FAILURE:
    "GET_OVERALL_REQUEST_APPROVAL_STATUS_FAILURE",

  REVIEW_OFFSET_DATA: "REVIEW_OFFSET_DATA",
  REVIEW_OFFSET_DATA_SUCCESS: "REVIEW_OFFSET_DATA_SUCCESS",
  REVIEW_OFFSET_DATA_FAILURE: "REVIEW_OFFSET_DATA_FAILURE",

  APPROVE_OFFSET_DATA: "APPROVE_OFFSET_DATA",
  APPROVE_OFFSET_DATA_SUCCESS: "APPROVE_OFFSET_DATA_SUCCESS",
  APPROVE_OFFSET_DATA_FAILURE: "APPROVE_OFFSET_DATA_FAILURE",

  SUBMIT_OFFSET_DATA: "SUBMIT_OFFSET_DATA",
  SUBMIT_OFFSET_DATA_SUCCESS: "SUBMIT_OFFSET_DATA_SUCCESS",
  SUBMIT_OFFSET_DATA_FAILURE: "SUBMIT_OFFSET_DATA_FAILURE",

  GET_OVERVIEW_APPROVAL_STATUS: "GET_OVERVIEW_APPROVAL_STATUS",
  GET_OVERVIEW_APPROVAL_STATUS_SUCCESS: "GET_OVERVIEW_APPROVAL_STATUS_SUCCESS",
  GET_OVERVIEW_APPROVAL_STATUS_FAILURE: "GET_OVERVIEW_APPROVAL_STATUS_FAILURE",

  GET_OFFSET_APPROVAL_STATUS: "GET_OFFSET_APPROVAL_STATUS",
  GET_OFFSET_APPROVAL_STATUS_SUCCESS: "GET_OFFSET_APPROVAL_STATUS_SUCCESS",
  GET_OFFSET_APPROVAL_STATUS_FAILURE: "GET_OFFSET_APPROVAL_STATUS_FAILURE",

  GET_SUPPLIER_SURVEY_RESULTS: "GET_SUPPLIER_SURVEY_RESULTS",
  GET_SUPPLIER_SURVEY_RESULTS_SUCCESS: "GET_SUPPLIER_SURVEY_RESULTS_SUCCESS",
  GET_SUPPLIER_SURVEY_RESULTS_FAILURE: "GET_SUPPLIER_SURVEY_RESULTS_FAILURE",

  GET_AUDITOR_CARBON_OFFSET_LIST: "GET_AUDITOR_CARBON_OFFSET_LIST",
  GET_AUDITOR_CARBON_OFFSET_LIST_SUCCESS:
    "GET_AUDITOR_CARBON_OFFSET_LIST_SUCCESS",
  GET_AUDITOR_CARBON_OFFSET_LIST_FAILURE:
    "GET_AUDITOR_CARBON_OFFSET_LIST_FAILURE",

  GET_AUDITOR_CARBON_OFFSET_IOT_DATA: "GET_AUDITOR_CARBON_OFFSET_IOT_DATA",
  GET_AUDITOR_CARBON_OFFSET_IOT_DATA_SUCCESS:
    "GET_AUDITOR_CARBON_OFFSET_IOT_DATA_SUCCESS",
  GET_AUDITOR_CARBON_OFFSET_IOT_DATA_FAILURE:
    "GET_AUDITOR_CARBON_OFFSET_IOT_DATA_FAILURE",

  GET_AUDITOR_CARBON_OFFSET_DETAILS: "GET_AUDITOR_CARBON_OFFSET_DETAILS",
  GET_AUDITOR_CARBON_OFFSET_DETAILS_SUCCESS:
    "GET_AUDITOR_CARBON_OFFSET_DETAILS_SUCCESS",
  GET_AUDITOR_CARBON_OFFSET_DETAILS_FAILURE:
    "GET_AUDITOR_CARBON_OFFSET_DETAILS_FAILURE",

  VALIDATE_AUDITOR_CARBON_OFFSET_DATA: "VALIDATE_AUDITOR_CARBON_OFFSET_DATA",
  VALIDATE_AUDITOR_CARBON_OFFSET_DATA_SUCCESS:
    "VALIDATE_AUDITOR_CARBON_OFFSET_DATA_SUCCESS",
  VALIDATE_AUDITOR_CARBON_OFFSET_DATA_FAILURE:
    "VALIDATE_AUDITOR_CARBON_OFFSET_DATA_FAILURE",

  REVIEW_AUDITOR_CARBON_OFFSET_DATA: "REVIEW_AUDITOR_CARBON_OFFSET_DATA",
  REVIEW_AUDITOR_CARBON_OFFSET_DATA_SUCCESS:
    "REVIEW_AUDITOR_CARBON_OFFSET_DATA_SUCCESS",
  REVIEW_AUDITOR_CARBON_OFFSET_DATA_FAILURE:
    "REVIEW_AUDITOR_CARBON_OFFSET_DATA_FAILURE",

  RESET_REVIEW_OFFSET_STATUS: "RESET_REVIEW_OFFSET_STATUS",
  RESET_APPROVE_OFFSET_STATUS: "RESET_APPROVE_OFFSET_STATUS",
  RESET_SUBMIT_OFFSET_STATUS: "RESET_SUBMIT_OFFSET_STATUS",
  RESET_EDIT_OFFSET_STATUS: "RESET_EDIT_OFFSET_STATUS",

  RESET_AUDITOR_REVIEW_OFFSET_STATUS: "RESET_AUDITOR_REVIEW_OFFSET_STATUS",
  RESET_AUDITOR_VALIDATE_OFFSET_STATUS: "RESET_AUDITOR_VALIDATE_OFFSET_STATUS",

  GET_CARBON_OFFSET_FORM_DETAILS: "GET_CARBON_OFFSET_FORM_DETAILS",
  GET_CARBON_OFFSET_FORM_DETAILS_SUCCESS:
    "GET_CARBON_OFFSET_FORM_DETAILS_SUCCESS",
  GET_CARBON_OFFSET_FORM_DETAILS_FAILURE:
    "GET_CARBON_OFFSET_FORM_DETAILS_FAILURE",

  UPDATE_CARBON_OFFSET_FORM_DETAILS: "UPDATE_CARBON_OFFSET_FORM_DETAILS",
  UPDATE_CARBON_OFFSET_FORM_DETAILS_SUCCESS:
    "UPDATE_CARBON_OFFSET_FORM_DETAILS_SUCCESS",
  UPDATE_CARBON_OFFSET_FORM_DETAILS_FAILURE:
    "UPDATE_CARBON_OFFSET_FORM_DETAILS_FAILURE",

  COMPANY_FUNCTIONALITY_SETUP: "COMPANY_FUNCTIONALITY_SETUP",
  COMPANY_FUNCTIONALITY_SETUP_SUCCESS: "COMPANY_FUNCTIONALITY_SETUP_SUCCESS",
  COMPANY_FUNCTIONALITY_SETUP_FAILURE: "COMPANY_FUNCTIONALITY_SETUP_FAILURE",

  FUNCTIONALITY_VIEW: "FUNCTIONALITY_VIEW",
  FUNCTIONALITY_VIEW_SUCCESS: "FUNCTIONALITY_VIEW_SUCCESS",
  FUNCTIONALITY_VIEW_FAILURE: "FUNCTIONALITY_VIEW_FAILURE",

  GET_PRODUCTION_DATA_LIST: "GET_PRODUCTION_DATA_LIST",
  GET_PRODUCTION_DATA_LIST_SUCCESS: "GET_PRODUCTION_DATA_LIST_SUCCESS",
  GET_PRODUCTION_DATA_LIST_FAILURE: "GET_PRODUCTION_DATA_LIST_FAILURE",

  ADD_PRODUCTION_DATA: "ADD_PRODUCTION_DATA",
  ADD_PRODUCTION_DATA_SUCCESS: "ADD_PRODUCTION_DATA_SUCCESS",
  ADD_PRODUCTION_DATA_FAILURE: "ADD_PRODUCTION_DATA_FAILURE",

  GET_FUNCTIONALITY_DATA: "GET_FUNCTIONALITY_DATA",
  GET_FUNCTIONALITY_DATA_SUCCESS: "GET_FUNCTIONALITY_DATA_SUCCESS",
  GET_FUNCTIONALITY_DATA_FAILURE: "GET_FUNCTIONALITY_DATA_FAILURE",

  RESET_ADD_PRODUCTION_DATA_STATUS: "RESET_ADD_PRODUCTION_DATA_STATUS",

  GET_PRODUCTION_EMISSION_GRAPH_DATA: "GET_PRODUCTION_EMISSION_GRAPH_DATA",
  GET_PRODUCTION_EMISSION_GRAPH_DATA_SUCCESS:
    "GET_PRODUCTION_EMISSION_GRAPH_DATA_SUCCESS",
  GET_PRODUCTION_EMISSION_GRAPH_DATA_FAILURE:
    "GET_PRODUCTION_EMISSION_GRAPH_DATA_FAILURE",

  GET_PRODUCTION_SCOPE_GRAPH_DATA: "GET_PRODUCTION_SCOPE_GRAPH_DATA",

  GET_PRODUCTION_SCOPE1_GRAPH_DATA: "GET_PRODUCTION_SCOPE1_GRAPH_DATA",
  GET_PRODUCTION_SCOPE1_GRAPH_DATA_SUCCESS:
    "GET_PRODUCTION_SCOPE1_GRAPH_DATA_SUCCESS",
  GET_PRODUCTION_SCOPE1_GRAPH_DATA_FAILURE:
    "GET_PRODUCTION_SCOPE1_GRAPH_DATA_FAILURE",

  GET_PRODUCTION_SCOPE2_GRAPH_DATA: "GET_PRODUCTION_SCOPE2_GRAPH_DATA",
  GET_PRODUCTION_SCOPE2_GRAPH_DATA_SUCCESS:
    "GET_PRODUCTION_SCOPE2_GRAPH_DATA_SUCCESS",
  GET_PRODUCTION_SCOPE2_GRAPH_DATA_FAILURE:
    "GET_PRODUCTION_SCOPE2_GRAPH_DATA_FAILURE",

  GET_PRODUCTION_SCOPE3_GRAPH_DATA: "GET_PRODUCTION_SCOPE3_GRAPH_DATA",
  GET_PRODUCTION_SCOPE3_GRAPH_DATA_SUCCESS:
    "GET_PRODUCTION_SCOPE3_GRAPH_DATA_SUCCESS",
  GET_PRODUCTION_SCOPE3_GRAPH_DATA_FAILURE:
    "GET_PRODUCTION_SCOPE3_GRAPH_DATA_FAILURE",
  RESET_COMPANY_FUNCTIONALITY_SETUP: "RESET_COMPANY_FUNCTIONALITY_SETUP",

  GET_SUSTAINABILITY_RISK_QUESTIONS: "GET_SUSTAINABILITY_RISK_QUESTIONS",
  GET_SUSTAINABILITY_RISK_QUESTIONS_SUCCESS:
    "GET_SUSTAINABILITY_RISK_QUESTIONS_SUCCESS",
  GET_SUSTAINABILITY_RISK_QUESTIONS_FAILURE:
    "GET_SUSTAINABILITY_RISK_QUESTIONS_FAILURE",

  CREATE_SUSTAINABILITY_RISK_QUESTIONS: "CREATE_SUSTAINABILITY_RISK_QUESTIONS",
  CREATE_SUSTAINABILITY_RISK_QUESTIONS_SUCCESS:
    "CREATE_SUSTAINABILITY_RISK_QUESTIONS_SUCCESS",
  CREATE_SUSTAINABILITY_RISK_QUESTIONS_FAILURE:
    "CREATE_SUSTAINABILITY_RISK_QUESTIONS_FAILURE",

  GET_SUSTAINABILITY_RISK_GENERIC_QUESTIONS:
    "GET_SUSTAINABILITY_RISK_GENERIC_QUESTIONS",
  GET_SUSTAINABILITY_RISK_GENERIC_QUESTIONS_SUCCESS:
    "GET_SUSTAINABILITY_RISK_GENERIC_QUESTIONS_SUCCESS",
  GET_SUSTAINABILITY_RISK_GENERIC_QUESTIONS_FAILURE:
    "GET_SUSTAINABILITY_RISK_GENERIC_QUESTIONS_FAILURE",

  SEND_SUSTAINABILITY_RISK_QUESTIONS: "SEND_SUSTAINABILITY_RISK_QUESTIONS",
  SEND_SUSTAINABILITY_RISK_QUESTIONS_SUCCESS:
    "SEND_SUSTAINABILITY_RISK_QUESTIONS_SUCCESS",
  SEND_SUSTAINABILITY_RISK_QUESTIONS_FAILURE:
    "SEND_SUSTAINABILITY_RISK_QUESTIONS_FAILURE",

  GET_SUSTAINABILITY_RISK_SURVEY_QUESTIONS:
    "GET_SUSTAINABILITY_RISK_SURVEY_QUESTIONS",
  GET_SUSTAINABILITY_RISK_SURVEY_QUESTIONS_SUCCESS:
    "GET_SUSTAINABILITY_RISK_SURVEY_QUESTIONS_SUCCESS",
  GET_SUSTAINABILITY_RISK_SURVEY_QUESTIONS_FAILURE:
    "GET_SUSTAINABILITY_RISK_SURVEY_QUESTIONS_FAILURE",

  SUSTAINABILITY_RISK_SURVEY_RESPONSE: "SUSTAINABILITY_RISK_SURVEY_RESPONSE",
  SUSTAINABILITY_RISK_SURVEY_RESPONSE_SUCCESS:
    "SUSTAINABILITY_RISK_SURVEY_RESPONSE_SUCCESS",
  SUSTAINABILITY_RISK_SURVEY_RESPONSE_FAILURE:
    "SUSTAINABILITY_RISK_SURVEY_RESPONSE_FAILURE",

  GET_SUSTAINABILITY_RISK_SURVEYS: "GET_SUSTAINABILITY_RISK_SURVEYS",
  GET_SUSTAINABILITY_RISK_SURVEYS_SUCCESS:
    "GET_SUSTAINABILITY_RISK_SURVEYS_SUCCESS",
  GET_SUSTAINABILITY_RISK_SURVEYS_FAILURE:
    "GET_SUSTAINABILITY_RISK_SURVEYS_FAILURE",

  GET_SUSTAINABILITY_RISK_RESPONDENT_LIST:
    "GET_SUSTAINABILITY_RISK_RESPONDENT_LIST",
  GET_SUSTAINABILITY_RISK_RESPONDENT_LIST_SUCCESS:
    "GET_SUSTAINABILITY_RISK_RESPONDENT_LIST_SUCCESS",
  GET_SUSTAINABILITY_RISK_RESPONDENT_LIST_FAILURE:
    "GET_SUSTAINABILITY_RISK_RESPONDENT_LIST_FAILURE",

  GET_SUSTAINABILITY_RISK_SURVEY_TRACK_QUESTIONS:
    "GET_SUSTAINABILITY_RISK_SURVEY_TRACK_QUESTIONS",
  GET_SUSTAINABILITY_RISK_SURVEY_TRACK_QUESTIONS_SUCCESS:
    "GET_SUSTAINABILITY_RISK_SURVEY_TRACK_QUESTIONS_SUCCESS",
  GET_SUSTAINABILITY_RISK_SURVEY_TRACK_QUESTIONS_FAILURE:
    "GET_SUSTAINABILITY_RISK_SURVEY_TRACK_QUESTIONS_FAILURE",

  GET_SUSTAINABILITY_RISK_SURVEY_TRACK_ANSWERS:
    "GET_SUSTAINABILITY_RISK_SURVEY_TRACK_ANSWERS",
  GET_SUSTAINABILITY_RISK_SURVEY_TRACK_ANSWERS_SUCCESS:
    "GET_SUSTAINABILITY_RISK_SURVEY_TRACK_ANSWERS_SUCCESS",
  GET_SUSTAINABILITY_RISK_SURVEY_TRACK_ANSWERS_FAILURE:
    "GET_SUSTAINABILITY_RISK_SURVEY_TRACK_ANSWERS_FAILURE",

  GET_SUSTAINABILITY_RISK_SURVEY_RESULTS:
    "GET_SUSTAINABILITY_RISK_SURVEY_RESULTS",
  GET_SUSTAINABILITY_RISK_SURVEY_RESULTS_SUCCESS:
    "GET_SUSTAINABILITY_RISK_SURVEY_RESULTS_SUCCESS",
  GET_SUSTAINABILITY_RISK_SURVEY_RESULTS_FAILURE:
    "GET_SUSTAINABILITY_RISK_SURVEY_RESULTS_FAILURE",

  SEND_SUSTAINABILITY_RISK_RESPONDENT_REMINDER:
    "SEND_SUSTAINABILITY_RISK_RESPONDENT_REMINDER",
  SEND_SUSTAINABILITY_RISK_RESPONDENT_REMINDER_SUCCESS:
    "SEND_SUSTAINABILITY_RISK_RESPONDENT_REMINDER_SUCCESS",
  SEND_SUSTAINABILITY_RISK_RESPONDENT_REMINDER_FAILURE:
    "SEND_SUSTAINABILITY_RISK_RESPONDENT_REMINDER_FAILURE",

  RESET_SEND_SUSTAINABILITY_RISK_RESPONDENT_REMINDER_STATUS:
    "RESET_SEND_SUSTAINABILITY_RISK_RESPONDENT_REMINDER_STATUS",
  RESET_SUSTAINABILITY_RISK_SCREENING_STATUS:
    "RESET_SUSTAINABILITY_RISK_SCREENING_STATUS",

  GET_SURVEY_INSIGHTS_LIST: "GET_SURVEY_INSIGHTS_LIST",
  GET_SURVEY_INSIGHTS_LIST_SUCCESS: "GET_SURVEY_INSIGHTS_LIST_SUCCESS",
  GET_SURVEY_INSIGHTS_LIST_FAILURE: "GET_SURVEY_INSIGHTS_LIST_FAILURE",

  GET_SURVEY_INSIGHTS_DETAILS: "GET_SURVEY_INSIGHTS_DETAILS",
  GET_SURVEY_INSIGHTS_DETAILS_SUCCESS: "GET_SURVEY_INSIGHTS_DETAILS_SUCCESS",
  GET_SURVEY_INSIGHTS_DETAILS_FAILURE: "GET_SURVEY_INSIGHTS_DETAILS_FAILURE",

  GET_ADMIN_ANNOUNCEMENT_LIST: "GET_ADMIN_ANNOUNCEMENT_LIST",
  GET_ADMIN_ANNOUNCEMENT_LIST_SUCCESS: "GET_ADMIN_ANNOUNCEMENT_LIST_SUCCESS",
  GET_ADMIN_ANNOUNCEMENT_LIST_FAILURE: "GET_ADMIN_ANNOUNCEMENT_LIST_FAILURE",

  ADD_ADMIN_ANNOUNCEMENT: "ADD_ADMIN_ANNOUNCEMENT",
  ADD_ADMIN_ANNOUNCEMENT_SUCCESS: "ADD_ADMIN_ANNOUNCEMENT_SUCCESS",
  ADD_ADMIN_ANNOUNCEMENT_FAILURE: "ADD_ADMIN_ANNOUNCEMENT_FAILURE",

  EDIT_ADMIN_ANNOUNCEMENT: "EDIT_ADMIN_ANNOUNCEMENT",
  EDIT_ADMIN_ANNOUNCEMENT_SUCCESS: "EDIT_ADMIN_ANNOUNCEMENT_SUCCESS",
  EDIT_ADMIN_ANNOUNCEMENT_FAILURE: "EDIT_ADMIN_ANNOUNCEMENT_FAILURE",

  GET_ADMIN_ANNOUNCEMENT: "GET_ADMIN_ANNOUNCEMENT",
  GET_ADMIN_ANNOUNCEMENT_SUCCESS: "GET_ADMIN_ANNOUNCEMENT_SUCCESS",
  GET_ADMIN_ANNOUNCEMENT_FAILURE: "GET_ADMIN_ANNOUNCEMENT_FAILURE",

  GET_ADMIN_ANNOUNCEMENT_CHANGELOG: "GET_ADMIN_ANNOUNCEMENT_CHANGELOG",
  GET_ADMIN_ANNOUNCEMENT_CHANGELOG_SUCCESS:
    "GET_ADMIN_ANNOUNCEMENT_CHANGELOG_SUCCESS",
  GET_ADMIN_ANNOUNCEMENT_CHANGELOG_FAILURE:
    "GET_ADMIN_ANNOUNCEMENT_CHANGELOG_FAILURE",

  RESET_ADD_ADMIN_ANNOUNCEMENT: "RESET_ADD_ADMIN_ANNOUNCEMENT",

  RESET_EDIT_ADMIN_ANNOUNCEMENT: "RESET_EDIT_ADMIN_ANNOUNCEMENT",

  GET_STANDARDS_QUESTIONNAIRE: "GET_STANDARDS_QUESTIONNAIRE",
  GET_STANDARDS_QUESTIONNAIRE_SUCCESS: "GET_STANDARDS_QUESTIONNAIRE_SUCCESS",
  GET_STANDARDS_QUESTIONNAIRE_FAILURE: "GET_STANDARDS_QUESTIONNAIRE_FAILURE",

  SAVE_STANDARDS_QUESTIONNAIRE: "SAVE_STANDARDS_QUESTIONNAIRE",
  SAVE_STANDARDS_QUESTIONNAIRE_SUCCESS: "SAVE_STANDARDS_QUESTIONNAIRE_SUCCESS",
  SAVE_STANDARDS_QUESTIONNAIRE_FAILURE: "SAVE_STANDARDS_QUESTIONNAIRE_FAILURE",

  RESET_SAVE_STANDARDS_QUESTIONNAIRE: "RESET_SAVE_STANDARDS_QUESTIONNAIRE",

  GET_STANDARDS_QUESTIONNAIRE_HISTORY: "GET_STANDARDS_QUESTIONNAIRE_HISTORY",
  GET_STANDARDS_QUESTIONNAIRE_HISTORY_SUCCESS:
    "GET_STANDARDS_QUESTIONNAIRE_HISTORY_SUCCESS",
  GET_STANDARDS_QUESTIONNAIRE_HISTORY_FAILURE:
    "GET_STANDARDS_QUESTIONNAIRE_HISTORY_FAILURE",

  VIEW_STANDARDS_QUESTIONNAIRE_ANSWER: "VIEW_STANDARDS_QUESTIONNAIRE_ANSWER",
  VIEW_STANDARDS_QUESTIONNAIRE_ANSWER_SUCCESS:
    "VIEW_STANDARDS_QUESTIONNAIRE_ANSWER_SUCCESS",
  VIEW_STANDARDS_QUESTIONNAIRE_ANSWER_FAILURE:
    "VIEW_STANDARDS_QUESTIONNAIRE_ANSWER_FAILURE",

  SUBMIT_STANDARDS_QUESTIONNAIRE: "SUBMIT_STANDARDS_QUESTIONNAIRE",
  SUBMIT_STANDARDS_QUESTIONNAIRE_SUCCESS:
    "SUBMIT_STANDARDS_QUESTIONNAIRE_SUCCESS",
  SUBMIT_STANDARDS_QUESTIONNAIRE_FAILURE:
    "SUBMIT_STANDARDS_QUESTIONNAIRE_FAILURE",

  RESET_SUBMIT_STANDARDS_QUESTIONNAIRE: "RESET_SUBMIT_STANDARDS_QUESTIONNAIRE",

  GET_STANDARDS_QUESTIONNAIRE_ANSWERS: "GET_STANDARDS_QUESTIONNAIRE_ANSWERS",
  GET_STANDARDS_QUESTIONNAIRE_ANSWERS_SUCCESS:
    "GET_STANDARDS_QUESTIONNAIRE_ANSWERS_SUCCESS",
  GET_STANDARDS_QUESTIONNAIRE_ANSWERS_FAILURE:
    "GET_STANDARDS_QUESTIONNAIRE_ANSWERS_FAILURE",

  APPROVE_STANDARDS_QUESTIONNAIRE: "APPROVE_STANDARDS_QUESTIONNAIRE",
  APPROVE_STANDARDS_QUESTIONNAIRE_SUCCESS:
    "APPROVE_STANDARDS_QUESTIONNAIRE_SUCCESS",
  APPROVE_STANDARDS_QUESTIONNAIRE_FAILURE:
    "APPROVE_STANDARDS_QUESTIONNAIRE_FAILURE",

  RESET_APPROVE_STANDARDS_QUESTIONNAIRE:
    "RESET_APPROVE_STANDARDS_QUESTIONNAIRE",

  RESET_STANDARDS_QUESTIONNAIRE_ANSWERS:
    "RESET_STANDARDS_QUESTIONNAIRE_ANSWERS",

  LIST_ASSESSMENT_CYCLE_COUNTRY: "LIST_ASSESSMENT_CYCLE_COUNTRY",
  LIST_ASSESSMENT_CYCLE_COUNTRY_SUCCESS:
    "LIST_ASSESSMENT_CYCLE_COUNTRY_SUCCESS",
  LIST_ASSESSMENT_CYCLE_COUNTRY_FAILURE:
    "LIST_ASSESSMENT_CYCLE_COUNTRY_FAILURE",

  RESET_TRADE_LOGIN_STATUS: "RESET_TRADE_LOGIN_STATUS",

  GET_DASHBOARD_OFFSET_STATISTICS: "GET_DASHBOARD_OFFSET_STATISTICS",
  GET_DASHBOARD_OFFSET_STATISTICS_SUCCESS:
    "GET_DASHBOARD_OFFSET_STATISTICS_SUCCESS",
  GET_DASHBOARD_OFFSET_STATISTICS_FAILURE:
    "GET_DASHBOARD_OFFSET_STATISTICS_FAILURE",

  GET_OFFSET_OVER_YEARS: "GET_OFFSET_OVER_YEARS",
  GET_OFFSET_OVER_YEARS_SUCCESS: "GET_OFFSET_OVER_YEARS_SUCCESS",
  GET_OFFSET_OVER_YEARS_FAILURE: "GET_OFFSET_OVER_YEARS_FAILURE",

  GET_EMISSION_VS_OFFSET: "GET_EMISSION_VS_OFFSET",
  GET_EMISSION_VS_OFFSET_SUCCESS: "GET_EMISSION_VS_OFFSET_SUCCESS",
  GET_EMISSION_VS_OFFSET_FAILURE: "GET_EMISSION_VS_OFFSET_FAILURE",

  AVERAGE_SCORE_SUPPLIER_INDUSTRY: "AVERAGE_SCORE_SUPPLIER_INDUSTRY",
  AVERAGE_SCORE_SUPPLIER_INDUSTRY_SUCCESS:
    "AVERAGE_SCORE_SUPPLIER_INDUSTRY_SUCCESS",
  AVERAGE_SCORE_SUPPLIER_INDUSTRY_FAILURE:
    "AVERAGE_SCORE_SUPPLIER_INDUSTRY_FAILURE",

  GET_SUSTAINABILITY_RISK_RATING: "GET_SUSTAINABILITY_RISK_RATING",
  GET_SUSTAINABILITY_RISK_RATING_SUCCESS:
    "GET_SUSTAINABILITY_RISK_RATING_SUCCESS",
  GET_SUSTAINABILITY_RISK_RATING_FAILURE:
    "GET_SUSTAINABILITY_RISK_RATING_FAILURE",

  DASHBOARD_MATERIALITY_DATA: "DASHBOARD_MATERIALITY_DATA",
  DASHBOARD_MATERIALITY_DATA_SUCCESS: "DASHBOARD_MATERIALITY_DATA_SUCCESS",
  DASHBOARD_MATERIALITY_DATA_FAILURE: "DASHBOARD_MATERIALITY_DATA_FAILURE",

  DASHBOARD_SUSTAINABILITY_DATA_DETAILS:
    "DASHBOARD_SUSTAINABILITY_DATA_DETAILS",
  DASHBOARD_SUSTAINABILITY_DATA_DETAILS_SUCCESS:
    "DASHBOARD_SUSTAINABILITY_DATA_DETAILS_SUCCESS",
  DASHBOARD_SUSTAINABILITY_DATA_DETAILS_FAILURE:
    "DASHBOARD_SUSTAINABILITY_DATA_DETAILS_FAILURE",

  DASHBOARD_SUPPLIER_DATA_DETAILS: "DASHBOARD_SUPPLIER_DATA_DETAILS",
  DASHBOARD_SUPPLIER_DATA_DETAILS_SUCCESS:
    "DASHBOARD_SUPPLIER_DATA_DETAILS_SUCCESS",
  DASHBOARD_SUPPLIER_DATA_DETAILS_FAILURE:
    "DASHBOARD_SUPPLIER_DATA_DETAILS_FAILURE",

  INITIATIVE_ATTACHMENT: "INITIATIVE_ATTACHMENT",
  INITIATIVE_ATTACHMENT_SUCCESS: "INITIATIVE_ATTACHMENT_SUCCESS",
  INITIATIVE_ATTACHMENT_FAILURE: "INITIATIVE_ATTACHMENT_FAILURE",

  GET_COMPANY_NAME_DETAILS: "GET_COMPANY_NAME_DETAILS",
  GET_COMPANY_NAME_DETAILS_SUCCESS: "GET_COMPANY_NAME_DETAILS_SUCCESS",
  GET_COMPANY_NAME_DETAILS_FAILURE: "GET_COMPANY_NAME_DETAILS_FAILURE",

  RESET_INITIATIVE_ATTACHMENT: "RESET_INITIATIVE_ATTACHMENT",
  REQUEST_REPORT_NFT: "REQUEST_REPORT_NFT",
  REQUEST_REPORT_NFT_SUCCESS: "REQUEST_REPORT_NFT_SUCCESS",
  REQUEST_REPORT_NFT_FAILURE: "REQUEST_REPORT_NFT_FAILURE",

  ADD_UPSTREAM_T_AND_D: "ADD_UPSTREAM_T_AND_D",
  ADD_UPSTREAM_T_AND_D_SUCCESS: "ADD_UPSTREAM_T_AND_D_SUCCESS",
  ADD_UPSTREAM_T_AND_D_FAILURE: "ADD_UPSTREAM_T_AND_D_FAILURE",

  ADD_DOWNSTREAM_T_AND_D: "ADD_DOWNSTREAM_T_AND_D",
  ADD_DOWNSTREAM_T_AND_D_SUCCESS: "ADD_DOWNSTREAM_T_AND_D_SUCCESS",
  ADD_DOWNSTREAM_T_AND_D_FAILURE: "ADD_DOWNSTREAM_T_AND_D_FAILURE",

  ADD_BUSINESS_TRAVEL: "ADD_BUSINESS_TRAVEL",
  ADD_BUSINESS_TRAVEL_SUCCESS: "ADD_BUSINESS_TRAVEL_SUCCESS",
  ADD_BUSINESS_TRAVEL_FAILURE: "ADD_BUSINESS_TRAVEL_FAILURE",

  ADD_PURCHASED_GOODS_AND_SERVICES: "ADD_PURCHASED_GOODS_AND_SERVICES",
  ADD_PURCHASED_GOODS_AND_SERVICES_SUCCESS:
    "ADD_PURCHASED_GOODS_AND_SERVICES_SUCCESS",
  ADD_PURCHASED_GOODS_AND_SERVICES_FAILURE:
    "ADD_PURCHASED_GOODS_AND_SERVICES_FAILURE",

  ADD_EMPLOYEE_COMMUTE: "ADD_EMPLOYEE_COMMUTE",
  ADD_EMPLOYEE_COMMUTE_SUCCESS: "ADD_EMPLOYEE_COMMUTE_SUCCESS",
  ADD_EMPLOYEE_COMMUTE_FAILURE: "ADD_EMPLOYEE_COMMUTE_FAILURE",

  UPDATE_UPSTREAM_T_AND_D: "UPDATE_UPSTREAM_T_AND_D",
  UPDATE_UPSTREAM_T_AND_D_SUCCESS: "UPDATE_UPSTREAM_T_AND_D_SUCCESS",
  UPDATE_UPSTREAM_T_AND_D_FAILURE: "UPDATE_UPSTREAM_T_AND_D_FAILURE",



  UPDATE_USE_OF_SOLD_PRODUCTS: "UPDATE_USE_OF_SOLD_PRODUCTS",
  UPDATE_USE_OF_SOLD_PRODUCTS_SUCCESS: "UPDATE_USE_OF_SOLD_PRODUCTS_SUCCESS",
  UPDATE_USE_OF_SOLD_PRODUCTS_FAILURE: "UPDATE_USE_OF_SOLD_PRODUCTS_FAILURE",


  
  UPDATE_INVESTMENTS: "UPDATE_INVESTMENTS",
  UPDATE_INVESTMENTS_SUCCESS: "UPDATE_INVESTMENTS_SUCCESS",
  UPDATE_INVESTMENTS_FAILURE: "UPDATE_INVESTMENTS_FAILURE",

  UPDATE_BUSINESS_TRAVEL: "UPDATE_BUSINESS_TRAVEL",
  UPDATE_BUSINESS_TRAVEL_SUCCESS: "UPDATE_BUSINESS_TRAVEL_SUCCESS",
  UPDATE_BUSINESS_TRAVEL_FAILURE: "UPDATE_BUSINESS_TRAVEL_FAILURE",

  UPDATE_EMPLOYEE_COMMUTE: "UPDATE_EMPLOYEE_COMMUTE",
  UPDATE_EMPLOYEE_COMMUTE_SUCCESS: "UPDATE_EMPLOYEE_COMMUTE_SUCCESS",
  UPDATE_EMPLOYEE_COMMUTE_FAILURE: "UPDATE_EMPLOYEE_COMMUTE_FAILURE",

  UPDATE_PURCHASED_GOODS_AND_SERVICES: "UPDATE_PURCHASED_GOODS_AND_SERVICES",
  UPDATE_PURCHASED_GOODS_AND_SERVICES_SUCCESS:
    "UPDATE_PURCHASED_GOODS_AND_SERVICES_SUCCESS",
  UPDATE_PURCHASED_GOODS_AND_SERVICES_FAILURE:
    "UPDATE_PURCHASED_GOODS_AND_SERVICES_FAILURE",

  UPDATE_DOWNSTREAM_T_AND_D: "UPDATE_DOWNSTREAM_T_AND_D",
  UPDATE_DOWNSTREAM_T_AND_D_SUCCESS: "UPDATE_DOWNSTREAM_T_AND_D_SUCCESS",
  UPDATE_DOWNSTREAM_T_AND_D_FAILURE: "UPDATE_DOWNSTREAM_T_AND_D_FAILURE",

  ADD_FUEL_AND_ENERGY_RELATED_ACTIVITIES:
    "ADD_FUEL_AND_ENERGY_RELATED_ACTIVITIES",
  ADD_FUEL_AND_ENERGY_RELATED_ACTIVITIES_SUCCESS:
    "ADD_FUEL_AND_ENERGY_RELATED_ACTIVITIES_SUCCESS",
  ADD_FUEL_AND_ENERGY_RELATED_ACTIVITIES_FAILURE:
    "ADD_FUEL_AND_ENERGY_RELATED_ACTIVITIES_FAILURE",

  ADD_CAPITAL_GOODS: "ADD_CAPITAL_GOODS",
  ADD_CAPITAL_GOODS_SUCCESS: "ADD_CAPITAL_GOODS_SUCCESS",
  ADD_CAPITAL_GOODS_FAILURE: "ADD_CAPITAL_GOODS_FAILURE",

  UPDATE_CAPITAL_GOODS: "UPDATE_CAPITAL_GOODS",
  UPDATE_CAPITAL_GOODS_SUCCESS: "UPDATE_CAPITAL_GOODS_SUCCESS",
  UPDATE_CAPITAL_GOODS_FAILURE: "UPDATE_CAPITAL_GOODS_FAILURE",

  ADD_FRANCHISE: "ADD_FRANCHISE",
  ADD_FRANCHISE_SUCCESS: "ADD_FRANCHISE_SUCCESS",
  ADD_FRANCHISE_FAILURE: "ADD_FRANCHISE_FAILURE",

  ADD_INVESTMENTS: "ADD_INVESTMENTS",
  ADD_INVESTMENTS_SUCCESS: "ADD_INVESTMENTS_SUCCESS",
  ADD_INVESTMENTS_FAILURE: "ADD_INVESTMENTS_FAILURE",



  UPDATE_FUEL_AND_ENERGY_RELATED_ACTIVITIES:
    "UPDATE_FUEL_AND_ENERGY_RELATED_ACTIVITIES",
  UPDATE_FUEL_AND_ENERGY_RELATED_ACTIVITIES_SUCCESS:
    "UPDATE_FUEL_AND_ENERGY_RELATED_ACTIVITIES_SUCCESS",
  UPDATE_FUEL_AND_ENERGY_RELATED_ACTIVITIES_FAILURE:
    "UPDATE_FUEL_AND_ENERGY_RELATED_ACTIVITIES_FAILURE",
  ADD_USE_OF_SOLD_PRODUCTS: "ADD_USE_OF_SOLD_PRODUCTS",
  ADD_USE_OF_SOLD_PRODUCTS_SUCCESS: "ADD_USE_OF_SOLD_PRODUCTS_SUCCESS",
  ADD_USE_OF_SOLD_PRODUCTS_FAILURE: "ADD_USE_OF_SOLD_PRODUCTS_FAILURE",

  ADD_END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS:
    "ADD_END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS",
  ADD_END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS_SUCCESS:
    "ADD_END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS_SUCCESS",
  ADD_END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS_FAILURE:
    "ADD_END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS_FAILURE",

  UPDATE_END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS:
    "UPDATE_END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS",
  UPDATE_END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS_SUCCESS:
    "UPDATE_END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS_SUCCESS",
  UPDATE_END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS_FAILURE:
    "UPDATE_END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS_FAILURE",

  ADD_PROCESSING_OF_SOLD_PRODUCTS: "ADD_PROCESSING_OF_SOLD_PRODUCTS",
  ADD_PROCESSING_OF_SOLD_PRODUCTS_SUCCESS:
    "ADD_PROCESSING_OF_SOLD_PRODUCTS_SUCCESS",
  ADD_PROCESSING_OF_SOLD_PRODUCTS_FAILURE:
    "ADD_END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS_FAILURE",
  
  UPDATE_PROCESSING_OF_SOLD_PRODUCTS:"UPDATE_PROCESSING_OF_SOLD_PRODUCTS",
  UPDATE_PROCESSING_OF_SOLD_PRODUCTS_SUCCESS:"UPDATE_PROCESSING_OF_SOLD_PRODUCTS_SUCCESS",
  UPDATE_PROCESSING_OF_SOLD_PRODUCTS_FAILURE:"UPDATE_PROCESSING_OF_SOLD_PRODUCTS_FAILURE",
};
