import { handleActions } from "redux-actions";
import immutable from "immutability-helper";

import { ActionTypes } from "../constants/actions";
import { STATUS } from "../constants";
import { parseError } from "../../services/client";
import {
  addFranchise,
  addInvestments,
  updateEmployeeCommute,
  updateUseOfSoldProducts,
  updateInvestments,
} from "../actions";
import { updateFuelAndEnergyRelatedActivities } from "../sagas/emission";
import { updateEndOfLifeTreatmentOfSoldProducts } from "../actions";

export const emissionState = {
  emissionList: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  emissionDetails: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  emissionInputs: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  addStationaryCombustion: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  addPurchasedElectricity: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  addRefrigerants: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  updateRefrigerants: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  addWaterDischarge: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  addWaterConsumption: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  addWasteCombustion: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  updatePurchasedElectricity: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  updateStationaryCombustion: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  updateMobileCombustion: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  updateWaterDischargeCombustion: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  updateWaterConsumptionCombustion: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  updateWasteCombustion: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  deleteEmissions: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  addMobileCombustion: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  addEmployeeCommuting: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  fuelList: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  customFuelList: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  fuelUnits: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  mobileCombustionInputs: {
    data: {
      activity_types: [],
      fuel_sources: [],
      custom_fuel_sources: [],
      vehicle_types: [],
      units: [],
    },
    status: STATUS.IDLE,
    message: "",
  },
  addTransportationCombustion: {
    data: { vhevk: true },
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  editTransportationCombustion: {
    data: { vhevk: true },
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  listAuditTrails: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  listFiles: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  uploadAttachement: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  deleteAttachement: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  addDevelopmentTraining: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  addEmployeeHealth: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  addWorkerSafetyTraining: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  addDescriminationIncident: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  addSupplierHumanRightsTraining: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  addSocialHumanRightsTraining: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  addSupplierScreening: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  addLocalCommunities: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  addPoliticalContributions: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  addCorruptionDisclosure: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  addCorruptionTraining: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  addCompetitiveDisclosure: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  addFinancialAssistance: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  addBoardDiversity: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  addManagementDiversity: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  updateBoardDiversity: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  updateManagementDiversity: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  addUploadFileEmission: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  getNonEmissionDetails: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  updateNonEmissionDetails: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  updateDevelopmentTrainingDetails: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  updateWorkerSafetyTraining: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  updateEmployeeTurnover: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  updateAgeBasedStatistics: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  updateGenderBasedStatistics: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  updateDiscriminationIncidentRecord: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  updateSupplierScreening: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  updateSupplierHumanRightsTraining: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  updateSocialHumanRightsTraining: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  updateLocalCommunities: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  updatePoliticalContributions: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  updateEmployeeHealthDetails: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  updateAntiCorruptionDisclosure: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  updateAntiCorruptionTraining: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  updateAntiCompetitiveDisclosure: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  updateSubsidiesFinancialAssistance: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  getAllEmissions: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  uploadHistory: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  uploadHistoryDetails: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  addEmployeeHire: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  addEmployeeBenefit: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  addEmployeeTrainingHours: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  updateEmployeeTrainingHours: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  addPerformanceAndCareerDevelopmentPrograms: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  updatePerformanceAndCareerDevelopmentPrograms: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  addEmployeeDiversity: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  addEconomicImpact: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  updateEconomicImpact: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  updateEmployeeDiversity: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  updateEmployeeBenefits: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  updateEmployeeHire: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  kgAvail: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  ltAvail: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  kiloliterAvail: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getStandardsQuestionnaire: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  addUpstreamTAndD: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  addDownstreamTAndD: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  addBusinessTravel: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  addpurchasedGoodsandServices: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  addEmployeeCommute: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  updateUpstreamTAndD: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  updateInvestments: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  updateUseOfSoldProducts: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  updateBusinessTravel: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  updateEmployeeCommute: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  updatepurchasedGoodsAndServices: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  updateDownstreamTAndD: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  updateUpstreamLeasedAsset: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  updateDownstreamLeasedAsset: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  updateFranchise: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  addFuelAndEnergyRelatedActivities: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  addCapitalGoods: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  updateCapitalGoods: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  addFranchise: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  addInvestments: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  countrylist: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  updateFuelAndEnergyRelatedActivities: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  addUseOfSoldProducts: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  addUpstreamLeasedAsset: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  addDownstreamLeasedAsset: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  addEndOfLifeTreatmentOfSoldProducts: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  updateEndOfLifeTreatmentOfSoldProducts: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  addProcessingOfSoldProducts: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
  updateProcessingOfSoldProducts: {
    data: {},
    status: STATUS.IDLE,
    message: "",
    isCalculateDone: false,
  },
};

const emissionActions = {
  emission: handleActions(
    {
      [ActionTypes.GET_EMISSION_LIST]: (state, { payload }) =>
        immutable(state, {
          emissionList: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_EMISSION_LIST_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          emissionList: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
          },
        }),
      [ActionTypes.GET_EMISSION_LIST_FAILURE]: (state, { payload }) =>
        immutable(state, {
          emissionList: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),
      [ActionTypes.CLEAR_EMISSION_LIST]: (state, { payload }) =>
        immutable(state, {
          emissionList: {
            data: { $set: [] },
            status: { $set: STATUS.IDLE },
          },
        }),

      [ActionTypes.GET_EMISSION]: (state, { payload }) =>
        immutable(state, {
          emissionDetails: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_EMISSION_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          emissionDetails: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
          },
        }),
      [ActionTypes.GET_EMISSION_FAILURE]: (state, { payload }) =>
        immutable(state, {
          emissionDetails: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),
      [ActionTypes.GET_ALL_EMISSIONS]: (state, { payload }) =>
        immutable(state, {
          getAllEmissions: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_ALL_EMISSIONS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getAllEmissions: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
          },
        }),
      [ActionTypes.GET_ALL_EMISSIONS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getAllEmissions: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),
      [ActionTypes.RESET_GET_ALL_EMISSION_STATUS]: (state, { payload }) =>
        immutable(state, {
          getAllEmissions: {
            data: { $set: [] },
            status: { $set: STATUS.IDLE },
          },
        }),

      [ActionTypes.GET_EMISSION_INPUT_FORMAT]: (state, { payload }) =>
        immutable(state, {
          emissionInputs: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_EMISSION_INPUT_FORMAT_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          emissionInputs: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
          },
        }),
      [ActionTypes.GET_EMISSION_INPUT_FORMAT_FAILURE]: (state, { payload }) =>
        immutable(state, {
          emissionInputs: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),
      [ActionTypes.RESET_GET_EMISSION_INPUT_FORMAT_STATUS]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          emissionInputs: {
            data: { $set: {} },
            message: { $set: "" },
            status: { $set: STATUS.IDLE },
          },
        }),

      [ActionTypes.ADD_PURCHASED_ELECTRICITY]: (state, { payload }) =>
        immutable(state, {
          addPurchasedElectricity: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_PURCHASED_ELECTRICITY_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          addPurchasedElectricity: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.ADD_PURCHASED_ELECTRICITY_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addPurchasedElectricity: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.ADD_REFRIGERANTS]: (state, { payload }) =>
        immutable(state, {
          addRefrigerants: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_REFRIGERANTS_SUCCESS]: (state, { payload, save }) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          addRefrigerants: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.ADD_REFRIGERANTS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addRefrigerants: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.ADD_WASTE_COMBUSTION]: (state, { payload }) =>
        immutable(state, {
          addWasteCombustion: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_WASTE_COMBUSTION_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          addWasteCombustion: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.ADD_WASTE_COMBUSTION_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addWasteCombustion: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.UPDATE_REFRIGERANTS]: (state, { payload }) =>
        immutable(state, {
          updateRefrigerants: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_REFRIGERANTS_SUCCESS]: (state, { payload, save }) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updateRefrigerants: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.UPDATE_REFRIGERANTS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          updateRefrigerants: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.ADD_WATER_DISCHARGE_COMBUSTION]: (state, { payload }) =>
        immutable(state, {
          addWaterDischarge: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_WATER_DISCHARGE_COMBUSTION_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          addWaterDischarge: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.ADD_WATER_DISCHARGE_COMBUSTION_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          addWaterDischarge: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.ADD_WATER_CONSUMPTION_COMBUSTION]: (state, { payload }) =>
        immutable(state, {
          addWaterConsumption: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_WATER_CONSUMPTION_COMBUSTION_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          addWaterConsumption: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.ADD_WATER_CONSUMPTION_COMBUSTION_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          addWaterConsumption: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.ADD_EMPLOYEE_HEALTH_DETAILS]: (state, { payload }) =>
        immutable(state, {
          addEmployeeHealth: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_EMPLOYEE_HEALTH_DETAILS_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        return immutable(state, {
          addEmployeeHealth: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.ADD_EMPLOYEE_HEALTH_DETAILS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addEmployeeHealth: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.UPDATE_EMPLOYEE_HEALTH_DETAILS]: (state, { payload }) =>
        immutable(state, {
          updateEmployeeHealthDetails: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_EMPLOYEE_HEALTH_DETAILS_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updateEmployeeHealthDetails: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.UPDATE_EMPLOYEE_HEALTH_DETAILS_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          updateEmployeeHealthDetails: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.UPDATE_PURCHASED_ELECTRICITY]: (state, { payload }) =>
        immutable(state, {
          updatePurchasedElectricity: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_PURCHASED_ELECTRICITY_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updatePurchasedElectricity: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.UPDATE_PURCHASED_ELECTRICITY_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          updatePurchasedElectricity: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.UPDATE_STATIONARY_COMBUSTION]: (state, { payload }) =>
        immutable(state, {
          updateStationaryCombustion: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_STATIONARY_COMBUSTION_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updateStationaryCombustion: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.UPDATE_STATIONARY_COMBUSTION_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          updateStationaryCombustion: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.UPDATE_MOBILE_COMBUSTION]: (state, { payload }) =>
        immutable(state, {
          updateMobileCombustion: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_MOBILE_COMBUSTION_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updateMobileCombustion: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.UPDATE_MOBILE_COMBUSTION_FAILURE]: (state, { payload }) =>
        immutable(state, {
          updateMobileCombustion: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.UPDATE_WATER_DISCHARGE_COMBUSTION]: (state, { payload }) =>
        immutable(state, {
          updateWaterDischargeCombustion: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_WATER_DISCHARGE_COMBUSTION_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updateWaterDischargeCombustion: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.UPDATE_WATER_DISCHARGE_COMBUSTION_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          updateWaterDischargeCombustion: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.UPDATE_WATER_CONSUMPTION_COMBUSTION]: (state, { payload }) =>
        immutable(state, {
          updateWaterConsumptionCombustion: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_WATER_CONSUMPTION_COMBUSTION_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updateWaterConsumptionCombustion: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.UPDATE_WATER_CONSUMPTION_COMBUSTION_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          updateWaterConsumptionCombustion: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.UPDATE_WASTE_COMBUSTION]: (state, { payload }) =>
        immutable(state, {
          updateWasteCombustion: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_WASTE_COMBUSTION_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updateWasteCombustion: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.UPDATE_WASTE_COMBUSTION_FAILURE]: (state, { payload }) =>
        immutable(state, {
          updateWasteCombustion: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.DELETE_EMISSIONS]: (state, { payload }) =>
        immutable(state, {
          deleteEmissions: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.DELETE_EMISSIONS_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          deleteEmissions: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
          },
        });
      },
      [ActionTypes.DELETE_EMISSIONS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          deleteEmissions: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),
      [ActionTypes.GET_EMISSION_INPUT_FORMAT]: (state, { payload }) =>
        immutable(state, {
          emissionInputs: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_EMISSION_INPUT_FORMAT_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          emissionInputs: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
          },
        }),
      [ActionTypes.GET_EMISSION_INPUT_FORMAT_FAILURE]: (state, { payload }) =>
        immutable(state, {
          emissionInputs: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.ADD_STATIONARY_COMBUSTION]: (state, { payload }) =>
        immutable(state, {
          addStationaryCombustion: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_STATIONARY_COMBUSTION_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          addStationaryCombustion: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.ADD_STATIONARY_COMBUSTION_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addStationaryCombustion: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.ADD_EMPLOYEE_COMMUTING]: (state, { payload }) =>
        immutable(state, {
          addEmployeeCommuting: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_EMPLOYEE_COMMUTING_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          addEmployeeCommuting: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.ADD_EMPLOYEE_COMMUTING_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addEmployeeCommuting: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.ADD_MOBILE_COMBUSTION]: (state, { payload }) =>
        immutable(state, {
          addMobileCombustion: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_MOBILE_COMBUSTION_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          addMobileCombustion: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.ADD_MOBILE_COMBUSTION_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addMobileCombustion: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.ADD_TRANSPORTATION_COMBUSTION]: (state, { payload }) =>
        immutable(state, {
          addTransportationCombustion: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_TRANSPORTATION_COMBUSTION_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          addTransportationCombustion: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.ADD_TRANSPORTATION_COMBUSTION_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          addTransportationCombustion: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.EDIT_TRANSPORTATION_COMBUSTION]: (state, { payload }) =>
        immutable(state, {
          editTransportationCombustion: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.EDIT_TRANSPORTATION_COMBUSTION_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          editTransportationCombustion: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.EDIT_TRANSPORTATION_COMBUSTION_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          editTransportationCombustion: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.RESET_ADD_COMBUSTION_STATUS]: (state, { payload }) =>
        immutable(state, { $set: emissionState }),

      // [ActionTypes.RESET__EMISSION_STATUS]: (state, { payload }) =>
      //   immutable(state, { $set: emissionState }),

      [ActionTypes.GET_EMISSION_FUEL_LIST]: (state, { payload }) =>
        immutable(state, {
          fuelList: {
            status: { $set: STATUS.RUNNING },
          },
          customFuelList: {
            status: { $set: STATUS.RUNNING },
          },
          fuelUnits: {
            status: { $set: STATUS.RUNNING },
          },
          kgAvail: {
            status: { $set: STATUS.RUNNING },
          },
          ltAvail: {
            status: { $set: STATUS.RUNNING },
          },
          kiloliterAvail: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_EMISSION_FUEL_LIST_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          fuelList: {
            data: { $set: payload.fuels ? payload.fuels : payload },
            status: { $set: STATUS.SUCCESS },
          },
          customFuelList: {
            data: { $set: payload.custom_fuels },
            status: { $set: STATUS.SUCCESS },
          },
          fuelUnits: {
            data: { $set: payload.units },
            status: { $set: STATUS.SUCCESS },
          },
          kgAvail: {
            data: { $set: payload.kg_availability || [] },
            status: { $set: STATUS.SUCCESS },
          },
          ltAvail: {
            data: { $set: payload.lt_availability || [] },
            status: { $set: STATUS.SUCCESS },
          },
          kiloliterAvail: {
            data: { $set: payload.kiloliter_availability || [] },
            status: { $set: STATUS.SUCCESS },
          },
        }),
      [ActionTypes.GET_EMISSION_FUEL_LIST_FAILURE]: (state, { payload }) =>
        immutable(state, {
          fuelList: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
          customFuelList: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
          fuelUnits: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
          kgAvail: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
          ltAvail: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
          kiloliterAvail: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.GET_MOBILE_COMBUSTION_INPUTS]: (state, { payload }) =>
        immutable(state, {
          mobileCombustionInputs: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_MOBILE_COMBUSTION_INPUTS_SUCCESS]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          mobileCombustionInputs: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
          },
        }),
      [ActionTypes.GET_MOBILE_COMBUSTION_INPUTS_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          mobileCombustionInputs: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.LIST_EMISSION_AUDIT_TRAILS]: (state, { payload }) =>
        immutable(state, {
          listAuditTrails: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.LIST_EMISSION_AUDIT_TRAILS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          listAuditTrails: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
          },
        }),
      [ActionTypes.LIST_EMISSION_AUDIT_TRAILS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          listAuditTrails: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),
      [ActionTypes.CLEAR_LIST_EMISSION_AUDIT_TRAILS]: (state, { payload }) =>
        immutable(state, {
          listAuditTrails: {
            data: { $set: [] },
            status: { $set: STATUS.IDLE },
          },
        }),

      [ActionTypes.LIST_EMISSION_FILES]: (state, { payload }) =>
        immutable(state, {
          listFiles: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.LIST_EMISSION_FILES_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          listFiles: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
          },
        }),
      [ActionTypes.LIST_EMISSION_FILES_FAILURE]: (state, { payload }) =>
        immutable(state, {
          listFiles: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),
      [ActionTypes.CLEAR_LIST_EMISSION_FILES]: (state, { payload }) =>
        immutable(state, {
          listFiles: {
            data: { $set: [] },
            status: { $set: STATUS.IDLE },
          },
        }),

      [ActionTypes.UPLOAD_EMISSION_ATTACHEMENT]: (state, { payload }) =>
        immutable(state, {
          uploadAttachement: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPLOAD_EMISSION_ATTACHEMENT_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          uploadAttachement: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
          },
        }),
      [ActionTypes.UPLOAD_EMISSION_ATTACHEMENT_FAILURE]: (state, { payload }) =>
        immutable(state, {
          uploadAttachement: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),
      [ActionTypes.CLEAR_UPLOAD_EMISSION_ATTACHEMENT]: (state, { payload }) =>
        immutable(state, {
          uploadAttachement: {
            data: { $set: {} },
            status: { $set: STATUS.IDLE },
          },
        }),

      [ActionTypes.DELETE_EMISSION_ATTACHEMENT]: (state, { payload }) =>
        immutable(state, {
          deleteAttachement: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.DELETE_EMISSION_ATTACHEMENT_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          deleteAttachement: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
          },
        }),
      [ActionTypes.DELETE_EMISSION_ATTACHEMENT_FAILURE]: (state, { payload }) =>
        immutable(state, {
          deleteAttachement: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),
      [ActionTypes.CLEAR_DELETE_EMISSION_ATTACHEMENT]: (state, { payload }) =>
        immutable(state, {
          deleteAttachement: {
            data: { $set: {} },
            status: { $set: STATUS.IDLE },
          },
        }),

      [ActionTypes.ADD_DEVELOPMENT_TRAINING_DETAILS]: (state, { payload }) =>
        immutable(state, {
          addDevelopmentTraining: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_DEVELOPMENT_TRAINING_DETAILS_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        return immutable(state, {
          addDevelopmentTraining: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.ADD_DEVELOPMENT_TRAINING_DETAILS_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          addDevelopmentTraining: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.UPDATE_DEVELOPMENT_TRAINING_DETAILS]: (state, { payload }) =>
        immutable(state, {
          updateDevelopmentTrainingDetails: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_DEVELOPMENT_TRAINING_DETAILS_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updateDevelopmentTrainingDetails: {
            data: { $set: payload },
            status: { $set: status },
          },
        });
      },
      [ActionTypes.UPDATE_DEVELOPMENT_TRAINING_DETAILS_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          updateDevelopmentTrainingDetails: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.UPDATE_SUPPLIER_HUMAN_RIGHTS_TRAINING]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          updateSupplierHumanRightsTraining: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_SUPPLIER_HUMAN_RIGHTS_TRAINING_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updateSupplierHumanRightsTraining: {
            data: { $set: payload },
            status: { $set: status },
          },
        });
      },
      [ActionTypes.UPDATE_SUPPLIER_HUMAN_RIGHTS_TRAINING_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          updateSupplierHumanRightsTraining: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.UPDATE_WORKER_SAFETY_TRAINING]: (state, { payload }) =>
        immutable(state, {
          updateWorkerSafetyTraining: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_WORKER_SAFETY_TRAINING_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updateWorkerSafetyTraining: {
            data: { $set: payload },
            status: { $set: status },
          },
        });
      },
      [ActionTypes.UPDATE_WORKER_SAFETY_TRAINING_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          updateWorkerSafetyTraining: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.UPDATE_EMPLOYEE_TURNOVER]: (state, { payload }) =>
        immutable(state, {
          updateEmployeeTurnover: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_EMPLOYEE_TURNOVER_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updateEmployeeTurnover: {
            data: { $set: payload },
            status: { $set: status },
          },
        });
      },
      [ActionTypes.UPDATE_EMPLOYEE_TURNOVER_FAILURE]: (state, { payload }) =>
        immutable(state, {
          updateEmployeeTurnover: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.UPDATE_AGE_BASED_STATISTICS]: (state, { payload }) =>
        immutable(state, {
          updateAgeBasedStatistics: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_AGE_BASED_STATISTICS_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updateAgeBasedStatistics: {
            data: { $set: payload },
            status: { $set: status },
          },
        });
      },
      [ActionTypes.UPDATE_AGE_BASED_STATISTICS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          updateAgeBasedStatistics: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.UPDATE_GENDER_BASED_STATISTICS]: (state, { payload }) =>
        immutable(state, {
          updateGenderBasedStatistics: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_GENDER_BASED_STATISTICS_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updateGenderBasedStatistics: {
            data: { $set: payload },
            status: { $set: status },
          },
        });
      },
      [ActionTypes.UPDATE_GENDER_BASED_STATISTICS_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          updateGenderBasedStatistics: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.UPDATE_DISCRIMINATION_INCIDENT_RECORD]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          updateDiscriminationIncidentRecord: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_DISCRIMINATION_INCIDENT_RECORD_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updateDiscriminationIncidentRecord: {
            data: { $set: payload },
            status: { $set: status },
          },
        });
      },
      [ActionTypes.UPDATE_DISCRIMINATION_INCIDENT_RECORD_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          updateDiscriminationIncidentRecord: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.UPDATE_SOCIAL_HUMAN_RIGHTS_TRAINING]: (state, { payload }) =>
        immutable(state, {
          updateSocialHumanRightsTraining: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_SOCIAL_HUMAN_RIGHTS_TRAINING_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updateSocialHumanRightsTraining: {
            data: { $set: payload },
            status: { $set: status },
          },
        });
      },
      [ActionTypes.UPDATE_SOCIAL_HUMAN_RIGHTS_TRAINING_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          updateSocialHumanRightsTraining: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.UPDATE_SUPPLIER_SCREENING]: (state, { payload }) =>
        immutable(state, {
          updateSupplierScreening: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_SUPPLIER_SCREENING_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updateSupplierScreening: {
            data: { $set: payload },
            status: { $set: status },
          },
        });
      },
      [ActionTypes.UPDATE_SUPPLIER_SCREENING_FAILURE]: (state, { payload }) =>
        immutable(state, {
          updateSupplierScreening: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.UPDATE_LOCAL_COMMUNITIES]: (state, { payload }) =>
        immutable(state, {
          updateLocalCommunities: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_LOCAL_COMMUNITIES_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updateLocalCommunities: {
            data: { $set: payload },
            status: { $set: status },
          },
        });
      },
      [ActionTypes.UPDATE_LOCAL_COMMUNITIES_FAILURE]: (state, { payload }) =>
        immutable(state, {
          updateLocalCommunities: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.UPDATE_POLITICAL_CONTRIBUTIONS]: (state, { payload }) =>
        immutable(state, {
          updatePoliticalContributions: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_POLITICAL_CONTRIBUTIONS_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updatePoliticalContributions: {
            data: { $set: payload },
            status: { $set: status },
          },
        });
      },
      [ActionTypes.UPDATE_POLITICAL_CONTRIBUTIONS_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          updatePoliticalContributions: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.ADD_WORKER_SAFETY_TRAINING]: (state, { payload }) =>
        immutable(state, {
          addWorkerSafetyTraining: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_WORKER_SAFETY_TRAINING_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        return immutable(state, {
          addWorkerSafetyTraining: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.ADD_WORKER_SAFETY_TRAINING_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addWorkerSafetyTraining: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.ADD_DESCRIMINATION_INCIDENT_RECORD]: (state, { payload }) =>
        immutable(state, {
          addDescriminationIncident: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_DESCRIMINATION_INCIDENT_RECORD_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        return immutable(state, {
          addDescriminationIncident: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.ADD_DESCRIMINATION_INCIDENT_RECORD_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          addDescriminationIncident: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.ADD_SUPPLIER_HUMAN_RIGHTS_TRAINING]: (state, { payload }) =>
        immutable(state, {
          addSupplierHumanRightsTraining: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_SUPPLIER_HUMAN_RIGHTS_TRAINING_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        return immutable(state, {
          addSupplierHumanRightsTraining: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.ADD_SUPPLIER_HUMAN_RIGHTS_TRAINING_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          addSupplierHumanRightsTraining: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.ADD_SOCIAL_HUMAN_RIGHTS_TRAINING]: (state, { payload }) =>
        immutable(state, {
          addSocialHumanRightsTraining: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_SOCIAL_HUMAN_RIGHTS_TRAINING_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        return immutable(state, {
          addSocialHumanRightsTraining: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.ADD_SOCIAL_HUMAN_RIGHTS_TRAINING_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          addSocialHumanRightsTraining: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.ADD_SUPPLIER_SCREENING]: (state, { payload }) =>
        immutable(state, {
          addSupplierScreening: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_SUPPLIER_SCREENING_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        return immutable(state, {
          addSupplierScreening: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.ADD_SUPPLIER_SCREENING_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addSupplierScreening: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.ADD_LOCAL_COMMUNITIES]: (state, { payload }) =>
        immutable(state, {
          addLocalCommunities: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_LOCAL_COMMUNITIES_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        return immutable(state, {
          addLocalCommunities: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.ADD_LOCAL_COMMUNITIES_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addLocalCommunities: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.ADD_POLITICAL_CONTRIBUTION]: (state, { payload }) =>
        immutable(state, {
          addPoliticalContributions: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_POLITICAL_CONTRIBUTION_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        return immutable(state, {
          addPoliticalContributions: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.ADD_POLITICAL_CONTRIBUTION_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addPoliticalContributions: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.ADD_ANTI_CORRUPTION_DISCLOSURE]: (state, { payload }) =>
        immutable(state, {
          addCorruptionDisclosure: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_ANTI_CORRUPTION_DISCLOSURE_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        return immutable(state, {
          addCorruptionDisclosure: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.ADD_ANTI_CORRUPTION_DISCLOSURE_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          addCorruptionDisclosure: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.ADD_ANTI_CORRUPTION_TRAINING]: (state, { payload }) =>
        immutable(state, {
          addCorruptionTraining: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_ANTI_CORRUPTION_TRAINING_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        return immutable(state, {
          addCorruptionTraining: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.ADD_ANTI_CORRUPTION_TRAINING_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          addCorruptionTraining: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.ADD_ANTI_COMPETITIVE_DISCLOSURE]: (state, { payload }) =>
        immutable(state, {
          addCompetitiveDisclosure: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_ANTI_COMPETITIVE_DISCLOSURE_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        return immutable(state, {
          addCompetitiveDisclosure: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.ADD_ANTI_COMPETITIVE_DISCLOSURE_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          addCompetitiveDisclosure: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.ADD_SUBSIDIES_FINANCIAL_ASSISTANCE]: (state, { payload }) =>
        immutable(state, {
          addFinancialAssistance: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_SUBSIDIES_FINANCIAL_ASSISTANCE_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        return immutable(state, {
          addFinancialAssistance: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.ADD_SUBSIDIES_FINANCIAL_ASSISTANCE_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          addFinancialAssistance: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.ADD_BOARD_DIVERSITY]: (state, { payload }) =>
        immutable(state, {
          addBoardDiversity: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_BOARD_DIVERSITY_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          addBoardDiversity: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.ADD_BOARD_DIVERSITY_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addBoardDiversity: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
      [ActionTypes.UPDATE_BOARD_DIVERSITY]: (state, { payload }) =>
        immutable(state, {
          updateBoardDiversity: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_BOARD_DIVERSITY_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          updateBoardDiversity: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.UPDATE_BOARD_DIVERSITY_FAILURE]: (state, { payload }) =>
        immutable(state, {
          updateBoardDiversity: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
      [ActionTypes.RESET_ADD_BOARD_DIVERSITY_STATUS]: (state, { payload }) =>
        immutable(state, {
          addBoardDiversity: {
            data: { $set: [] },
            status: { $set: STATUS.IDLE },
          },
        }),
      [ActionTypes.CLEAR_UPDATE_BOARD_DIVERSITY_STATUS]: (state, { payload }) =>
        immutable(state, {
          updateBoardDiversity: {
            data: { $set: [] },
            status: { $set: STATUS.IDLE },
          },
        }),

      [ActionTypes.ADD_MANAGEMENT_DIVERSITY]: (state, { payload }) =>
        immutable(state, {
          addManagementDiversity: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_MANAGEMENT_DIVERSITY_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          addManagementDiversity: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.ADD_MANAGEMENT_DIVERSITY_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addManagementDiversity: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
      [ActionTypes.UPDATE_MANAGEMENT_DIVERSITY]: (state, { payload }) =>
        immutable(state, {
          updateManagementDiversity: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_MANAGEMENT_DIVERSITY_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          updateManagementDiversity: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.UPDATE_MANAGEMENT_DIVERSITY_FAILURE]: (state, { payload }) =>
        immutable(state, {
          updateManagementDiversity: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.CLEAR_UPDATE_MANAGEMENT_DIVERSITY_STATUS]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          updateManagementDiversity: {
            data: { $set: [] },
            status: { $set: STATUS.IDLE },
          },
        }),

      [ActionTypes.RESET_ADD_MANAGEMENT_DIVERSITY_STATUS]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          addManagementDiversity: {
            data: { $set: [] },
            status: { $set: STATUS.IDLE },
          },
        }),

      [ActionTypes.ADD_UPLOAD_FILE_EMISSION]: (state, { payload }) =>
        immutable(state, {
          addUploadFileEmission: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_UPLOAD_FILE_EMISSION_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        return immutable(state, {
          addUploadFileEmission: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.ADD_UPLOAD_FILE_EMISSION_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addUploadFileEmission: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.ADD_EMPLOYEE_HIRE]: (state, { payload }) =>
        immutable(state, {
          addEmployeeHire: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_EMPLOYEE_HIRE_SUCCESS]: (state, { payload, save }) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          addEmployeeHire: {
            data: { $set: payload },
            status: { $set: status },
          },
        });
      },
      [ActionTypes.ADD_EMPLOYEE_HIRE_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addEmployeeHire: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),
      [ActionTypes.UPDATE_EMPLOYEE_HIRE]: (state, { payload }) =>
        immutable(state, {
          updateEmployeeHire: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_EMPLOYEE_HIRE_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updateEmployeeHire: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
          },
        });
      },
      [ActionTypes.UPDATE_EMPLOYEE_HIRE_FAILURE]: (state, { payload }) =>
        immutable(state, {
          updateEmployeeHire: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),
      [ActionTypes.ADD_EMPLOYEE_BENEFIT]: (state, { payload }) =>
        immutable(state, {
          addEmployeeBenefit: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_EMPLOYEE_BENEFIT_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          addEmployeeBenefit: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
          },
        });
      },
      [ActionTypes.ADD_EMPLOYEE_BENEFIT_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addEmployeeBenefit: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.UPDATE_EMPLOYEE_BENEFITS]: (state, { payload }) =>
        immutable(state, {
          updateEmployeeBenefits: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_EMPLOYEE_BENEFITS_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updateEmployeeBenefits: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
          },
        });
      },
      [ActionTypes.UPDATE_EMPLOYEE_BENEFITS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          updateEmployeeBenefits: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.ADD_EMPLOYEE_DIVERSITY]: (state, { payload }) =>
        immutable(state, {
          addEmployeeDiversity: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_EMPLOYEE_DIVERSITY_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          addEmployeeDiversity: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
          },
        });
      },
      [ActionTypes.ADD_EMPLOYEE_DIVERSITY_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addEmployeeDiversity: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.UPDATE_EMPLOYEE_DIVERSITY]: (state, { payload }) =>
        immutable(state, {
          updateEmployeeDiversity: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_EMPLOYEE_DIVERSITY_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updateEmployeeDiversity: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
          },
        });
      },
      [ActionTypes.UPDATE_EMPLOYEE_DIVERSITY_FAILURE]: (state, { payload }) =>
        immutable(state, {
          updateEmployeeDiversity: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.ADD_ECONOMIC_IMPACT]: (state, { payload }) =>
        immutable(state, {
          addEconomicImpact: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_ECONOMIC_IMPACT_SUCCESS]: (state, { payload, save }) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          addEconomicImpact: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
          },
        });
      },
      [ActionTypes.ADD_ECONOMIC_IMPACT_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addEconomicImpact: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.UPDATE_ECONOMIC_IMPACT]: (state, { payload }) =>
        immutable(state, {
          updateEconomicImpact: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_ECONOMIC_IMPACT_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updateEconomicImpact: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
          },
        });
      },
      [ActionTypes.UPDATE_ECONOMIC_IMPACT_FAILURE]: (state, { payload }) =>
        immutable(state, {
          updateEconomicImpact: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.GET_NON_EMISSION_DETAILS]: (state, { payload }) =>
        immutable(state, {
          getNonEmissionDetails: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_NON_EMISSION_DETAILS_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          getNonEmissionDetails: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
          },
        });
      },
      [ActionTypes.GET_NON_EMISSION_DETAILS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getNonEmissionDetails: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.GET_UPLOAD_HISTORY]: (state, { payload }) =>
        immutable(state, {
          uploadHistory: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_UPLOAD_HISTORY_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          uploadHistory: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
          },
        });
      },
      [ActionTypes.GET_UPLOAD_HISTORY_FAILURE]: (state, { payload }) =>
        immutable(state, {
          uploadHistory: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.GET_UPLOAD_HISTORY_DETAILS]: (state, { payload }) =>
        immutable(state, {
          uploadHistoryDetails: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_UPLOAD_HISTORY_DETAILS_SUCCESS]: (
        state,
        { payload }
      ) => {
        return immutable(state, {
          uploadHistoryDetails: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
          },
        });
      },
      [ActionTypes.GET_UPLOAD_HISTORY_DETAILS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          uploadHistoryDetails: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.CLEAR_GET_NON_EMISSION_DETAILS]: (state, { payload }) =>
        immutable(state, {
          getNonEmissionDetails: {
            status: { $set: STATUS.IDLE },
            data: { $set: {} },
          },
        }),

      [ActionTypes.UPDATE_NON_EMISSION_DETAILS]: (state, { payload }) =>
        immutable(state, {
          updateNonEmissionDetails: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_NON_EMISSION_DETAILS_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        return immutable(state, {
          updateNonEmissionDetails: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.UPDATE_NON_EMISSION_DETAILS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          updateNonEmissionDetails: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.UPDATE_SUBSIDIES_FINANCIAL_ASSISTANCE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          updateSubsidiesFinancialAssistance: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_SUBSIDIES_FINANCIAL_ASSISTANCE_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updateSubsidiesFinancialAssistance: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.UPDATE_SUBSIDIES_FINANCIAL_ASSISTANCE_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          updateSubsidiesFinancialAssistance: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.UPDATE_ANTI_COMPETITIVE_DISCLOSURE]: (state, { payload }) =>
        immutable(state, {
          updateAntiCompetitiveDisclosure: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_ANTI_COMPETITIVE_DISCLOSURE_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updateAntiCompetitiveDisclosure: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.UPDATE_ANTI_COMPETITIVE_DISCLOSURE_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          updateAntiCompetitiveDisclosure: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.UPDATE_ANTI_CORRUPTION_TRAINING]: (state, { payload }) =>
        immutable(state, {
          updateAntiCorruptionTraining: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_ANTI_CORRUPTION_TRAINING_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updateAntiCorruptionTraining: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.UPDATE_ANTI_CORRUPTION_TRAINING_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          updateAntiCorruptionTraining: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.UPDATE_ANTI_CORRUPTION_DISCLOSURE]: (state, { payload }) =>
        immutable(state, {
          updateAntiCorruptionDisclosure: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_ANTI_CORRUPTION_DISCLOSURE_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updateAntiCorruptionDisclosure: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.UPDATE_ANTI_CORRUPTION_DISCLOSURE_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          updateAntiCorruptionDisclosure: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),
      [ActionTypes.GET_ALL_EMISSIONS]: (state, { payload, save }) => {
        return immutable(state, {
          addLocalCommunities: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.ADD_EMPLOYEE_TRAINING_HOURS]: (state, { payload }) =>
        immutable(state, {
          addEmployeeTrainingHours: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_EMPLOYEE_TRAINING_HOURS_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          addEmployeeTrainingHours: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
          },
        });
      },
      [ActionTypes.ADD_EMPLOYEE_TRAINING_HOURS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addEmployeeTrainingHours: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),
      [ActionTypes.UPDATE_EMPLOYEE_TRAINING_HOURS]: (state, { payload }) =>
        immutable(state, {
          updateEmployeeTrainingHours: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_EMPLOYEE_TRAINING_HOURS_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updateEmployeeTrainingHours: {
            data: { $set: payload },
            status: { $set: status },
          },
        });
      },
      [ActionTypes.UPDATE_EMPLOYEE_TRAINING_HOURS_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          updateEmployeeTrainingHours: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),
      [ActionTypes.RESET_EMPLOYEE_TRAINING_HOURS]: (state, { payload }) =>
        immutable(state, {
          updateEmployeeTrainingHours: {
            message: { $set: "" },
            status: { $set: STATUS.IDLE },
          },
        }),

      [ActionTypes.ADD_PERFORMANCE_AND_CAREER_DEVELOPMENT_PROGRAMS]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          addPerformanceAndCareerDevelopmentPrograms: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_PERFORMANCE_AND_CAREER_DEVELOPMENT_PROGRAMS_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          addPerformanceAndCareerDevelopmentPrograms: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
          },
        });
      },
      [ActionTypes.ADD_PERFORMANCE_AND_CAREER_DEVELOPMENT_PROGRAMS_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          addPerformanceAndCareerDevelopmentPrograms: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),
      [ActionTypes.UPDATE_PERFORMANCE_AND_CAREER_DEVELOPMENT_PROGRAMS]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          updatePerformanceAndCareerDevelopmentPrograms: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_PERFORMANCE_AND_CAREER_DEVELOPMENT_PROGRAMS_SUCCESS]:
        (state, { payload, save }) => {
          let status = save ? STATUS.SUCCESS : STATUS.IDLE;
          return immutable(state, {
            updatePerformanceAndCareerDevelopmentPrograms: {
              data: { $set: payload },
              status: { $set: status },
            },
          });
        },
      [ActionTypes.UPDATE_PERFORMANCE_AND_CAREER_DEVELOPMENT_PROGRAMS_FAILURE]:
        (state, { payload }) =>
          immutable(state, {
            updatePerformanceAndCareerDevelopmentPrograms: {
              message: { $set: parseError(payload) },
              status: { $set: STATUS.ERROR },
            },
          }),
      [ActionTypes.RESET_PERFORMANCE_AND_CAREER_DEVELOPMENT_PROGRAMS]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          updatePerformanceAndCareerDevelopmentPrograms: {
            message: { $set: "" },
            status: { $set: STATUS.IDLE },
          },
        }),
      [ActionTypes.RESET_EMISSION_DELETE]: (state, { payload }) =>
        immutable(state, {
          deleteEmissions: {
            message: { $set: "" },
            status: { $set: STATUS.IDLE },
          },
        }),
      [ActionTypes.GET_STANDARDS_QUESTIONNAIRE]: (state, { payload }) =>
        immutable(state, {
          getStandardsQuestionnaire: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_STANDARDS_QUESTIONNAIRE_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getStandardsQuestionnaire: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_STANDARDS_QUESTIONNAIRE_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getStandardsQuestionnaire: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.ADD_UPSTREAM_T_AND_D]: (state, { payload }) =>
        immutable(state, {
          addUpstreamTAndD: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_UPSTREAM_T_AND_D_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          addUpstreamTAndD: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.ADD_UPSTREAM_T_AND_D_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addUpstreamTAndD: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.ADD_DOWNSTREAM_T_AND_D]: (state, { payload }) =>
        immutable(state, {
          addDownstreamTAndD: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_DOWNSTREAM_T_AND_D_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          addDownstreamTAndD: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.ADD_DOWNSTREAM_T_AND_D_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addDownstreamTAndD: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),
      [ActionTypes.ADD_BUSINESS_TRAVEL]: (state, { payload }) =>
        immutable(state, {
          addBusinessTravel: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_BUSINESS_TRAVEL_SUCCESS]: (state, { payload, save }) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          addBusinessTravel: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.ADD_BUSINESS_TRAVEL_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addBusinessTravel: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),
      [ActionTypes.ADD_PURCHASED_GOODS_AND_SERVICES]: (state, { payload }) =>
        immutable(state, {
          addpurchasedGoodsandServices: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_PURCHASED_GOODS_AND_SERVICES_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          addpurchasedGoodsandServices: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.ADD_PURCHASED_GOODS_AND_SERVICES_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          addpurchasedGoodsandServices: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),
      [ActionTypes.ADD_EMPLOYEE_COMMUTE]: (state, { payload }) =>
        immutable(state, {
          addEmployeeCommute: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_EMPLOYEE_COMMUTE_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          addEmployeeCommute: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.ADD_EMPLOYEE_COMMUTE_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addEmployeeCommute: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.UPDATE_UPSTREAM_T_AND_D]: (state, { payload }) =>
        immutable(state, {
          updateUpstreamTAndD: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_UPSTREAM_T_AND_D_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updateUpstreamTAndD: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.UPDATE_UPSTREAM_T_AND_D_FAILURE]: (state, { payload }) =>
        immutable(state, {
          updateUpstreamTAndD: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.UPDATE_USE_OF_SOLD_PRODUCTS]: (state, { payload }) =>
        immutable(state, {
          updateUseOfSoldProducts: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_USE_OF_SOLD_PRODUCTS_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updateUseOfSoldProducts: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.UPDATE_USE_OF_SOLD_PRODUCTS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          updateUseOfSoldProducts: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),





        [ActionTypes.UPDATE_INVESTMENTS]: (state, { payload }) =>
          immutable(state, {
            updateInvestments: {
              status: { $set: STATUS.RUNNING },
            },
          }),
        [ActionTypes.UPDATE_INVESTMENTS_SUCCESS]: (
          state,
          { payload, save }
        ) => {
          let status = save ? STATUS.SUCCESS : STATUS.IDLE;
          return immutable(state, {
            updateInvestments: {
              data: { $set: payload },
              status: { $set: status },
              isCalculateDone: { $set: !save },
            },
          });
        },
        [ActionTypes.UPDATE_INVESTMENTS_FAILURE]: (state, { payload }) =>
          immutable(state, {
            updateInvestments: {
              message: { $set: parseError(payload) },
              status: { $set: STATUS.ERROR },
            },
          }),




      [ActionTypes.UPDATE_EMPLOYEE_COMMUTE]: (state, { payload }) =>
        immutable(state, {
          updateEmployeeCommute: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_EMPLOYEE_COMMUTE_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updateEmployeeCommute: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.UPDATE_EMPLOYEE_COMMUTE_FAILURE]: (state, { payload }) =>
        immutable(state, {
          updateEmployeeCommute: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.UPDATE_BUSINESS_TRAVEL]: (state, { payload }) =>
        immutable(state, {
          updateBusinessTravel: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_BUSINESS_TRAVEL_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updateBusinessTravel: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.UPDATE_BUSINESS_TRAVEL_FAILURE]: (state, { payload }) =>
        immutable(state, {
          updateBusinessTravel: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.UPDATE_PURCHASED_GOODS_AND_SERVICES]: (state, { payload }) =>
        immutable(state, {
          updatepurchasedGoodsAndServices: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_PURCHASED_GOODS_AND_SERVICES_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updatepurchasedGoodsAndServices: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.UPDATE_PURCHASED_GOODS_AND_SERVICES_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          updatepurchasedGoodsAndServices: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.UPDATE_DOWNSTREAM_T_AND_D]: (state, { payload }) =>
        immutable(state, {
          updateDownstreamTAndD: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_DOWNSTREAM_T_AND_D_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updateDownstreamTAndD: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.UPDATE_DOWNSTREAM_T_AND_D_FAILURE]: (state, { payload }) =>
        immutable(state, {
          updateDownstreamTAndD: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.ADD_FUEL_AND_ENERGY_RELATED_ACTIVITIES]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          addFuelAndEnergyRelatedActivities: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_FUEL_AND_ENERGY_RELATED_ACTIVITIES_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          addFuelAndEnergyRelatedActivities: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.ADD_FUEL_AND_ENERGY_RELATED_ACTIVITIES_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          addFuelAndEnergyRelatedActivities: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.ADD_CAPITAL_GOODS]: (state, { payload }) =>
        immutable(state, {
          addCapitalGoods: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_CAPITAL_GOODS_SUCCESS]: (state, { payload, save }) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          addCapitalGoods: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.ADD_CAPITAL_GOODS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addCapitalGoods: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.UPDATE_CAPITAL_GOODS]: (state, { payload }) =>
        immutable(state, {
          updateCapitalGoods: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_CAPITAL_GOODS_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updateCapitalGoods: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.UPDATE_CAPITAL_GOODS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          updateCapitalGoods: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.ADD_FRANCHISE]: (state, { payload }) =>
        immutable(state, {
          addFranchise: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_FRANCHISE_SUCCESS]: (state, { payload, save }) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          addFranchise: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.ADD_FRANCHISE_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addFranchise: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),



        [ActionTypes.ADD_INVESTMENTS]: (state, { payload }) =>
          immutable(state, {
            addInvestments: {
              status: { $set: STATUS.RUNNING },
            },
          }),
        [ActionTypes.ADD_INVESTMENTS_SUCCESS]: (state, { payload, save }) => {
          let status = save ? STATUS.SUCCESS : STATUS.IDLE;
          return immutable(state, {
            addInvestments: {
              data: { $set: payload },
              status: { $set: status },
              isCalculateDone: { $set: !save },
            },
          });
        },
        [ActionTypes.ADD_INVESTMENTS_FAILURE]: (state, { payload }) =>
          immutable(state, {
            addInvestments: {
              message: { $set: parseError(payload) },
              status: { $set: STATUS.ERROR },
            },
          }),




      [ActionTypes.GET_COUNTRY_LIST]: (state, { payload }) =>
        immutable(state, {
          countrylist: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.UPDATE_FUEL_AND_ENERGY_RELATED_ACTIVITIES]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          updateFuelAndEnergyRelatedActivities: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_FUEL_AND_ENERGY_RELATED_ACTIVITIES_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updateFuelAndEnergyRelatedActivities: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.UPDATE_FUEL_AND_ENERGY_RELATED_ACTIVITIES_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          updateFuelAndEnergyRelatedActivities: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),
      [ActionTypes.ADD_USE_OF_SOLD_PRODUCTS]: (state, { payload }) =>
        immutable(state, {
          addUseOfSoldProducts: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_USE_OF_SOLD_PRODUCTS_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          addUseOfSoldProducts: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.ADD_USE_OF_SOLD_PRODUCTS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addUseOfSoldProducts: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.ADD_UPSTREAM_LEASED_ASSET]: (state, { payload }) =>
        immutable(state, {
          addUpstreamLeasedAsset: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_UPSTREAM_LEASED_ASSET_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          addUpstreamLeasedAsset: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.ADD_UPSTREAM_LEASED_ASSET_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addUpstreamLeasedAsset: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.UPDATE_UPSTREAM_LEASED_ASSET]: (state, { payload }) =>
        immutable(state, {
          updateUpstreamLeasedAsset: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_UPSTREAM_LEASED_ASSET_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updateUpstreamLeasedAsset: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.UPDATE_UPSTREAM_LEASED_ASSET_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          updateUpstreamLeasedAsset: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.UPDATE_DOWNSTREAM_LEASED_ASSET]: (state, { payload }) =>
        immutable(state, {
          updateDownstreamLeasedAsset: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_DOWNSTREAM_LEASED_ASSET_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updateDownstreamLeasedAsset: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.UPDATE_DOWNSTREAM_LEASED_ASSET_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          updateDownstreamLeasedAsset: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.UPDATE_FRANCHISE]: (state, { payload }) =>
        immutable(state, {
          updateFranchise: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_FRANCHISE_SUCCESS]: (state, { payload, save }) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updateFranchise: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.UPDATE_FRANCHISE_FAILURE]: (state, { payload }) =>
        immutable(state, {
          updateFranchise: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.ADD_DOWNSTREAM_LEASED_ASSET]: (state, { payload }) =>
        immutable(state, {
          addDownstreamLeasedAsset: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_DOWNSTREAM_LEASED_ASSET_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          addDownstreamLeasedAsset: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.ADD_DOWNSTREAM_LEASED_ASSET_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addDownstreamLeasedAsset: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.ADD_END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          addEndOfLifeTreatmentOfSoldProducts: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          addEndOfLifeTreatmentOfSoldProducts: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.ADD_END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          addEndOfLifeTreatmentOfSoldProducts: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),
      [ActionTypes.UPDATE_END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          updateEndOfLifeTreatmentOfSoldProducts: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updateEndOfLifeTreatmentOfSoldProducts: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.UPDATE_END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS_FAILUREE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          updateEndOfLifeTreatmentOfSoldProducts: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

      [ActionTypes.ADD_PROCESSING_OF_SOLD_PRODUCTS]: (state, { payload }) =>
        immutable(state, {
          addProcessingOfSoldProducts: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_PROCESSING_OF_SOLD_PRODUCTS_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          addProcessingOfSoldProducts: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.ADD_PROCESSING_OF_SOLD_PRODUCTS_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          addProcessingOfSoldProducts: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),
      [ActionTypes.UPDATE_PROCESSING_OF_SOLD_PRODUCTS]: (state, { payload }) =>
        immutable(state, {
          updateProcessingOfSoldProducts: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_PROCESSING_OF_SOLD_PRODUCTS_SUCCESS]: (
        state,
        { payload, save }
      ) => {
        let status = save ? STATUS.SUCCESS : STATUS.IDLE;
        return immutable(state, {
          updateProcessingOfSoldProducts: {
            data: { $set: payload },
            status: { $set: status },
            isCalculateDone: { $set: !save },
          },
        });
      },
      [ActionTypes.UPDATE_PROCESSING_OF_SOLD_PRODUCTS_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          updateProcessingOfSoldProducts: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),
    },

    emissionState
  ),
};

export default emissionActions;
