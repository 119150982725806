import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Chip,
  CircularProgress,
  Container,
  Pagination,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import useStyles from "./styles";
import {
  auditorReaction,
  clearEmissionList,
  getEmissionList,
  resetAuditedData,
} from "../../../../redux/actions";
import CeroButton from "../../../../components/CeroButton";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import { STATUS } from "../../../../redux/constants";
import EmployeeHireTable from "../../../EmissionList/EmployeeHireTable";
import { sampleYearArray } from "../../../../constants";
import { rolesEnum } from "../../../../layouts/DashboardLayout/pages";
import CeroCheckboxTable from "../../../../components/CeroCheckboxTable";
import { getCookie } from "../../../../services/cookie";
import {
  MobileColumns,
  PurchasedElectricityColumns,
  RefrigerantsColumns,
  StationaryColumns,
  TransportationColumns,
  WasteCombustionColumns,
  WaterConsumptionColumns,
  WaterDischargeColumns,
} from "./AuditdataColumns";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import StatusCard from "../AuditDetailsStatus";
import PoliticalContributionsApproval from "../../../ApprovalDetailsCard/PoliticalContributionsApproval";
import EmployeeHealthApproval from "../../../ApprovalDetailsCard/EmployeeHealthApproval";
import PerformanceAndCareerApproval from "../../../ApprovalDetailsCard/PerformanceAndCareerApproval";
import TrainingHoursApproval from "../../../ApprovalDetailsCard/TrainingHoursApproval";
import DiscriminationIncidentApproval from "../../../ApprovalDetailsCard/DiscriminationIncidentApproval";
import WorkerSafetyApproval from "../../../ApprovalDetailsCard/WorkerSafetyApproval";
import EmployeeBenefitsApproval from "../../../ApprovalDetailsCard/EmployeeBenefitsApproval";
import CeroPopUp from "../../../../components/CeroPopUp";
import EmployeeDiversityApproval from "../../../ApprovalDetailsCard/EmployeeDiversityApproval";
import SupplierScreeningApproval from "../../../ApprovalDetailsCard/SupplierScreeningApproval";
import SupplierHumanRightsApproval from "../../../ApprovalDetailsCard/SupplierHumanRightsApproval";
import LocalCommunitiesApproval from "../../../ApprovalDetailsCard/LocalCommunitiesApproval";
import SocialHumanRightsApproval from "../../../ApprovalDetailsCard/SocialHumanRightsApproval";
import AntiCorruptionDisclosureApproval from "../../../ApprovalDetailsCard/AntiCorruptionDisclosure";
import ManagementDiversityApproval from "../../../ApprovalDetailsCard/MangementDiversity";
import BoardDiversityApproval from "../../../ApprovalDetailsCard/BoardDiversity";
import AntiCompatitiveDisclosureApproval from "../../../ApprovalDetailsCard/AntiCompatitiveDisclosure";
import EconomicImpactApproval from "../../../ApprovalDetailsCard/EconomicImpact";
import SubsidiesAndFinancialAssistanceApproval from "../../../ApprovalDetailsCard/SubsidiesAndFinancialAssistance";
import AntiCorruptionTrainingApproval from "../../../ApprovalDetailsCard/AntiCorruptionTraining";
import EmployeeHireApproval from "../../../ApprovalDetailsCard/EmployeeHireAndTurnOver/index.jsx";
import {
  UpstreamDistributionAverage,
  UpstreamDistributionSite,
  UpstreamTransportationDistance,
  UpstreamTransportationFuel,
  UpstreamTransportationRefrigerant,
  UpstreamTransportationSpend,
  BusinessTravelDistance,
  BusinessTravelFuel,
  BusinessTravelSpend,
  EmployeeCommuteAverage,
  EmployeeCommuteDistance,
  EmployeeCommuteFuel,
  PurchasedGoodsSupplierSpecific,
  PurchasedGoodsAverageData,
  PurchasedGoodsSpend,
  CapitalGoodsAverage,
  CapitalGoodsSupplier,
  CapitalGoodsSpend,
  FuelEnergyPurchasedFules,
  FuelEnergyPurchasedElectricity,
  FuelEnergyTandDLosses,
  FuelEnergyPurchasedAndSold,
  UpstreamLeasedAsset,
  UpstreamLeasedAverage1,
  UpstreamLeasedAverage2,
  DirectUsephaseFuels,
  DirectUsephaseGreenhouse,
  DirectUsephaseProducts,
  IndirectUsephaseCalculation,
  IndirectUseOfEmission,
  ProcessingOfSoldProductsAverage,
  ProcessingOfSoldProductsSite,
  InvestmentSpecific,
  AverageDataMethod1,
  ProjectSpecificMethod,
  AverageDataMethod2,
  LifetimeEmission,
} from "../../../ApprovalStatus/MonthlyFacilityDetails/ApprovalStatusScope3Table/ApprovalTableColumns";

import { useSnackbar } from "notistack";
import { EndOfLifeTreatmentColumns } from "../../../ApprovalStatus/MonthlyFacilityDetails/ApprovalStatusTable/ApprovalTableColumns";

const DEFAULT_ITEMS_PER_PAGE = 20;

const CompanyEmissionList = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id, type } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const selectedMonth = queryParams.get("month");
  const selectedFacility = queryParams.get("facility");
  const selectedYear = queryParams.get("year");
  const company = queryParams.get("company");
  const period = queryParams.get("period");
  const country = queryParams.get("country");

  const [canShowReviewButton, setCanShowReviewButton] = useState(true);
  const [page, setPage] = useState(1);
  const [auditConfirm, setAuditConfirm] = useState(false);
  const [reviewId, setReviewId] = useState([]);
  const [comment, setComment] = useState("");
  const [auditId, setAuditId] = useState([]);
  const [reviewPopup, setReviewPopup] = useState(false);
  const [checkedRows, setCheckedRows] = useState([]);
  const [filteredData, setFilteredData] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isApprovalAll, setIsApprovalAll] = useState(false);
  const [sortDataAscending, setSortDataAscending] = useState(false);
  const [sortDataDescending, setSortDataDescending] = useState(false);

  const emissionData = useSelector(
    (state) => state.emission.emissionList.data.emissions
  );
  const totalEntries = useSelector(
    (state) => state.emission.emissionList.data.total_count
  );
  const emissionDataStatus = useSelector(
    (state) => state.emission.emissionList.status
  );
  const auditedDataStatus = useSelector((state) => state.audit.auditorReaction);
  const role = useSelector((state) => state.auth.role);

  const dataCount =
    emissionData?.length ||
    emissionData?.based_on_gender?.length ||
    emissionData?.gender_distribution?.length;

  const isAuditor = role.includes(rolesEnum.AUDITOR);
  const isOnlyAdmin =
    (role.includes(rolesEnum.ADMIN) && role.length === 1) || role === "admin";

  const emissionType = type;
  const getTableColumn = {
    stationary_combustion: StationaryColumns,
    mobile_combustion: MobileColumns,
    purchased_electricity: PurchasedElectricityColumns,
    water_discharge: WaterDischargeColumns,
    water_consumption: WaterConsumptionColumns,
    refrigerants: RefrigerantsColumns,
    transportation: TransportationColumns,
    waste: WasteCombustionColumns,
    end_of_life_treatment: EndOfLifeTreatmentColumns,
  };

  var x = sessionStorage.getItem("year");
  const [filter, setFilter] = useState({
    month: selectedMonth,
    year: selectedYear || Number(x) || sampleYearArray[0].key,
    facility_id: selectedFacility,
  });

  const distributionIds = {};
  for (const key in emissionData) {
    if (
      Object.prototype.hasOwnProperty.call(emissionData, key) &&
      Array.isArray(emissionData[key])
    ) {
      distributionIds[key] = emissionData[key].map((item) => item.id);
    }
  }

  const filteredIds = Object.values(distributionIds).flat();
  const duplicateIds = filteredIds.filter(
    (item, index) => filteredIds.indexOf(item) === index
  );
  const topicIds = Array.isArray(emissionData)
    ? emissionData && emissionData?.map((item) => item.id)
    : duplicateIds;

  useEffect(() => {
    onLoadMore(DEFAULT_ITEMS_PER_PAGE, page);
    return () => {
      clearEmissionList();
    };
  }, [filter]);

  const onLoadMore = (limit, pageNumber) => {
    const skip = (pageNumber - 1) * 20;
    const newLimit = DEFAULT_ITEMS_PER_PAGE * pageNumber;
    const filterRequest = {
      limit: newLimit,
      skip,
      month: selectedMonth,
      year: selectedYear || Number(x) || sampleYearArray[0].key,
      facility_id: selectedFacility,
      period: period,
      country: country,
    };
    if (!filterRequest.facility_id) {
      delete filterRequest.facility_id;
    }
    dispatch(
      getEmissionList(
        emissionType,
        filterRequest,
        isAuditor,
        company,
        typeof selectedFacility === "string"
          ? selectedFacility === "null"
            ? null
            : selectedFacility.split(",")
          : selectedFacility,
        period,
        country
      )
    );
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    onLoadMore(DEFAULT_ITEMS_PER_PAGE, newPage);
  };

  const startItem = (page - 1) * DEFAULT_ITEMS_PER_PAGE + 1;
  const endItem = Math.min(
    startItem + DEFAULT_ITEMS_PER_PAGE - 1,
    totalEntries
  );

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const tableTopics = [
    "stationary_combustion",
    "mobile_combustion",
    "refrigerants",
    "transportation",
    "purchased_electricity",
    "water_discharge",
    "water_consumption",
    "waste",
    "upstream_t_and_d",
    "business_travel",
    "purchased_goods_and_services",
    "downstream_t_and_d",
    "capital_goods",
    "fuel_and_energy_related_activities",
    "employee_commute",
    "use_of_sold_products",
    "upstream_leased_asset",
    "downstream_leased_asset",
    "franchises",
    "processing_of_sold_products",
    "end_of_life_treatment",
  ].includes(emissionType);

  const multipleRecordTopics = [
    "employees_benefit",
    "employee_health_safety_incident_record",
    "worker_safety_training_procedures",
    "training_hours_per_employee",
    "performance_and_career_development_programs",
  ].includes(emissionType);

  const commonTopics = [
    "employees_hire_and_turnover",
    "employees_diversity_and_ratio_of_basic_salary",
    "board_diversity",
  ];
  const [hideButton, setHideButton] = useState(null);
  const [singleRecord, setSingleRecord] = useState(null);
  useEffect(() => {
    const hideBtn =
      emissionType === "management_diversity" ||
      emissionType === "board_diversity"
        ? emissionData?.based_on_gender?.filter(
            (item) => item.status === "approved"
          )
        : commonTopics.includes(emissionType)
        ? emissionType === "employees_hire_and_turnover"
          ? emissionData?.gender_distribution
              ?.filter((item) => item.status === "approved")
              ?.filter(
                (item, index, self) =>
                  index === self.findIndex((t) => t.id === item.id)
              )
          : emissionData?.gender_distribution?.filter(
              (item) => item.status === "approved"
            )
        : Array.isArray(emissionData)
        ? emissionData?.filter((item) => item.status === "approved")
        : null;
    const singleRec =
      hideBtn?.[0]?.id ||
      hideBtn?.based_on_gender?.[0]?.id ||
      hideBtn?.gender_distribution?.[0]?.id;
    setHideButton(hideBtn);
    setSingleRecord(singleRec);
  }, [emissionData]);

  const getHeader = () => {
    return (
      <Box className={classes.tableTopic}>
        <Typography variant="h7" component="span">
          <span className={classes.tableTitle}>
            {emissionType.replace(/_/g, " ").replace("asset", "assets")}
          </span>
        </Typography>
        <Box>
          {!tableTopics && hideButton?.length > 0 && (
            <>
              <CeroButton
                buttonText={
                  hideButton?.length > 1 ? "Validate All" : "Validate"
                }
                variant="contained"
                className={classes.validateButton}
                onClick={handleOpenPopup}
              />
              {hideButton?.length == 1 && (
                <CeroButton
                  buttonText="Review"
                  variant="contained"
                  className={classes.buttonPrimary}
                  onClick={() => handleReviewPopup(singleRecord)}
                />
              )}
            </>
          )}
          <CeroButton
            buttonText="Back"
            variant="outlined"
            className={classes.buttonSecondary}
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate(-1)}
          />
        </Box>
        <CeroPopUp
          primaryPopUp={{
            open: isPopupOpen,
            onClose: () => setIsPopupOpen(false),
            content: getConfirmation(),
            header: { title: "" },
          }}
          showCloseButton={true}
          classes={{ dialog: classes.dialog }}
        />
        <CeroPopUp
          primaryPopUp={{
            open: reviewPopup,
            onClose: () => setReviewPopup(false),
            content: getReviewComments(),
            header: { title: "Review Comments" },
          }}
        />
      </Box>
    );
  };

  const handleCheckedRowsChange = (newCheckedRows) => {
    console.log(newCheckedRows, "nikhil");

    setCheckedRows(newCheckedRows);
  };

  const getStatusColor = (item) => {
    if (item === "approved") {
      return (
        <Tooltip title="Approved" arrow>
          <Chip className={classes.signalApproved} />
        </Tooltip>
      );
    } else if (item === "submitted") {
      return (
        <Tooltip title="Submitted" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      );
    } else if (item === "audited") {
      return (
        <Tooltip title="Audited" arrow>
          <Chip className={classes.signalAudited} />
        </Tooltip>
      );
    } else if (item === "review") {
      return (
        <Tooltip title="Review" arrow>
          <Chip className={classes.signalReview} />
        </Tooltip>
      );
    } else if (item === "verified") {
      return (
        <Tooltip title="Verified" arrow>
          <Chip className={classes.signalVerified} />
        </Tooltip>
      );
    } else if (item === "added") {
      return (
        <Tooltip title="Added" arrow>
          <Chip className={classes.signalAdded} />
        </Tooltip>
      );
    } else if (item === "assigned") {
      return (
        <Tooltip title="assigned" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      );
    } else if (item === "Awaiting approval") {
      return (
        <Tooltip title="Awaiting approval" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      );
    } else if (item === "In Progress") {
      return (
        <Tooltip title="In Progress" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      );
    } else if (item === "pending_approval") {
      return (
        <Tooltip title="Pending Approval" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={item} arrow>
          <Chip className={classes.signalAdded} />
          {item}
        </Tooltip>
      );
    }
  };

  const handleFilterSelect = (value) => {
    setFilteredData(value);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setReviewPopup(false);
  };

  const getEmissionData = (rowData) => {
    if (!Array.isArray(rowData)) return [];
    const data = rowData.map((item) => ({
      ...item,
      status: item.status ? getStatusColor(item.status) : "",
      treatment_required: item.treatment_required ? "Yes" : "No",
    }));
    if (filteredData) {
      return data.filter((item) => item.status?.props?.title === filteredData);
    }
    if (sortDataAscending) {
      return data.sort((a, b) =>
        a.display_status.localeCompare(b.display_status)
      );
    } else if (sortDataDescending) {
      return data.sort((a, b) =>
        b.display_status.localeCompare(a.display_status)
      );
    }
    return data;
  };
  const typeMap = {
    upstream_t_and_d: "upstream_T&D",
    downstream_t_and_d: "downstream_T&D",
    end_of_life_treatment: "end_of_life_treatment_of_sold_products",
  };

  const onSelectEmissionData = (emission) => {
    isAuditor
      ? navigate(
          `/emissions/${typeMap[emissionType] || emissionType}/details/${
            emission.id
          }/?company=${company}`
        )
      : navigate(
          `/emissions/${typeMap[emissionType] || emissionType}/details/${
            emission.id
          }`
        );
  };
  const scope3Topics = [
    "upstream_t_and_d",
    "business_travel",
    "purchased_goods_and_services",
    "downstream_t_and_d",
    "capital_goods",
    "fuel_and_energy_related_activities",
    "employee_commute",
    "use_of_sold_products",
    "upstream_leased_asset",
    "downstream_leased_asset",
    "franchises",
    "processing_of_sold_products",
  ];

  const collectListsFromDict = (data) => {
    let TotalElementsList = [];

    for (const key in data) {
      if (Array.isArray(data[key])) {
        // If the value is an array, add its elements to TotalElementsList
        TotalElementsList = TotalElementsList.concat(data[key]);
      } else if (typeof data[key] === "object" && data[key] !== null) {
        // If the value is an object (nested dictionary), iterate through it
        for (const subKey in data[key]) {
          if (Array.isArray(data[key][subKey])) {
            TotalElementsList = TotalElementsList.concat(data[key][subKey]);
          }
        }
      }
    }

    return TotalElementsList;
  };
  console.log(emissionType, "niktype");

  const TotalElementsList = scope3Topics.includes(emissionType)
    ? collectListsFromDict(emissionData)
    : [];
  const data =
    tableTopics && emissionData
      ? scope3Topics.includes(emissionType)
        ? TotalElementsList.map((item) => item.status)
        : Array.isArray(emissionData) &&
          emissionData?.map((item) => item.status)
      : undefined;

  const hasApprove = data && data?.includes("approved");

  const allVerified = tableTopics && !hasApprove;

  const getIcon = () => {
    return (
      <>
        <Tooltip title="Validate" arrow>
          <CheckCircleOutlineOutlinedIcon
            onClick={handleOpenPopup}
            className={classes.icon}
          />
        </Tooltip>
        {canShowReviewButton && (
          <Tooltip title="Review" arrow>
            <ArrowCircleLeftOutlinedIcon
              onClick={handleReviewPopup}
              className={classes.icon}
            />
          </Tooltip>
        )}
        <CeroPopUp
          primaryPopUp={{
            open: isPopupOpen,
            onClose: () => setIsPopupOpen(false),
            content: getConfirmation(),
            header: { title: "" },
          }}
          showCloseButton={true}
          classes={{ dialog: classes.dialog }}
        />
        <CeroPopUp
          primaryPopUp={{
            open: reviewPopup,
            onClose: () => setReviewPopup(false),
            content: getReviewComments(),
            header: { title: "Review Comments" },
          }}
        />
      </>
    );
  };

  const handleAudit = (item) => {
    setAuditConfirm(true);
    setAuditId(item);
  };

  const handleReviewPopup = (item) => {
    setReviewPopup(true);
    setReviewId(item);
    setAuditId(item);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const onSubmitReview = () => {
    setReviewPopup(false);
    if (tableTopics) {
      dispatch(auditorReaction(company, id, checkedRows, comment, "review"));
    } else if (multipleRecordTopics && emissionData?.length > 1) {
      dispatch(auditorReaction(company, id, [reviewId], comment, "review"));
    } else if (multipleRecordTopics && emissionData?.length === 1) {
      dispatch(auditorReaction(company, id, topicIds, comment, "review"));
    } else if (reviewId) {
      dispatch(auditorReaction(company, id, [reviewId], comment, "review"));
    } else {
      dispatch(auditorReaction(company, id, topicIds, comment, "review"));
    }
    setComment("");
  };

  const handleIndividualApprove = () => {
    dispatch(auditorReaction(company, id, [auditId], comment, "verified"));
  };

  const handleAction = () => {
    setIsApprovalAll(true);
    if (tableTopics) {
      dispatch(auditorReaction(company, id, checkedRows, comment, "verified"));
    } else {
      dispatch(auditorReaction(company, id, topicIds, comment, "verified"));
    }
    handleClosePopup();
  };

  useEffect(() => {
    if (auditedDataStatus.status === STATUS.SUCCESS) {
      enqueueSnackbar(auditedDataStatus?.data?.message, { variant: "success" });
      const filterRequest = {
        month: selectedMonth,
        year: selectedYear || Number(x) || sampleYearArray[0].key,
        facility_id: selectedFacility,
        period: period,
        country: country,
      };
      dispatch(
        getEmissionList(
          emissionType,
          filterRequest,
          isAuditor,
          company,
          typeof selectedFacility === "string"
            ? selectedFacility === "null"
              ? null
              : selectedFacility.split(",")
            : selectedFacility,
          period,
          country
        )
      );
      dispatch(resetAuditedData());
    } else if (auditedDataStatus.status === STATUS.ERROR) {
      dispatch(resetAuditedData());
      enqueueSnackbar(
        auditedDataStatus.data.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
    }
  }, [
    enqueueSnackbar,
    auditedDataStatus,
    dispatch,
    id,
    type,
    dataCount,
    company,
  ]);

  const getConfirmation = () => {
    return (
      <Box className={classes.popUp}>
        <Typography variant="body1" style={{ fontWeight: 500 }}>
          Are you sure you want to validate all records?
        </Typography>
        <div className={classes.buttonContainerReview}>
          <CeroButton
            className={classes.buttonPrimary}
            variant="contained"
            onClick={handleAction}
            buttonText="Yes"
          />
          <CeroButton
            className={classes.buttonSecondary}
            variant="outlined"
            onClick={handleClosePopup}
            buttonText="No"
          />
        </div>
      </Box>
    );
  };

  const getReviewComments = () => {
    return (
      <>
        <TextField
          id="outlined-multiline-static"
          multiline
          rows={5}
          placeholder="Comment"
          style={{ width: "100%" }}
          value={comment}
          onChange={handleCommentChange}
          className={classes.textField}
        />
        <div className={classes.textLimit}>
          <div className={classes.noComments}>
            {comment.trim().length === 0 ? "No comments" : ""}
          </div>
          <div className={classes.maxCharacters}>
            {"Maximum 200 characters"}
          </div>
        </div>
        <div className={classes.reviewButtons}>
          <CeroButton
            className={classes.buttonPrimary}
            variant="contained"
            onClick={onSubmitReview}
            buttonText="Save"
          />
          <CeroButton
            className={classes.buttonSecondary}
            variant="outlined"
            onClick={() => setReviewPopup(false)}
            buttonText="Cancel"
          />
        </div>
      </>
    );
  };

  const getAuditConfirm = () => {
    return (
      <Box className={classes.popUp}>
        <Typography variant="body1" style={{ fontWeight: 500 }}>
          Are you sure you want to approve?
        </Typography>
        <div className={classes.buttonContainerReview}>
          <CeroButton
            className={classes.buttonPrimary}
            variant="contained"
            onClick={() => {
              setAuditConfirm(false);
              handleIndividualApprove();
            }}
            buttonText="Yes"
          />
          <CeroButton
            className={classes.buttonSecondary}
            variant="outlined"
            onClick={() => setAuditConfirm(false)}
            buttonText="No"
          />
        </div>
      </Box>
    );
  };

  const twoTyreTopics = [
    "upstream_t_and_d",
    "downstream_t_and_d",
    "use_of_sold_products",
    "investments",
  ];
  const scope3TopicDataMapper = {
    upstream_t_and_d: {
      transportation: {
        distance_based: {
          categoryTitle: "Upstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Transportation",
          subCategory: "Distance Based",
          tableColumns: UpstreamTransportationDistance,
        },
        fuel_based: {
          categoryTitle: "Upstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Transportation",
          subCategory: "Fuel Based",
          tableColumns: UpstreamTransportationFuel,
        },
        refrigerant_leakage: {
          categoryTitle: "Upstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Transportation",
          subCategory: "Refrigerant Leakage Based",
          tableColumns: UpstreamTransportationRefrigerant,
        },
        spend_based: {
          categoryTitle: "Upstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Transportation",
          subCategory: "Spend Based",
          tableColumns: UpstreamTransportationSpend,
        },
      },
      distribution: {
        site_specific: {
          categoryTitle: "Upstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Distribution",
          subCategory: "Site Specific Based",
          tableColumns: UpstreamDistributionSite,
        },
        average_method: {
          categoryTitle: "Upstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Distribution",
          subCategory: "Average Method Based",
          tableColumns: UpstreamDistributionAverage,
        },
      },
    },
    downstream_t_and_d: {
      transportation: {
        distance_based: {
          categoryTitle: "Downstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Transportation",
          subCategory: "Distance Based",
          tableColumns: UpstreamTransportationDistance,
        },
        fuel_based: {
          categoryTitle: "Downstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Transportation",
          subCategory: "Fuel Based",
          tableColumns: UpstreamTransportationFuel,
        },
        refrigerant_leakage: {
          categoryTitle: "Downstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Transportation",
          subCategory: "Refrigerant Leakage Based",
          tableColumns: UpstreamTransportationRefrigerant,
        },
        spend_based: {
          categoryTitle: "Downstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Transportation",
          subCategory: "Spend Based",
          tableColumns: UpstreamTransportationSpend,
        },
      },
      distribution: {
        site_specific: {
          categoryTitle: "Downstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Distribution",
          subCategory: "Site Specific Based",
          tableColumns: UpstreamDistributionSite,
        },
        average_method: {
          categoryTitle: "Downstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Distribution",
          subCategory: "Average Method Based",
          tableColumns: UpstreamDistributionAverage,
        },
      },
    },
    business_travel: {
      spend_based: {
        categoryTitle: "Calculation Method: ",
        category: "Spend Based",
        tableColumns: BusinessTravelSpend,
      },
      fuel_based: {
        categoryTitle: "Calculation Method: ",
        category: "Fuel Based",
        tableColumns: BusinessTravelFuel,
      },
      distance_based: {
        categoryTitle: "Calculation Method: ",
        category: "Distance Based",
        tableColumns: BusinessTravelDistance,
      },
    },
    employee_commute: {
      average_method: {
        categoryTitle: "Calculation Method: ",
        category: "average data method",
        tableColumns: EmployeeCommuteAverage,
      },
      distance_based: {
        categoryTitle: "Calculation Method: ",
        category: "distance based",
        tableColumns: EmployeeCommuteDistance,
      },
      fuel_based: {
        categoryTitle: "Calculation Method: ",
        category: "fuel based",
        tableColumns: EmployeeCommuteFuel,
      },
    },
    purchased_goods_and_services: {
      spend_based: {
        categoryTitle: "Calculation Method: ",
        category: "Spend Based",
        tableColumns: PurchasedGoodsSpend,
      },
      average_method: {
        categoryTitle: "Calculation Method: ",
        category: "Average Data Method",
        tableColumns: PurchasedGoodsAverageData,
      },
      supplier_specific: {
        categoryTitle: "Calculation Method: ",
        category: "Supplier-specific Method",
        tableColumns: PurchasedGoodsSupplierSpecific,
      },
    },
    capital_goods: {
      spend_based: {
        categoryTitle: "Calculation Method: ",
        category: "Spend Based",
        tableColumns: CapitalGoodsSpend,
      },
      supplier_specific: {
        categoryTitle: "Calculation Method: ",
        category: "Supplier specific",
        tableColumns: CapitalGoodsSupplier,
      },
      average_method: {
        categoryTitle: "Calculation Method: ",
        category: "Average method",
        tableColumns: CapitalGoodsAverage,
      },
    },
    fuel_and_energy_related_activities: {
      purchased_fuel: {
        categoryTitle: "Calculation Method: ",
        category: "Upstream emissions of purchased fuels",
        tableColumns: FuelEnergyPurchasedFules,
      },
      purchased_electricity: {
        categoryTitle: "Calculation Method: ",
        category: "Upstream emissions of purchased electricity",
        tableColumns: FuelEnergyPurchasedElectricity,
      },
      transmission_distribution_loss: {
        categoryTitle: "Calculation Method: ",
        category: "Emissions from transmission and distribution losses",
        tableColumns: FuelEnergyTandDLosses,
      },
      life_cycle_emission: {
        categoryTitle: "Calculation Method: ",
        category: "Life cycle emissions from power that is purchased and sold",
        tableColumns: FuelEnergyPurchasedAndSold,
      },
    },
    upstream_leased_asset: {
      asset_specific: {
        categoryTitle: "Calculation Method:",
        category: "Asset specific method",
        tableColumns: UpstreamLeasedAsset,
      },
      lessor_specific: {
        categoryTitle: "Calculation Method:",
        category: "Lessor specific method",
        tableColumns: UpstreamLeasedAsset,
      },
      average_method_type_1: {
        categoryTitle: "Calculation Method:",
        category: "Average data method type 1",
        tableColumns: UpstreamLeasedAverage1,
      },
      average_method_type_2: {
        categoryTitle: "Calculation Method:",
        category: "Average data method type 2",
        tableColumns: UpstreamLeasedAverage2,
      },
    },
    downstream_leased_asset: {
      asset_specific: {
        categoryTitle: "Calculation Method:",
        category: "Asset specific method",
        tableColumns: UpstreamLeasedAsset,
      },
      lessor_specific: {
        categoryTitle: "Calculation Method:",
        category: "Lessor specific method",
        tableColumns: UpstreamLeasedAsset,
      },
      average_method_type_1: {
        categoryTitle: "Calculation Method:",
        category: "Average data method type 1",
        tableColumns: UpstreamLeasedAverage1,
      },
      average_method_type_2: {
        categoryTitle: "Calculation Method:",
        category: "Average data method type 2",
        tableColumns: UpstreamLeasedAverage2,
      },
    },
    use_of_sold_products: {
      direct_use_phase: {
        fuel_feedstock: {
          categoryTitle: "Types of emissions from sold products: ",
          subCategoryTitle: "Choose the type of direct use phase emission : ",
          category: "Direct use phase emissions",
          subCategory: "Fuels and Feedstock",
          tableColumns: DirectUsephaseFuels,
        },
        greenhouse_gas_emissions: {
          categoryTitle: "Types of emissions from sold products: ",
          subCategoryTitle: "Choose the type of direct use phase emission : ",
          category: "Direct use phase emissions",
          subCategory:
            "Greenhouse gases and products that contain or form greenhouse gases that are emitted during use",
          tableColumns: DirectUsephaseGreenhouse,
        },
        products_consume_energy: {
          categoryTitle: "Types of emissions from sold products: ",
          subCategoryTitle: "Choose the type of direct use phase emission : ",
          category: "Direct use phase emissions",
          subCategory: "Products that directly consume energy during use",
          tableColumns: DirectUsephaseProducts,
        },
      },
      indirect_use_phase: {
        sold_intermediate_products: {
          categoryTitle: "Types of emissions from sold products: ",
          subCategoryTitle: "Choose the type of indirect use phase emission : ",
          category: "Indirect use phase emissions",
          subCategory: "Calculation method for sold intermediate products",
          tableColumns: IndirectUsephaseCalculation,
        },
        indirect_use_of_emission_from_products: {
          categoryTitle: "Types of emissions from sold products: ",
          subCategoryTitle: "Choose the type of indirect use phase emission : ",
          category: "Indirect use phase emissions",
          subCategory:
            "Indirect use phase emissions from products that indirectly consume energy during use",
          tableColumns: IndirectUseOfEmission,
        },
      },
    },
    processing_of_sold_products: {
      average_data: {
        categoryTitle: "Calculation Method:",
        category: "Average data method",
        tableColumns: ProcessingOfSoldProductsAverage,
      },
      site_specific: {
        categoryTitle: "Calculation Method: ",
        category: "Site Specific Method",
        tableColumns: ProcessingOfSoldProductsSite,
      },
    },
    investments: {
      equity_investments: {
        investment_specific: {
          categoryTitle: "Type of financial investment: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Equity investments",
          subCategory: "Investment-specific method",
          tableColumns: InvestmentSpecific,
        },
        average_data_method: {
          categoryTitle: "Type of financial investment: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Equity investments",
          subCategory: "Average-data method",
          tableColumns: AverageDataMethod1,
        },
      },
      project_finance_and_debt: {
        project_specific: {
          categoryTitle: "Type of financial investment: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Product finance and from debt investments",
          subCategory: "Project-specific method",
          tableColumns: ProjectSpecificMethod,
        },
        average_data_method: {
          categoryTitle: "Type of financial investment: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Product finance and from debt investments",
          subCategory: "Average-data method",
          tableColumns: AverageDataMethod2,
        },
        total_lifetime_emissions: {
          categoryTitle: "Type of financial investment: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Product finance and from debt investments",
          subCategory: "Total lifetime emissions",
          tableColumns: LifetimeEmission,
        },
      },
    },
  };

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        {emissionDataStatus === STATUS.SUCCESS ? (
          <>
            {getHeader()}
            {emissionType === "employees_hire_and_turnover" ? (
              <>
                {/* <StatusCard emissionData={emissionData} emissionType={emissionType}/> */}
                <EmployeeHireApproval
                  emissionData={emissionData}
                  dataStatus={emissionDataStatus}
                  onLoadMore={onLoadMore}
                  emissionType={emissionType}
                  isAuditor={isAuditor}
                  company={company}
                  isOnlyAdmin={!isOnlyAdmin}
                  period={period}
                  isDeleteActionRequired={true}
                  handleCallback={handleAudit}
                  handleReviewCallback={handleReviewPopup}
                />
                <CeroPopUp
                  primaryPopUp={{
                    open: auditConfirm,
                    onClose: () => setAuditConfirm(false),
                    content: getAuditConfirm(),
                    header: { title: "" },
                  }}
                  showCloseButton={true}
                  classes={{ dialog: classes.dialog }}
                />
              </>
            ) : emissionType === "employees_benefit" ? (
              <>
                <EmployeeBenefitsApproval
                  emissionData={emissionData}
                  handleCallback={handleAudit}
                  handleReviewCallback={handleReviewPopup}
                />
                <CeroPopUp
                  primaryPopUp={{
                    open: auditConfirm,
                    onClose: () => setAuditConfirm(false),
                    content: getAuditConfirm(),
                    header: { title: "" },
                  }}
                  showCloseButton={true}
                  classes={{ dialog: classes.dialog }}
                />
              </>
            ) : emissionType === "employee_health_safety_incident_record" ? (
              <>
                <EmployeeHealthApproval
                  emissionData={emissionData}
                  handleCallback={handleAudit}
                  handleReviewCallback={handleReviewPopup}
                />
                <CeroPopUp
                  primaryPopUp={{
                    open: auditConfirm,
                    onClose: () => setAuditConfirm(false),
                    content: getAuditConfirm(),
                    header: { title: "" },
                  }}
                  showCloseButton={true}
                  classes={{ dialog: classes.dialog }}
                />
              </>
            ) : emissionType === "worker_safety_training_procedures" ? (
              <>
                <WorkerSafetyApproval
                  emissionData={emissionData}
                  handleCallback={handleAudit}
                  handleReviewCallback={handleReviewPopup}
                />
                <CeroPopUp
                  primaryPopUp={{
                    open: auditConfirm,
                    onClose: () => setAuditConfirm(false),
                    content: getAuditConfirm(),
                    header: { title: "" },
                  }}
                  showCloseButton={true}
                  classes={{ dialog: classes.dialog }}
                />
              </>
            ) : emissionType === "discrimination_incident_record" ? (
              <>
                <DiscriminationIncidentApproval
                  emissionData={emissionData}
                  handleCallback={handleAudit}
                  handleReviewCallback={handleReviewPopup}
                />
                <CeroPopUp
                  primaryPopUp={{
                    open: auditConfirm,
                    onClose: () => setAuditConfirm(false),
                    content: getAuditConfirm(),
                    header: { title: "" },
                  }}
                  showCloseButton={true}
                  classes={{ dialog: classes.dialog }}
                />
              </>
            ) : emissionType === "training_hours_per_employee" ? (
              <>
                <TrainingHoursApproval
                  emissionData={emissionData}
                  handleCallback={handleAudit}
                  handleReviewCallback={handleReviewPopup}
                />
                <CeroPopUp
                  primaryPopUp={{
                    open: auditConfirm,
                    onClose: () => setAuditConfirm(false),
                    content: getAuditConfirm(),
                    header: { title: "" },
                  }}
                  showCloseButton={true}
                  classes={{ dialog: classes.dialog }}
                />
              </>
            ) : emissionType ===
              "performance_and_career_development_programs" ? (
              <>
                <PerformanceAndCareerApproval
                  emissionData={emissionData}
                  handleCallback={handleAudit}
                  handleReviewCallback={handleReviewPopup}
                />
                <CeroPopUp
                  primaryPopUp={{
                    open: auditConfirm,
                    onClose: () => setAuditConfirm(false),
                    content: getAuditConfirm(),
                    header: { title: "" },
                  }}
                  showCloseButton={true}
                  classes={{ dialog: classes.dialog }}
                />
              </>
            ) : emissionType ===
              "employees_diversity_and_ratio_of_basic_salary" ? (
              <>
                {/* <StatusCard emissionData={emissionData} emissionType={emissionType}/> */}
                <EmployeeDiversityApproval
                  emissionData={emissionData}
                  handleCallback={handleAudit}
                  handleReviewCallback={handleReviewPopup}
                />
                <CeroPopUp
                  primaryPopUp={{
                    open: auditConfirm,
                    onClose: () => setAuditConfirm(false),
                    content: getAuditConfirm(),
                    header: { title: "" },
                  }}
                  showCloseButton={true}
                  classes={{ dialog: classes.dialog }}
                />
              </>
            ) : emissionType ===
              "supplier_screening_using_enviormental_social_criteria" ? (
              <>
                {/* <StatusCard emissionData={emissionData} emissionType={emissionType}/> */}
                <SupplierScreeningApproval
                  emissionData={emissionData}
                  handleCallback={handleAudit}
                  handleReviewCallback={handleReviewPopup}
                />
                <CeroPopUp
                  primaryPopUp={{
                    open: auditConfirm,
                    onClose: () => setAuditConfirm(false),
                    content: getAuditConfirm(),
                    header: { title: "" },
                  }}
                  showCloseButton={true}
                  classes={{ dialog: classes.dialog }}
                />
              </>
            ) : emissionType === "suppliers_human_rights" ? (
              <>
                {/* <StatusCard emissionData={emissionData} emissionType={emissionType}/> */}
                <SupplierHumanRightsApproval
                  emissionData={emissionData}
                  handleCallback={handleAudit}
                  handleReviewCallback={handleReviewPopup}
                />
                <CeroPopUp
                  primaryPopUp={{
                    open: auditConfirm,
                    onClose: () => setAuditConfirm(false),
                    content: getAuditConfirm(),
                    header: { title: "" },
                  }}
                  showCloseButton={true}
                  classes={{ dialog: classes.dialog }}
                />
              </>
            ) : emissionType === "local_communities" ? (
              <>
                {/* <StatusCard emissionData={emissionData} emissionType={emissionType}/> */}
                <LocalCommunitiesApproval
                  emissionData={emissionData}
                  handleCallback={handleAudit}
                  handleReviewCallback={handleReviewPopup}
                />
                <CeroPopUp
                  primaryPopUp={{
                    open: auditConfirm,
                    onClose: () => setAuditConfirm(false),
                    content: getAuditConfirm(),
                    header: { title: "" },
                  }}
                  showCloseButton={true}
                  classes={{ dialog: classes.dialog }}
                />
              </>
            ) : emissionType === "social_engagement_human_rights_training" ? (
              <>
                {/* <StatusCard emissionData={emissionData} emissionType={emissionType}/> */}
                <SocialHumanRightsApproval
                  emissionData={emissionData}
                  handleCallback={handleAudit}
                  handleReviewCallback={handleReviewPopup}
                />
                <CeroPopUp
                  primaryPopUp={{
                    open: auditConfirm,
                    onClose: () => setAuditConfirm(false),
                    content: getAuditConfirm(),
                    header: { title: "" },
                  }}
                  showCloseButton={true}
                  classes={{ dialog: classes.dialog }}
                />
              </>
            ) : emissionType === "political_contributions" ? (
              <>
                {/* <StatusCard emissionData={emissionData} emissionType={emissionType}/> */}
                <PoliticalContributionsApproval
                  emissionData={emissionData}
                  handleCallback={handleAudit}
                  handleReviewCallback={handleReviewPopup}
                />
                <CeroPopUp
                  primaryPopUp={{
                    open: auditConfirm,
                    onClose: () => setAuditConfirm(false),
                    content: getAuditConfirm(),
                    header: { title: "" },
                  }}
                  showCloseButton={true}
                  classes={{ dialog: classes.dialog }}
                />
              </>
            ) : emissionType === "social_engagement_human_rights_training" ? (
              <>
                {/* <StatusCard emissionData={emissionData} emissionType={emissionType}/> */}
                <SocialHumanRightsApproval
                  emissionData={emissionData}
                  handleCallback={handleAudit}
                  handleReviewCallback={handleReviewPopup}
                />
                <CeroPopUp
                  primaryPopUp={{
                    open: auditConfirm,
                    onClose: () => setAuditConfirm(false),
                    content: getAuditConfirm(),
                    header: { title: "" },
                  }}
                  showCloseButton={true}
                  classes={{ dialog: classes.dialog }}
                />
              </>
            ) : emissionType === "board_diversity" ? (
              <>
                {/* <StatusCard emissionData={emissionData} emissionType={emissionType}/> */}
                <BoardDiversityApproval
                  emissionData={emissionData}
                  handleCallback={handleAudit}
                  handleReviewCallback={handleReviewPopup}
                />
                <CeroPopUp
                  primaryPopUp={{
                    open: auditConfirm,
                    onClose: () => setAuditConfirm(false),
                    content: getAuditConfirm(),
                    header: { title: "" },
                  }}
                  showCloseButton={true}
                  classes={{ dialog: classes.dialog }}
                />
              </>
            ) : emissionType === "management_diversity" ? (
              <>
                {/* <StatusCard emissionData={emissionData} emissionType={emissionType}/> */}
                <ManagementDiversityApproval
                  emissionData={emissionData}
                  handleCallback={handleAudit}
                  handleReviewCallback={handleReviewPopup}
                />
                <CeroPopUp
                  primaryPopUp={{
                    open: auditConfirm,
                    onClose: () => setAuditConfirm(false),
                    content: getAuditConfirm(),
                    header: { title: "" },
                  }}
                  showCloseButton={true}
                  classes={{ dialog: classes.dialog }}
                />
              </>
            ) : emissionType === "anti_corruption_disclosure" ? (
              <>
                {/* <StatusCard emissionData={emissionData} emissionType={emissionType}/> */}
                <AntiCorruptionDisclosureApproval
                  emissionData={emissionData}
                  handleCallback={handleAudit}
                  handleReviewCallback={handleReviewPopup}
                />
                <CeroPopUp
                  primaryPopUp={{
                    open: auditConfirm,
                    onClose: () => setAuditConfirm(false),
                    content: getAuditConfirm(),
                    header: { title: "" },
                  }}
                  showCloseButton={true}
                  classes={{ dialog: classes.dialog }}
                />
              </>
            ) : emissionType === "anti_competitive_disclosure" ? (
              <>
                {/* <StatusCard emissionData={emissionData} emissionType={emissionType}/> */}
                <AntiCompatitiveDisclosureApproval
                  emissionData={emissionData}
                  handleCallback={handleAudit}
                  handleReviewCallback={handleReviewPopup}
                />
                <CeroPopUp
                  primaryPopUp={{
                    open: auditConfirm,
                    onClose: () => setAuditConfirm(false),
                    content: getAuditConfirm(),
                    header: { title: "" },
                  }}
                  showCloseButton={true}
                  classes={{ dialog: classes.dialog }}
                />
              </>
            ) : emissionType === "economic_impact" ? (
              <>
                {/* <StatusCard emissionData={emissionData} emissionType={emissionType}/> */}
                <EconomicImpactApproval
                  emissionData={emissionData}
                  handleCallback={handleAudit}
                  handleReviewCallback={handleReviewPopup}
                />
                <CeroPopUp
                  primaryPopUp={{
                    open: auditConfirm,
                    onClose: () => setAuditConfirm(false),
                    content: getAuditConfirm(),
                    header: { title: "" },
                  }}
                  showCloseButton={true}
                  classes={{ dialog: classes.dialog }}
                />
              </>
            ) : emissionType === "subsidies_financial_assistance" ? (
              <>
                {/* <StatusCard emissionData={emissionData} emissionType={emissionType}/> */}
                <SubsidiesAndFinancialAssistanceApproval
                  emissionData={emissionData}
                  handleCallback={handleAudit}
                  handleReviewCallback={handleReviewPopup}
                />
                <CeroPopUp
                  primaryPopUp={{
                    open: auditConfirm,
                    onClose: () => setAuditConfirm(false),
                    content: getAuditConfirm(),
                    header: { title: "" },
                  }}
                  showCloseButton={true}
                  classes={{ dialog: classes.dialog }}
                />
              </>
            ) : emissionType === "anti_corruption_training" ? (
              <>
                {/* <StatusCard emissionData={emissionData} emissionType={emissionType}/> */}
                <AntiCorruptionTrainingApproval
                  emissionData={emissionData}
                  handleCallback={handleAudit}
                  handleReviewCallback={handleReviewPopup}
                />
                <CeroPopUp
                  primaryPopUp={{
                    open: auditConfirm,
                    onClose: () => setAuditConfirm(false),
                    content: getAuditConfirm(),
                    header: { title: "" },
                  }}
                  showCloseButton={true}
                  classes={{ dialog: classes.dialog }}
                />
              </>
            ) : [
                "upstream_t_and_d",
                "business_travel",
                "purchased_goods_and_services",
                "downstream_t_and_d",
                "capital_goods",
                "fuel_and_energy_related_activities",
                "employee_commute",
                "use_of_sold_products",
                "upstream_leased_asset",
                "downstream_leased_asset",
                "franchises",
                "processing_of_sold_products",
              ].includes(emissionType) ? (
              twoTyreTopics.includes(type) ? (
                Object.keys(scope3TopicDataMapper[type]).map((key) =>
                  Object.keys(scope3TopicDataMapper[type][key]).map(
                    (subKey) => {
                      if (emissionData[key]?.[subKey]?.length > 0) {
                        return (
                          <div key={`${key}-${subKey}`}>
                            <div className={classes.subHeads}>
                              <Typography variant="h7" component="span">
                                <span className={classes.tableSubTitle}>
                                  {`${scope3TopicDataMapper[type][key][subKey]["categoryTitle"]} 
                          ${scope3TopicDataMapper[type][key][subKey]["category"]}`}
                                </span>
                              </Typography>
                              <br />
                            </div>
                            <div className={classes.subHeads}>
                              <Typography variant="h7" component="span">
                                <span className={classes.tableSubTitle}>
                                  {`${scope3TopicDataMapper[type][key][subKey]["subCategoryTitle"]} 
                          ${scope3TopicDataMapper[type][key][subKey]["subCategory"]}`}
                                </span>
                              </Typography>
                            </div>
                            <CeroCheckboxTable
                              columns={
                                scope3TopicDataMapper[type][key][subKey][
                                  "tableColumns"
                                ] || []
                              }
                              data={getEmissionData(
                                emissionData[key]?.[subKey]
                              )}
                              hasMore={false}
                              loading={false}
                              loadMore={onLoadMore}
                              classes={{
                                tableContainer: classes.tableContainer,
                              }}
                              showSelectAllCheckbox={!allVerified}
                              isSelectable
                              showDropdown={true}
                              checkedRows={checkedRows}
                              onCheckedRowsChange={handleCheckedRowsChange}
                              showIcon={!allVerified && getIcon()}
                              showFilterIcon={true}
                              onSelectRow={onSelectEmissionData}
                              onFilterSelect={handleFilterSelect}
                              setSortDataAscending={setSortDataAscending}
                              setSortDataDescending={setSortDataDescending}
                              sortDataAscending={sortDataAscending}
                              sortDataDescending={sortDataDescending}
                              showSort={true}
                            />
                          </div>
                        );
                      }
                    }
                  )
                )
              ) : (
                Object.keys(scope3TopicDataMapper[type]).map((key) => {
                  if (emissionData[key]?.length > 0) {
                    return (
                      <div key={`${key}`}>
                        <div className={classes.subHeads}>
                          <Typography variant="h7" component="span">
                            <span className={classes.tableSubTitle}>
                              {`${scope3TopicDataMapper[type][key]["categoryTitle"]} 
                          ${scope3TopicDataMapper[type][key]["category"]}`}
                            </span>
                          </Typography>
                          <br />
                        </div>
                        <CeroCheckboxTable
                          columns={
                            scope3TopicDataMapper[type][key]["tableColumns"] ||
                            []
                          }
                          data={getEmissionData(emissionData[key])}
                          hasMore={false}
                          loading={false}
                          loadMore={onLoadMore}
                          classes={{ tableContainer: classes.tableContainer }}
                          showSelectAllCheckbox={!allVerified}
                          isSelectable
                          showDropdown={true}
                          checkedRows={checkedRows}
                          onCheckedRowsChange={handleCheckedRowsChange}
                          showIcon={!allVerified && getIcon()}
                          showFilterIcon={true}
                          onSelectRow={onSelectEmissionData}
                          onFilterSelect={handleFilterSelect}
                          setSortDataAscending={setSortDataAscending}
                          setSortDataDescending={setSortDataDescending}
                          sortDataAscending={sortDataAscending}
                          sortDataDescending={sortDataDescending}
                          showSort={true}
                        />
                      </div>
                    );
                  }
                })
              )
            ) : (
              <>
                <CeroCheckboxTable
                  columns={getTableColumn[type] || []}
                  data={getEmissionData(emissionData)}
                  hasMore={false}
                  loading={false}
                  loadMore={onLoadMore}
                  classes={{ tableContainer: classes.tableContainer }}
                  showSelectAllCheckbox={!allVerified}
                  isSelectable
                  showDropdown={true}
                  checkedRows={checkedRows}
                  onCheckedRowsChange={handleCheckedRowsChange}
                  showIcon={!allVerified && getIcon()}
                  showFilterIcon={true}
                  onSelectRow={onSelectEmissionData}
                  onFilterSelect={handleFilterSelect}
                  setSortDataAscending={setSortDataAscending}
                  setSortDataDescending={setSortDataDescending}
                  sortDataAscending={sortDataAscending}
                  sortDataDescending={sortDataDescending}
                  showSort={true}
                />
                <CeroPopUp
                  primaryPopUp={{
                    open: auditConfirm,
                    onClose: () => setAuditConfirm(false),
                    content: getAuditConfirm(),
                    header: { title: "" },
                  }}
                  classes={{ dialog: classes.dialog }}
                />
                {totalEntries > 0 && (
                  <div className={classes.pagination}>
                    <Typography variant="body" style={{ fontSize: 12 }}>
                      Showing {startItem} to {endItem} of {totalEntries} entries
                    </Typography>
                    <Pagination
                      count={Math.ceil(totalEntries / DEFAULT_ITEMS_PER_PAGE)}
                      page={page}
                      color="primary"
                      onChange={handlePageChange}
                    />
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        )}
      </Container>
    </DashboardLayout>
  );
};

export default CompanyEmissionList;
