import { all, put, call, takeLatest } from "redux-saga/effects";

import { request } from "../../services/client";
import { ActionTypes } from "../constants/actions";
import { APIEndpoints } from "../constants";

const uploadFileEmissionUrlMap = {
  employees_turnover: APIEndpoints.ADD_EMPLOYEES_TURNOVER,
  age_based_statistics: APIEndpoints.ADD_AGE_BASED_STATISTICS,
  gender_based_statistics: APIEndpoints.ADD_GENDER_BASED_STATISTICS,
  board_diversity: APIEndpoints.ADD_BOARD_DIVERSITY,
  management_diversity: APIEndpoints.ADD_MANAGEMENT_DIVERSITY,
  tax: APIEndpoints.ADD_TAX,
  direct_economic_impact: APIEndpoints.ADD_DIRECT_ECONOMIC_IMPACT,
  indirect_economic_impact: APIEndpoints.ADD_INDIRECT_ECONOMIC_IMPACT,
};

const nonEmissionDetailsMap = {
  employees_turnover: "employees_turnover",
  age_based_statistics: "age-based-statistics",
  gender_based_statistics: "gender-based-statistics",
  board_diversity: "board-diversity",
  management_diversity: "management-diversity",
  tax: "tax",
  direct_economic_impact: "direct_economic_impact",
  indirect_economic_impact: "indirect-economic-impact",
};

const updateNonEmissionDetailsMap = {
  employees_turnover: "employees-turnover",
  age_based_statistics: "age-based-statistics",
  gender_based_statistics: "gender-based-statistics",
  board_diversity: "board-diversity",
  management_diversity: "management-diversity",
  tax: "tax",
  direct_economic_impact: "direct_economic_impact",
  indirect_economic_impact: "Indirect_economic_impact",
};

export function* getEmissionList(action) {
  try {
    const {
      emissionType,
      filter,
      isAuditor,
      isStakeholder,
      company,
      facilities,
      period,
      country,
    } = action.payload;
    const payload = filter;
    const apiEndpoint = isAuditor
      ? APIEndpoints.GET_AUDITOR_EMISSION_LIST(emissionType, company)
      : isStakeholder
      ? APIEndpoints.GET_STAKEHOLDER_TOPICS_DATA(
          company,
          emissionType,
          filter.year
        )
      : APIEndpoints.GET_EMISSION_LIST(emissionType);
    if (!payload.month) delete payload.month;
    if (!payload.facility_id) delete payload.facility_id;
    const response = yield call(request, apiEndpoint, {
      method: isAuditor ? "GET" : "POST",
      payload: isAuditor
        ? payload
        : {
            year: filter.year,
            //  "query": "",
            skip: filter.skip || 0,
            limit: filter.limit || 40,
            month: filter.month,
            facilities: facilities,
            period: period,
            country: country,
          },
    });
    yield put({
      type: ActionTypes.GET_EMISSION_LIST_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_EMISSION_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* getEmission(action) {
  try {
    const {
      emissionType,
      emissionId,
      isAuditor,
      company,
      distribution,
      classification,
    } = action.payload;

    const apiEndpoint = isAuditor
      ? APIEndpoints.GET_AUDITOR_EMISSION(
          emissionType,
          emissionId,
          company,
          distribution,
          classification
        )
      : APIEndpoints.GET_EMISSION(
          emissionType,
          emissionId,
          distribution,
          classification
        );
    const response = yield call(request, apiEndpoint, {
      method: "GET",
    });
    yield put({
      type: ActionTypes.GET_EMISSION_SUCCESS,
      payload: response.emission || {},
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_EMISSION_FAILURE,
      payload: err,
    });
  }
}

export function* getEmissionInputFormat(action) {
  try {
    const { emissionType, assessment, year, facility } = action.payload;
    const yearValue = year === undefined ? 0 : year;
    const response = yield call(
      request,
      APIEndpoints.GET_EMISSION_INPUT_FORMAT(
        emissionType,
        assessment,
        yearValue,
        facility
      ),
      {
        method: "GET",
      }
    );
    yield put({
      type: ActionTypes.GET_EMISSION_INPUT_FORMAT_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_EMISSION_INPUT_FORMAT_FAILURE,
      payload: err,
    });
  }
}

export function* addPurchasedElectricity(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]); // Append file
          }
        } else {
          formData.append(key, requestData[key]); // Append other data
        }
      }
    }

    const response = yield call(
      request,
      APIEndpoints.ADD_PURCHASED_ELECTRICITY,
      {
        method: "POST",
        isFormData: true,
        payload: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );

    yield put({
      type: ActionTypes.ADD_PURCHASED_ELECTRICITY_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_PURCHASED_ELECTRICITY_FAILURE,
      payload: err,
    });
  }
}

export function* addRefrigerants(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]); // Append file
          }
        } else {
          formData.append(key, requestData[key]); // Append other data
        }
      }
    }

    const response = yield call(request, APIEndpoints.ADD_REFRIGERANTS, {
      method: "POST",
      isFormData: true,
      payload: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    yield put({
      type: ActionTypes.ADD_REFRIGERANTS_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_REFRIGERANTS_FAILURE,
      payload: err,
    });
  }
}

export function* updateRefrigerants(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_REFRIGERANTS(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_REFRIGERANTS_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_REFRIGERANTS_FAILURE,
      payload: err,
    });
  }
}

export function* addWaterDischarge(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;
    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]); // Append file
          }
        } else {
          formData.append(key, requestData[key]); // Append other data
        }
      }
    }

    const response = yield call(request, APIEndpoints.ADD_WATER_DISCHARGE, {
      method: "POST",
      isFormData: true,
      payload: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    yield put({
      type: ActionTypes.ADD_WATER_DISCHARGE_COMBUSTION_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_WATER_DISCHARGE_COMBUSTION_FAILURE,
      payload: err,
    });
  }
}

export function* addWaterConsumption(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]); // Append file
          }
        } else {
          formData.append(key, requestData[key]); // Append other data
        }
      }
    }

    const response = yield call(request, APIEndpoints.ADD_WATER_CONSUMPTION, {
      method: "POST",
      isFormData: true,
      payload: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    yield put({
      type: ActionTypes.ADD_WATER_CONSUMPTION_COMBUSTION_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_WATER_CONSUMPTION_COMBUSTION_FAILURE,
      payload: err,
    });
  }
}

export function* addWasteCombustion(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]); // Append file
          }
        } else {
          formData.append(key, requestData[key]); // Append other data
        }
      }
    }
    const response = yield call(request, APIEndpoints.ADD_WASTE_COMBUSTION, {
      method: "POST",
      isFormData: true,
      payload: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    yield put({
      type: ActionTypes.ADD_WASTE_COMBUSTION_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_WASTE_COMBUSTION_FAILURE,
      payload: err,
    });
  }
}

export function* updatePurchasedElectricity(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_PURCHASED_ELECTRICITY(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_PURCHASED_ELECTRICITY_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_PURCHASED_ELECTRICITY_FAILURE,
      payload: err,
    });
  }
}

export function* updateStationaryCombustion(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_STATIONARY_COMBUSTION(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_STATIONARY_COMBUSTION_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_STATIONARY_COMBUSTION_FAILURE,
      payload: err,
    });
  }
}

export function* updateDevelopmentTrainingDetails(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_DEVELOPMENT_TRAINING_DETAILS(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_DEVELOPMENT_TRAINING_DETAILS_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_DEVELOPMENT_TRAINING_DETAILS_FAILURE,
      payload: err,
    });
  }
}

export function* updateWorkerSafetyTraining(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_WORKER_SAFETY_TRAINING(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_WORKER_SAFETY_TRAINING_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_WORKER_SAFETY_TRAINING_FAILURE,
      payload: err,
    });
  }
}

export function* updateMobileCombustion(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_MOBILE_COMBUSTION(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_MOBILE_COMBUSTION_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_MOBILE_COMBUSTION_FAILURE,
      payload: err,
    });
  }
}

export function* updateWaterDischargeCombustion(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_WATER_DISCHARGE_COMBUSTION(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_WATER_DISCHARGE_COMBUSTION_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_WATER_DISCHARGE_COMBUSTION_FAILURE,
      payload: err,
    });
  }
}

export function* updateWaterConsumptionCombustion(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_WATER_CONSUMPTION_COMBUSTION(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_WATER_CONSUMPTION_COMBUSTION_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_WATER_CONSUMPTION_COMBUSTION_FAILURE,
      payload: err,
    });
  }
}

export function* updateWasteCombustion(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_WASTE_COMBUSTION(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_WASTE_COMBUSTION_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_WASTE_COMBUSTION_FAILURE,
      payload: err,
    });
  }
}

export function* deleteEmissions(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.DELETE_EMISSIONS(requestData.id),
      {
        method: "DELETE",
      }
    );
    yield put({
      type: ActionTypes.DELETE_EMISSIONS_SUCCESS,
      payload: response,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.DELETE_EMISSIONS_FAILURE,
      payload: err,
    });
  }
}

export function* addStationaryCombustion(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    // Append all fields from requestData to FormData
    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]); // Append file
          }
        } else {
          formData.append(key, requestData[key]); // Append other data
        }
      }
    }

    const response = yield call(
      request,
      APIEndpoints.ADD_STATIONARY_COMBUSTION,
      {
        method: "POST",
        isFormData: true,
        payload: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    yield put({
      type: ActionTypes.ADD_STATIONARY_COMBUSTION_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_STATIONARY_COMBUSTION_FAILURE,
      payload: err,
    });
  }
}

export function* addMobileCombustion(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]); // Append file
          }
        } else {
          formData.append(key, requestData[key]); // Append other data
        }
      }
    }

    const response = yield call(request, APIEndpoints.ADD_MOBILE_COMBUSTION, {
      method: "POST",
      isFormData: true,
      payload: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    yield put({
      type: ActionTypes.ADD_MOBILE_COMBUSTION_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_MOBILE_COMBUSTION_FAILURE,
      payload: err,
    });
  }
}

export function* addEmployeeCommuting(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]); // Append file
          }
        } else {
          formData.append(key, requestData[key]); // Append other data
        }
      }
    }

    const response = yield call(request, APIEndpoints.ADD_EMPLOYEE_COMMUTE, {
      method: "POST",
      isFormData: true,
      payload: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    yield put({
      type: ActionTypes.ADD_EMPLOYEE_COMMUTING_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_EMPLOYEE_COMMUTING_FAILURE,
      payload: err,
    });
  }
}

export function* getEmissionFuelList(action) {
  try {
    const { emissionType } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.GET_EMISSION_FUEL_LIST(emissionType),
      {
        method: "GET",
      }
    );
    yield put({
      type: ActionTypes.GET_EMISSION_FUEL_LIST_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_EMISSION_FUEL_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* addTransportationCombustion(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]); // Append file
          }
        } else {
          formData.append(key, requestData[key]); // Append other data
        }
      }
    }

    const response = yield call(
      request,
      APIEndpoints.ADD_TRANSPORTATION_COMBUSTION,
      {
        method: "POST",
        isFormData: true,
        payload: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    yield put({
      type: ActionTypes.ADD_TRANSPORTATION_COMBUSTION_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_TRANSPORTATION_COMBUSTION_FAILURE,
      payload: err,
    });
  }
}

export function* editTransportationCombustion(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.EDIT_TRANSPORTATION_COMBUSTION(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.EDIT_TRANSPORTATION_COMBUSTION_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.EDIT_TRANSPORTATION_COMBUSTION_FAILURE,
      payload: err,
    });
  }
}

export function* getMobileCombustionInputs(action) {
  try {
    const { emissionType } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.GET_EMISSION_FUEL_LIST(emissionType),
      {
        method: "GET",
      }
    );
    yield put({
      type: ActionTypes.GET_MOBILE_COMBUSTION_INPUTS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_MOBILE_COMBUSTION_INPUTS_FAILURE,
      payload: err,
    });
  }
}

export function* listAuditTrails(action) {
  try {
    const { emissionId, isAuditor, company } = action.payload;
    const apiEndpoint = isAuditor
      ? APIEndpoints.LIST_AUDITOR_EMISSION_AUDIT_TRAILS(emissionId, company)
      : APIEndpoints.LIST_EMISSION_AUDIT_TRAILS(emissionId);
    const response = yield call(request, apiEndpoint, {
      method: "GET",
    });
    yield put({
      type: ActionTypes.LIST_EMISSION_AUDIT_TRAILS_SUCCESS,
      payload: response.audit_status,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.LIST_EMISSION_AUDIT_TRAILS_FAILURE,
      payload: err.message,
    });
  }
}

export function* listEmissionFiles({ payload }) {
  try {
    const { emissionId, isAuditor, company } = payload;
    const apiEndpoint = isAuditor
      ? APIEndpoints.LIST_AUDITOR_EMISSION_FILES(emissionId, company)
      : APIEndpoints.LIST_EMISSION_FILES(emissionId);
    const response = yield call(request, apiEndpoint, {
      method: "GET",
    });
    yield put({
      type: ActionTypes.LIST_EMISSION_FILES_SUCCESS,
      payload: response.attachments,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.LIST_EMISSION_FILES_FAILURE,
      payload: err?.message,
    });
  }
}

export function* uploadAttachement(action) {
  try {
    const { emissionId, file } = action.payload;
    const formData = new FormData();
    formData.append("file", file);
    const response = yield call(
      request,
      APIEndpoints.UPLOAD_EMISSION_ATTACHEMENT(emissionId),
      {
        method: "POST",
        isFormData: true,
        payload: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    yield put({
      type: ActionTypes.UPLOAD_EMISSION_ATTACHEMENT_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPLOAD_EMISSION_ATTACHEMENT_FAILURE,

      payload: err.message,
    });
  }
}

export function* deleteAttachemnt(action) {
  try {
    const { emissionId, attachementId } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.DELETE_EMISSION_ATTACHEMENT(emissionId, attachementId),
      {
        method: "DELETE",
      }
    );
    yield put({
      type: ActionTypes.DELETE_EMISSION_ATTACHEMENT_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.DELETE_EMISSION_ATTACHEMENT_FAILURE,
      payload: err.message,
    });
  }
}

export function* addDevelopmentTraining(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]);
          }
        } else {
          formData.append(key, requestData[key]);
        }
      }
    }
    const response = yield call(
      request,
      APIEndpoints.ADD_DEVELOPMET_TRAINING_DETAILS,
      {
        // method: 'POST',
        // payload: requestData
        method: "POST",
        isFormData: true,
        payload: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    yield put({
      type: ActionTypes.ADD_DEVELOPMENT_TRAINING_DETAILS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_DEVELOPMENT_TRAINING_DETAILS_FAILURE,
      payload: err,
    });
  }
}

export function* addEmployeeHealth(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]);
          }
        } else if (
          typeof requestData[key] === "object" &&
          requestData[key] !== null &&
          !Array.isArray(requestData[key])
        ) {
          formData.append(key, JSON.stringify(requestData[key])); // Stringify the object
        } else {
          formData.append(key, requestData[key]);
        }
      }
    }
    const response = yield call(
      request,
      APIEndpoints.ADD_EMPLOYEE_HEALTH_DETAILS,
      {
        // method: 'POST',
        // payload: requestData
        method: "POST",
        isFormData: true,
        payload: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    yield put({
      type: ActionTypes.ADD_EMPLOYEE_HEALTH_DETAILS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_EMPLOYEE_HEALTH_DETAILS_FAILURE,
      payload: err,
    });
  }
}

export function* updateEmployeeHealthDetails(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_EMPLOYEE_HEALTH_DETAILS(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_EMPLOYEE_HEALTH_DETAILS_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_EMPLOYEE_HEALTH_DETAILS_FAILURE,
      payload: err,
    });
  }
}

export function* addWorkerSafetyTraining(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]);
          }
        } else if (key == "questions") {
          formData.append("questions", JSON.stringify(requestData[key]));
        } else {
          formData.append(key, requestData[key]);
        }
      }
    }
    const response = yield call(
      request,
      APIEndpoints.ADD_WORKER_SAFETY_TRAINING,
      {
        // method: 'POST',
        // payload: requestData
        method: "POST",
        isFormData: true,
        payload: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    yield put({
      type: ActionTypes.ADD_WORKER_SAFETY_TRAINING_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_WORKER_SAFETY_TRAINING_FAILURE,
      payload: err,
    });
  }
}

export function* addDescriminationIncident(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]);
          }
        } else {
          formData.append(key, requestData[key]);
        }
      }
    }
    const response = yield call(
      request,
      APIEndpoints.ADD_DESCRIMINATION_INCIDENT_RECORD,
      {
        // method: 'POST',
        // payload: requestData
        method: "POST",
        isFormData: true,
        payload: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    yield put({
      type: ActionTypes.ADD_DESCRIMINATION_INCIDENT_RECORD_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_DESCRIMINATION_INCIDENT_RECORD_FAILURE,
      payload: err,
    });
  }
}

export function* addSupplierHumanRightsTraining(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]);
          }
        } else {
          formData.append(key, requestData[key]);
        }
      }
    }
    const response = yield call(
      request,
      APIEndpoints.ADD_SUPPLIER_HUMAN_RIGHTS_TRAINING,
      {
        // method: 'POST',
        // payload: requestData
        method: "POST",
        isFormData: true,
        payload: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    yield put({
      type: ActionTypes.ADD_SUPPLIER_HUMAN_RIGHTS_TRAINING_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_SUPPLIER_HUMAN_RIGHTS_TRAINING_FAILURE,
      payload: err,
    });
  }
}

export function* addSocialHumanRightsTraining(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]);
          }
        } else {
          formData.append(key, requestData[key]);
        }
      }
    }
    const response = yield call(
      request,
      APIEndpoints.ADD_SOCIAL_HUMAN_RIGHTS_TRAINING,
      {
        // method: 'POST',
        // payload: requestData
        method: "POST",
        isFormData: true,
        payload: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    yield put({
      type: ActionTypes.ADD_SOCIAL_HUMAN_RIGHTS_TRAINING_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_SOCIAL_HUMAN_RIGHTS_TRAINING_FAILURE,
      payload: err,
    });
  }
}

export function* addSupplierScreening(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]);
          }
        } else {
          formData.append(key, requestData[key]);
        }
      }
    }
    const response = yield call(request, APIEndpoints.ADD_SUPPLIER_SCREENING, {
      // method: 'POST',
      // payload: requestData
      method: "POST",
      isFormData: true,
      payload: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    yield put({
      type: ActionTypes.ADD_SUPPLIER_SCREENING_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_SUPPLIER_SCREENING_FAILURE,
      payload: err,
    });
  }
}

export function* addLocalCommunities(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]);
          }
        } else {
          formData.append(key, requestData[key]);
        }
      }
    }
    const response = yield call(request, APIEndpoints.ADD_LOCAL_COMMUNITIES, {
      // method: 'POST',
      // payload: requestData
      method: "POST",
      isFormData: true,
      payload: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    yield put({
      type: ActionTypes.ADD_LOCAL_COMMUNITIES_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_LOCAL_COMMUNITIES_FAILURE,
      payload: err,
    });
  }
}

export function* addPoliticalContribution(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]);
          }
        } else {
          formData.append(key, requestData[key]);
        }
      }
    }
    const response = yield call(
      request,
      APIEndpoints.ADD_POLITICAL_CONTRIBUTION,
      {
        // method: 'POST',
        // payload: requestData
        method: "POST",
        isFormData: true,
        payload: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    yield put({
      type: ActionTypes.ADD_POLITICAL_CONTRIBUTION_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_POLITICAL_CONTRIBUTION_FAILURE,
      payload: err,
    });
  }
}

export function* addAntiCorruptionDisclosure(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]);
          }
        } else {
          formData.append(key, requestData[key]);
        }
      }
    }
    const response = yield call(
      request,
      APIEndpoints.ADD_ANTI_CORRUPTION_DISCLOSURE,
      {
        // method: 'POST',
        // payload: requestData
        method: "POST",
        isFormData: true,
        payload: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    yield put({
      type: ActionTypes.ADD_ANTI_CORRUPTION_DISCLOSURE_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_ANTI_CORRUPTION_DISCLOSURE_FAILURE,
      payload: err,
    });
  }
}

export function* addAntiCorruptionTraining(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]);
          }
        } else {
          formData.append(key, requestData[key]);
        }
      }
    }
    const response = yield call(
      request,
      APIEndpoints.ADD_ANTI_CORRUPTION_TRAINING,
      {
        // method: 'POST',
        // payload: requestData
        method: "POST",
        isFormData: true,
        payload: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    yield put({
      type: ActionTypes.ADD_ANTI_CORRUPTION_TRAINING_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_ANTI_CORRUPTION_TRAINING_FAILURE,
      payload: err,
    });
  }
}

export function* addAntiCompetitiveDisclosure(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]);
          }
        } else {
          formData.append(key, requestData[key]);
        }
      }
    }
    const response = yield call(
      request,
      APIEndpoints.ADD_ANTI_COMPETITIVE_DISCLOSURE,
      {
        // method: 'POST',
        // payload: requestData
        method: "POST",
        isFormData: true,
        payload: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    yield put({
      type: ActionTypes.ADD_ANTI_COMPETITIVE_DISCLOSURE_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_ANTI_COMPETITIVE_DISCLOSURE_FAILURE,
      payload: err,
    });
  }
}

export function* addFinancialAssistance(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]);
          }
        } else if (key === "financial_assistant_by_facilities") {
          formData.append(
            "financial_assistant_by_facilities",
            JSON.stringify(requestData[key])
          );
        } else {
          formData.append(key, requestData[key]);
        }
      }
    }
    const response = yield call(
      request,
      APIEndpoints.ADD_SUBSIDIES_FINANCIAL_ASSISTANCE,
      {
        // method: 'POST',
        // payload: requestData
        method: "POST",
        isFormData: true,
        payload: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    yield put({
      type: ActionTypes.ADD_SUBSIDIES_FINANCIAL_ASSISTANCE_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_SUBSIDIES_FINANCIAL_ASSISTANCE_FAILURE,
      payload: err,
    });
  }
}

export function* addBoardDiversity(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]);
          }
        } else if (
          typeof requestData[key] === "object" &&
          requestData[key] !== null &&
          !Array.isArray(requestData[key])
        ) {
          formData.append(key, JSON.stringify(requestData[key])); // Stringify the object
        } else {
          formData.append(key, requestData[key]);
        }
      }
    }
    const response = yield call(request, APIEndpoints.ADD_BOARD_DIVERSITY, {
      // method: 'POST',
      // payload: requestData
      method: "POST",
      isFormData: true,
      payload: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    yield put({
      type: ActionTypes.ADD_BOARD_DIVERSITY_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_BOARD_DIVERSITY_FAILURE,
      payload: err,
    });
  }
}
export function* addManagementDiversity(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]);
          }
        } else if (
          typeof requestData[key] === "object" &&
          requestData[key] !== null &&
          !Array.isArray(requestData[key])
        ) {
          formData.append(key, JSON.stringify(requestData[key])); // Stringify the object
        } else {
          formData.append(key, requestData[key]);
        }
      }
    }
    const response = yield call(
      request,
      APIEndpoints.ADD_MANAGEMENT_DIVERSITY,
      {
        // method: 'POST',
        // payload: requestData
        method: "POST",
        isFormData: true,
        payload: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    yield put({
      type: ActionTypes.ADD_MANAGEMENT_DIVERSITY_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_MANAGEMENT_DIVERSITY_FAILURE,
      payload: err,
    });
  }
}

export function* addUploadFileEmissions(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      uploadFileEmissionUrlMap[requestData.emissionType],
      {
        method: "POST",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.ADD_UPLOAD_FILE_EMISSION_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_UPLOAD_FILE_EMISSION_FAILURE,
      payload: err,
    });
  }
}

export function* getNonEmissionDetails(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.GET_NON_EMISSION_DETAILS(
        requestData.id,
        nonEmissionDetailsMap[requestData.emissionType]
      ),
      {
        method: "GET",
      }
    );
    yield put({
      type: ActionTypes.GET_NON_EMISSION_DETAILS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_NON_EMISSION_DETAILS_FAILURE,
      payload: err,
    });
  }
}

export function* getAllEmissions(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.GET_ALL_EMISSIONS(requestData.selectedYear),
      {
        method: "POST",
        payload: {
          emission_types: requestData.topicNames || requestData.emissionsArray,
          year: requestData.selectedYear,
          facilities: requestData.facility || requestData.facilitiesDataApi,
          period: requestData.period,
        },
      }
    );
    yield put({
      type: ActionTypes.GET_ALL_EMISSIONS_SUCCESS,
      payload: response,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_ALL_EMISSIONS_FAILURE,
      payload: err,
    });
  }
}

export function* updateNonEmissionDetails(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_NON_EMISSION_DETAILS(
        requestData.id,
        updateNonEmissionDetailsMap[requestData.emissionType]
      ),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_NON_EMISSION_DETAILS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_NON_EMISSION_DETAILS_FAILURE,
      payload: err,
    });
  }
}

export function* updateEmployeeTurnover(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_EMPLOYEE_TURNOVER(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_EMPLOYEE_TURNOVER_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_EMPLOYEE_TURNOVER_FAILURE,
      payload: err,
    });
  }
}

export function* updateAgeBasedStatistics(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_AGE_BASED_STATISTICS(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_AGE_BASED_STATISTICS_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_AGE_BASED_STATISTICS_FAILURE,
      payload: err,
    });
  }
}

export function* updateGenderBasedStatistics(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_GENDER_BASED_STATISTICS(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_GENDER_BASED_STATISTICS_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_GENDER_BASED_STATISTICS_FAILURE,
      payload: err,
    });
  }
}

export function* updateDiscriminationIncidentRecord(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_DISCRIMINATION_INCIDENT_RECORD(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_DISCRIMINATION_INCIDENT_RECORD_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_DISCRIMINATION_INCIDENT_RECORD_FAILURE,
      payload: err,
    });
  }
}

export function* updateSupplierScreening(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_SUPPLIER_SCREENING(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_SUPPLIER_SCREENING_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_SUPPLIER_SCREENING_FAILURE,
      payload: err,
    });
  }
}

export function* updateSupplierHumanRightsTraining(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_SUPPLIER_HUMAN_RIGHTS_TRAINING(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_SUPPLIER_HUMAN_RIGHTS_TRAINING_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_SUPPLIER_HUMAN_RIGHTS_TRAINING_FAILURE,
      payload: err,
    });
  }
}

export function* updateSocialHumanRightsTraining(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_SOCIAL_HUMAN_RIGHTS_TRAINING(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_SOCIAL_HUMAN_RIGHTS_TRAINING_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_SOCIAL_HUMAN_RIGHTS_TRAINING_FAILURE,
      payload: err,
    });
  }
}

export function* updateLocalCommunities(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_LOCAL_COMMUNITIES(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_LOCAL_COMMUNITIES_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_LOCAL_COMMUNITIES_FAILURE,
      payload: err,
    });
  }
}

export function* updatePoliticalContributions(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_POLITICAL_CONTRIBUTIONS(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_POLITICAL_CONTRIBUTIONS_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_POLITICAL_CONTRIBUTIONS_FAILURE,
      payload: err,
    });
  }
}

export function* updateAntiCorruptionDisclosure(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_ANTI_CORRUPTION_DISCLOSURE(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_ANTI_CORRUPTION_DISCLOSURE_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_ANTI_CORRUPTION_DISCLOSURE_FAILURE,
      payload: err,
    });
  }
}

export function* updateAntiCorruptionTraining(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_ANTI_CORRUPTION_TRAINING(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_ANTI_CORRUPTION_TRAINING_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_ANTI_CORRUPTION_TRAINING_FAILURE,
      payload: err,
    });
  }
}

export function* updateAntiCompetitiveDisclosure(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_ANTI_COMPETITIVE_DISCLOSURE(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_ANTI_COMPETITIVE_DISCLOSURE_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_ANTI_COMPETITIVE_DISCLOSURE_FAILURE,
      payload: err,
    });
  }
}

export function* updateSubsidiesFinancialAssistance(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_SUBSIDIES_FINANCIAL_ASSISTANCE(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_SUBSIDIES_FINANCIAL_ASSISTANCE_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_SUBSIDIES_FINANCIAL_ASSISTANCE_FAILURE,
      payload: err,
    });
  }
}

export function* getUploadHistory({ payload }) {
  try {
    const response = yield call(
      request,
      APIEndpoints.GET_UPLOAD_HISTORY(payload.emissionType, payload.filter),
      {
        method: "POST",
        payload: { emissionType: payload.emissionType },
      }
    );
    yield put({
      type: ActionTypes.GET_UPLOAD_HISTORY_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_UPLOAD_HISTORY_FAILURE,
      payload: err,
    });
  }
}

export function* getUploadHistoryDetails({ payload }) {
  try {
    const response = yield call(
      request,
      APIEndpoints.GET_UPLOAD_HISTORY_DETAILS(
        payload.emissionType,
        payload.blob_url
      ),
      {
        method: "POST",
        payload: { blob_url: payload.blob_url },
      }
    );

    yield put({
      type: ActionTypes.GET_UPLOAD_HISTORY_DETAILS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_UPLOAD_HISTORY_DETAILS_FAILURE,
      payload: err,
    });
  }
}

export function* addEmployeeHire(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]);
          }
        } else if (
          typeof requestData[key] === "object" &&
          requestData[key] !== null &&
          !Array.isArray(requestData[key])
        ) {
          formData.append(key, JSON.stringify(requestData[key])); // Stringify the object
        } else {
          formData.append(key, requestData[key]);
        }
      }
    }
    const response = yield call(request, APIEndpoints.ADD_EMPLOYEE_HIRE, {
      // method: 'POST',
      // payload: requestData
      method: "POST",
      isFormData: true,
      payload: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    yield put({
      type: ActionTypes.ADD_EMPLOYEE_HIRE_SUCCESS,
      payload: response,
      save: true,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_EMPLOYEE_HIRE_FAILURE,
      payload: err,
    });
  }
}

export function* addEmployeeBenefit(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]);
          }
        } else {
          formData.append(key, requestData[key]);
        }
      }
    }
    const response = yield call(request, APIEndpoints.ADD_EMPLOYEE_BENEFIT, {
      // method: 'POST',
      // payload: requestData
      method: "POST",
      isFormData: true,
      payload: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    yield put({
      type: ActionTypes.ADD_EMPLOYEE_BENEFIT_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_EMPLOYEE_BENEFIT_FAILURE,
      payload: err,
    });
  }
}
export function* addEmployeeDiversity(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]);
          }
        } else {
          formData.append(key, requestData[key]);
        }
      }
    }
    const response = yield call(request, APIEndpoints.ADD_EMPLOYEE_DIVERSITY, {
      // method: 'POST',
      // payload: requestData
      method: "POST",
      isFormData: true,
      payload: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    yield put({
      type: ActionTypes.ADD_EMPLOYEE_DIVERSITY_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_EMPLOYEE_DIVERSITY_FAILURE,
      payload: err,
    });
  }
}

export function* addEmployeeTrainingHours(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]);
          }
        } else {
          formData.append(key, requestData[key]);
        }
      }
    }
    const response = yield call(
      request,
      APIEndpoints.ADD_EMPLOYEE_TRAINING_HOURS,
      {
        // method: 'POST',
        // payload: requestData
        method: "POST",
        isFormData: true,
        payload: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    yield put({
      type: ActionTypes.ADD_EMPLOYEE_TRAINING_HOURS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_EMPLOYEE_TRAINING_HOURS_FAILURE,
      payload: err,
    });
  }
}

export function* updateEmployeeTrainingHours(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_EMPLOYEE_TRAINING_HOURS(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_EMPLOYEE_TRAINING_HOURS_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_EMPLOYEE_TRAINING_HOURS_FAILURE,
      payload: err,
    });
  }
}
export function* addPerformanceAndCareerDevelopmentPrograms(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]);
          }
        } else {
          formData.append(key, requestData[key]);
        }
      }
    }
    const response = yield call(
      request,
      APIEndpoints.ADD_PERFORMANCE_AND_CAREER_DEVELOPMENT_PROGRAMS,
      {
        // method: 'POST',
        // payload: requestData
        method: "POST",
        isFormData: true,
        payload: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    yield put({
      type: ActionTypes.ADD_PERFORMANCE_AND_CAREER_DEVELOPMENT_PROGRAMS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_PERFORMANCE_AND_CAREER_DEVELOPMENT_PROGRAMS_FAILURE,
      payload: err,
    });
  }
}
export function* updatePerformanceAndCareerDevelopmentPrograms(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_PERFORMANCE_AND_CAREER_DEVELOPMENT_PROGRAMS(
        requestData.id
      ),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_PERFORMANCE_AND_CAREER_DEVELOPMENT_PROGRAMS_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_PERFORMANCE_AND_CAREER_DEVELOPMENT_PROGRAMS_FAILURE,
      payload: err,
    });
  }
}
export function* addEconomicImpact(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]);
          }
        } else {
          formData.append(key, requestData[key]);
        }
      }
    }
    const response = yield call(request, APIEndpoints.ADD_ECONOMIC_IMPACT, {
      // method: 'POST',
      // payload: requestData
      method: "POST",
      isFormData: true,
      payload: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    yield put({
      type: ActionTypes.ADD_ECONOMIC_IMPACT_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_ECONOMIC_IMPACT_FAILURE,
      payload: err,
    });
  }
}
export function* updateEconomicImpact(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_ECONOMIC_IMPACT(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_ECONOMIC_IMPACT_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_ECONOMIC_IMPACT_FAILURE,
      payload: err,
    });
  }
}

export function* updateEmployeeDiversity(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_EMPLOYEE_DIVERSITY(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_EMPLOYEE_DIVERSITY_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_EMPLOYEE_DIVERSITY_FAILURE,
      payload: err,
    });
  }
}
export function* updateBoardDiversity(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_BOARD_DIVERSITY(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_BOARD_DIVERSITY_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_BOARD_DIVERSITY_FAILURE,
      payload: err,
    });
  }
}
export function* updateManagementDiversity(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_MANAGEMENT_DIVERSITY(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_MANAGEMENT_DIVERSITY_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_MANAGEMENT_DIVERSITY_FAILURE,
      payload: err,
    });
  }
}

export function* updateEmployeeBenefits(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_EMPLOYEE_BENEFITS(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_EMPLOYEE_BENEFITS_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_EMPLOYEE_BENEFITS_FAILURE,
      payload: err,
    });
  }
}

export function* updateEmployeeHire(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_EMPLOYEE_HIRE(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_EMPLOYEE_HIRE_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_EMPLOYEE_HIRE_FAILURE,
      payload: err,
    });
  }
}

export function* getStandardsQuestionnaire(action) {
  try {
    const { id, assessment_id } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.GET_STANDARDS_QUESTIONNAIRE(id, assessment_id),
      {
        method: "GET",
      }
    );
    yield put({
      type: ActionTypes.GET_STANDARDS_QUESTIONNAIRE_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_STANDARDS_QUESTIONNAIRE_FAILURE,
      payload: err,
    });
  }
}

export function* addUpstreamTAndD(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]); // Append file
          }
        } else {
          formData.append(key, requestData[key]); // Append other data
        }
      }
    }

    const response = yield call(request, APIEndpoints.ADD_UPSTREAM_T_AND_D, {
      method: "POST",
      isFormData: true,
      payload: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    yield put({
      type: ActionTypes.ADD_UPSTREAM_T_AND_D_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_UPSTREAM_T_AND_D_FAILURE,
      payload: err,
    });
  }
}

export function* addDownstreamTAndD(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]); // Append file
          }
        } else {
          formData.append(key, requestData[key]); // Append other data
        }
      }
    }

    const response = yield call(request, APIEndpoints.ADD_DOWNSTREAM_T_AND_D, {
      method: "POST",
      isFormData: true,
      payload: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    yield put({
      type: ActionTypes.ADD_DOWNSTREAM_T_AND_D_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_DOWNSTREAM_T_AND_D_FAILURE,
      payload: err,
    });
  }
}

export function* addBusinessTravel(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]); // Append file
          }
        } else {
          formData.append(key, requestData[key]); // Append other data
        }
      }
    }

    const response = yield call(request, APIEndpoints.ADD_BUSINESS_TRAVEL, {
      method: "POST",
      isFormData: true,
      payload: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    yield put({
      type: ActionTypes.ADD_BUSINESS_TRAVEL_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_BUSINESS_TRAVEL_FAILURE,
      payload: err,
    });
  }
}

export function* addpurchasedGoodsandServices(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]); // Append file
          }
        } else {
          formData.append(key, requestData[key]); // Append other data
        }
      }
    }

    const response = yield call(
      request,
      APIEndpoints.ADD_PURCHASED_GOODS_AND_SERVICES,
      {
        method: "POST",
        isFormData: true,
        payload: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    yield put({
      type: ActionTypes.ADD_PURCHASED_GOODS_AND_SERVICES_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_PURCHASED_GOODS_AND_SERVICES_FAILURE,
      payload: err,
    });
  }
}

export function* addEmployeeCommute(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]); // Append file
          }
        } else {
          formData.append(key, requestData[key]); // Append other data
        }
      }
    }

    const response = yield call(request, APIEndpoints.ADD_EMPLOYEE_COMMUTE, {
      method: "POST",
      isFormData: true,
      payload: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    yield put({
      type: ActionTypes.ADD_EMPLOYEE_COMMUTE_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_EMPLOYEE_COMMUTE_FAILURE,
      payload: err,
    });
  }
}
export function* updateUpstreamTAndD(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_UPSTREAM_T_AND_D(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_UPSTREAM_T_AND_D_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_UPSTREAM_T_AND_D_FAILURE,
      payload: err,
    });
  }
}

export function* updateUpstreamLeasedAsset(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_UPSTREAM_LEASED_ASSET(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_UPSTREAM_LEASED_ASSET_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_UPSTREAM_LEASED_ASSET_FAILURE,
      payload: err,
    });
  }
}




export function* updateFranchise(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_FRANCHISE(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_FRANCHISE_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_FRANCHISE_FAILURE,
      payload: err,
    });
  }
}






export function* updateUseOfSoldProducts(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_USE_OF_SOLD_PRODUCTS(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_USE_OF_SOLD_PRODUCTS_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_USE_OF_SOLD_PRODUCTS_FAILURE,
      payload: err,
    });
  }
}

export function* updateInvestments(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_INVESTMENTS(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_INVESTMENTS_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_INVESTMENTS_FAILURE,
      payload: err,
    });
  }
}


export function* updateDownstreamLeasedAsset(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_DOWNSTREAM_LEASED_ASSET(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_DOWNSTREAM_LEASED_ASSET_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_DOWNSTREAM_LEASED_ASSET_FAILURE,
      payload: err,
    });
  }
}

export function* updateBusinessTravel(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_BUSINESS_TRAVEL(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_BUSINESS_TRAVEL_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_BUSINESS_TRAVEL_FAILURE,
      payload: err,
    });
  }
}

export function* updateEmployeeCommute(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_EMPLOYEE_COMMUTE(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_EMPLOYEE_COMMUTE_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_EMPLOYEE_COMMUTE_FAILURE,
      payload: err,
    });
  }
}

export function* updatePurchasedGoodsAndServices(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_PURCHASED_GOODS_AND_SERVICES(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_PURCHASED_GOODS_AND_SERVICES_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_PURCHASED_GOODS_AND_SERVICES_FAILURE,
      payload: err,
    });
  }
}
export function* updateDownstreamTAndD(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_DOWNSTREAM_T_AND_D(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_DOWNSTREAM_T_AND_D_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_DOWNSTREAM_T_AND_D_FAILURE,
      payload: err,
    });
  }
}

export function* addFuelAndEnergyRelatedActivities(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]); // Append file
          }
        } else {
          formData.append(key, requestData[key]); // Append other data
        }
      }
    }

    const response = yield call(
      request,
      APIEndpoints.ADD_FUEL_AND_ENERGY_RELATED_ACTIVITIES,
      {
        method: "POST",
        isFormData: true,
        payload: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    yield put({
      type: ActionTypes.ADD_FUEL_AND_ENERGY_RELATED_ACTIVITIES_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_FUEL_AND_ENERGY_RELATED_ACTIVITIES_FAILURE,
      payload: err,
    });
  }
}

export function* addCapitalGoods(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]); // Append file
          }
        } else {
          formData.append(key, requestData[key]); // Append other data
        }
      }
    }

    const response = yield call(request, APIEndpoints.ADD_CAPITAL_GOODS, {
      method: "POST",
      isFormData: true,
      payload: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    yield put({
      type: ActionTypes.ADD_CAPITAL_GOODS_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_CAPITAL_GOODS_FAILURE,
      payload: err,
    });
  }
}

export function* updateCapitalGoods(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_CAPITAL_GOODS(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_CAPITAL_GOODS_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_CAPITAL_GOODS_FAILURE,
      payload: err,
    });
  }
}



export function* addFranchise(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]); // Append file
          }
        } else {
          formData.append(key, requestData[key]); // Append other data
        }
      }
    }

    const response = yield call(request, APIEndpoints.ADD_FRANCHISE, {
      method: "POST",
      isFormData: true,
      payload: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    yield put({
      type: ActionTypes.ADD_FRANCHISE_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_FRANCHISE_FAILURE,
      payload: err,
    });
  }
}







export function* addInvestments(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]); // Append file
          }
        } else {
          formData.append(key, requestData[key]); // Append other data
        }
      }
    }

    const response = yield call(request, APIEndpoints.ADD_INVESTMENTS, {
      method: "POST",
      isFormData: true,
      payload: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    yield put({
      type: ActionTypes.ADD_INVESTMENTS_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_INVESTMENTS_FAILURE,
      payload: err,
    });
  }
}






export function* addUseOfSoldProducts(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]); // Append file
          }
        } else {
          formData.append(key, requestData[key]); // Append other data
        }
      }
    }

    const response = yield call(
      request,
      APIEndpoints.ADD_USE_OF_SOLD_PRODUCTS,
      {
        method: "POST",
        isFormData: true,
        payload: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    yield put({
      type: ActionTypes.ADD_USE_OF_SOLD_PRODUCTS_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_USE_OF_SOLD_PRODUCTS_FAILURE,
      payload: err,
    });
  }
}

export function* addUpstreamLeasedAsset(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;
    console.log("nikData", requestData);

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]); // Append file
          }
        } else {
          formData.append(key, requestData[key]); // Append other data
        }
      }
    }

    const response = yield call(
      request,
      APIEndpoints.ADD_UPSTREAM_LEASED_ASSET,
      {
        method: "POST",
        isFormData: true,
        payload: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    yield put({
      type: ActionTypes.ADD_UPSTREAM_LEASED_ASSET_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_UPSTREAM_LEASED_ASSET_FAILURE,
      payload: err,
    });
  }
}
export function* addDownstreamLeasedAsset(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;
    console.log("nikData", requestData);

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]); // Append file
          }
        } else {
          formData.append(key, requestData[key]); // Append other data
        }
      }
    }

    const response = yield call(
      request,
      APIEndpoints.ADD_DOWNSTREAM_LEASED_ASSET,
      {
        method: "POST",
        isFormData: true,
        payload: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    yield put({
      type: ActionTypes.ADD_DOWNSTREAM_LEASED_ASSET_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_DOWNSTREAM_LEASED_ASSET_FAILURE,
      payload: err,
    });
  }
}

export function* addEndOfLifeTreatmentOfSoldProducts(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;
    console.log("nikData", requestData);

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]); // Append file
          }
        } else {
          formData.append(key, requestData[key]); // Append other data
        }
      }
    }

    const response = yield call(
      request,
      APIEndpoints.ADD_END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS,
      {
        method: "POST",
        isFormData: true,
        payload: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    yield put({
      type: ActionTypes.ADD_END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS_FAILURE,
      payload: err,
    });
  }
}

export function* updateFuelAndEnergyRelatedActivities(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_FUEL_AND_ENERGY_RELATED_ACTIVITIES(requestData.id),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_FUEL_AND_ENERGY_RELATED_ACTIVITIES_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_FUEL_AND_ENERGY_RELATED_ACTIVITIES_FAILURE,
      payload: err,
    });
  }
}

export function* updateEndOfLifeTreatmentOfSoldProducts(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS(
        requestData.id
      ),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS_FAILURE,
      payload: err,
    });
  }
}

export function* addProcessingOfSoldProducts(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;
    console.log("nikData", requestData);

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]); // Append file
          }
        } else {
          formData.append(key, requestData[key]); // Append other data
        }
      }
    }

    const response = yield call(
      request,
      APIEndpoints.ADD_PROCESSING_OF_SOLD_PRODUCTS,
      {
        method: "POST",
        isFormData: true,
        payload: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    yield put({
      type: ActionTypes.ADD_PROCESSING_OF_SOLD_PRODUCTS_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_PROCESSING_OF_SOLD_PRODUCTS_FAILURE,
      payload: err,
    });
  }
}
export function* updateProcessingOfSoldProducts(action) {
  try {
    const { requestData } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.UPDATE_PROCESSING_OF_SOLD_PRODUCTS(
        requestData.id
      ),
      {
        method: "PUT",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_PROCESSING_OF_SOLD_PRODUCTS_SUCCESS,
      payload: response.emission,
      save: requestData.save,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_PROCESSING_OF_SOLD_PRODUCTS_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.GET_EMISSION_LIST, getEmissionList),
    takeLatest(ActionTypes.GET_EMISSION, getEmission),
    takeLatest(ActionTypes.GET_EMISSION_INPUT_FORMAT, getEmissionInputFormat),
    takeLatest(ActionTypes.ADD_STATIONARY_COMBUSTION, addStationaryCombustion),
    takeLatest(ActionTypes.GET_EMISSION_INPUT_FORMAT, getEmissionInputFormat),
    takeLatest(ActionTypes.ADD_PURCHASED_ELECTRICITY, addPurchasedElectricity),
    takeLatest(ActionTypes.ADD_REFRIGERANTS, addRefrigerants),
    takeLatest(ActionTypes.ADD_WASTE_COMBUSTION, addWasteCombustion),
    takeLatest(ActionTypes.UPDATE_REFRIGERANTS, updateRefrigerants),
    takeLatest(ActionTypes.ADD_WATER_DISCHARGE_COMBUSTION, addWaterDischarge),
    takeLatest(
      ActionTypes.ADD_WATER_CONSUMPTION_COMBUSTION,
      addWaterConsumption
    ),
    takeLatest(
      ActionTypes.UPDATE_PURCHASED_ELECTRICITY,
      updatePurchasedElectricity
    ),
    takeLatest(
      ActionTypes.UPDATE_STATIONARY_COMBUSTION,
      updateStationaryCombustion
    ),
    takeLatest(ActionTypes.UPDATE_MOBILE_COMBUSTION, updateMobileCombustion),
    takeLatest(
      ActionTypes.UPDATE_WATER_DISCHARGE_COMBUSTION,
      updateWaterDischargeCombustion
    ),
    takeLatest(
      ActionTypes.UPDATE_WATER_CONSUMPTION_COMBUSTION,
      updateWaterConsumptionCombustion
    ),
    takeLatest(ActionTypes.UPDATE_WASTE_COMBUSTION, updateWasteCombustion),
    takeLatest(ActionTypes.DELETE_EMISSIONS, deleteEmissions),
    takeLatest(ActionTypes.ADD_MOBILE_COMBUSTION, addMobileCombustion),
    takeLatest(ActionTypes.ADD_EMPLOYEE_COMMUTING, addEmployeeCommuting),
    takeLatest(ActionTypes.GET_EMISSION_FUEL_LIST, getEmissionFuelList),
    takeLatest(
      ActionTypes.GET_MOBILE_COMBUSTION_INPUTS,
      getMobileCombustionInputs
    ),
    takeLatest(
      ActionTypes.ADD_TRANSPORTATION_COMBUSTION,
      addTransportationCombustion
    ),
    takeLatest(
      ActionTypes.EDIT_TRANSPORTATION_COMBUSTION,
      editTransportationCombustion
    ),
    takeLatest(ActionTypes.LIST_EMISSION_AUDIT_TRAILS, listAuditTrails),
    takeLatest(ActionTypes.LIST_EMISSION_FILES, listEmissionFiles),
    takeLatest(ActionTypes.UPLOAD_EMISSION_ATTACHEMENT, uploadAttachement),
    takeLatest(ActionTypes.DELETE_EMISSION_ATTACHEMENT, deleteAttachemnt),
    takeLatest(
      ActionTypes.ADD_DEVELOPMENT_TRAINING_DETAILS,
      addDevelopmentTraining
    ),
    takeLatest(ActionTypes.ADD_EMPLOYEE_HEALTH_DETAILS, addEmployeeHealth),
    takeLatest(ActionTypes.ADD_WORKER_SAFETY_TRAINING, addWorkerSafetyTraining),
    takeLatest(
      ActionTypes.ADD_DESCRIMINATION_INCIDENT_RECORD,
      addDescriminationIncident
    ),
    takeLatest(
      ActionTypes.ADD_SUPPLIER_HUMAN_RIGHTS_TRAINING,
      addSupplierHumanRightsTraining
    ),
    takeLatest(
      ActionTypes.ADD_SOCIAL_HUMAN_RIGHTS_TRAINING,
      addSocialHumanRightsTraining
    ),
    takeLatest(ActionTypes.ADD_SUPPLIER_SCREENING, addSupplierScreening),
    takeLatest(ActionTypes.ADD_LOCAL_COMMUNITIES, addLocalCommunities),
    takeLatest(
      ActionTypes.ADD_POLITICAL_CONTRIBUTION,
      addPoliticalContribution
    ),
    takeLatest(
      ActionTypes.ADD_ANTI_CORRUPTION_DISCLOSURE,
      addAntiCorruptionDisclosure
    ),
    takeLatest(
      ActionTypes.ADD_ANTI_CORRUPTION_TRAINING,
      addAntiCorruptionTraining
    ),
    takeLatest(
      ActionTypes.ADD_ANTI_COMPETITIVE_DISCLOSURE,
      addAntiCompetitiveDisclosure
    ),
    takeLatest(
      ActionTypes.ADD_SUBSIDIES_FINANCIAL_ASSISTANCE,
      addFinancialAssistance
    ),
    takeLatest(ActionTypes.ADD_BOARD_DIVERSITY, addBoardDiversity),
    takeLatest(ActionTypes.ADD_MANAGEMENT_DIVERSITY, addManagementDiversity),
    takeLatest(ActionTypes.ADD_UPLOAD_FILE_EMISSION, addUploadFileEmissions),
    takeLatest(ActionTypes.GET_NON_EMISSION_DETAILS, getNonEmissionDetails),
    takeLatest(
      ActionTypes.UPDATE_NON_EMISSION_DETAILS,
      updateNonEmissionDetails
    ),
    takeLatest(
      ActionTypes.UPDATE_DEVELOPMENT_TRAINING_DETAILS,
      updateDevelopmentTrainingDetails
    ),
    takeLatest(
      ActionTypes.UPDATE_WORKER_SAFETY_TRAINING,
      updateWorkerSafetyTraining
    ),
    takeLatest(ActionTypes.UPDATE_EMPLOYEE_TURNOVER, updateEmployeeTurnover),
    takeLatest(
      ActionTypes.UPDATE_AGE_BASED_STATISTICS,
      updateAgeBasedStatistics
    ),
    takeLatest(
      ActionTypes.UPDATE_GENDER_BASED_STATISTICS,
      updateGenderBasedStatistics
    ),
    takeLatest(
      ActionTypes.UPDATE_DISCRIMINATION_INCIDENT_RECORD,
      updateDiscriminationIncidentRecord
    ),
    takeLatest(ActionTypes.UPDATE_SUPPLIER_SCREENING, updateSupplierScreening),
    takeLatest(
      ActionTypes.UPDATE_SUPPLIER_HUMAN_RIGHTS_TRAINING,
      updateSupplierHumanRightsTraining
    ),
    takeLatest(
      ActionTypes.UPDATE_SOCIAL_HUMAN_RIGHTS_TRAINING,
      updateSocialHumanRightsTraining
    ),
    takeLatest(ActionTypes.UPDATE_LOCAL_COMMUNITIES, updateLocalCommunities),
    takeLatest(
      ActionTypes.UPDATE_POLITICAL_CONTRIBUTIONS,
      updatePoliticalContributions
    ),
    takeLatest(
      ActionTypes.UPDATE_EMPLOYEE_HEALTH_DETAILS,
      updateEmployeeHealthDetails
    ),
    takeLatest(
      ActionTypes.UPDATE_ANTI_CORRUPTION_DISCLOSURE,
      updateAntiCorruptionDisclosure
    ),
    takeLatest(
      ActionTypes.UPDATE_ANTI_CORRUPTION_TRAINING,
      updateAntiCorruptionTraining
    ),
    takeLatest(
      ActionTypes.UPDATE_ANTI_COMPETITIVE_DISCLOSURE,
      updateAntiCompetitiveDisclosure
    ),
    takeLatest(
      ActionTypes.UPDATE_SUBSIDIES_FINANCIAL_ASSISTANCE,
      updateSubsidiesFinancialAssistance
    ),
    takeLatest(ActionTypes.GET_ALL_EMISSIONS, getAllEmissions),
    takeLatest(ActionTypes.GET_UPLOAD_HISTORY, getUploadHistory),
    takeLatest(ActionTypes.GET_UPLOAD_HISTORY_DETAILS, getUploadHistoryDetails),
    takeLatest(ActionTypes.ADD_EMPLOYEE_HIRE, addEmployeeHire),
    takeLatest(ActionTypes.ADD_EMPLOYEE_BENEFIT, addEmployeeBenefit),
    takeLatest(
      ActionTypes.ADD_EMPLOYEE_TRAINING_HOURS,
      addEmployeeTrainingHours
    ),
    takeLatest(
      ActionTypes.UPDATE_EMPLOYEE_TRAINING_HOURS,
      updateEmployeeTrainingHours
    ),
    takeLatest(
      ActionTypes.ADD_PERFORMANCE_AND_CAREER_DEVELOPMENT_PROGRAMS,
      addPerformanceAndCareerDevelopmentPrograms
    ),
    takeLatest(
      ActionTypes.UPDATE_PERFORMANCE_AND_CAREER_DEVELOPMENT_PROGRAMS,
      updatePerformanceAndCareerDevelopmentPrograms
    ),
    takeLatest(ActionTypes.ADD_EMPLOYEE_DIVERSITY, addEmployeeDiversity),
    takeLatest(ActionTypes.ADD_ECONOMIC_IMPACT, addEconomicImpact),
    takeLatest(ActionTypes.UPDATE_ECONOMIC_IMPACT, updateEconomicImpact),
    takeLatest(ActionTypes.UPDATE_EMPLOYEE_DIVERSITY, updateEmployeeDiversity),
    takeLatest(ActionTypes.UPDATE_BOARD_DIVERSITY, updateBoardDiversity),
    takeLatest(
      ActionTypes.UPDATE_MANAGEMENT_DIVERSITY,
      updateManagementDiversity
    ),
    takeLatest(ActionTypes.UPDATE_EMPLOYEE_BENEFITS, updateEmployeeBenefits),
    takeLatest(ActionTypes.UPDATE_EMPLOYEE_HIRE, updateEmployeeHire),
    takeLatest(
      ActionTypes.GET_STANDARDS_QUESTIONNAIRE,
      getStandardsQuestionnaire
    ),
    takeLatest(ActionTypes.ADD_UPSTREAM_T_AND_D, addUpstreamTAndD),
    takeLatest(ActionTypes.ADD_DOWNSTREAM_T_AND_D, addDownstreamTAndD),
    takeLatest(ActionTypes.ADD_BUSINESS_TRAVEL, addBusinessTravel),
    takeLatest(ActionTypes.ADD_EMPLOYEE_COMMUTE, addEmployeeCommute),
    takeLatest(ActionTypes.UPDATE_UPSTREAM_T_AND_D, updateUpstreamTAndD),
    takeLatest(
      ActionTypes.UPDATE_USE_OF_SOLD_PRODUCTS,
      updateUseOfSoldProducts
    ),
    takeLatest(
      ActionTypes.UPDATE_INVESTMENTS,
      updateInvestments
    ),
    takeLatest(
      ActionTypes.UPDATE_UPSTREAM_LEASED_ASSET,
      updateUpstreamLeasedAsset
    ),
    takeLatest(
      ActionTypes.UPDATE_DOWNSTREAM_LEASED_ASSET,
      updateDownstreamLeasedAsset
    ),
    takeLatest(
      ActionTypes.UPDATE_FRANCHISE,
      updateFranchise
    ),
    takeLatest(ActionTypes.UPDATE_BUSINESS_TRAVEL, updateBusinessTravel),
    takeLatest(ActionTypes.UPDATE_EMPLOYEE_COMMUTE, updateEmployeeCommute),
    takeLatest(
      ActionTypes.ADD_PURCHASED_GOODS_AND_SERVICES,
      addpurchasedGoodsandServices
    ),
    takeLatest(
      ActionTypes.UPDATE_PURCHASED_GOODS_AND_SERVICES,
      updatePurchasedGoodsAndServices
    ),
    takeLatest(ActionTypes.UPDATE_DOWNSTREAM_T_AND_D, updateDownstreamTAndD),
    takeLatest(
      ActionTypes.ADD_FUEL_AND_ENERGY_RELATED_ACTIVITIES,
      addFuelAndEnergyRelatedActivities
    ),
    takeLatest(ActionTypes.ADD_CAPITAL_GOODS, addCapitalGoods),
    takeLatest(ActionTypes.UPDATE_CAPITAL_GOODS, updateCapitalGoods),
    takeLatest(ActionTypes.ADD_FRANCHISE, addFranchise),
    takeLatest(ActionTypes.ADD_INVESTMENTS, addInvestments),

    takeLatest(
      ActionTypes.UPDATE_FUEL_AND_ENERGY_RELATED_ACTIVITIES,
      updateFuelAndEnergyRelatedActivities
    ),
    takeLatest(ActionTypes.ADD_USE_OF_SOLD_PRODUCTS, addUseOfSoldProducts),
    takeLatest(ActionTypes.ADD_UPSTREAM_LEASED_ASSET, addUpstreamLeasedAsset),
    takeLatest(
      ActionTypes.ADD_DOWNSTREAM_LEASED_ASSET,
      addDownstreamLeasedAsset
    ),
    takeLatest(
      ActionTypes.ADD_END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS,
      addEndOfLifeTreatmentOfSoldProducts
    ),
    takeLatest(
      ActionTypes.UPDATE_END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS,
      updateEndOfLifeTreatmentOfSoldProducts
    ),
    takeLatest(
      ActionTypes.ADD_PROCESSING_OF_SOLD_PRODUCTS,
      addProcessingOfSoldProducts
    ),
    takeLatest(
      ActionTypes.UPDATE_PROCESSING_OF_SOLD_PRODUCTS,
      updateProcessingOfSoldProducts
    ),
  ]);
}
