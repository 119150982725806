import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    height: "100%",
    width: "100%",
    maxWidth: "100%",
  },
  buttonAdd: {
    backgroundColor: `${theme.palette.Primary.background}!important`,
    fontWeight: "400!important",
    fontSize: 13,
    minWidth: 100,
    textTransform: "none",
    margin: theme.spacing(5),
    borderRadius: 6,
  },
  buttonApply: {
    backgroundColor: `${theme.palette.Primary.background}!important`,
    fontWeight: "400!important",
    fontSize: 13,
    minWidth: 60,
    textTransform: "none",
    borderRadius: 6,
    padding: theme.spacing(1.5, 8),
  },
  pagination: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(3),
    alignItems: "center",
    "& .MuiPagination-root": {
      backgroundColor: theme.palette.background.white,
      padding: theme.spacing(1),
    },
    "& .MuiButtonBase-root": {
      fontWeight: 600,
    },
    "& .MuiButtonBase-root.Mui-selected": {
      backgroundColor: theme.palette.Primary.background,
      borderRadius: 0,
    },
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .MuiCircularProgress-root": {
      color: theme.palette.Primary.background,
    },
  },
  tableTitle: {
    fontWeight: 700,
    fontSize: 18,
    textTransform: "capitalize",
  },
  tableTopic: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 20,
    alignItems: "center",
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor: theme.palette.Primary.contrastText,
    },
    marginRight: theme.spacing(5),
    marginLeft: theme.spacing(5),
  },
  tableContainer: {
    padding: 0,
    width: "100%",
    maxHeight: "calc(100vh - 120px)",
    background: theme.palette.background.white,
    marginTop: theme.spacing(5),
  },
  signalAdded: {
    backgroundColor: theme.palette.status.added,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1),
  },
  signalApproved: {
    backgroundColor: theme.palette.status.approved,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1),
  },
  signalReview: {
    backgroundColor: theme.palette.status.review,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1),
  },
  signalPending: {
    backgroundColor: theme.palette.status.pendingApproval,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1),
  },
  signalAudited: {
    backgroundColor: theme.palette.status.audited,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1),
  },
  signalVerified: {
    backgroundColor: theme.palette.status.verified,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1),
  },
  icon: {
    marginLeft: theme.spacing(10),
    color: theme.palette.Primary.background,
    cursor: "pointer",
    "&:hover": {
      background: "white",
      borderRadius: "50%",
    },
  },
  popUp: {
    margin: "auto",
  },
  buttonContainerReview: {
    display: "flex",
    justifyContent: "space-around",
    paddingTop: 20,
    paddingBottom: 15,
    width: "50%",
    margin: "auto",
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    fontWeight: 400,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
    width: "fit-content",
    height: 37,
    fontSize: 14,
  },
  validateButton: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    fontWeight: 400,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
    width: "fit-content",
    height: 37,
    fontSize: 14,
    marginRight: theme.spacing(5),
  },
  textField: {
    backgroundColor: theme.palette.background.white,
  },
  noComments: {
    float: "left",
    fontFamily: "Arial, sans-serif",
    fontSize: 12,
  },
  maxCharacters: {
    float: "right",
    fontSize: 12,
  },
  textLimit: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 10,
  },
  reviewButtons: {
    marginRight: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    width: "20%",
  },
  dialog: {
    width: "40% !important",
    textAlign: "center",
  },
  tableSubTitle: {
    fontWeight: 500,
    fontSize: 16,
    textTransform: "capitalize",
  },
  subHeads: {
    marginTop: "10px",
    marginBottom: "10px",
  },
}));

export default useStyles;
