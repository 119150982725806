import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Typography, Box, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { months, sampleYear } from "../../../constants";
import { STATUS } from "../../../redux/constants";
import { addMobileCombustionValidation } from "./schema";
import {
  addFranchise,
  addMobileCombustion,
  getEmissionFuelList,
  resetAddCombustionStatus,
} from "../../../redux/actions";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import CeroButton from "../../../components/CeroButton";
import CeroSelect from "../../../components/CeroSelect";
import CeroInput from "../../../components/CeroInput";
import useStyles from "./styles";
import DownloadIcon from "@mui/icons-material/Download";
import EmissionUploadDrawer from "../../EmissionList/EmissionUploadDrawer";
import { useLocation, useNavigate } from "react-router-dom";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CeroItemPair from "../../../components/CeroItemPair";
import FileUpload from "../FileUpload";

const AddFranchise = (props) => {
  const { onCancel, onDownload, onUpload, onValidate, uploadDisabled } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const isCalculateDone = useSelector(
    (state) => state.emission.addFranchise.isCalculateDone
  );
  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );
  const activityTypesData = useSelector(
    (state) => state.emission.mobileCombustionInputs.data.activity_types
  );
  const fuelSourceData = useSelector(
    (state) => state.emission.mobileCombustionInputs.data.fuel_sources
  );
  const customFuelSourceData = useSelector(
    (state) => state.emission.mobileCombustionInputs.data.custom_fuel_sources
  );
  const vehicleTypeData = useSelector(
    (state) => state.emission.mobileCombustionInputs.data.vehicle_types
  );
  const fuelUnitData = useSelector(
    (state) => state.emission.mobileCombustionInputs.data.units
  );
  const addEmissionData = useSelector(
    (state) => state.emission.addFranchise
  );
  const emissionPerformData = useSelector((state) => state.emission.fuelList);
  const currentYear = new Date().getFullYear().toString();
  const [selectedFile, setSelectedFile] = useState(null);

  const formik = useFormik({
    initialValues: {
      facility: "",
      year: currentYear,
      month: "",
      calculationMethod: "",
      averagedatamethod: "",
      averageDataSubMethod:"",
      buildingTypeUnit: "",
      scope1Emission: "",
      scope2Emission: "",
      numberofbuilding:"",
      averageemissionfactor: "",
      totalFloorSpace: "",
      buildingType:"",
      franchisename:"",
      country:"",
      city:"",
    },
    validationSchema: addMobileCombustionValidation,
    onSubmit: () => {},
  });

  const selectedUnitType = activityTypesData.find(
    (item) => item.id === formik.values.activityType
  );


  const calculationMap = {};
  const calculationInputList =
    emissionPerformData?.data?.calculation_method?.map((item) => {
      calculationMap[item.id] = item.name.toLowerCase();
      return {
        key: item.id,
        value: item.name,
      };
    });
  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));
  const activityType = activityTypesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const averageMethodMap = {};

  const averageMethodList = emissionPerformData?.data?.average_data_types?.map(
    (item) => {
      averageMethodMap[item.id] = item.name.toLowerCase();
      return {
        key: item.id,
        value: item.name,
      };
    }
  );
  const buildingTypeList = emissionPerformData?.data?.building_types?.map(
    (item) => {
      return {
        key: item.id,
        value: item.name,
      };
    }
  );

  const buildingTypeUnitList =
    emissionPerformData?.data?.units?.map((item) => {
      return {
        key: item.name,
        value: item.name,
      };
    });

  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));

//   const calculationNamesMap = {};
//   const calculationInputList =
//     emissionPerformData?.data?.calculation_method?.map((item) => {
//       calculationNamesMap[item.id] = item.name; // Populate namesMap
//       return {
//         key: item.id,
//         value: item.name,
//       };
//     }) || [];
 
  useEffect(() => {
    dispatch(getEmissionFuelList("franchises"));
    return () => {
      dispatch(resetAddCombustionStatus());
    };
  }, [dispatch]);

//   useEffect(() => {
//     dispatch(getCountryList());
//   }, [dispatch]);

  useEffect(() => {
    if (addEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar("Franchises added successfully", {
        variant: "success",
      });
      dispatch(resetAddCombustionStatus());
      onCancel();
    } else if (addEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar(
        addEmissionData.message.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
    }
  }, [addEmissionData, dispatch, enqueueSnackbar, onCancel]);

  const onCalculate = () => {
    const requestData = {
      facility_id: formik.values.facility,
      year: formik.values.year,
      month: formik.values.month,
      calculation_method: formik.values.calculationMethod,
      scope_1_emissions:formik.values.scope1Emission,
      scope_2_emissions:formik.values.scope2Emission,
      type_of_average_data_method: formik.values.averageDataSubMethod,
      number_of_buildings: formik.values.numberofbuilding,
      average_emission_factor_of_building_type:formik.values.averageemissionfactor,
      total_floor_space:formik.values.totalFloorSpace,
      building_type_id:formik.values.buildingType,
      building_type_unit: formik.values.buildingTypeUnit,
      franchise_name:formik.values.franchisename ,
      country_id:formik.values.country ,
      city:formik.values.city ,
      save: false,
      file: selectedFile,
    };
    dispatch(addFranchise(requestData));
  };

  const onAddMobileCombustionData = () => {
    const requestData = {
        facility_id: formik.values.facility,
        year: formik.values.year,
        month: formik.values.month,
        calculation_method: formik.values.calculationMethod,
        scope_1_emissions:formik.values.scope1Emission,
        scope_2_emissions:formik.values.scope2Emission,
        type_of_average_data_method: formik.values.averageDataSubMethod,
        number_of_buildings: formik.values.numberofbuilding,
        average_emission_factor_of_building_type:formik.values.averageemissionfactor,
        total_floor_space:formik.values.totalFloorSpace,
        building_type_id:formik.values.buildingType,
        building_type_unit: formik.values.buildingTypeUnit,
        franchise_name:formik.values.franchisename ,
        country_id:formik.values.country ,
        city:formik.values.city,
        save: true,
        file: selectedFile,
    };
    sessionStorage.setItem("year", requestData.year);
    dispatch(addFranchise(requestData));
  };
  const averageUnitInputList = emissionPerformData?.data?.mass_unit?.map(
    (item) => ({
      key: item.name,
      value: item.name,
    })
  );
//   const buildingUnitInputList = emissionPerformData?.data?.building_type_unit?.map(
//     (item) => {
//       return {
//         key: item.id,
//         value: item.name,
//       };
//     }
//   );
//   const countryListData = useSelector(
//     (state) => state.emission.countryList
//   );

//   const countryOptions = countryListData?.list_of_countries.map((item) => ({
//     key: item.code,  // or item.id, based on backend
//     value: item.name,
//   }));
  
//   const buildingtypeid = emissionPerformData?.data?.type_of_building?.map(
//     (item) => ({
//       key: item.name,
//       value: item.name,
//     })
//   );

 const countryList = emissionPerformData?.data?.countries?.map(
    (item) => ({
      key: item.id,
      value: item.name,
    })
  );

  const [isOpenEmissionTypeUpload, setIsOpenEmissionTypeUpload] =
    useState(false);
  const onCloseDrawerUpload = () => {
    setIsOpenEmissionTypeUpload(false);
  };
  const handleFileUpload = (file) => {
    setSelectedFile(file);
    // dispatch(uploadEmissionAttachement("emissionId", file));
  };

  const handleFileRemove = () => {
    setSelectedFile(null);
  };
  const info = () => {
    return (
      <div>
        <Typography variant="body2">
          Fuel use: Mobile combustion by the usage of fuel for mobile operations
          such as transport.
          <br />
          Distance activity: Distance travelled by the usage of fuel
        </Typography>
      </div>
    );
  };

  return (
    <>
      <div className={classes.backContainer}>
        <div
          className={classes.backButtonDiv}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIosIcon />
          Back
        </div>
        <div className={classes.templateButtonDiv}>
        </div>
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
        <Typography className={classes.title} variant="h6" component="div">
  Add Franchises
</Typography>

<Box className={classes.topContainer}>
  <Grid className={classes.dynamicBoard}>
    {/* Always Visible Fields */}
    <Grid item className={classes.individualInput}>
      <CeroSelect
        required
        id="facility"
        name="facility"
        label="Facility"
        fullWidth
        options={facilitiesList}
        selectedValue={formik.values.facility}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.facility && formik.errors.facility}
      />
    </Grid>

    <Grid item className={classes.individualInput}>
      <CeroAutoComplete
        id="year"
        name="year"
        label="Assessment Year"
        onChange={(e, value) =>
          formik.setFieldValue("year", value.id)
        }
        onBlur={formik.handleBlur}
        error={formik.touched.year && formik.errors.year}
        options={yearList}
        value={formik.values.year}
      />
    </Grid>

    <Grid item className={classes.individualInput}>
      <CeroSelect
        required
        id="month"
        name="month"
        label="Month"
        fullWidth
        options={months}
        selectedValue={formik.values.month}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.month && formik.errors.month}
      />
    </Grid>
    <Grid item className={classes.individualInput}>
          <CeroInput
            required
            id="franchisename"
            name="franchisename"
            label="Franchise Name"
            value={formik.values.franchisename}
            fullWidth
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.franchisename && formik.errors.franchisename}
          />
        </Grid>

        <Grid item className={classes.individualInput}>
          <CeroSelect
            required
            id="countryid"
            name="country"
            label="Country"
            fullWidth
            options={countryList}
            selectedValue={formik.values.country}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.country && formik.errors.country}
          />
        </Grid>
        <Grid item className={classes.individualInput}>
          <CeroInput
            required
            id="city"
            name="city"
            label="City"
            value={formik.values.city}
            fullWidth
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.city && formik.errors.city}
          />
        </Grid>

    <Grid item className={classes.individualInput}>
      <CeroSelect
        required
        id="calculationMethod"
        name="calculationMethod"
        label="Calculation Method"
        fullWidth
        options={calculationInputList}
        selectedValue={formik.values.calculationMethod}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.calculationMethod && formik.errors.calculationMethod}
      />
    </Grid>

    {/* Franchise-Specific Method */}
    {calculationMap[formik.values.calculationMethod] === "franchise-specific method" && (
      <>
        <Grid item className={classes.individualInput}>
          <CeroInput
            required
            id="scope1Emission"
            name="scope1Emission"
            label="Scope 1 Emission"
            value={formik.values.scope1Emission}
            fullWidth
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.scope1Emission && formik.errors.scope1Emission}
          />
        </Grid>

        <Grid item className={classes.individualInput}>
          <CeroInput
            required
            id="scope2Emission"
            name="scope2Emission"
            label="Scope 2 Emission"
            value={formik.values.scope2Emission}
            fullWidth
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.scope2Emission && formik.errors.scope2Emission}
          />
        </Grid>
      </>
    )}

    {/* Average-Data Method */}
    {calculationMap[formik.values.calculationMethod] === "average-data method" && (
      <>
        
        
        <Grid item className={classes.individualInput}>
          <CeroSelect
            required
            id="averageDataSubMethod"
            name="averageDataSubMethod"
            label="Choose Type of average data method"
            fullWidth
            options={averageMethodList}
            selectedValue={formik.values.averageDataSubMethod}
            onChange={(e) =>
              formik.setFieldValue("averageDataSubMethod", e.target.value)
            }
            onBlur={formik.handleBlur}
            error={formik.touched.averageDataSubMethod && formik.errors.averageDataSubMethod}
          />
        </Grid>
       

        {averageMethodMap[formik.values.averageDataSubMethod] === "type1 - floor space data is available" && (
          <>
            <Grid item className={classes.individualInput}>
                    <CeroSelect
                      required
                      id="buildingType"
                      name="buildingType"
                      label="Building type"
                      fullWidth
                      options={buildingTypeList}
                      selectedValue={formik.values.buildingType || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.buildingType &&
                        formik.errors.buildingType
                      }
                    />
                  </Grid>
                  <Grid item className={classes.individualInput}>
                    <CeroSelect
                      required
                      id="buildingTypeUnit"
                      name="buildingTypeUnit"
                      label="Unit"
                      fullWidth
                      options={buildingTypeUnitList}
                      selectedValue={formik.values.buildingTypeUnit || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.buildingTypeUnit &&
                        formik.errors.buildingTypeUnit
                      }
                    />
                  </Grid>

            <Grid item className={classes.individualInput}>
              <CeroInput
                required
                id="totalFloorSpace"
                name="totalFloorSpace"
                label="Total floor space of building type"
                value={formik.values.totalFloorSpace}
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.totalFloorSpace && formik.errors.totalFloorSpace}
                type="number"
              />
            </Grid>
          </>
        )}

        {averageMethodMap[formik.values.averageDataSubMethod] === "type2 - floor space data is unavailable" && (
          <>
            <Grid item className={classes.individualInput}>
              <CeroInput
                required
                id="numberofbuilding"
                name="numberofbuilding"
                label="Number of Buildings"
                value={formik.values.numberofbuilding}
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.numberofbuilding && formik.errors.numberofbuilding}
                type="number"
              />
            </Grid>

            <Grid item className={classes.individualInput}>
              <CeroInput
                required
                id="averageemissionfactor"
                name="averageemissionfactor"
                label="Average emission factor for building type (kg CO2 e/m2/year)"
                value={formik.values.averageemissionfactor}
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.averageemissionfactor && formik.errors.averageemissionfactor}
                type="number"
              />
            </Grid>
          </>
        )}
      </>
    )}
  </Grid>


            <CeroButton
              buttonText="Calculate"
              className={classes.buttonPrimary}
              onClick={onCalculate}
              disabled={!formik.dirty || !formik.isValid}
            />
            <CeroButton
              buttonText="Clear"
              variant="outlined"
              className={classes.buttonSecondary}
              onClick={formik.resetForm}
            />
            {isCalculateDone && (
              <Box className={classes.bottomContainer}>
                <Typography
                  variant="h6"
                  component="h6"
                  className={classes.previewTitle}
                >
                  Emission Preview
                </Typography>
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  justifyContent="space-between"
                  spacing={8}
                >
                  <Grid item container direction="column" xs={12} md={6}>
                    <CeroItemPair
                      title={
                        <>
                          CO<sub>2</sub>
                        </>
                      }
                      value={`${addEmissionData.data.co2} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={
                        <>
                          CH<sub>4</sub>
                        </>
                      }
                      value={`${addEmissionData.data.ch4} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={
                        <>
                          BioFuel CO<sub>2</sub>
                        </>
                      }
                      value={`${addEmissionData.data.biofuel_co2} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                  </Grid>
                  <Grid
                    className={classes.secondResultContainer}
                    item
                    container
                    direction="column"
                    xs={6}
                  >
                    <CeroItemPair
                      title={
                        <>
                          CO<sub>2</sub>e
                        </>
                      }
                      value={`${addEmissionData.data.co2e} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={
                        <>
                          N<sub>2</sub>O
                        </>
                      }
                      value={`${addEmissionData.data.n2o} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={<>EF</>}
                      value={
                        <>
                          {addEmissionData.data.ef} kgCO<sub>2</sub>e/unit
                        </>
                      }
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
          <FileUpload
            onFileUpload={handleFileUpload}
            onFileRemove={handleFileRemove}
          />
        </Box>
        <Box className={classes.buttonContainer}>
          <CeroButton
            buttonText="Add Data"
            disabled={
              !isCalculateDone || addEmissionData.status === STATUS.RUNNING
            }
            className={classes.buttonPrimary}
            onClick={() => onAddMobileCombustionData(formik.values)}
          />

          {/* <div className={classes.buttonContainer}>
                <CeroButton
                   buttonText="Bulk Data Upload"
                   className={classes.bulkButton}
                   startIcon={<FileUploadIcon />}
                   onClick={() => setIsOpenEmissionTypeUpload(true)}
                />
                <CeroButton
                    buttonText="Download Template"
                    className={classes.bulkButton}
                    startIcon={<DownloadIcon />}
                    onClick={onDownload}
                  />
                </div> */}
        </Box>
        <EmissionUploadDrawer
          isOpen={isOpenEmissionTypeUpload}
          onClose={onCloseDrawerUpload}
          onUpload={onUpload}
          onValidate={onValidate}
          uploadDisabled={uploadDisabled}
        />
      </Container>
    </>
  );
};

export default AddFranchise;
