import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Paper, Typography } from "@mui/material";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { getEsgOverviewGraph } from "../../../redux/actions";
import { getCookie } from "../../../services/cookie";

const EsgOverviewGraph = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const esgData = useSelector(
    (state) => state.dashboard.getEsgOverviewGraph.data.response
  );

  const userRole = getCookie("role");
  if (userRole === "auditor") {
    var role = "auditor";
  } else {
    role = "business";
  }

  useEffect(() => {
    dispatch(getEsgOverviewGraph(role));
  }, [dispatch, role]);

  const capitalizeFirstLetter = (string) => {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  };

  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }
    const myChartRef = chartRef.current.getContext("2d");

    chartInstance.current = new Chart(myChartRef, {
      type: "doughnut",
      data: {
        labels:
          esgData &&
          esgData?.map((entry) => capitalizeFirstLetter(entry.category_type)),
        datasets: [
          {
            data: esgData && esgData?.map((entry) => entry.total_records),
            backgroundColor: ["#846BB5", "#6FBFBA", "#FBCE74"],
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: true,
            position: "right",
            labels: {
              usePointStyle: true,
              padding: 30,
              color: "black",
            },
          },
          datalabels: {
            color: "white",
            font: {
              weight: "bold",
              size: 12,
            },
            formatter: (value, context) => {
              return value.toFixed(0);
            },
          },
        },
        maintainAspectRatio: false,
        responsive: true,
        aspectRatio: 2,
        layout: {
          padding: {
            bottom: 20,
            left: 40,
          },
        },
      },
      plugins: [ChartDataLabels],
    });

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [esgData]);

  return (
    <Paper className={classes.container}>
      <Typography className={classes.graphTitle}>ESG Overview</Typography>
      <div className={classes.doughnutChart}>
        <canvas ref={chartRef} />
      </div>
    </Paper>
  );
};

export default EsgOverviewGraph;
