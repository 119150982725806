import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Grid,
  Typography,
  Box,
  Tabs,
  Tab,
  IconButton,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import { STATUS } from "../../../redux/constants";
import {
  resetAddCombustionStatus,
  deleteEmissions,
} from "../../../redux/actions";
import CeroButton from "../../../components/CeroButton";
import ListComments from "../ListComment";
import ListAuditTrails from "../ListAuditTrails";
import ListEmissionFiles from "../ListEmissionFiles";
import ListTicketDrawer from "../../common/ListTicketsDrawer";
import CeroConfirmDrawer from "../../../components/CeroConfirmDrawer";
import useStyles from "./styles";
import TicketIcon from "../../../assets/icons/TicketIcon.png";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { getCookie } from "../../../services/cookie";
import CeroItemPair from "../../../components/CeroItemPair";
import CeroInfoPair from "../../../components/CeroInfoPair";
import EmissionDetailsApprovalHeader from "../EmissionApprovalHeader";

const InvestmentsDetails = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const readOnlyMode = getCookie("read_only_mode") === "disable_edit";

  const role = useSelector((state) => state.auth.role);

  const {
    emissionId,
    emissionData,
    onCancel,
    isDeleteEnable,
    setIsDrawerOpen,
    isAuditor,
    company,
    subType,
    approvalId,
  } = props;

  const [value, setValue] = useState(0);
  const [showTickets, setShowTickets] = useState(false);
  const [displayWarning, setDisplayWarning] = useState(false);

  const deleteEmissionData = useSelector(
    (state) => state.emission.deleteEmissions
  );

  useEffect(() => {
    if (deleteEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar("Investments record deleted successfully", {
        variant: "success",
      });
      dispatch(resetAddCombustionStatus());
      onCancel();
    } else if (deleteEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar(
        deleteEmissionData.message.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetAddCombustionStatus());
    }
  }, [deleteEmissionData, enqueueSnackbar, onCancel, dispatch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onConfirmDelete = () => {
    const requestData = {
      id: emissionId,
    };
    dispatch(deleteEmissions(requestData));
  };

  const onclickShowTickets = () => {
    setShowTickets(true);
  };

  const onCloseTickets = () => {
    setShowTickets(false);
  };

  const onUpdatePurchasedElectricity = () => {
    navigate(`/emissions/edit/investments/${emissionId}`);
  };
  const monthDict = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };
  return (
    <>
      <div className={classes.headerContainer}>
        <div
          className={classes.backContainer}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIosIcon />
          Back
        </div>
        <Box className={classes.alignButton}>
          <EmissionDetailsApprovalHeader
            emissionId={emissionId}
            can_review_action_do={emissionData.can_review_action_do}
            subType={subType}
            approvalId={approvalId}
          />
          <CeroButton
            buttonText="Raise a ticket"
            className={classes.buttonPrimary}
            onClick={() => setIsDrawerOpen(true)}
            disabled={readOnlyMode}
          />
          <IconButton onClick={onclickShowTickets} disabled={readOnlyMode}>
            <img src={TicketIcon} alt="Logo" width="25" height="25" />
          </IconButton>
        </Box>
      </div>
      <Container className={classes.container}>
  <Box className={classes.innerContainer}>
    <Box className={classes.header}>
      <Typography variant="h6" component="div">
        Investments
      </Typography>
    </Box>
    <Box className={classes.topContainer}>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        justifyContent="space-between"
        display="contents"
        spacing={12}
      >
        <Grid item container direction="column" display="contents" xs={10}>
          <Box className={classes.actionBox}>
            <CeroInfoPair
              title="Status:"
              value={
                emissionData.status
                  ? emissionData.status === "pending_approval"
                    ? "pending approval"
                    : emissionData.status
                  : "NA"
              }
              classes={{
                container: classes.statusContainer,
                title: classes.statusTitle,
                value:
                  emissionData.status === "In Progress" ||
                  emissionData.status === "pending_approval"
                    ? classes.Inprogress
                    : classes[emissionData?.status],
              }}
            />
          </Box>

          <CeroItemPair
            title="Facility:"
            value={emissionData.facility_name || "NA"}
            classes={{
              container: classes.infoContainer,
              title: classes.diversityTitle,
              value: classes.value,
            }}
          />

          <CeroItemPair
            title="Month and Year:"
            value={
              emissionData.year && emissionData.month
                ? `${monthDict[emissionData.month]} ${emissionData.year}`
                : "NA"
            }
            classes={{
              container: classes.infoContainer,
              title: classes.diversityTitle,
              value: classes.value,
            }}
          />

          <CeroItemPair
            title="Types of financial investment:"
            value={emissionData.investment_type_name || "NA"}
            classes={{
              container: classes.infoContainer,
              title: classes.diversityTitle,
              value: classes.value,
            }}
          />

          <CeroItemPair
            title="Calculation Method:"
            value={emissionData.calculation_method_name || "NA"}
            classes={{
              container: classes.infoContainer,
              title: classes.diversityTitle,
              value: classes.value,
            }}
          />

          {/* Investment-specific Method */}
          {emissionData?.calculation_method_name?.toLowerCase() ===
            "investment-specific method" && (
            <>
              <CeroItemPair
                title="Scope 1 emissions of equity investments"
                value={emissionData.scope1_emissions || "NA"}
                classes={{
                  container: classes.infoContainer,
                  title: classes.diversityTitle,
                  value: classes.value,
                }}
              />
              <CeroItemPair
                title="Unit"
                value={emissionData.scope1_unit || "NA"}
                classes={{
                  container: classes.infoContainer,
                  title: classes.diversityTitle,
                  value: classes.value,
                }}
              />
              <CeroItemPair
                title="Scope 2 emissions of equity investments"
                value={emissionData.scope2_emissions || "NA"}
                classes={{
                  container: classes.infoContainer,
                  title: classes.diversityTitle,
                  value: classes.value,
                }}
              />
              <CeroItemPair
                title="Unit"
                value={emissionData.scope1_unit || "NA"}
                classes={{
                  container: classes.infoContainer,
                  title: classes.diversityTitle,
                  value: classes.value,
                }}
              />
              <CeroItemPair
                title="Share of equity (%)"
                value={emissionData.share_percentage || "NA"}
                classes={{
                  container: classes.infoContainer,
                  title: classes.diversityTitle,
                  value: classes.value,
                }}
              />
            </>
          )}

          {/* Average-data Method - Equity Investments */}
          {emissionData?.calculation_method_name?.toLowerCase() ===
            "average-data method" &&
            emissionData?.investment_type_name === "Equity Investments" && (
              <>
                <CeroItemPair
                  title="Sector related to investment:"
                  value={emissionData.sector_name || "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.diversityTitle,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Industry:"
                  value={emissionData.industry_name || "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.diversityTitle,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Industry Subcategory:"
                  value={emissionData.industry_subcategory_name || "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.diversityTitle,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Investee company total revenue:"
                  value={emissionData.total_revenue || "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.diversityTitle,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Currency:"
                  value={emissionData.currency || "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.diversityTitle,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Share of equity (%)"
                  value={emissionData.share_percentage || "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.diversityTitle,
                    value: classes.value,
                  }}
                />
              </>
            )}

          {/* Average-data Method - Project Finance & Debt Investments */}
          {emissionData?.calculation_method_name?.toLowerCase() ===
            "average-data method" &&
            emissionData?.investment_type_name ===
              "Project Finance & Debt Investments" && (
              <>
                <CeroItemPair
                  title="Project construction/operating sector:"
                  value={emissionData.sector_name || "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.diversityTitle,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Industry:"
                  value={emissionData.industry_name || "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.diversityTitle,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Industry Subcategory:"
                  value={emissionData.industry_subcategory_name || "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.diversityTitle,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Project construction cost/revenue in the reporting year($):"
                  value={emissionData.total_revenue || "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.diversityTitle,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Currency:"
                  value={emissionData.currency || "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.diversityTitle,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Share of total project costs (%)"
                  value={emissionData.share_percentage || "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.diversityTitle,
                    value: classes.value,
                  }}
                />
              </>
            )}

          {/* Project-specific Method */}
          {emissionData?.calculation_method_name?.toLowerCase() ===
            "project-specific method" && (
            <>
              <CeroItemPair
                title="Scope 1 emissions of relevant projects"
                value={emissionData.scope1_emissions || "NA"}
                classes={{
                  container: classes.infoContainer,
                  title: classes.diversityTitle,
                  value: classes.value,
                }}
              />
              <CeroItemPair
                title="Unit"
                value={emissionData.scope1_unit || "NA"}
                classes={{
                  container: classes.infoContainer,
                  title: classes.diversityTitle,
                  value: classes.value,
                }}
              />
              <CeroItemPair
                title="Scope 2 emissions of relevant projects"
                value={emissionData.scope2_emissions || "NA"}
                classes={{
                  container: classes.infoContainer,
                  title: classes.diversityTitle,
                  value: classes.value,
                }}
              />
              <CeroItemPair
                title="Unit"
                value={emissionData.scope1_unit || "NA"}
                classes={{
                  container: classes.infoContainer,
                  title: classes.diversityTitle,
                  value: classes.value,
                }}
              />
              <CeroItemPair
                title="Share of equity (%)"
                value={emissionData.share_percentage || "NA"}
                classes={{
                  container: classes.infoContainer,
                  title: classes.diversityTitle,
                  value: classes.value,
                }}
              />
            </>
          )}

          {/* Lifetime Emissions */}
          {emissionData?.calculation_method_name?.toLowerCase() ===
            "lifetime-emissions" && (
            <>
              <CeroItemPair
                title="Projected annual emissions of project"
                value={emissionData.projected_annual_emissions || "NA"}
                classes={{
                  container: classes.infoContainer,
                  title: classes.diversityTitle,
                  value: classes.value,
                }}
              />
              <CeroItemPair
                title="Unit"
                value={emissionData.projected_annual_emissions_unit|| "NA"}
                classes={{
                  container: classes.infoContainer,
                  title: classes.diversityTitle,
                  value: classes.value,
                }}
              />
              <CeroItemPair
                title="Projected lifetime of projects"
                value={emissionData.project_lifetime || "NA"}
                classes={{
                  container: classes.infoContainer,
                  title: classes.diversityTitle,
                  value: classes.value,
                }}
              />
              <CeroItemPair
                title="Share of total project costs(%)"
                value={emissionData.share_percentage || "NA"}
                classes={{
                  container: classes.infoContainer,
                  title: classes.diversityTitle,
                  value: classes.value,
                }}
              />
            </>
          )}
                {emissionData?.audited_by && (
                  <>
                    <CeroItemPair
                      title="Audited by:"
                      value={
                        emissionData.audited_by ? emissionData.audited_by : "NA"
                      }
                      classes={{
                        container: classes.infoContainer,
                        title: classes.diversityTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title="Audited on:"
                      value={
                        emissionData.audited_on
                          ? dayjs(emissionData.audited_on).format("DD/MM/YYYY")
                          : "NA"
                      }
                      classes={{
                        container: classes.infoContainer,
                        title: classes.diversityTitle,
                        value: classes.value,
                      }}
                    />
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.bottomContainer}>
            <Typography
              variant="h6"
              component="h6"
              className={classes.previewTitle}
            >
              Emission
            </Typography>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" xs={6}>
                <Typography className={classes.previewItem}>
                  CO<sub>2</sub>: {emissionData?.co2} tonnes
                </Typography>
                <Typography className={classes.previewItem}>
                  CH<sub>4</sub>: {emissionData?.ch4} tonnes
                </Typography>
                <Typography className={classes.previewItem}>
                  BioFuel CO<sub>2</sub>: {emissionData.biofuel_co2} tonnes
                </Typography>
              </Grid>
              <Grid item container direction="column" xs={6}>
                <Typography className={classes.previewItem}>
                  CO<sub>2</sub>e: {emissionData?.co2e} tonnes
                </Typography>
                <Typography className={classes.previewItem}>
                  N<sub>2</sub>O: {emissionData?.n2o} tonnes
                </Typography>
                <Typography className={classes.previewItem}>
                  EF: {emissionData?.ef} kgCO<sub>2</sub>e/unit
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className={classes.buttonContainer}>
          {isDeleteEnable && (
            <CeroButton
              buttonText={<DeleteOutlineIcon />}
              className={clsx(classes.button, classes.deleteButton)}
              onClick={() => setDisplayWarning(true)}
              disabled={readOnlyMode}
            />
          )}
          {emissionData?.is_able_to_update === true && (
            // (emissionData.status === "added" ||
            //   emissionData.status === "review") &&
            //   (isFacMgr || isSusManager) && (
            <CeroButton
              buttonText="Update"
              className={clsx(classes.button, classes.buttonPrimary)}
              onClick={onUpdatePurchasedElectricity}
              disabled={readOnlyMode}
            />
          )}
        </Box>
        <Box
          className={classes.tabContainer}
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <Tabs
            value={value}
            className={classes.tabs}
            onChange={handleChange}
            aria-label="emission tabs"
          >
            <Tab label="Comments" id="comments" />
            <Tab label="Attachment" id="files" />
            <Tab label="Change Logs" id="audit-history" />
          </Tabs>
        </Box>
        {value === 0 && (
          <ListComments
            emissionId={emissionId}
            isAuditor={isAuditor}
            company={company}
          />
        )}
        {value === 2 && (
          <ListAuditTrails
            emissionId={emissionId}
            isAuditor={isAuditor}
            company={company}
          />
        )}
        {value === 1 && (
          <ListEmissionFiles
            emissionId={emissionId}
            isAuditor={isAuditor}
            company={company}
          />
        )}
        {displayWarning && (
          <CeroConfirmDrawer
            isOpen={displayWarning}
            onClose={() => setDisplayWarning(false)}
            onConfirm={onConfirmDelete}
          />
        )}
        {showTickets && (
          <ListTicketDrawer
            isOpen={showTickets}
            scope="emission"
            scopeId={emissionId}
            onClose={onCloseTickets}
          />
        )}
      </Container>
    </>
  );
};

export default InvestmentsDetails;
